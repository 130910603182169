import { Domains, EventTypes } from '@mfe/services/window-messages';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, loginFromUrl } from '@mfe/to-be-migrated/redux/auth';
import { postMessage } from '@mfe/to-be-migrated/redux/utils';

export const UserProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {
    user: { auth },
  } = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.isAuthenticated) dispatch(loginFromUrl());
    else {
      dispatch(
        postMessage({
          domain: Domains.General,
          eventType: EventTypes.Loaded,
        })
      );

      //Re-adding Domain Usage to avoid breaking salesforce integration in production.
      //We can remove this later when the codes in production are expecting domain as general
      dispatch(
        postMessage({
          domain: Domains.Usage,
          eventType: EventTypes.Loaded,
        })
      );
    }
  }, [dispatch, auth]);

  return children;
};
