import { TokenType } from '@mfe/shared/schema-types';

export const DEFAULT_VENDOR = 'com.viasat.care.usage';

export type DisplayPlatform = 'salesforce' | 'myviasat' | 'IVR';
export enum DisplayLocationEnum {
  Header = 'header',
  Body = 'body',
  Footer = 'footer',
}

export const getDisplayPlatform = (
  tokenType: TokenType | undefined
): DisplayPlatform =>
  tokenType === TokenType.Salesforce ? 'salesforce' : 'myviasat';
