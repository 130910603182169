import { AnalyticsContext, SelfDescribingEvent } from '@mfe/services/analytics';

export type ContextData =
  | ContextUiVersion
  | (ContextUiVersion & ContextProductData);

type ContextUiVersion = {
  ui_version: 'hornet';
};

export type ContextProductData = {
  product_name: string;
  product_price: string;
  product_type_id: string;
};

export type Context = Omit<AnalyticsContext, 'data'> & {
  data: ContextData;
};

export type AnalyticsEvent<Data = void> = Omit<
  SelfDescribingEvent,
  'data' | 'context'
> & {
  data?: Data;
  context?: Context[];
};

export enum AnalyticsEventName {
  BUY_MORE_CONTEXT = 'buymore_context',
  BUY_MORE_SELECTED = 'buy_more_selected',
  BUY_MORE_INITIATED = 'buy_more_initiated',
  BUY_MORE_ORDER_FAILED = 'buy_more_order_failed',
  BUY_MORE_ORDER_SUBMITTED = 'buy_more_order_submitted',
  BUY_MORE_ORDER_SUCCESSFUL = 'buy_more_order_successful',
  BUY_MORE_INITIALIZATION_FAILED = 'buy_more_initialization_failed',
}

export enum AnalyticsEventSourceEnum {
  USAGE_PAGE = 'usage_page',
  USAGE_PAGE_RETRY = 'usage_page_retry',
  PLAN_DETAILS = 'plan_details', // TODO: This should be removed because this modal no longer exists
  // TODO: An entry for the overview page should instead be added
}

export type AnalyticsEventSource = `${AnalyticsEventSourceEnum}`;

export type EventSourceProperty = {
  source: AnalyticsEventSource;
};

export type EventFailureProperty = {
  failure_reason: string;
};

export type AnalyticsVendor = 'com.viasat.care.buymore';
export type EventSchemaVersion = '1-0-0' | '1-0-1';
export type ContextSchemaVersion = '1-0-1';
