import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Platform } from '@mfe/shared/schema-types';
import { selectConfig } from '@mfe/shared/redux/config';
import { EventTypes } from '@mfe/services/window-messages';
import { ModalTypeEnum } from '@mfe/shared/components';
import { postUsageMessage } from '@mfe/to-be-migrated/redux/utils';
import { refetchBuyMoreHistory } from '@mfe/to-be-migrated/redux/buyMoreHistory';
import { refetchUsage } from '@mfe/to-be-migrated/redux/plan';
import { useModalContext } from '@mfe/shared/components';

import { ErrorEnum } from './utils/types';

const BuyMoreModalContent = React.lazy(() => import('./containers/BuyMore'));

const DialogContent: React.FC = () => {
  const {
    dialogType,
    dialogTypeHandlers: { resetDialogType },
  } = useModalContext();

  const dispatch = useDispatch();

  const config = useSelector(selectConfig);

  const isBuyMoreOpen = dialogType === ModalTypeEnum.BuyMore;

  const onBuyMoreClose = React.useCallback(
    (isSuccess: boolean, error?: ErrorEnum) => {
      resetDialogType();

      if (isSuccess || error === ErrorEnum.PURCHASE_TRANSACTION_ERROR) {
        dispatch(refetchUsage());
        dispatch(refetchBuyMoreHistory());
        if (config.platform !== Platform.Web) {
          dispatch(postUsageMessage({ eventType: EventTypes.RefetchPlanInfo }));
        }
      }
    },
    [dispatch, resetDialogType]
  );

  if (dialogType !== ModalTypeEnum.BuyMore) {
    return null;
  }

  return (
    <BuyMoreModalContent
      isModalOpen={isBuyMoreOpen}
      handleClose={onBuyMoreClose}
    />
  );
};

export default DialogContent;
