import i18n, { FormatFunction } from 'i18next';
import numeral from 'numeral';
import { get } from 'lodash';
import { format as dateFnsFormat, isValid } from 'date-fns';
import { enUS, ptBR, es, it } from 'date-fns/locale';
import { userLocaleFetcher } from './localeFetcher';
import { getDateFormat } from './formats';
import 'numeral/locales';

const dateFnsLocaleLookup = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es,
  'it-IT': it,
};

const numeralLocaleLookup = {
  'en-US': 'en',
  'pt-BR': 'pt-br',
  'es-419': 'es',
  'it-IT': 'it',
};

const getLocales = (): {
  dateFnsLocale: typeof enUS | typeof ptBR | typeof es | typeof it;
  numeralLocale: string;
} => {
  // For datefns we actually want the users language not locale
  // as datefns will translate the date based on the language we pass in
  const dateFnsLocale = get(dateFnsLocaleLookup, i18n.language, enUS);
  // For numeral we do actually want the locale as formatting for things
  // like prices and numbers change based off locale not language
  const numeralLocale = get(
    numeralLocaleLookup,
    userLocaleFetcher.locale,
    'en'
  );

  return { dateFnsLocale, numeralLocale };
};

const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const format: FormatFunction = (value: any, format?: string): string => {
  if (format === undefined) return value as string;
  if (typeof value === 'string') {
    const f = format.toLowerCase();
    if (f === 'uppercase') return value.toUpperCase();
    if (f === 'lowercase') return value.toLowerCase();
    if (f === 'titlecase') return toTitleCase(value);
  }

  const { dateFnsLocale, numeralLocale } = getLocales();
  if (typeof value === 'number') {
    numeral.locale(numeralLocale);
    return numeral(value).format(format);
  }

  if (isValid(new Date(value))) {
    try {
      const dateFormat = getDateFormat(userLocaleFetcher.locale, format as any);
      return dateFnsFormat(new Date(value), dateFormat, {
        locale: dateFnsLocale,
      });
    } catch {
      console.error(
        `Error: date format invalid. Replace with locale date format. Format: ${format}`
      );
      return dateFnsFormat(value, format, { locale: dateFnsLocale });
    }
  } else console.error(`Error: Cannot format value: ${value}`);

  return value as string;
};

export default format;
