import { gql } from '@apollo/client';
import { call, put } from 'redux-saga/effects';
import { postMessage } from '../utils';
import { graphqlMutation } from '@mfe/shared/redux/graphql';
import { Mutation } from '@mfe/shared/schema-types';
import { Domains, AuthEventTypes } from '@mfe/services/window-messages';
import { setError, clearError } from '../error';
import { refetchUserInfo } from '../userInfo';

export const DELETE_USERNAME = gql`
  mutation deleteUsername($partyId: ID!, $typeName: ID!, $value: String!) {
    deleteUsername(partyId: $partyId, typeName: $typeName, value: $value) {
      typeName
      value
      errorMessage
    }
  }
`;

export interface DeleteUsernameArgs {
  username: string;
  partyId: string;
}

export function* deleteUsernameMutation({
  payload: { username, partyId },
}: {
  type: string;
  payload: DeleteUsernameArgs;
}) {
  yield put(
    postMessage({
      domain: Domains.General,
      eventType: AuthEventTypes.DeleteUsernameStarted,
    })
  );
  yield put(clearError({}));
  const typeName = 'viasat_my_sso_ldap';
  const mutationResponse: Mutation = yield call(graphqlMutation, {
    mutation: DELETE_USERNAME,
    variables: {
      partyId,
      typeName,
      value: username,
    },
    fetchPolicy: 'no-cache',
  });

  const errorMessage = mutationResponse.deleteUsername?.errorMessage;

  if (!errorMessage) {
    yield put(
      postMessage({
        domain: Domains.General,
        eventType: AuthEventTypes.DeleteUsernameSuccess,
      })
    );

    yield put(refetchUserInfo());
  } else {
    yield put(
      setError({
        operation: 'deleteUsername',
        message: [
          `deleteUsername attempt failed.`,
          `PartyId: ${partyId}`,
          `ExternalId type: ${typeName}`,
          `ExternalId value: ${username}`,
        ],
      })
    );

    yield put(
      postMessage({
        domain: Domains.General,
        eventType: AuthEventTypes.DeleteUsernameFailure,
        data: {
          errorMessage,
        },
      })
    );
  }
}
