import { gql } from '@apollo/client';
import { Query } from '@mfe/shared/schema-types';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ConfigState,
  setConfig,
  fetchConfig as fetchConfigAction,
  getMaintenanceStatus as getMaintenanceStatusAction,
} from './slice';
import { graphqlQuery } from '@mfe/shared/redux/graphql';

export const GET_CONFIG = gql`
  query getConfig {
    config {
      analyticsUrl
      env
      vppUrl
      aemBaseUrl
      aemContentBaseUrl

      ...LaunchDarklyFlags
    }
  }

  fragment LaunchDarklyFlags on ConfidantConfig {
    allowAnalytics
    showChangePlanSMB
    showChat
    showDevicesGraph
    showDonutChart
    inMaintenance
    showACPBanner
    showOverviewRedesign
    showBroadbandLabels
    showNetworkPage
    showUnleashedUsage
    getMockTier
    setACHasDefault
    showAddonsShop
    disableResidentialAddons
    disableSmbAddons
    showRemoveAddonFlow
  }
`;

export const GET_MAINTENANCE_STATUS = gql`
  query getMaintenanceStatus {
    getMaintenanceStatus {
      inMaintenance
    }
  }
`;

export function* fetchConfig() {
  const data: Query = yield call(graphqlQuery, {
    query: GET_CONFIG,
  });

  yield put(setConfig({ ...data.config, loadingConfig: false } as ConfigState));
}

export function* getMaintenance() {
  const data: Query = yield call(graphqlQuery, {
    query: GET_MAINTENANCE_STATUS,
  });

  yield put(
    setConfig({
      inMaintenance: data.getMaintenanceStatus.inMaintenance,
    } as ConfigState)
  );
}

export function* watchConfig() {
  yield all([
    takeLatest(fetchConfigAction.type, fetchConfig),
    takeLatest(getMaintenanceStatusAction.type, getMaintenance),
  ]);
}
