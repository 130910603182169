import { createAction, createSlice } from '@reduxjs/toolkit';

import { WorkOrder } from '@mfe/shared/graphql/FO/schemaTypes';
import { SummarizedWorkOrder } from '@mfe/shared/schema-types';

import { filterByWorkOrderType, WorkOrderTypes } from '../../serverUtils';

type WorkOrderState = {
  workOrders: WorkOrder[] | null;
  loading: boolean;
  error: null | boolean | string;
  installDate: string | null;
};

const initialState: WorkOrderState = {
  workOrders: null,
  loading: false,
  error: null,
  installDate: null,
};

export const workOrdersSlice = createSlice({
  name: 'workOrders',
  initialState,
  reducers: {
    getWorkOrders: (state) => {
      state.loading = true;
    },
    setWorkOrders: (state, action) => {
      const installWorkOrders = filterByWorkOrderType(action.payload, [
        WorkOrderTypes.INSTALL,
        WorkOrderTypes.COMMERCIAL_INSTALL,
      ]);

      const installDate =
        (installWorkOrders?.[0]?.schedule?.from as string) ?? null;

      state.loading = false;
      state.workOrders = action.payload;
      state.installDate = installDate;
    },
    setWorkOrdersError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const displayServiceCallAlert = createAction<SummarizedWorkOrder>(
  'workOrders/displayServiceCallAlert'
);

export const { getWorkOrders, setWorkOrders, setWorkOrdersError } =
  workOrdersSlice.actions;

export const selectWorkOrders = (state: { workOrders: WorkOrderState }) =>
  state.workOrders;
