import { gql } from '@apollo/client';

export const GET_AGGREGATED_BILLING_INFO = gql`
  query getAggregatedBillingInfo(
    $refetchData: Boolean = true
    $includeBillingAccount: Boolean = true
    $includeAutoPay: Boolean = true
    $includeInvoices: Boolean = true
    $nextBillPeriodStartDate: String
  ) {
    getAutopayMethod(refetchData: $refetchData) @include(if: $includeAutoPay) {
      useForRecurringPayment
      paymentProduct
      paymentMethod
      nameOnCard
      last4Digits
      isExpired
      displayName
      ccType
      ccExpYear
      ccExpMonth
      id
    }
    getBillingAccount(refetchData: $refetchData)
      @include(if: $includeBillingAccount) {
      accountNumber
      accountStatus
      balance {
        value
        text
        alphabeticCode
      }
      billingStatus
      billDate
      creditClass
      dueDate
      invoiceAmount {
        value
        text
        alphabeticCode
      }
      payDate
      paymentDueDays
      billingCycleDayOfMonth
      nextBillPeriodStartDate
    }
    getInvoices(
      refetchData: $refetchData
      nextBillPeriodStartDate: $nextBillPeriodStartDate
    ) @include(if: $includeInvoices) {
      dueDate
      electronicInvoiceAvailable
      invoiceNumber
      invoicePDFUrl
      statementDate
      invoiceAmount {
        value
        text
        alphabeticCode
      }
      amountOwedOnInvoice {
        value
        text
        alphabeticCode
      }
      billingCycleDate
      invoiceStatus
      invoiceSettlementStatus
    }
  }
`;

export const AUTOPAY_REFETCH_TRUE_FRAGMENT = gql`
  fragment AutopayMethodRefetchTrue on Query {
    getAutopayMethod(refetchData: true) {
      useForRecurringPayment
      paymentProduct
      paymentMethod
      nameOnCard
      last4Digits
      isExpired
      displayName
      ccType
      ccExpYear
      ccExpMonth
      id
    }
  }
`;

export const AUTOPAY_REFETCH_FALSE_FRAGMENT = gql`
  fragment AutopayMethodRefetchFalse on Query {
    getAutopayMethod(refetchData: false) {
      useForRecurringPayment
      paymentProduct
      paymentMethod
      nameOnCard
      last4Digits
      isExpired
      displayName
      ccType
      ccExpYear
      ccExpMonth
      id
    }
  }
`;

export const BILLING_ACCOUNT_REFETCH_TRUE_FRAGMENT = gql`
  fragment BillingAccountRefetchTrue on Query {
    getBillingAccount(refetchData: true) {
      accountNumber
      accountStatus
      balance {
        value
        text
        alphabeticCode
      }
      billingStatus
      billDate
      creditClass
      dueDate
      invoiceAmount {
        value
        text
        alphabeticCode
      }
      payDate
      paymentDueDays
      billingCycleDayOfMonth
      nextBillPeriodStartDate
    }
  }
`;

export const BILLING_ACCOUNT_REFETCH_FALSE_FRAGMENT = gql`
  fragment BillingAccountRefetchFalse on Query {
    getBillingAccount(refetchData: false) {
      accountNumber
      accountStatus
      balance {
        value
        text
        alphabeticCode
      }
      billingStatus
      billDate
      creditClass
      dueDate
      invoiceAmount {
        value
        text
        alphabeticCode
      }
      payDate
      paymentDueDays
      billingCycleDayOfMonth
      nextBillPeriodStartDate
    }
  }
`;

export const GET_BILLING_ACCOUNT = gql`
  query getBillingAccount($refetchData: Boolean) {
    getBillingAccount(refetchData: $refetchData) {
      accountNumber
      accountStatus
      balance {
        value
        text
        alphabeticCode
      }
      billingStatus
      billDate
      creditClass
      dueDate
      invoiceAmount {
        value
        text
        alphabeticCode
      }
      payDate
      paymentDueDays
      billingCycleDayOfMonth
      nextBillPeriodStartDate
    }
  }
`;

export const GET_PAYMENT_HISTORY = gql`
  query getPaymentHistory(
    $refetchData: Boolean
    $nextBillPeriodStartDate: String
  ) {
    getPaymentHistory(
      refetchData: $refetchData
      nextBillPeriodStartDate: $nextBillPeriodStartDate
    ) {
      paymentAmount {
        value
        text
      }
      paymentDate
      paymentMethod
      paymentStatus
      paymentTransactionId
      paymentType
      isExpired
      isRecurring
      isRefund
      barCode
      invoiceNum
      last4Digits
      txnStatus
    }
  }
`;

export const GET_BOLETO_VALUE = gql`
  query getBoletoValue($input: GetBoletoValueInput!) {
    getBoletoValue(input: $input) {
      billingAccount
      boletoBarCode
      boletoDueDate
      boletoExpDate
      gatewayTransactionId
      invoiceNum
      invoiceSequenceNumber
      paymentTransactionId
      url
    }
  }
`;

export const SEND_BOLETO_EMAIL = gql`
  query sendBoletoEmail($invoiceNumber: String, $orderId: String) {
    sendBoletoEmail(invoiceNumber: $invoiceNumber, orderId: $orderId)
  }
`;

export const GET_ORDERS_BY_CUSTOMER_RELATIONSHIP_ID = gql`
  query getOrdersByCustomerRelationshipId {
    getOrdersByCustomerRelationshipId {
      state
      orderId
    }
  }
`;

export const GET_PENDING_PAYMENTS = gql`
  query getPendingPayments {
    getPendingPayments {
      last4Digits
      paymentAmount {
        value
        text
      }
      paymentDate
      paymentMethod
      isRecurring
      isRefund
      txnStatus
    }
  }
`;

export const GET_INVOICE_PDF = gql`
  query getInvoicePDF($input: getInvoicePDFInput!) {
    getInvoicePDF(input: $input) {
      invoicePDF
    }
  }
`;
