import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors, Txt } from '@vst/beam';

import { Bar } from '@mfe/shared/components';
import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';

import { borderRadiusValues, barHighlightColorBuyMore } from './utils';
import styles from './UsageBar.module.scss';

export const UsageBar = () => {
  return (
    <div>
      <Labels />
      <DataUsageBar />
      <UsageTypes />
    </div>
  );
};

const Labels = () => {
  const { t } = useTranslation('Usage');

  const {
    data: {
      usage: { displayedTotalDataCap, displayedTotalDataUsed },
    },
  } = useSelector(selectPlanInfo);

  const totalDataAvailable = t('Global:dataFormat', {
    amount: displayedTotalDataCap,
  });

  return (
    <div className={styles['labelsWrapperWhite']}>
      <div data-cy="data-used">
        <Txt color="inherit" component="span" variant="tinyBold">
          {t('Global:dataFormat', {
            amount: displayedTotalDataUsed.toFixed(1),
          })}
        </Txt>
        <Txt color="inherit" component="span" variant="tinyRegular">
          {t('dataUsed')}
        </Txt>
      </div>
      <Txt data-cy="total-data" color="inherit" variant="tinyBold">
        {t('totalDataAvailable', { totalData: totalDataAvailable })}
      </Txt>
    </div>
  );
};

const DataUsageBar = () => {
  const {
    data: {
      usage: {
        buyMoreDataCap,
        monthlyDataUsedRatio,
        buyMoreDataUsedRatio,
        displayedTotalDataCap,
      },
    },
  } = useSelector(selectPlanInfo);

  const borderRadii = {
    monthly: {
      background: buyMoreDataCap
        ? borderRadiusValues.left
        : borderRadiusValues.all,
      highlight:
        monthlyDataUsedRatio === 1 && !buyMoreDataCap
          ? borderRadiusValues.all
          : borderRadiusValues.left,
    },
    buyMore: {
      background: borderRadiusValues.right,
      highlight:
        buyMoreDataUsedRatio > 0.9 ? borderRadiusValues.rightSmall : '0px',
    },
  };

  return (
    <div style={{ display: 'flex', margin: '4px 0 16px' }}>
      <Bar
        testID="usage-bar"
        width="100%"
        height={12}
        percentage={monthlyDataUsedRatio * 100}
        backgroundColor={colors.gray[200]}
        color={colors.teal[600]}
        backgroundBorderRadius={borderRadii.monthly.background}
        highlightBorderRadius={borderRadii.monthly.highlight}
      />

      {buyMoreDataCap > 0 && (
        <Bar
          testID="usage-bar-buy-more"
          height={12}
          width={`${(buyMoreDataCap / displayedTotalDataCap) * 100}%`}
          percentage={buyMoreDataUsedRatio * 100}
          backgroundColor={colors.gray[200]}
          color={barHighlightColorBuyMore}
          backgroundBorderRadius={borderRadii.buyMore.background}
          highlightBorderRadius={borderRadii.buyMore.highlight}
          backgroundBorderLeft={`1px solid ${colors.gray[200]}`}
        />
      )}
    </div>
  );
};

const UsageTypes = () => {
  const { t } = useTranslation('Usage');

  const {
    data: {
      usage: { buyMoreDataCap },
    },
  } = useSelector(selectPlanInfo);

  const showBuyMoreIndicator = !!buyMoreDataCap;

  return (
    <div className={styles['usageTypesWrapperWhite']}>
      <div className={styles['usageType']}>
        <span
          className={styles['usageTypeIndicator']}
          style={{ background: colors.teal[600] }}
        />
        <Txt
          data-cy="high-speed-data-indicator"
          color="inherit"
          variant="tinyRegular"
        >
          {t('dataType.highSpeed')}
        </Txt>
      </div>
      {showBuyMoreIndicator && (
        <div className={styles['usageType']}>
          <span
            className={styles['usageTypeIndicator']}
            style={{
              background: `linear-gradient(to bottom right, ${colors.teal[600]} 40%, ${colors.teal[300]} 40% 60%, ${colors.teal[600]} 60% 100%)`,
            }}
          />
          <Txt
            data-cy="buy-more-data-indicator"
            color="inherit"
            variant="tinyRegular"
          >
            {t('dataType.highSpeedBuyMore')}
          </Txt>
        </div>
      )}
    </div>
  );
};
