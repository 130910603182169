class Storage {
  protected storage: globalThis.Storage | null = null;

  constructor() {
    // This if statement is necessary to build
    if (typeof window !== 'undefined') {
      this.storage = window.sessionStorage;
    }
  }

  setItem(key: string, value: any) {
    this.storage?.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any | null {
    const response = this.storage?.getItem(key);
    const parsedValue = response ? JSON.parse(response) : null;
    return parsedValue;
  }

  removeItem(key: string) {
    this.storage?.removeItem(key);
  }
}

export default new Storage();
