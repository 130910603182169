import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Badge, Surface, Txt } from '@vst/beam';

import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';

import { IconWithTooltip } from '../icon-with-tooltip';
import styles from './styles.module.scss';

export const DataRemaining = () => {
  const { t } = useTranslation('Usage');

  const {
    data: {
      characteristics: { isUnlimited },
      usage: {
        hasBreachedDataAllowance,
        monthlyDataRemaining,
        buyMoreDataRemaining,
      },
    },
  } = useSelector(selectPlanInfo);

  const remainingData = monthlyDataRemaining + buyMoreDataRemaining;

  if (hasBreachedDataAllowance)
    return (
      <div data-cy="no-high-speed-data-remaining">
        <Surface
          mb="8px"
          radius="4px"
          theme={'light'}
          style={{ background: 'inherit' }}
        >
          <Badge
            size="small"
            emphasis={'medium'}
            state="warning"
            label={t('noHighSpeedData')}
          />
        </Surface>
        <Txt variant="heading3" component="span" color="inherit">
          {isUnlimited
            ? t('standardData.heading')
            : t('allHighSpeedDataUsed.heading')}
        </Txt>
        {isUnlimited && (
          <IconWithTooltip toolTipText={t('standardData.tooltip')} />
        )}
      </div>
    );

  return (
    <div data-cy="high-speed-data-remaining" className={styles['heading']}>
      <Txt component="span" variant="subHeading3" color="inherit">
        <Trans
          ns="Usage"
          i18nKey="remainingData"
          values={{ amount: remainingData }}
        />
      </Txt>
    </div>
  );
};
