import { createSlice } from '@reduxjs/toolkit';

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface GetCookiePayload {
  key: string;
}

export interface GoToUrl {
  url: string;
}
export interface EventState {
  listenersAdded: boolean;
  cookies: {
    [key: string]: string;
  };
  origin?: string;
}

export const initialEventState: EventState = {
  listenersAdded: false,
  cookies: {},
};

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialEventState,
  reducers: {
    addListeners: (state) => {
      state.listenersAdded = true;
      return state;
    },
    goToUrl: (state, _: { payload: GoToUrl }) => state,
    logout: (state) => state,
    setCookie: (state, _: { payload: KeyValuePair }) => state,
    _setCookie: (state, { payload }: { payload: KeyValuePair }) => {
      const newCookie = { [payload.key]: payload.value };
      state.cookies = { ...state.cookies, ...newCookie };
    },
    getCookie: (state, _: { payload: GetCookiePayload }) => state,
    setOrigin: (state, { payload }: { payload: Partial<EventState> }) => {
      state.origin = payload.origin;
    },
  },
});

export const {
  addListeners,
  goToUrl,
  _setCookie,
  getCookie,
  setCookie,
  logout,
  setOrigin,
} = eventSlice.actions;

export const selectEvent = (state: { event: EventState }) => state.event;
