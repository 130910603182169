/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Address,
  StreamOnProduct,
  SubmitFullOrderInput,
  UserDetails,
} from '@mfe/shared/schema-types';
import { AppState, initialState, Pages, Persona } from './types';

export const streamOnPromoSlice = createSlice({
  name: 'streamOnPromo',
  initialState,
  reducers: {
    checkUserEligibility: (state) => state,
    getInvoiceHistory: (state) => state,
    getAllDetails: (state) => state,
    submitFullOrder: (state, _: PayloadAction<SubmitFullOrderInput>) => state,
    scrubAddress: (state, _: PayloadAction<{ address: Address | null }>) =>
      state,
    deactivateStreamOn: (state) => state,
    setUserEligibility(
      state,
      action: PayloadAction<{
        isEligible: boolean;
        hasStreamOnActive: boolean;
      }>
    ) {
      state.isEligible = action.payload.isEligible;
      state.hasStreamOnActive = action.payload.hasStreamOnActive;
    },
    setShippingAddress(state, action: PayloadAction<Address>) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...restOfAddress } = action.payload;
      state.shippingAddress = restOfAddress;
    },
    setOfferDetailsLoading(state, action: PayloadAction<boolean>) {
      state.offerDetails.loading = action.payload;
    },
    setOfferDetails(state, action: PayloadAction<StreamOnProduct>) {
      state.offerDetails = {
        loading: false,
        ...action.payload,
      };
    },
    setUserDetails(state, action: PayloadAction<UserDetails>) {
      if (!action.payload) {
        state.userDetails = { ...initialState.userDetails };
      } else {
        state.userDetails = action.payload;
      }
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsOrderSubmitting(state, action: PayloadAction<boolean>) {
      state.isOrderSubmitting = action.payload;
    },
    setIsScrubbingLoading(state, action: PayloadAction<boolean>) {
      state.isScrubbingLoading = action.payload;
    },
    setIsEditingNumber(state, action: PayloadAction<boolean>) {
      state.isEditingNumber = action.payload;
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.userDetails.phoneNumber = action.payload;
    },
    setScrubLocation(state, action: PayloadAction<Address | null>) {
      state.scrubLocation = action.payload;
    },
    setConfirmModal(state, action: PayloadAction<boolean>) {
      state.confirmModal = action.payload;
    },
    setOrderSucceeded(state, action: PayloadAction<boolean>) {
      state.didOrderSucceed = action.payload;
    },
    setIsEdittingAddress(state, action: PayloadAction<boolean>) {
      state.isEdittingAddress = action.payload;
    },
    setEditAddress(state, action: PayloadAction<Address>) {
      state.editAddress = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<Pages>) {
      state.currentPage = action.payload;
    },
    setInvoiceHistoryLoading(state, action: PayloadAction<boolean>) {
      state.currentMonthlyBill.loading = action.payload;
    },
    setInvoiceHistory(state, action: PayloadAction<number>) {
      state.currentMonthlyBill.currentMonthlyBill = action.payload;
      state.currentMonthlyBill.loading = false;
    },
    setPersona(state, action: PayloadAction<Persona>) {
      state.persona = action.payload;
    },
    setOrderFail(state, action: PayloadAction<string | null>) {
      state.failureReason = action.payload;
    },
    setOrderAttempts(state, action: PayloadAction<number>) {
      state.orderAttempts = action.payload;
    },
    addOrderAttempts(state) {
      state.orderAttempts = state.orderAttempts + 1;
    },
    setDeactivateStreamOn(
      state,
      action: PayloadAction<Partial<AppState['deactivate']>>
    ) {
      state.deactivate = {
        ...state.deactivate,
        ...action.payload,
      };
    },
  },
});

export const {
  setConfirmModal,
  setCurrentPage,
  setEditAddress,
  setInvoiceHistory,
  setIsEdittingAddress,
  setIsLoading,
  setIsOrderSubmitting,
  setIsScrubbingLoading,
  setOfferDetails,
  setOrderAttempts,
  addOrderAttempts,
  setOrderFail,
  setPersona,
  setPhoneNumber,
  setScrubLocation,
  setShippingAddress,
  setUserDetails,
  setIsEditingNumber,
  setUserEligibility,
  checkUserEligibility,
  getInvoiceHistory,
  getAllDetails,
  submitFullOrder,
  scrubAddress,
  deactivateStreamOn,
  setDeactivateStreamOn,
  setInvoiceHistoryLoading,
  setOfferDetailsLoading,
  setOrderSucceeded,
} = streamOnPromoSlice.actions;

export default streamOnPromoSlice.reducer;

export const selectStreamOnPromo = (state: { streamOnPromo: AppState }) =>
  state.streamOnPromo;
