import { takeLatest, call, select, put } from 'redux-saga/effects';

import { BuyMoreProducts } from '@mfe/shared/schema-types';
import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

import {
  setBuyMoreHistoryData,
  setBuyMoreHistoryError,
  getBuyMoreHistory,
  refetchBuyMoreHistory,
} from './buyMoreHistorySlice';
import { selectCurrentUsage, waitForUsage } from '../plan';
import { waitForToken, waitForUserInfo } from '../utils/utilsSagas';
import { GET_BUY_MORE_HISTORY } from './query';

export function* fetchBuyMoreHistory(refetchData = false) {
  yield call(waitForToken);
  yield call(waitForUserInfo);
  yield call(waitForUsage);

  const {
    data: { startDate, endDate },
  } = yield select(selectCurrentUsage);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_BUY_MORE_HISTORY,
    variables: { refetchData: true, startDate, endDate },
    fetchPolicy: refetchData ? 'network-only' : 'cache-first',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setBuyMoreHistoryError(runtimeError ?? errors));
    return;
  }

  if (data?.getBuyMoreHistory) {
    yield put(
      setBuyMoreHistoryData(
        data.getBuyMoreHistory?.products as BuyMoreProducts[]
      )
    );
  }
}

function* refetchBuyMoreHistoryData() {
  yield call(fetchBuyMoreHistory, true);
}

export function* watchBuyMoreHistory() {
  yield takeLatest(getBuyMoreHistory.type, fetchBuyMoreHistory);
  yield takeLatest(refetchBuyMoreHistory.type, refetchBuyMoreHistoryData);
}
