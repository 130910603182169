/* eslint-disable @typescript-eslint/no-explicit-any */
export enum Domains {
  General = 'General',
  Usage = 'MyViasat-TSUsage',
  Overview = 'MyViasat-Overview',
  Profile = 'MyViasat-Profile',
  Billing = 'MyViasat-ManageBilling',
}

export enum AuthEventTypes {
  Logout = 'Logout',
  GoToUrl = 'GoToUrl',
  SetCookie = 'SetCookie',
  GetCookie = 'GetCookie',
  LoginFromCode = 'LoginFromCode',
  GetAuthParams = 'GetAuthParams',
  SendAuthParams = 'SendAuthParams',
  LoginEvent = 'LoginEvent',
  SendToken = 'SendToken',
  DeleteUsernameStarted = 'DeleteUsernameStarted',
  DeleteUsernameSuccess = 'DeleteUsernameSuccess',
  DeleteUsernameFailure = 'DeleteUsernameFailure',
}

export enum EventTypes {
  BuyMore = 'buy-more',
  Init = 'init',
  Loaded = 'loaded',
  Resize = 'resize',
  PayBill = 'paybill',
  ChangePlan = 'change-plan',
  ChatWithUs = 'chat-with-us',
  FAQSpecificQuestion = 'faq-specific-question',
  PlanDetails = 'plan-details',
  StreamOnGuide = 'stream-on-guide',
  StreamOnLearnMore = 'stream-on-learn-more-click',
  ShieldLearnMore = 'shield-learn-more',
  InvalidToken = 'invalid-or-expired-token',
  NoTokenProvided = 'no-token-provided',
  RefreshToken = 'refresh-token',
  TokenValid = 'token-valid',
  TokenWarning = 'token-warning',
  MVLaunchDarklyConfig = 'mv-launchdarkly-config',
  ForceEnable = 'force-enable',
  VisitPlanUsage = 'visit-plan-usage',
  SendAuthParams = 'send-auth-params',
  OpenInNewTab = 'open-in-new-tab',
  Profile = 'profile',
  Installation = 'Installation',
  SetOrigin = 'SetOrigin',
  GetToken = 'GetToken',
  PushNotification = 'PushNotification',
  ChangeLanguage = 'ChangeLanguage',
  CopyText = 'copy-text',
  ScrollTop = 'scroll-top',
  GoToACP = 'go-to-acp',
  BackToMVOverview = 'back-to-mv-overview',
  Autopay = 'autopay',
  ChangeBillDate = 'change-bill-date',
  OneTimePayment = 'one-time-payment',
  ViewStatement = 'view-statement',
  ViewStatementLoaded = 'view-statement-loaded',
  ClientConfig = 'client-config',
  BillingContext = 'billing-context',
  ChangedPlan = 'changed-plan',
  RefetchPlanInfo = 'refetch-plan-info',
  ScheduledInstall = 'scheduled-install',
}

export interface UserTokenError {
  eventType: EventTypes;
  message: string;
  subject?: string;
  details?: any;
}

export interface WindowMessageData {
  domain: Domains;
  eventType: EventTypes | AuthEventTypes;
  subject?: string;
  data?: any;
}
