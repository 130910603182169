import { useSelector } from 'react-redux';

import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import { ProductInstanceStatus } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { useCanPlaceOrders } from './use-can-place-orders';
import { useHasPlanChangePending } from './use-has-plan-change-pending';

export const useCanChangePlan = () => {
  const { isSuspended, isDisconnected } = useCanPlaceOrders();
  const hasPlanChangePending = useHasPlanChangePending();

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  const {
    user: { productInstanceStatus },
  } = useSelector(selectUser);

  const { issuer } = useSelector(selectConfig);
  const isSalesforce = issuer === Issuer.Salesforce;

  if (
    productInstanceStatus === ProductInstanceStatus.Error ||
    isPreInstall ||
    isSuspended ||
    isDisconnected
  ) {
    return false;
  }

  return !(hasPlanChangePending || isSalesforce);
};
