import { createSlice } from '@reduxjs/toolkit';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

type BroadbandLabelState = {
  loading: boolean;
  error: ErrorsObj | RuntimeError | null;
  data: {
    upi: string | null;
    serviceAreaId: string | null;
    satelliteName: string | null;
    serviceId: string | null;
    iduDeviceModel: string | null;
  };
};

export const initialBroadbandLabelState: BroadbandLabelState = {
  loading: false,
  error: null,
  data: {
    upi: '',
    serviceAreaId: '',
    satelliteName: '',
    serviceId: '',
    iduDeviceModel: '',
  },
};

export const broadbandLabelSlice = createSlice({
  name: 'broabandLabel',
  initialState: initialBroadbandLabelState,
  reducers: {
    getBroadbandLabelAttributes: (state) => {
      state.loading = true;
    },
    setBroadbandLabelAttributes: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    setBroadbandLabelAttributesError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  getBroadbandLabelAttributes,
  setBroadbandLabelAttributes,
  setBroadbandLabelAttributesError,
} = broadbandLabelSlice.actions;

export const selectBroadbandLabelAttributes = (state: {
  broabandLabel: BroadbandLabelState;
}) => state.broabandLabel;
