/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AnalyticsEventNames, AnalyticsVendors, SchemaVersions } from './types';
import { TokenType } from '@mfe/shared/schema-types';
export interface ContextSchema {
  schema: string;
  data: any;
}

interface EventLogParams {
  label?: string;
  property?: string;
  value?: number;
  context?: ContextSchema[];
}

export interface LogEvent {
  category: string;
  action: string;
  params?: EventLogParams;
}

export interface SelfDescribingEvent {
  schema: string;
  data: any;
  context?: ContextSchema[];
}

export interface InViewStructuredEvent extends LogEvent {
  selector: string;
  triggerOnce?: boolean;
}

export interface SelfDescribingEventInput {
  eventName: AnalyticsEventNames;
  data?: any;
  vendor?: AnalyticsVendors;
  version?: SchemaVersions;
  context?: ContextSchema[];
}

export interface TrackBillingEventInput {
  eventName: AnalyticsEventNames | string;
  version: SchemaVersions;
  data: Record<string, any>;
}

export interface InViewSelfDescribingEvent extends SelfDescribingEventInput {
  selector: string;
  triggerOnce?: boolean;
}

export interface AnalyticsState {
  isPixelLoaded: boolean;
  usageContext: ContextSchema;
  display_platform: string;
  billingContext: ContextSchema;
}

export const initialAnalyticsState: AnalyticsState = {
  // PIXEL no longer being used. Set to true to allow ACP to load
  isPixelLoaded: true,
  usageContext: {} as ContextSchema,
  display_platform: 'myviasat',
  billingContext: {} as ContextSchema,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialAnalyticsState,
  reducers: {
    initializePixel: (state) => state,
    setPixelLoaded: (
      state,
      { payload: { isPixelLoaded } }: { payload: { isPixelLoaded: boolean } }
    ) => {
      state.isPixelLoaded = isPixelLoaded;
    },
    setUsageContext: (state, { payload }: { payload: ContextSchema }) => {
      state.usageContext = payload;
    },
    structuredEvent: (state, { payload }: { payload: LogEvent }) => state,
    selfDescribingEvent: (
      state,
      {
        payload,
      }: {
        payload: SelfDescribingEventInput;
      }
    ) => state,
    inViewStructuredEvent: (
      state,
      { payload }: { payload: InViewStructuredEvent }
    ) => state,
    inViewSelfDescribingEvent: (
      state,
      {
        payload,
      }: {
        payload: InViewSelfDescribingEvent;
      }
    ) => state,
    setDisplayPlatform: (state, { payload }: { payload: TokenType }) => {
      state.display_platform =
        payload === TokenType.Okta ? 'myviasat' : 'salesforce';
    },
    addProductMetaContext: (
      state,
      { payload }: { payload: { version: string } }
    ) => state,
    setBillingContext: (state, { payload }: { payload: ContextSchema }) => {
      state.billingContext = payload;
    },
    trackBillingEvent: (
      state,
      { payload }: { payload: TrackBillingEventInput }
    ) => state,
  },
});

export const {
  setPixelLoaded,
  setUsageContext,
  structuredEvent,
  inViewStructuredEvent,
  initializePixel,
  inViewSelfDescribingEvent,
  selfDescribingEvent,
  setDisplayPlatform,
  addProductMetaContext,
  setBillingContext,
  trackBillingEvent,
} = analyticsSlice.actions;

export const selectAnalytics = (state: { analytics: AnalyticsState }) =>
  state.analytics;
