import { takeLatest, call, delay, all, put, select } from 'redux-saga/effects';
import {
  initRaygun as initRaygunAction,
  setRaygunUser as setRaygunUserAction,
  sendRaygunError as sendRayGunErrorAction,
  setRaygunLoaded,
  InitRaygunOptions,
  selectRaygun,
} from './raygunSlice';
import { setUser, selectUser } from '../auth/authUserSlice';

export declare function rg4js(type: string, option?: any): void;

export function attemptRaygunInit(options: InitRaygunOptions): boolean {
  // Disable raygun for prod + localhost. Prod because MFEs create a lot of sessions so it's expensive
  if (
    window.location.hostname.includes('localhost') ||
    (window.location.hostname.includes('prod') &&
      !window.location.pathname.includes('Registration'))
  )
    return true;

  try {
    rg4js('apiKey', options.apiKey);
    rg4js('enablePulse', options.enablePulse ?? false);
    rg4js('enableCrashReporting', options.enableCrashReporting ?? false);
    rg4js('filterSensitiveData', [
      'firstName',
      'lastName',
      'email',
      'phone',
      'phoneNumber',
      'address',
      'shippingAddress',
      'billingAddress',
      'serviceAddress',
      'fullName',
      'lat',
      'latitude',
      'long',
      'longitude',
      'givenName',
      'familyName',
      'addressLines',
      'addressLine',
    ]);
    rg4js('setFilterScope', 'all');
    rg4js('logContentsOfXhrCalls', true);
    rg4js('endSession');

    if (options.version) rg4js('setVersion', options.version);

    return true;
  } catch {
    return false;
  }
}

export function* sendRaygunErrorGenerator({
  payload,
}: {
  type: string;
  payload: { error: Error; data?: any; tags?: string[] };
}) {
  const { error, data, tags } = payload;
  rg4js('send', { error, customData: data, tags });
  yield;
}

export function sendRaygunError(error: Error, data?: any, tags?: string[]) {
  rg4js('send', { error, customData: data, tags });
}

export interface SetRaygunUserOptions {
  accountNumber: string;
  username?: string;
}

export function initRaygunUser(options: SetRaygunUserOptions) {
  rg4js('setUser', {
    isAnonymous: false,
    identifier: options.accountNumber,
    firstName: options.username ?? '',
    fullName: options.username ?? '',
  });
}

export function warnNoRaygun() {
  console.warn('Raygun is not loaded!');
}

export function* initRaygun({
  payload,
}: {
  type: string;
  payload: InitRaygunOptions;
}) {
  let attempts = 0;
  let giveUp = false;
  let initSuccessful = false;

  while (!initSuccessful && !giveUp) {
    initSuccessful = yield call(attemptRaygunInit, payload);
    if (!initSuccessful) {
      attempts += 1;
      if (attempts >= 10) {
        giveUp = true;
      } else {
        yield delay(500 * attempts);
      }
    }
  }
  yield put(setRaygunLoaded({ loaded: initSuccessful }));
}

export function* raygunSetUser() {
  const {
    user: {
      accountNumber,
      auth: { username },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);
  if (!accountNumber || !username) return;

  const { loaded }: ReturnType<typeof selectRaygun> = yield select(
    selectRaygun
  );
  if (loaded) {
    yield call(initRaygunUser, {
      accountNumber,
      username,
    });
  } else {
    yield call(warnNoRaygun);
  }
}

export function* watchRaygun() {
  yield all([
    takeLatest(initRaygunAction.type, initRaygun),
    takeLatest(setRaygunUserAction.type, raygunSetUser),
    takeLatest(setUser.type, raygunSetUser),
    takeLatest(setRaygunLoaded.type, raygunSetUser),
    takeLatest(sendRayGunErrorAction.type, sendRaygunErrorGenerator),
  ]);
}
