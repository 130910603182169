import { useSelector } from 'react-redux';

import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceStatus } from '@mfe/shared/schema-types';

export const useCanPlaceOrders = () => {
  const {
    user: { isACPSuspended, isSoftSuspended, productInstanceStatus },
  } = useSelector(selectUser);

  const isSuspended =
    productInstanceStatus === ProductInstanceStatus.Suspended ||
    isACPSuspended ||
    isSoftSuspended;
  const isDisconnected =
    productInstanceStatus === ProductInstanceStatus.Deactivated ||
    productInstanceStatus === ProductInstanceStatus.Canceled;

  const canPlaceOrders = !isSuspended && !isDisconnected;

  return { isSuspended, isDisconnected, canPlaceOrders };
};
