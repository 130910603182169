/* eslint-disable @typescript-eslint/ban-ts-comment */
import { gql } from '@apollo/client';
import {
  takeLatest,
  call,
  all,
  select,
  spawn,
  take,
  put,
} from 'redux-saga/effects';

import {
  AuthEventTypes,
  WindowMessageData,
} from '@mfe/services/window-messages';
import { graphqlQueryWithErrors } from '@mfe/shared/redux/graphql';
import { AuthUserState, fetchUserAction, selectUser, setUser } from '../auth';
import {
  postMessage as postMessageAction,
  refreshContext as refreshContextAction,
  openInNewTab as openInNewTabAction,
} from './utilsSlice';
import { isPWAMyViasat } from '@mfe/shared/util';
import { selectEvent } from '../events';
import { selectConfig } from '@mfe/shared/redux/config';
import { sendMessageToMobileApp } from '@mfe/shared/cross-platform-events';
import { Platform } from '@mfe/shared/schema-types';
import { selectUserInfo, setUserInfo } from '../userInfo';

enum AllowedEvents {
  'init',
  'loaded',
  'resize',
  'GetToken',
  'scroll-top',
  'token-valid',
  'invalid-or-expired-token',
}

const validateAuthEvents = (
  eventType: AuthEventTypes | AllowedEvents
): boolean => {
  return eventType in AuthEventTypes || eventType in AllowedEvents;
};

export const postMessage = (
  data: WindowMessageData,
  eventOrigin: string | undefined
) => {
  const message = { ...data };
  if (
    !eventOrigin &&
    !validateAuthEvents(message.eventType as AuthEventTypes)
  ) {
    console.error(
      'Invalid origin or invalid event',
      eventOrigin,
      message.eventType
    );
    return;
  }
  const origin = eventOrigin ?? '*';

  // @ts-ignore
  if (window.ReactNativeWebView && !isPWAMyViasat()) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  } else {
    window.parent.postMessage(message, origin);
  }
};

export function* postMessageSaga({
  payload,
}: {
  type: string;
  payload: WindowMessageData;
}) {
  const {
    user: {
      auth: { subject },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  // NOTE: Do not remove or replace this line, if you have to do so please coordinate with a GAC personel to understand the implications,
  // and let them know of the changes. The origin here is being used as a middleware to handle the origin of the parent window.
  const { origin }: ReturnType<typeof selectEvent> = yield select(selectEvent);

  yield call(
    postMessage,
    {
      ...payload,
      subject,
    } as WindowMessageData,
    origin
  );
}

export function* waitForToken() {
  const user: AuthUserState = yield select(selectUser);
  if (user.loading) {
    yield take(setUser.type);
  }
}

export function* waitForUserInfo() {
  const { loading } = yield select(selectUserInfo);

  if (loading) {
    yield take(setUserInfo.type);
  }
}

export function* waitForProductKind() {
  const { user, loading }: AuthUserState = yield select(selectUser);
  if (!user.productKind) {
    yield take(fetchUserAction.type);
  }
  if (loading) {
    yield take(setUser.type);
  }
}

export function* openInNewTab({ payload }: { type: string; payload: string }) {
  const { platform } = yield select(selectConfig);
  if (!payload) return;

  if (platform === Platform.Web) {
    window.open(payload, '_blank');
  } else {
    yield put(
      sendMessageToMobileApp({
        type: 'open-in-new-tab',
        data: { url: payload },
      })
    );
  }
}

const REFRESH_CONTEXT = gql`
  query getRefreshContext {
    getRefreshContext
  }
`;

export function* refreshContext() {
  yield call(graphqlQueryWithErrors, {
    query: REFRESH_CONTEXT,
    fetchPolicy: 'no-cache',
  });
}

export function* watchUtils() {
  yield spawn(function* () {
    yield all([
      takeLatest(postMessageAction.type, postMessageSaga),
      takeLatest(refreshContextAction.type, refreshContext),
      takeLatest(openInNewTabAction.type, openInNewTab),
    ]);
  });
}
