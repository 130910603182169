import React from 'react';
import { Button, IllustrativeIcon, Surface } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface FlowFailureOutcomeProps {
  goToOverview: () => void;
  refreshPage?: () => void;
  children: React.ReactNode;
}
export const FlowFailureOutcome = ({
  goToOverview,
  refreshPage,
  children,
}: FlowFailureOutcomeProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'UnableToLoadAppointmentTimes',
  });

  const buttonVariant = refreshPage ? 'secondary' : 'primary';

  return (
    <Surface
      data-cy="flow-failure-outcome-container"
      className={styles['container']}
      p="40px"
      radius="16px"
    >
      <IllustrativeIcon
        data-cy="flow-failure-outcome-Alert-Icon"
        name="Alert"
        icon={Alert}
        color="alert_400"
        style={{ width: 100, height: 100 }}
      />
      {children}
      <div className={styles['buttonContainer']}>
        <Button
          className={styles['backToMyViasat']}
          data-cy="flow-failure-outcome-goBackMyViasat"
          fluid
          onClick={goToOverview}
          variant={buttonVariant}
        >
          {t('backToMyViasat')}
        </Button>
        {refreshPage && (
          <Button
            className={styles['backToMyViasat']}
            data-cy="flow-failure-outcome-refreshPage"
            fluid
            onClick={refreshPage}
          >
            {t('refreshButton')}
          </Button>
        )}
      </div>
    </Surface>
  );
};
