import { useState, useEffect } from 'react';

enum Resolutions {
  Px375 = '375px',
  Px376 = '376px',
  Px767 = '767px',
  Px768 = '768px',
  Px991 = '991px',
  Px992 = '992px',
  Px1199 = '1199px',
  Px1200 = '1200px',
}
// Based on https://www.figma.com/file/RqLNJvvXBB4XccXi04TL3i/My-Viasat---Source-of-Truth?node-id=18%3A116
export const useScreenResolution = () => {
  const [isExtraSmall, setIsExtraSmall] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [isMedium, setIsMedium] = useState(false);
  const [isLarge, setIsLarge] = useState(false);
  const [isExtraLarge, setIsExtraLarge] = useState(false);

  useEffect(() => {
    const windowResized = () => {
      setIsExtraSmall(
        window.matchMedia(`screen and (max-width: ${Resolutions.Px375})`)
          .matches
      );
      setIsSmall(
        window.matchMedia(
          `screen and (min-width: ${Resolutions.Px376}) and (max-width: ${Resolutions.Px767})`
        ).matches
      );
      setIsMedium(
        window.matchMedia(
          `screen and (min-width: ${Resolutions.Px768}) and (max-width: ${Resolutions.Px991})`
        ).matches
      );
      setIsLarge(
        window.matchMedia(
          `screen and (min-width: ${Resolutions.Px992}) and (max-width: ${Resolutions.Px1199})`
        ).matches
      );
      setIsExtraLarge(
        window.matchMedia(`screen and (min-width: ${Resolutions.Px1200})`)
          .matches
      );
    };

    window.addEventListener('resize', windowResized);
    windowResized();
    return () => window.removeEventListener('resize', windowResized);
  }, []);

  return {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
  };
};
