import { select, takeLatest, all, put, spawn } from 'redux-saga/effects';

import i18n from '@mfe/services/translations-service';

import {
  selectLocale,
  setLocaleState,
  setLanguageCookie as setLanguageCookieAction,
  updateTranslation as updateTranslationAction,
  requestLocaleCookie as requestLocaleCookieAction,
  requestLanguageCookie as requestLanguageCookieAction,
} from './localeSlice';
import { setCookie, getCookie } from '../events';

export function* requestLocaleCookie() {
  yield put(
    getCookie({
      key: 'userLocale',
    })
  );
}

export function* requestLanguageCookie() {
  yield put(
    getCookie({
      key: 'displayLanguage',
    })
  );
}

export function* setLanguageCookie({
  payload: { displayLanguage },
}: {
  type: string;
  payload: { displayLanguage: string };
}) {
  yield put(
    setCookie({
      key: 'displayLanguage',
      value: displayLanguage,
    })
  );

  yield put(updateTranslationAction());
}

export function* updateTranslation() {
  const {
    locale: { displayLanguage },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  yield i18n.changeLanguage(displayLanguage);
}

export function* watchLocale() {
  yield spawn(function* () {
    yield all([
      takeLatest(requestLocaleCookieAction.type, requestLocaleCookie),
      takeLatest(requestLanguageCookieAction.type, requestLanguageCookie),
      takeLatest(setLanguageCookieAction.type, setLanguageCookie),
      takeLatest(updateTranslationAction.type, updateTranslation),
      takeLatest(setLocaleState.type, updateTranslation),
    ]);
  });
}
