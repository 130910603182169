import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchWithErrorsQuery } from '@mfe/shared/redux/graphql';
import { GetHistoricalUsageByBillCyclePayload } from '@mfe/shared/schema-types';
import { UnleashedTimeInterval } from './utils';

import {
  BillCycleDuration,
  BillCycles,
  parseBillCycles,
  UsageData,
} from './utils';

export interface HistoricalUsageInfoState {
  loading: boolean;
  billCycles: BillCycles;
  billCycleDurations: BillCycleDuration[] | [];
  errors: FetchWithErrorsQuery['errors'] | null;
  unleashedUsage: {
    [UnleashedTimeInterval.LAST_30_DAYS]: UsageData[];
    [UnleashedTimeInterval.LAST_60_DAYS]: UsageData[];
    [UnleashedTimeInterval.LAST_90_DAYS]: UsageData[];
  };
}

export const initialState: HistoricalUsageInfoState = {
  loading: true,
  billCycles: {},
  billCycleDurations: [],
  errors: null,
  unleashedUsage: {
    [UnleashedTimeInterval.LAST_30_DAYS]: [],
    [UnleashedTimeInterval.LAST_60_DAYS]: [],
    [UnleashedTimeInterval.LAST_90_DAYS]: [],
  },
};

type SetHistoricalUsageInfoAction = PayloadAction<
  Partial<GetHistoricalUsageByBillCyclePayload>
>;

type TimeIntervalInDays = `${UnleashedTimeInterval}`;

export type FetchUnleashedUsageAction = PayloadAction<{
  start: string;
  end: string;
  selectedInterval: TimeIntervalInDays;
}>;

export type SetUnleashedUsageAction = PayloadAction<{
  usageData: UsageData[] | [];
  interval: TimeIntervalInDays;
}>;

export const historicalUsageInfoSlice = createSlice({
  name: 'historicalUsageInfo',
  initialState,
  reducers: {
    setApiErrors: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    setHistoricalUsageInfo: (
      state,
      { payload }: { payload: SetHistoricalUsageInfoAction['payload'] }
    ) => {
      state.loading = false;
      state.errors = null;

      const processedData = parseBillCycles(payload, state);
      state.billCycles = processedData.billCycles;
      state.billCycleDurations = processedData.billCycleDurations;
    },
    fetchHistoricalUsageInfo: (state) => {
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchUnleashedUsage: (state, action: FetchUnleashedUsageAction) => {
      state.loading = true;
    },
    setUnleashedUsage: (state, action: SetUnleashedUsageAction) => {
      state.loading = false;
      state.unleashedUsage[action.payload.interval] = action.payload.usageData;
    },
    reset: () => initialState,
  },
});

export const {
  setHistoricalUsageInfo,
  fetchHistoricalUsageInfo,
  fetchUnleashedUsage,
  setUnleashedUsage,
  setApiErrors,
  reset,
} = historicalUsageInfoSlice.actions;

export const selectHistoricalUsageInfo = (state: {
  historicalUsageInfo: HistoricalUsageInfoState;
}) => state.historicalUsageInfo;
