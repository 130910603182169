import { colors, Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';

import { parseDateStringWithoutTime } from '@mfe/shared/util';

export const DaysLeft = () => {
  const { t } = useTranslation('Usage');
  const {
    data: {
      usage: { endDate, daysLeft },
    },
  } = useSelector(selectPlanInfo);

  if (!endDate || !daysLeft) {
    return null;
  }

  const renewalDate = parseDateStringWithoutTime(endDate);

  return (
    <div data-cy="days-left" style={{ color: colors.gray[600] }}>
      <Txt variant="bodySmallBold" component="span">
        {daysLeft !== 1
          ? t('daysLeft', { daysLeft })
          : t('dayLeft', { daysLeft })}
      </Txt>
      <Txt color="inherit" variant="bodySmallRegular" component="span">
        {t('dataRenewsOn', { renewalDate })}
      </Txt>
    </div>
  );
};
