import { useTranslation } from 'react-i18next';
import { PersonWithHeadset } from '@vst/beam-icons/illustrative-icons';
import { Surface, Txt, IllustrativeIcon, Button } from '@vst/beam';

import styles from './styles.module.scss';

interface NoAppointmentsAvailableProps {
  navigateToPreviousPage?: () => void;
  className?: string;
  description: string | React.ReactElement;
}

const NoAppointmentsAvailable = ({
  className = '',
  navigateToPreviousPage,
  description,
}: NoAppointmentsAvailableProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'NoAppointmentsAvailable',
  });

  return (
    <Surface
      className={`${styles['wrapper']} ${className}`}
      px={{ xs: '16px', md: '112px' }}
      my={{ xs: undefined, sm: '40px' }}
      radius="16px"
    >
      <IllustrativeIcon
        name="PersonWithHeadset"
        icon={PersonWithHeadset}
        color="teal_600"
        width={80}
        height={80}
        data-cy="Person-Icon"
      />

      <Txt
        variant="heading6"
        data-cy="NoAppointmentsAvailable-title"
        mt="24px"
        mb="16px"
      >
        {t('title')}
      </Txt>

      <Surface
        className={styles['description']}
        data-cy="NoAppointmentsAvailable-description"
      >
        <Txt variant="linkBodySmall" style={{ textAlign: 'center' }}>
          {description}
        </Txt>
      </Surface>

      {navigateToPreviousPage && (
        <Button
          data-cy="NoAppointmentsAvailable-button"
          className={styles['backToMyViasat']}
          onClick={navigateToPreviousPage}
          fluid
        >
          {t('goBack')}
        </Button>
      )}
    </Surface>
  );
};

export default NoAppointmentsAvailable;
