import { Icon, Tooltip } from '@vst/beam';
import { Info } from '@vst/beam-icons/icons';

export const IconWithTooltip = ({
  toolTipText,
  toolTipPosition,
}: {
  toolTipText: string;
  toolTipPosition?: 'center' | undefined;
}) => {
  return (
    <span
      style={{
        display: 'inline-block',
        height: '24px',
        verticalAlign: 'middle',
        marginLeft: 4,
      }}
    >
      <Tooltip
        style={{ width: 200 }}
        bodyText={toolTipText}
        position={toolTipPosition ? 'top' : 'bottom'}
        beakPosition={toolTipPosition ?? 'start'}
      >
        <Icon icon={Info} color="gray_900" size="16px" />
      </Tooltip>
    </span>
  );
};
