import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { BillCycleDaysPayload } from '@mfe/shared/schema-types';
import { addDays, parse } from 'date-fns';
import type { RuntimeError, ErrorsObj } from '@mfe/shared/redux/graphql';

export type State = BillCycleDaysPayload & {
  loading: boolean;
  billingCycleDayOfMonthUpdateDate: string | null;
  error: ErrorsObj | RuntimeError | null;
  newBillCycleDay: number | null;
  isConfirmationModalOpen: boolean;
};

type SetBillCycleDaysAction = PayloadAction<BillCycleDaysPayload>;

export const initialState: State = {
  canChangeBillDate: false,
  days: [],
  billingCycleDayOfMonthUpdateDate: null,
  // Reason why the user can't change the bill cycle day
  reason: null,
  loading: false,
  // API / client runtime error
  error: null,
  newBillCycleDay: null,
  isConfirmationModalOpen: false,
};

export const updateBillCycleSlice = createSlice({
  name: 'updateBillCycle',
  initialState,
  reducers: {
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setBillCycleDays: (state, action: SetBillCycleDaysAction) => {
      state.canChangeBillDate = action.payload.canChangeBillDate;
      state.days = action.payload.days;
      state.reason = action.payload.reason;
      state.loading = false;
      state.error = null;

      if (action.payload.billingCycleDayOfMonthUpdateDate) {
        const parsedDate = parse(
          action.payload.billingCycleDayOfMonthUpdateDate ??
            new Date().toString(),
          'yyyy-MM-dd',
          new Date()
        );
        const addDaysToLastUpdate = addDays(parsedDate, 35).toString();
        state.billingCycleDayOfMonthUpdateDate = addDaysToLastUpdate;
      }
    },
    setError: (state, { payload }: { payload: State['error'] }) => {
      state.error = payload;
      state.loading = false;
    },
    triggerBillCycleDayUpdate: (state, { payload }: { payload: number }) => {
      state.loading = true;
    },
    setNewBillCycleDay: (state, { payload }: { payload: number }) => {
      state.loading = false;
      state.newBillCycleDay = payload;
    },
    setIsConfirmationModalOpen: (state, { payload }: { payload: boolean }) => {
      state.isConfirmationModalOpen = payload;
    },
    reset: () => initialState,
  },
});

export const getBillCycleDays = createAction(
  'updateBillCycle/getBillCycleDays'
);

export const {
  setLoading,
  setBillCycleDays,
  setError: setUpdateBillCycleError,
  triggerBillCycleDayUpdate,
  setNewBillCycleDay,
  setIsConfirmationModalOpen,
  reset,
} = updateBillCycleSlice.actions;

export const selectUpdateBillCycle = (state: {
  updateBillCycle: State;
}): State => state.updateBillCycle;
