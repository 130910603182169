import { createSlice } from '@reduxjs/toolkit';
import { StreamOnStatus } from '@mfe/shared/schema-types';

export interface StreamOnState {
  loading: boolean;
  streamOn: {
    hasStreamOn: boolean;
    status: StreamOnStatus;
    macAddress: string;
    dataSavedGB: number;
    shippingInfo?: {
      deliveryAddress: string;
      trackingUrl?: string;
      trackingNumber: string;
    };
  };
}

export const initialStreamOnState: StreamOnState = {
  loading: true,
  streamOn: {
    hasStreamOn: false,
    status: StreamOnStatus.Error,
    macAddress: '',
    dataSavedGB: 0,
    shippingInfo: {
      deliveryAddress: 'No address available',
      trackingNumber: 'loading',
    },
  },
};

export const streamOnSlice = createSlice({
  name: 'streamOn',
  initialState: initialStreamOnState,
  reducers: {
    setStreamOn: (
      state,
      { payload }: { payload: Partial<StreamOnState['streamOn']> }
    ) => {
      state.loading = false;
      state.streamOn = { ...state.streamOn, ...payload };
    },
    resetStreamOn: () => initialStreamOnState,
  },
});

export const { setStreamOn, resetStreamOn } = streamOnSlice.actions;

export const selectStreamOn = (state: { streamOn: StreamOnState }) =>
  state.streamOn;
