import { call, put, all, takeLatest } from 'redux-saga/effects';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

import {
  getBroadbandLabelAttributes,
  setBroadbandLabelAttributes,
  setBroadbandLabelAttributesError,
} from './broadbandLabelSlice';
import { GET_BROADBAND_LABEL_ATTRIBUTES } from './query';
import { waitForToken } from '../utils/utilsSagas';

function* fetchBroadbandLabelAttributes() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_BROADBAND_LABEL_ATTRIBUTES,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setBroadbandLabelAttributesError(runtimeError ?? errors));
    return;
  }

  if (data?.getBroadbandLabelAttributes) {
    yield put(setBroadbandLabelAttributes(data?.getBroadbandLabelAttributes));
  }
}

export function* watchBroadbandLabelAttributes() {
  yield all([
    takeLatest(getBroadbandLabelAttributes.type, fetchBroadbandLabelAttributes),
  ]);
}
