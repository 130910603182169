import { ApolloLink, HttpLink } from '@apollo/client';
import { Locale } from '@mfe/shared/schema-types';
import { logTimeLink } from './logTimeLink';
import { getSecureLink, TokenInfo } from './secureLink';

export const getLink = (
  uri: string,
  tokenInfo?: TokenInfo,
  locale?: Locale
): ApolloLink => {
  const secureLink = getSecureLink(tokenInfo, locale);
  const httpLink = new HttpLink({ uri, credentials: 'include' });

  return ApolloLink.from([logTimeLink, secureLink, httpLink]);
};
