import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';

export const useShowAddonCustomerSupportMessage = () => {
  const { showAddonsShop, showRemoveAddonFlow } = useSelector(selectConfig);

  if (showAddonsShop && showRemoveAddonFlow) {
    return false;
  }

  return true;
};
