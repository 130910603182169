import { useRouter } from 'next/router';

const useNavigate = (): {
  goTo: (link: string, params?: Record<string, string>) => unknown;
  goBack: () => unknown;
  replace: (link: string, skipLogic?: { skip: string }) => unknown;
} => {
  const router = useRouter();

  const goTo = (link: string) => router.push(`/${link}`);
  const goBack = () => router.back();

  const replace = (link: string, skipLogic?: { skip: string }) =>
    router.replace(`/${link}`);

  return { goTo, goBack, replace };
};

export default useNavigate;
