import { OAuthClient } from '@mfe/shared/schema-types';
import { MVCamelCaseEnvironment as Environment } from '@mfe/services/env-files';

type AuthObject = {
  appClient: OAuthClient;
  webClient: OAuthClient;
  brazilClient?: OAuthClient;
  europeClient?: OAuthClient;
  baseUrl: string;
  europeBaseUrl: string;
};

type EnvironmentObject = Record<Environment, AuthObject>;

type AuthParams = {
  okta: EnvironmentObject;
  mySSO: EnvironmentObject;
};

const okta: EnvironmentObject = {
  devLocal: {
    appClient: {
      clientId: '0oa4j01uuyUl9qCIO1d7',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: '0oa4j0flomnpt7bS11d7',
      redirectUrl: 'http://localhost:3000/callback',
    },
    europeClient: {
      clientId: '0oa5azj18aRpn9bot0x7',
      redirectUrl: 'http://localhost:3000/it/callback',
    },
    europeBaseUrl: 'https://login.uat.viasat.eu/oauth2/aus4k4k4zl1B3v5Gh0x7',
    baseUrl: 'https://login.uat.viasat.com/oauth2/aus2euitmtjYDBklu1d7',
  },
  dev: {
    appClient: {
      clientId: '0oa4j01uuyUl9qCIO1d7',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: '0oa4izxzauralNIPr1d7',
      redirectUrl: 'https://my-viasat-dev.icat.viasat.io/callback',
    },
    europeClient: {
      clientId: '',
      redirectUrl: 'https://my-viasat-dev.icat.viasat.io/it/callback',
    },
    europeBaseUrl: 'https://login.uat.viasat.eu/oauth2/aus4k4k4zl1B3v5Gh0x7',
    baseUrl: 'https://login.uat.viasat.com/oauth2/aus2euitmtjYDBklu1d7',
  },
  test: {
    appClient: {
      clientId: '0oa4j01uuyUl9qCIO1d7',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: '0oa4j04fdpc02rQY41d7',
      redirectUrl: 'https://my-viasat-test.icat.viasat.io/callback',
    },
    europeClient: {
      clientId: '0oa5b09ygmkqI4i2f0x7',
      redirectUrl: 'https://my-viasat-test.icat.viasat.io/it/callback',
    },
    europeBaseUrl: 'https://login.uat.viasat.eu/oauth2/aus4k4k4zl1B3v5Gh0x7',
    baseUrl: 'https://login.uat.viasat.com/oauth2/aus2euitmtjYDBklu1d7',
  },
  preprod: {
    appClient: {
      clientId: '0oa4j01uuyUl9qCIO1d7',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: '0oa4j0wa2mn0GYWim1d7',
      redirectUrl: 'https://my-viasat-preprod.icat.viasat.io/callback',
    },
    europeClient: {
      clientId: '0oa5b0a9uiZLa9s5g0x7',
      redirectUrl: 'https://my-viasat-preprod.icat.viasat.io/it/callback',
    },
    europeBaseUrl: 'https://login.uat.viasat.eu/oauth2/aus4k4k4zl1B3v5Gh0x7',
    baseUrl: 'https://login.uat.viasat.com/oauth2/aus2euitmtjYDBklu1d7',
  },
  prod: {
    appClient: {
      clientId: '0oa1z84fg6goaTk0m697',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: '0oa1z83muzscFURhw697',
      redirectUrl: 'https://my.viasat.com/callback',
    },
    brazilClient: {
      clientId: '0oa3b2xnzokpCd85t697',
      redirectUrl: 'https://minha.viasat.com/callback',
    },
    europeClient: {
      clientId: '0oa8a1i0j6xufTUuA417',
      redirectUrl: 'https://my.viasat.com/it/callback',
    },
    europeBaseUrl: 'https://login.viasat.eu/oauth2/aus57k2ty8KJskF8P417',
    baseUrl: 'https://login.viasat.com/oauth2/auss5o0l1DDF9mIke696',
  },
};

const mySSO: EnvironmentObject = {
  devLocal: {
    appClient: {
      clientId: 'my-viasat-mobile',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: 'my-viasat-web-local-dev',
      redirectUrl: 'http://localhost:3000/callback',
    },
    baseUrl: 'https://mysso.my.test.viasat.com',
    europeBaseUrl: '',
  },
  dev: {
    appClient: {
      clientId: 'my-viasat-mobile',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: 'my-viasat-web-dev',
      redirectUrl: 'https://my-viasat-dev.icat.viasat.io/callback',
    },
    baseUrl: 'https://mysso.my.test.viasat.com',
    europeBaseUrl: '',
  },
  test: {
    appClient: {
      clientId: 'my-viasat-mobile',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: 'my-viasat-web-test',
      redirectUrl: 'https://my-viasat-test.icat.viasat.io/callback',
    },
    baseUrl: 'https://mysso.my.test.viasat.com',
    europeBaseUrl: '',
  },
  preprod: {
    appClient: {
      clientId: 'my-viasat-mobile',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: 'my-viasat-web-preprod',
      redirectUrl: 'https://my-viasat-preprod.icat.viasat.io/callback',
    },
    baseUrl: 'https://mysso.my.test.viasat.com',
    europeBaseUrl: '',
  },
  prod: {
    appClient: {
      clientId: 'my-viasat-mobile-pac',
      redirectUrl: 'viasat00004://callback',
    },
    webClient: {
      clientId: 'my-viasat-web',
      redirectUrl: 'https://my.viasat.com/callback',
    },
    baseUrl: 'https://mysso.my.viasat.com',
    europeBaseUrl: '',
  },
};

export const authParams: AuthParams = {
  okta,
  mySSO,
};
