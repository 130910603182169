import { PixelAPI, TrackerConfig } from './types';
import { injectScript } from './utils/dom-helper';
import { DEFAULT_PARAMS, PIXEL_CORE_SCRIPT_URL } from './constants';

let _pixelInstance: any;
let partyId = '';

const retryAnalyticsEvents = (fn: () => void) => {
  const retryInterval = setInterval(() => {
    if (_pixelInstance) {
      fn();
      clearInterval(retryInterval);
    }
  }, 1000);
};

const init: PixelAPI['init'] = async (
  trackerConfig: TrackerConfig | undefined
) => {
  if (_pixelInstance) return Promise.resolve(true);

  const w: any = window ?? {};

  if (w['pixel']) {
    _pixelInstance = w['pixel'];
    return Promise.resolve(true);
  }

  const config = (window as any)['pixel_config'] ?? {
    ...DEFAULT_PARAMS,
    ...trackerConfig,
  };

  if (!config.appId)
    throw new Error('[analytics] - cannot init analytics without an appId');

  w.GlobalSnowplowNamespace = w.GlobalSnowplowNamespace || [];
  w.GlobalSnowplowNamespace.push('pixel');

  w['pixel'] = (...args: any[]): void => {
    const pixel = w['pixel'];
    if (!pixel.q) {
      pixel.q = [];
    }
    pixel.q.push(args);
  };

  w['pixel'].q = w['pixel'].q || [];

  await injectScript(PIXEL_CORE_SCRIPT_URL);

  const { appId, env, collectorUrl, forceUnsecureTracker } = config;

  const isDev = env === 'DEV' || env === 'DEV_LOCAL';
  const cookieDomain = isDev ? 'localhost' : 'viasat.com';

  w['pixel']('newTracker', appId, collectorUrl, {
    appId,
    cookieDomain,
    eventMethod: 'get',
    forceUnsecureTracker, // set this to true if you're checking localhost:9090
  });

  _pixelInstance = w['pixel'];
  return Promise.resolve(true);
};

const setUserId: PixelAPI['setUserId'] = async (userId) => {
  partyId = userId;
  retryAnalyticsEvents(() => {
    _pixelInstance('setUserId', userId);
  });
};

const addGlobalContexts: PixelAPI['addGlobalContexts'] = async (context) => {
  retryAnalyticsEvents(() => {
    _pixelInstance('addGlobalContexts', [context]);
  });
};

const trackStructEvent: PixelAPI['trackStructEvent'] = async (
  category,
  action,
  eventParams,
  hasDelay = true
) => {
  adobeTracker(`${category} ${action}`, { eventParams });

  if (hasDelay) {
    retryAnalyticsEvents(() => {
      _pixelInstance(
        'trackStructEvent',
        category,
        action,
        eventParams.label ?? '',
        eventParams.property ?? '',
        eventParams.value ?? 0,
        eventParams?.context
      );
    });
    return;
  }

  _pixelInstance &&
    _pixelInstance(
      'trackStructEvent',
      category,
      action,
      eventParams.label ?? '',
      eventParams.property ?? '',
      eventParams.value ?? 0,
      eventParams?.context
    );
};

const trackSelfDescribingEvent: PixelAPI['trackSelfDescribingEvent'] = async (
  event,
  context
) => {
  adobeTracker(event.schema, { event, context });

  retryAnalyticsEvents(() =>
    _pixelInstance('trackSelfDescribingEvent', event, context)
  );
};

const adobeTracker = (eventName: string, otherData: any) => {
  const w: any = window ?? {};
  w.adobeDataLayer?.push({
    event: 'aaEvent',
    eventData: { eventName },
    userData: { partyId },
    otherData,
  });
};

export const pixelAPI: PixelAPI = {
  init,
  setUserId,
  trackStructEvent,
  trackSelfDescribingEvent,
  addGlobalContexts,
};
