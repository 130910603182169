import { createSlice } from '@reduxjs/toolkit';

export type AddressType = 'shipping' | 'billing';

export type AddressUpdateState = {
  addressLine1: string;
  addressLine2: string;
  state: string;
  municipality: string;
  postalCode: string;
  countryCode: string;
  addressSelection: string;
  addressType: AddressType | null;
  successful: boolean | null;
};

const initialState: AddressUpdateState = {
  addressLine1: '',
  addressLine2: '',
  state: '',
  municipality: '',
  postalCode: '',
  countryCode: '',
  addressSelection: 'recommended',
  addressType: null,
  successful: null,
};

export const updateAddressSlice = createSlice({
  name: 'updateAddress',
  initialState: initialState,
  reducers: {
    setAddressType: (state, { payload }: { payload: AddressType | null }) => {
      state.addressType = payload;
    },
    setAddressLine1: (state, { payload }: { payload: string }) => {
      state.addressLine1 = payload;
    },
    setAddressLine2: (state, { payload }: { payload: string }) => {
      state.addressLine2 = payload;
    },
    setState: (state, { payload }: { payload: string }) => {
      state.state = payload;
    },
    setMunicipality: (state, { payload }: { payload: string }) => {
      state.municipality = payload;
    },
    setPostalCode: (state, { payload }: { payload: string }) => {
      state.postalCode = payload;
    },
    setCountryCode: (state, { payload }: { payload: string }) => {
      state.countryCode = payload;
    },
    setAddressSelection: (state, { payload }: { payload: string }) => {
      state.addressSelection = payload;
    },
    setUpdateSuccessful: (state, { payload }: { payload: boolean }) => {
      state.successful = payload;
    },
    submitAddress: (state) => state,
    updateAddress: (state) => state,
    resetAddress: (state) => {
      state = initialState;
    },
  },
});

export const {
  setAddressType,
  setAddressLine1,
  setAddressLine2,
  setState,
  setMunicipality,
  setPostalCode,
  setCountryCode,
  setAddressSelection,
  submitAddress,
  updateAddress,
  setUpdateSuccessful,
  resetAddress,
} = updateAddressSlice.actions;

export const selectUpdateAddress = (state: {
  updateAddress: AddressUpdateState;
}) => state.updateAddress;
