import { createSlice } from '@reduxjs/toolkit';
import {
  Address,
  AddressGroup,
  PhoneGroup,
  AccountType,
  Phone,
  UpdateAccountInfoSmbInput,
  UpdateAccountInfoInput,
} from '@mfe/shared/schema-types';

export interface UserInfoState {
  loading: boolean;
  wasUpdateSuccessful: boolean | null;
  userInfo: {
    username: string;
    partyId: string;
    accountNumber: string;
    address: AddressGroup;
    isPreInstall: boolean;
    accountType: AccountType;
    businessName: string;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: PhoneGroup;
    accountHolder: IndividualInfo;
    isACPEligible: boolean;
  };
}

interface IndividualInfo {
  fullName: string;
  firstName: string;
  lastName: string;
  partyId: string;
  email: string;
  phone: PhoneGroup;
}

const blankAddress: Address = {
  addressLines: [],
  region: '',
  postalCode: '',
  municipality: '',
  countryCode: '',
};

const blankPhone: Phone = {
  phoneNumber: '',
  labels: [],
};

export const initialState: UserInfoState = {
  loading: true,
  wasUpdateSuccessful: null,
  userInfo: {
    businessName: '',
    fullName: '',
    firstName: '',
    lastName: '',
    username: '',
    partyId: '',
    accountNumber: '',
    email: '',
    phone: {
      primary: blankPhone,
      mobile: null,
      secondary: blankPhone,
    },
    address: {
      billing: blankAddress,
      service: blankAddress,
      shipping: blankAddress,
    },
    isPreInstall: false,
    isACPEligible: false,
    accountType: AccountType.Residential,
    accountHolder: {
      fullName: '',
      firstName: '',
      lastName: '',
      partyId: '',
      email: '',
      phone: {
        primary: blankPhone,
        mobile: null,
        secondary: blankPhone,
      },
    },
  },
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (
      state,
      { payload }: { payload: Partial<UserInfoState['userInfo']> }
    ) => {
      state.loading = false;
      const address = state.userInfo.address;
      const accountHolder = state.userInfo.accountHolder;
      state.userInfo = { ...state.userInfo, ...payload };
      state.userInfo.address = {
        ...address,
        ...payload.address,
      };
      state.userInfo.accountHolder = {
        ...accountHolder,
        ...payload.accountHolder,
      };
    },
    setAddress: (state, { payload }: { payload: AddressGroup }) => {
      state.userInfo.address = {
        ...state.userInfo.address,
        ...payload,
      };
    },
    setUpdateFailed: (state) => {
      state.wasUpdateSuccessful = false;
    },
    setUpdateSuccess: (state) => {
      state.wasUpdateSuccessful = true;
    },
    resetUpdateStatus: (state) => {
      // state.isUpdateFailed = false;
      state.wasUpdateSuccessful = null;
    },
    updateContactInfo: (state, _: { payload: UpdateAccountInfoInput }) => {
      state.loading = true;
    },
    updateContactInfoSMB: (
      state,
      _: { payload: UpdateAccountInfoSmbInput }
    ) => {
      state.loading = true;
    },
    refetchUserInfo: (state) => {
      state.loading = true;
    },
    reset: () => initialState,
  },
});

export const {
  setUserInfo,
  setUpdateFailed,
  setUpdateSuccess,
  refetchUserInfo,
  updateContactInfo,
  updateContactInfoSMB,
  reset,
  setAddress,
  resetUpdateStatus,
} = userInfoSlice.actions;

export const selectUserInfo = (state: {
  userInfo: UserInfoState;
}): UserInfoState => {
  return { ...state.userInfo };
};
