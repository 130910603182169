import { FetchWithErrorsQuery } from '@mfe/shared/redux/graphql';
import { Assessment, AssessmentName } from '@mfe/shared/schema-types';
import { createSlice } from '@reduxjs/toolkit';

const HOME_NETWORK_ASSESSMENT_NAMES = [
  AssessmentName.DishAlignment,
  AssessmentName.DishToModemConnection,
  AssessmentName.WifiModem,
];
const VIASAT_NETWORK_ASSESSMENT_NAMES = [
  AssessmentName.ViasatNetwork,
  AssessmentName.CurrentWeatherStatus,
];

export type NetworkState = {
  loading: boolean;
  error: FetchWithErrorsQuery['errors'] | null;
  diagnosticData: Assessment[] | null;
  runDate: string | null;
  isRebootModemModalOpen: boolean;
  isModemOnline: boolean;
};
export const initialState: NetworkState = {
  loading: false,
  error: null,
  diagnosticData: null,
  runDate: null,
  isRebootModemModalOpen: false,
  isModemOnline: true,
};

export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    runDiagnostic: (state) => {
      state.loading = true;
      state.error = null;
      state.diagnosticData = null;
    },

    setDiagnostic: (state, { payload }: { payload: Assessment[] }) => {
      state.loading = false;
      state.error = null;
      state.diagnosticData = payload;
    },

    resetDiagnosticData: (state) => {
      state.diagnosticData = null;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    setRunDate: (state, { payload }) => {
      state.runDate = payload;
    },

    setIsRebootModemModalOpen: (state, { payload }) => {
      state.isRebootModemModalOpen = payload;
    },
    setModemStatus: (state, { payload }: { payload: boolean }) => {
      state.isModemOnline = payload;
    },
    resetModemIssue: (state) => {
      const updateDiagnosticData = state.diagnosticData?.map((assessment) => {
        if (assessment.name === AssessmentName.WifiModem) {
          return { ...assessment, isOK: true };
        }
        return assessment;
      });
      if (updateDiagnosticData) {
        state.diagnosticData = updateDiagnosticData;
      }
    },
  },
});

export const {
  runDiagnostic,
  setDiagnostic,
  setRunDate,
  setError,
  resetDiagnosticData,
  setIsRebootModemModalOpen,
  resetModemIssue,
  setModemStatus,
} = networkSlice.actions;

export const selectNetwork = (state: { network: NetworkState }) =>
  state.network;

export const selectNetworkIssues = (state: { network: NetworkState }) => {
  const diagnosticData = state.network.diagnosticData;
  if (!diagnosticData) {
    return null;
  }
  const diagnosticIssues = diagnosticData.filter(
    (diagnostic) => diagnostic.isOK === false
  );

  if (!diagnosticIssues.length) {
    return null;
  }
  return diagnosticIssues;
};

export const selectDiagnostics = (state: { network: NetworkState }) => {
  const diagnosticData = state.network.diagnosticData;
  const runDate = state.network.runDate;
  if (!diagnosticData) {
    return { viasatAssessments: null, homeAssessments: null, runDate };
  }

  const viasatAssessments: Assessment[] = [],
    diagnosticIssues: Assessment[] = [],
    homeAssessments: Assessment[] = [];

  diagnosticData.forEach((assessment) => {
    if (VIASAT_NETWORK_ASSESSMENT_NAMES.includes(assessment.name)) {
      viasatAssessments.push(assessment);
    }
    if (HOME_NETWORK_ASSESSMENT_NAMES.includes(assessment.name)) {
      homeAssessments.push(assessment);
    }
    if (!assessment.isOK) {
      diagnosticIssues.push(assessment);
    }
  });

  return { viasatAssessments, homeAssessments, diagnosticIssues, runDate };
};
