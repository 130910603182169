import React from 'react';
import { colors } from '@vst/beam';

export interface BarProps {
  color: string;
  backgroundColor?: string;
  percentage: number;
  testID?: string;
  width: string;
  height: number;
  backgroundBorderRadius?: string;
  backgroundBorderLeft?: string;
  highlightBorderRadius?: string;
}

export const Bar = ({
  color,
  backgroundColor = colors.gray[200],
  percentage,
  width,
  height,
  backgroundBorderRadius = '4px',
  highlightBorderRadius = '4px',
  backgroundBorderLeft,
  testID,
}: BarProps) => {
  const highlightWidth = `${
    percentage < 4 && percentage > 0 ? 4 : percentage
  }%`;

  return (
    <div
      style={{
        width,
        height,
        display: 'flex',
        alignItems: 'stretch',
      }}
      data-cy={testID}
    >
      <GaugeBackground
        borderLeft={backgroundBorderLeft}
        backgroundColor={backgroundColor}
        borderRadius={backgroundBorderRadius}
      />
      <GaugeHighlight
        borderRadius={highlightBorderRadius}
        width={highlightWidth}
        color={color}
      />
    </div>
  );
};

interface GaugeBackgroundProps {
  borderRadius: string;
  backgroundColor: string;
  borderLeft?: string;
}

const GaugeBackground = ({
  borderRadius,
  backgroundColor,
  borderLeft,
}: GaugeBackgroundProps) => (
  <div
    style={{
      width: '100%',
      backgroundColor: backgroundColor,
      borderRadius,
      borderLeft,
    }}
  />
);

interface GaugeHighlightProps {
  width: string;
  color: string;
  borderRadius: string;
}

const GaugeHighlight = ({
  width,
  color = colors.teal[500],
  borderRadius,
}: GaugeHighlightProps) => (
  <div
    style={{
      marginLeft: '-100%',
      width,
      background: color,
      borderRadius,
    }}
  />
);
