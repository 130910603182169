enum CHARACTERISTIC_VALUES {
  USAGE_METER_UNLIMITED = 'unlimited',
  FAMILY_UNLEASHED = 'unleashed',
  UNLIMITED_AVERAGE = 'unlimited_average',
}

export const hasUnlimitedUsageMeter = (usageMeter: string) =>
  usageMeter
    ?.toLowerCase()
    .includes(CHARACTERISTIC_VALUES.USAGE_METER_UNLIMITED);

export const isUnleashed = (family: string) =>
  family?.toLowerCase().includes(CHARACTERISTIC_VALUES.FAMILY_UNLEASHED);

export const hasUnlimitedAverage = (usageMeter: string) =>
  usageMeter?.toLowerCase().includes(CHARACTERISTIC_VALUES.UNLIMITED_AVERAGE);
