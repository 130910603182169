import { takeLatest, takeEvery, all, spawn } from 'redux-saga/effects';
import { SagaInput } from '@mfe/shared/redux/shared';
import * as actions from './actions';

function log(action: any) {
  if (process.env['NODE_ENV'] !== 'production') console.log(action);
}

function goToUrl({ payload }: SagaInput<typeof actions.goToUrl>) {
  window.location.assign(payload);
}

function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function* watchUtils() {
  yield spawn(function* () {
    yield all([
      takeEvery('*', log),
      takeLatest(actions.goToUrl.type, goToUrl),
      takeLatest(actions.scrollToTop.type, scrollToTop),
    ]);
  });
}

export { goToUrl, scrollToTop } from './actions';
