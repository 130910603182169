import { Badge, Txt } from '@vst/beam';
import styles from './index.module.scss';
import { AddonIcon } from '../add-on-icon';

export type AddonCardSmallProps = {
  title?: string;
  kind?: string;
  description?: string;
  badge: React.ComponentProps<typeof Badge>;
};

export const AddonCardSmall: React.FC<AddonCardSmallProps> = (props) => {
  const { title, description, kind, badge } = props;
  return (
    <div className={styles['layout']}>
      <div>
        <AddonIcon className={styles['image']} kind={kind ?? ''} />
      </div>
      <div className={styles['content']}>
        <div className={styles['header']}>
          <Txt variant="bodyLargeBold">{title}</Txt>
          <Badge {...badge} />
        </div>
        <Txt variant="bodySmallRegular">{description}</Txt>
      </div>
    </div>
  );
};
