export function buildIgluSchema<
  AnalyticsVendor extends string,
  AnalyticsEventName extends string,
  SchemaVersion extends string
>(
  vendor: AnalyticsVendor,
  eventName: AnalyticsEventName,
  version: SchemaVersion
) {
  return `iglu:${vendor}/${eventName}/jsonschema/${version}`;
}
