/*
 * TODO: Use these icons from '@vst/beam-icons/icons'
 */
export const Construction = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M70 21.6666C70 28.1 64.7667 33.3333 58.3333 33.3333C56.9667 33.3333 55.6667 33.0666 54.4667 32.6333L16.5333 70.5333L9.46667 63.4667L34.1667 38.7666L28.2333 32.8333L25.8667 35.2L21.1667 30.5V39.9333L18.8 42.3L7 30.5L9.36667 28.1333H18.7667L14.0667 23.4333L25.8667 11.6333C29.7667 7.73331 36.1 7.73331 40 11.6333L32.9333 18.7L37.6333 23.4L35.2667 25.7666L41.2 31.7L47.3667 25.5333C46.9333 24.3333 46.6667 23.0333 46.6667 21.6666C46.6667 15.2333 51.9 9.99998 58.3333 9.99998C60.2667 9.99998 62.0667 10.5333 63.6667 11.3666L54.6667 20.3666L59.6333 25.3333L68.6333 16.3333C69.4667 17.9333 70 19.7333 70 21.6666Z" />
      <path d="M45.9423 50.5729L53.0133 43.5019L73.0006 63.4892L65.9296 70.5602L45.9423 50.5729Z" />
    </svg>
  );
};

export const Language = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M39.9667 6.66669C21.5667 6.66669 6.66669 21.6 6.66669 40C6.66669 58.4 21.5667 73.3334 39.9667 73.3334C58.4 73.3334 73.3334 58.4 73.3334 40C73.3334 21.6 58.4 6.66669 39.9667 6.66669ZM63.0667 26.6667H53.2334C52.1667 22.5 50.6334 18.5 48.6334 14.8C54.7667 16.9 59.8667 21.1667 63.0667 26.6667ZM40 13.4667C42.7667 17.4667 44.9334 21.9 46.3667 26.6667H33.6334C35.0667 21.9 37.2334 17.4667 40 13.4667ZM14.2 46.6667C13.6667 44.5334 13.3334 42.3 13.3334 40C13.3334 37.7 13.6667 35.4667 14.2 33.3334H25.4667C25.2 35.5334 25 37.7334 25 40C25 42.2667 25.2 44.4667 25.4667 46.6667H14.2ZM16.9334 53.3334H26.7667C27.8334 57.5 29.3667 61.5 31.3667 65.2C25.2334 63.1 20.1334 58.8667 16.9334 53.3334V53.3334ZM26.7667 26.6667H16.9334C20.1334 21.1334 25.2334 16.9 31.3667 14.8C29.3667 18.5 27.8334 22.5 26.7667 26.6667V26.6667ZM40 66.5334C37.2334 62.5334 35.0667 58.1 33.6334 53.3334H46.3667C44.9334 58.1 42.7667 62.5334 40 66.5334ZM47.8 46.6667H32.2C31.9 44.4667 31.6667 42.2667 31.6667 40C31.6667 37.7334 31.9 35.5 32.2 33.3334H47.8C48.1 35.5 48.3334 37.7334 48.3334 40C48.3334 42.2667 48.1 44.4667 47.8 46.6667ZM48.6334 65.2C50.6334 61.5 52.1667 57.5 53.2334 53.3334H63.0667C59.8667 58.8334 54.7667 63.1 48.6334 65.2V65.2ZM54.5334 46.6667C54.8 44.4667 55 42.2667 55 40C55 37.7334 54.8 35.5334 54.5334 33.3334H65.8C66.3334 35.4667 66.6667 37.7 66.6667 40C66.6667 42.3 66.3334 44.5334 65.8 46.6667H54.5334Z" />
    </svg>
  );
};

export const Phone = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.8 16.6667C22 19.6333 22.5 22.5333 23.3 25.3L19.3 29.3C17.9333 25.3 17.0667 21.0667 16.7667 16.6667H21.8V16.6667ZM54.6667 56.7333C57.5 57.5333 60.4 58.0333 63.3333 58.2333V63.2C58.9333 62.9 54.7 62.0333 50.6667 60.7L54.6667 56.7333V56.7333ZM25 10H13.3333C11.5 10 10 11.5 10 13.3333C10 44.6333 35.3667 70 66.6667 70C68.5 70 70 68.5 70 66.6667V55.0333C70 53.2 68.5 51.7 66.6667 51.7C62.5333 51.7 58.5 51.0333 54.7667 49.8C54.4333 49.6667 54.0667 49.6333 53.7333 49.6333C52.8667 49.6333 52.0333 49.9667 51.3667 50.6L44.0333 57.9333C34.6 53.1 26.8667 45.4 22.0667 35.9667L29.4 28.6333C30.3333 27.7 30.6 26.4 30.2333 25.2333C29 21.5 28.3333 17.5 28.3333 13.3333C28.3333 11.5 26.8333 10 25 10Z" />
    </svg>
  );
};

export const Security = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M40 3.33331L10 16.6666V36.6666C10 55.1666 22.8 72.4666 40 76.6666C57.2 72.4666 70 55.1666 70 36.6666V16.6666L40 3.33331ZM40 39.9666H63.3333C61.5667 53.7 52.4 65.9333 40 69.7666V40H16.6667V21L40 10.6333V39.9666Z" />
    </svg>
  );
};

export const Video = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30 23.3333V50L53.3334 36.6667L30 23.3333ZM70 10H10C6.33337 10 3.33337 13 3.33337 16.6667V56.6667C3.33337 60.3333 6.33337 63.3333 10 63.3333H26.6667V70H53.3334V63.3333H70C73.6667 63.3333 76.6667 60.3333 76.6667 56.6667V16.6667C76.6667 13 73.6667 10 70 10ZM70 56.6667H10V16.6667H70V56.6667Z" />
    </svg>
  );
};

export const Briefcase = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M67 20H53.6667V13.3334C53.6667 9.63335 50.7 6.66669 47 6.66669H33.6667C29.9667 6.66669 27 9.63335 27 13.3334V20H13.6667C9.96667 20 7.03333 22.9667 7.03333 26.6667L7 63.3334C7 67.0334 9.96667 70 13.6667 70H67C70.7 70 73.6667 67.0334 73.6667 63.3334V26.6667C73.6667 22.9667 70.7 20 67 20ZM47 20H33.6667V13.3334H47V20Z" />
    </svg>
  );
};
