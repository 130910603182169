import { MvEnv, Locale, OAuthClient } from '@mfe/shared/schema-types';

import { authParams } from './constants';

const getAuthParams = (
  env: MvEnv,
  authMethod: 'web' | 'mobile',
  locale: Locale
) => {
  const baseUrl =
    locale === Locale.ItIt
      ? authParams.okta[env].europeBaseUrl
      : authParams.okta[env].baseUrl;

  let oAuthClient: OAuthClient =
    authParams.okta[env][authMethod === 'web' ? 'webClient' : 'appClient'];

  if (locale === Locale.ItIt) {
    oAuthClient = authParams.okta[env]['europeClient'] as OAuthClient;
  }
  if (env === MvEnv.Prod && authMethod === 'web' && locale === Locale.PtBr) {
    oAuthClient = authParams.okta[MvEnv.Prod]['brazilClient'] as OAuthClient;
  }

  const { clientId, redirectUrl } = oAuthClient;

  return {
    client: { clientId, redirectUrl },
    baseUrl,
  };
};

export default getAuthParams;
