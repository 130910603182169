import React from 'react';
import { Button, IllustrativeIcon, Surface } from '@vst/beam';
import { CheckMark } from '@vst/beam-icons/illustrative-icons';

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface FlowSuccessOutcomeProps {
  goToOverview: () => void;
  children: React.ReactNode;
}

export const FlowSuccessOutcome = ({
  goToOverview,
  children,
}: FlowSuccessOutcomeProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'AppointmentScheduled',
  });

  return (
    <Surface
      data-cy="flow-success-outcome-component"
      className={styles['container']}
      p="40px"
      radius="16px"
      mb="56px"
    >
      <IllustrativeIcon
        data-cy="flow-success-outcome-CheckMark-Icon"
        name="CheckMark"
        icon={CheckMark}
        color="green_600"
        width={95}
        height={95}
      />
      {children}

      <Button
        className={styles['backToMyViasat']}
        data-cy="flow-success-outcome-goBackMyViasat"
        fluid
        onClick={goToOverview}
      >
        {t('backToMyViasat')}
      </Button>
    </Surface>
  );
};
