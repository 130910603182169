import { TextField, Surface } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import {
  setAddressLine1,
  setAddressLine2,
  setPostalCode,
  setMunicipality,
  setState,
  selectUpdateAddress,
} from '@mfe/to-be-migrated/redux/updateAddress';
import { useTranslation } from 'react-i18next';
import { validateAddress } from './validation';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';

export const UpdateAddressForm = () => {
  const dispatch = useDispatch();

  const {
    addressLine1,
    addressLine2,
    postalCode,
    municipality,
    state,
    addressType,
  } = useSelector(selectUpdateAddress);

  const {
    locale: { displayLanguage },
  } = useSelector(selectLocale);

  const { t } = useTranslation('UpdateAddress', {
    keyPrefix: 'form.textFields',
  });

  return (
    <div className={styles['form-container']}>
      <TextField
        data-cy="address-line-1"
        id="addressLine1"
        name="addressLine1"
        labelProps={{ labelText: t('streetAddress') }}
        required={true}
        fluid={true}
        onChange={(e) => dispatch(setAddressLine1(e.currentTarget.value))}
        value={addressLine1}
        error={validateAddress(displayLanguage, {
          input: addressLine1,
          addressType: addressType ?? 'shipping',
          type: 'addressLine1',
        })}
      />
      <Surface className={styles['layout-container']}>
        <TextField
          data-cy="address-line-2"
          id="addressLine2"
          name="addressLine2"
          labelProps={{ labelText: t('addressLine2') }}
          fluid={true}
          onChange={(e) => dispatch(setAddressLine2(e.currentTarget.value))}
          value={addressLine2}
        />
        <TextField
          data-cy="city"
          id="city"
          name="city"
          labelProps={{ labelText: t('city') }}
          required={true}
          fluid={true}
          onChange={(e) => dispatch(setMunicipality(e.currentTarget.value))}
          value={municipality}
          error={validateAddress(displayLanguage, {
            input: municipality,
            type: 'city',
          })}
        />
      </Surface>
      <Surface className={styles['layout-container']}>
        <TextField
          data-cy="state"
          id="state"
          name="state"
          labelProps={{ labelText: t('state') }}
          required={true}
          fluid={true}
          onChange={(e) => dispatch(setState(e.currentTarget.value))}
          value={state}
          error={validateAddress(displayLanguage, {
            input: state,
            type: 'state',
          })}
        />
        <TextField
          data-cy="postal-code"
          id="postalCode"
          name="postalCode"
          labelProps={{ labelText: t('zipPostalCode') }}
          required={true}
          fluid={true}
          onChange={(e) => dispatch(setPostalCode(e.currentTarget.value))}
          value={postalCode}
          error={validateAddress(displayLanguage, {
            input: postalCode,
            type: 'postalCode',
          })}
        />
      </Surface>
    </div>
  );
};
