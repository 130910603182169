import { ApolloLink } from '@apollo/client';

export const logTimeLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });

  const response = forward(operation);
  if (!response || !response.map) return response;

  return response.map((data) => {
    const time =
      new Date().getTime() - operation.getContext()['start'].getTime();
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      rg4js('trackEvent', {
        type: 'customTiming',
        name: `${operation.operationName}`,
        duration: time,
      });
    } catch {
      // No op
    }

    return data;
  });
});
