export function injectScript(scriptUrl: string) {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];

    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = scriptUrl;

    script.onload = () => resolve(true);
    script.onerror = (error: unknown) => reject(error);

    head.appendChild(script);
  });
}
