import { queryString } from '@viasat/res-apps-lib/build/utils';
import { MvEnv, OAuthClient } from '@mfe/shared/schema-types';
import { TokenInfo } from './authUserSlice';

export const TOKEN_INFO_COOKIE_NAME = 'tokenInfo';
export const USER_ID_COOKIE_NAME = 'user_id';
export const DISMISS_COOKIE_NAME = 'dismiss';
export const REDIRECT_PATH_COOKIE_NAME = 'redirectPath';
export const MS_IN_A_MINUTE = 60000;
export type LoginType = 'password' | 'cookie';

const OLD_LOGOUT_PATH = '/federation/logout';
// MySSO OAuth Authorize URL: https://wiki.viasat.com/display/IAM/SSO+-+OAuth+Integration#SSOOAuthIntegration-3.2Authorize
const OLD_AUTHORIZE_PATH = '/federation/oauth2/authorize';
const OLD_RESPONSE_TYPE = 'code';
const OLD_SCOPE = ['uid'];

const LOGOUT_PATH = '/v1/logout';
const AUTHORIZE_PATH = '/v1/authorize';
const RESPONSE_TYPE = 'code';
const SCOPE = ['openid', 'profile', 'email', 'offline_access', 'sbn'];

export const getAuthUrls = (
  client: OAuthClient,
  baseUrl: string,
  idToken?: string
): { loginUrl: string; logoutUrl: string } => {
  if (baseUrl.includes('mysso')) {
    const loginUrl = `${baseUrl}${OLD_AUTHORIZE_PATH}?${queryString({
      client_id: client.clientId,
      response_type: OLD_RESPONSE_TYPE,
      redirect_uri: client.redirectUrl ?? '',
      scope: OLD_SCOPE.join(' '),
    })}`;
    const logoutUrl = `${baseUrl}${OLD_LOGOUT_PATH}?${queryString({
      goto: loginUrl,
    })}`;
    return { loginUrl, logoutUrl };
  }

  const loginUrl = `${baseUrl}${AUTHORIZE_PATH}?${queryString({
    client_id: client.clientId,
    response_type: RESPONSE_TYPE,
    redirect_uri: client.redirectUrl ?? '',
    scope: SCOPE.join(' '),
    state: 'asdfasdf',
  })}`;
  const logoutUrl = `${baseUrl}${LOGOUT_PATH}?${queryString({
    id_token_hint: idToken ?? '',
    post_logout_redirect_uri: loginUrl,
  })}`;
  return { loginUrl, logoutUrl: idToken ? logoutUrl : loginUrl };
};

// Slightly modified from: https://stackoverflow.com/a/1099670
export const getURLParams = (url: string): Record<string, string> => {
  const re = /[?&]?([^=]+)=([^&]*)/g;
  const qs = url.replace(/.*\?/, '').replace(/\+/g, ' ');
  const params: Record<string, string> = {};
  let tokens: RegExpExecArray | null;

  // Loop over entire url
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const retrieveAuthCode = (
  url: string | undefined,
  client: OAuthClient
): string | null => {
  if (!url) return null;
  const params = getURLParams(url);
  if (url.startsWith(client.redirectUrl ?? '') && params['code']) {
    return params['code'];
  }

  return null;
};

export const parseTokenInfoCookie = (token?: string): TokenInfo | null => {
  if (!token) return null;

  try {
    const parsedToken: TokenInfo = JSON.parse(token);
    const expirationTime = new Date(parsedToken.accessTokenExpirationTime);
    if (!parsedToken.accessToken || isNaN(expirationTime.getTime())) {
      return null;
    }

    return parsedToken;
  } catch {
    return null;
  }
};

export const getMFEEnvFromUrl = (url: string): MvEnv => {
  if (url.includes(MvEnv.Dev)) return MvEnv.Dev;
  if (url.includes(MvEnv.Test)) return MvEnv.Test;
  if (url.includes(MvEnv.Preprod)) return MvEnv.Preprod;
  if (url.includes(MvEnv.Prod)) return MvEnv.Prod;
  return MvEnv.DevLocal;
};

export const getTokenFromUrl = () => {
  const url = new URL(window.location.href);
  const env = getMFEEnvFromUrl(window.location.href);
  const urlSearchParams: URLSearchParams = new URLSearchParams(url.search);
  const token = urlSearchParams.get('token') as string;
  const productInstanceId = urlSearchParams.get('productInstanceId');
  const partyId = urlSearchParams.get('partyId');

  return { token, productInstanceId, partyId, env };
};
