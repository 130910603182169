import { call } from 'redux-saga/effects';
import { gql } from '@apollo/client';
import { DocumentNode, GraphQLError } from 'graphql';
import { Saga } from 'redux-saga';
import { Query, Mutation } from '@mfe/shared/schema-types';
import {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  QueryOptions,
} from '@apollo/client';

class ReduxGraphQLClient {
  public clientFetcherSaga: Saga | undefined;

  public initialize(saga: Saga) {
    this.clientFetcherSaga = saga;
  }

  get getClient(): Saga {
    if (!this.clientFetcherSaga) {
      const message =
        'Cannot use graphql redux methods without initializing the redux graphql client';
      console.error(message);
      throw new Error(message);
    }

    return this.clientFetcherSaga;
  }
}
// NOTE: This graphql client must be initialized by the consuming application
// before the sagas calling this will work
export const reduxGraphQL = new ReduxGraphQLClient();

export type ErrorsObj = { [key: string]: { message: string; code: any } };
function parseGraphqlErrors(
  apiErrors: ApolloQueryResult<Query> | FetchResult<Mutation>
): ErrorsObj | null {
  const { errors } = apiErrors;
  if (!errors) {
    return null;
  }

  const result: ErrorsObj = {};
  errors.forEach((err: GraphQLError) => {
    result[err.path as unknown as string] = {
      message: err.message,
      code: err.extensions['code'],
    };
  });

  return result;
}

export type RuntimeError = {
  message: string;
};

type WithErrors = {
  errors: ErrorsObj | null;
  runtimeError?: RuntimeError;
};

export type FetchWithErrorsQuery = WithErrors & {
  data: Query | null;
};
export type FetchWithErrorsMutation = WithErrors & {
  data: Mutation | null;
};

function* fetchWithErrorParsing(
  fetchFunction: ApolloClient<any>['query'] | ApolloClient<any>['mutate'],
  options:
    | Omit<QueryOptions, 'errorPolicy'>
    | Omit<MutationOptions, 'errorPolicy'>
): Generator<any, FetchWithErrorsQuery | FetchWithErrorsMutation> {
  try {
    const result: any = yield call(fetchFunction, {
      ...(options as any),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    });

    return {
      data: result.data,
      errors: parseGraphqlErrors(result),
    };
  } catch (ex: any) {
    return {
      data: null,
      errors: null,
      runtimeError: {
        message: ex.message || 'unknown error',
      },
    };
  }
}

export function* graphqlQuery(options: QueryOptions): any {
  const client = yield reduxGraphQL.getClient();
  const result: ApolloQueryResult<Query> = yield call(client.query, options);

  return result.data;
}

export function* graphqlQueryWithErrors(
  options: Omit<QueryOptions, 'errorPolicy'>
): any {
  const client = yield reduxGraphQL.getClient();
  const result: FetchWithErrorsQuery = yield fetchWithErrorParsing(
    client.query,
    options
  );

  return result;
}

export function* evictFromCache({
  id,
  fieldName,
}: {
  id: string;
  fieldName?: string;
}): any {
  const client = yield reduxGraphQL.getClient();
  client.cache.evict({ id, fieldName });
  client.cache.gc();
}

export function* readApolloCacheQueryFragment(
  fragment: DocumentNode
): Generator<any, unknown, any> {
  const client = yield reduxGraphQL.getClient();
  const data: unknown = yield call(client.readFragment.bind(client), {
    id: 'ROOT_QUERY',
    fragment,
  });

  return data;
}

export function* writeApolloCacheQueryFragment({
  fragment,
  data,
}: {
  fragment: DocumentNode;
  data: unknown;
}): any {
  const client = yield reduxGraphQL.getClient();
  yield call(client.writeFragment.bind(client), {
    id: 'ROOT_QUERY',
    fragment,
    data,
  });
}

export function* graphqlMutation(options: MutationOptions<any, any>): any {
  const client = yield reduxGraphQL.getClient();
  const result: FetchResult<Mutation> = yield call(client.mutate, options);

  return result.data;
}

export function* graphqlMutationWithErrors(
  options: Omit<MutationOptions, 'errorPolicy'>
): any {
  const client = yield reduxGraphQL.getClient();
  const result: FetchWithErrorsMutation = yield fetchWithErrorParsing(
    client.mutate,
    options
  );

  return result;
}

export function* refreshContext() {
  yield call(graphqlQueryWithErrors, {
    query: gql`
      query getRefreshContext {
        getRefreshContext
      }
    `,
    fetchPolicy: 'no-cache',
  });
}
