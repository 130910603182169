import {
  GetCurrentUsagePayload,
  GetDownloadSpeedsPayload,
  GetPlanCharacteristicsPayload,
} from '@mfe/shared/schema-types';

export const initialUsageState: GetCurrentUsagePayload = {
  buyMoreDataCap: 0,
  buyMoreDataRemaining: 0,
  buyMoreDataUsed: 0,
  buyMoreDataUsedRatio: 0,
  daysLeft: null,
  displayedTotalDataCap: 0,
  displayedTotalDataUsed: 0,
  endDate: null,
  freeZoneDataUsed: 0,
  hasBreachedDataAllowance: false,
  monthlyDataCap: 0,
  monthlyDataRemaining: 0,
  monthlyDataUsed: 0,
  monthlyDataUsedRatio: 0,
  standardDataUsed: 0,
  startDate: null,
};

export const initialCharacteristicsState: GetPlanCharacteristicsPayload = {
  bestFor: '',
  dataAllowanceText: '',
  dataCap: '',
  dataCapUnits: '',
  family: '',
  freeZoneTime: '',
  hasUnlimitedUsageMeter: false,
  id: '',
  inflectionPoint: '',
  inflectionPointText: '',
  isBuyMoreAvailable: false,
  isGoodFor: '',
  isUnleashed: false,
  isUnlimited: false,
  name: '',
  titleAddOns: '',
  uploadSpeed: '',
  uploadSpeedText: '',
  usageMeter: '',
};

export const initialDownloadSpeedsState: GetDownloadSpeedsPayload = {
  downloadRangeText: '',
  downloadSpeed: '',
  downloadSpeedText: '',
  downloadUnits: '',
  id: '',
};

export const initialPriceState = '';
