import { allBeamColors } from '@vst/beam/src/react/common';
import { TFunction } from 'i18next';

import {
  ConstructionOutlined,
  LanguageOutlined,
  OnDemandVideoOutlined,
  PhoneOutlined,
  SecurityOutlined,
  WorkOutlined,
} from '@vst/beam-icons/icons';
import { IconComponentType, colors } from '@vst/beam';

export enum AddOnType {
  ViasatVoice = 'Viasat Voice',
  OfficeHours = 'Office Hours',
  ViasatShieldPremium = 'shield',
  StaticIP = 'Static IP',
  ViasatStream = 'Viasat Stream',
  Stream = 'stream',
  Care = 'care',
}

type AddOnPropsType = {
  iconBackground: string | undefined;
  iconName: IconComponentType | undefined;
  iconColor: allBeamColors;
} | null;

export const getProps = (addOnName: string): AddOnPropsType => {
  if (!addOnName) return null;

  if (addOnName.toLowerCase().includes(AddOnType.Stream)) {
    return {
      iconBackground: colors['violet'][100],
      iconName: OnDemandVideoOutlined,
      iconColor: 'violet_500',
    };
  }

  if (addOnName.toLowerCase().includes(AddOnType.Care)) {
    return {
      iconBackground: colors['green'][100],
      iconName: ConstructionOutlined,
      iconColor: 'green_600',
    };
  }

  if (addOnName.toLowerCase().includes(AddOnType.ViasatShieldPremium)) {
    return {
      iconBackground: colors['warm'][100],
      iconName: SecurityOutlined,
      iconColor: 'warm_500',
    };
  }

  switch (addOnName) {
    case AddOnType.ViasatVoice:
      return {
        iconBackground: colors['blue'][100],
        iconName: PhoneOutlined,
        iconColor: 'blue_600',
      };

    case AddOnType.OfficeHours:
      return {
        iconBackground: colors['pink'][100],
        iconName: WorkOutlined,
        iconColor: 'pink_500',
      };

    case AddOnType.StaticIP:
      return {
        iconBackground: colors['gray'][200],
        iconName: LanguageOutlined,
        iconColor: 'gray_700',
      };

    default:
      return {
        iconBackground: undefined,
        iconName: undefined,
        iconColor: 'inherit',
      };
  }
};
