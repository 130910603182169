import React from 'react';
import { Icon, Txt, Logo } from '@vst/beam';
import { FacebookOutlined, Twitter } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ViasatLogoWhite } from '@vst/beam-icons/logos/viasat';

import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { Locale } from '@mfe/shared/schema-types';

import { FeedbackButton } from './feedback-button';
import { Version } from './version';

import styles from './styles.module.scss';

const ViasatLogo = () => (
  <Logo logo={ViasatLogoWhite} size={154} alt="Viasat" />
);

const getTranslationContext = (locale: Locale) => {
  switch (locale) {
    case Locale.PtBr:
      return 'brazil';
    case Locale.ItIt:
      return 'italy';
    default:
      return undefined;
  }
};

export const Footer = () => {
  const { t } = useTranslation('Footer');
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const context = getTranslationContext(userLocale);

  const createATagProps = (
    link: string
  ): React.AnchorHTMLAttributes<HTMLAnchorElement> => ({
    href: link,
    rel: 'noreferrer',
    target: '_blank',
  });

  const FacebookIcon = (
    <a
      {...createATagProps(t('facebookLink', { context }))}
      className={styles['footerButton']}
      data-cy="footer-facebook"
    >
      <Icon icon={FacebookOutlined} size={19} />
    </a>
  );

  const TwitterIcon = (
    <a
      {...createATagProps(t('twitterLink', { context }))}
      className={styles['footerButton']}
      data-cy="footer-twitter"
    >
      <Icon icon={Twitter} size={18} />
    </a>
  );

  const Copyright = (
    <Txt variant="smallRegular" component="span" data-cy="footer-legal">
      {t('copyright', { currentYear: new Date().getFullYear() })}
    </Txt>
  );

  const Legal = (
    <Txt variant="smallRegular" component="span" data-cy="footer-legal">
      <a
        data-cy="footer-legal-link"
        {...createATagProps(t('legalLink', { context }))}
      >
        {t('legal')}
      </a>
    </Txt>
  );

  return (
    <footer className={styles['footerContainer']}>
      <div className={styles['footerLegalAndLogo']}>
        <ViasatLogo />
        <div className={styles['footerLegal']}>
          {Copyright}
          <Version />
          {Legal}
        </div>
      </div>

      <div className={styles['footerButtonsContainer']}>
        {FacebookIcon}
        {TwitterIcon}
      </div>
      <FeedbackButton />
    </footer>
  );
};
