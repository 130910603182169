import 'intersection-observer';

export const inViewObserver = (
  selector: string,
  triggerOnce: boolean,
  analyticsFunction: any
): void => {
  try {
    const selectorElements = document.querySelectorAll(selector);

    if (selectorElements.length) {
      selectorElements.forEach((selectorElement) => {
        let lastTimeout: any;
        const intersect = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                lastTimeout = setTimeout(() => {
                  analyticsFunction();
                }, 1000);

                if (triggerOnce) intersect.unobserve(selectorElement);
              } else {
                if (lastTimeout) clearTimeout(lastTimeout);
              }
            });
          },
          {
            threshold: [0.5],
          }
        );

        intersect.observe(selectorElement);
      });
    }
  } catch (e) {
    // do nothing
  }
};
