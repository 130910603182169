export const useDetectBrowser = (): null | BrowserString => {
  if (!window || !window.navigator || !window.navigator.userAgent) return null;
  const nav = window.navigator,
    ua = window.navigator.userAgent.toLowerCase();
  // Detect browsers (only the ones that have some kind of quirky, we need to work around)
  if (
    nav.appName.toLowerCase().indexOf('microsoft') !== -1 ||
    nav.appName.toLowerCase().match(/trident/gi) !== null
  )
    return 'IE';

  if (ua.match(/android|iphone|kindle|ipad/gi) !== null) return 'MobileWeb';
  if (ua.match(/chrome/gi) !== null) return 'Chrome';
  if (ua.match(/firefox/gi) !== null) return 'Firefox';
  if (ua.match(/safari/gi) !== null) return 'Safari';
  if (ua.match(/webkit/gi) !== null) return 'Webkit';
  if (ua.match(/gecko/gi) !== null) return 'Gecko';
  if (ua.match(/opera/gi) !== null) return 'Opera';
  // If any case miss we will return null
  return null;
};

type BrowserString =
  | 'IE'
  | 'MobileWeb'
  | 'Chrome'
  | 'Firefox'
  | 'Safari'
  | 'Webkit'
  | 'Gecko'
  | 'Opera';
