import { Schedule } from '@mfe/shared/schema-types';

export const excludeTimeSlot = (
  slotStart: string | null,
  availableSlots: Schedule[]
) => {
  if (!slotStart) return availableSlots;

  const filteredSlots = availableSlots.filter(
    (slot: Schedule) => slot.from !== slotStart
  );

  return filteredSlots;
};
