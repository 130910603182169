import { CONTEXT_UI } from './constants';
import {
  ContextProductData,
  Context as AnalyticsContext,
  AnalyticsEventName,
} from './types';

export const contextFactory = (
  properties?: ContextProductData
): AnalyticsContext => ({
  schema: AnalyticsEventName.BUY_MORE_CONTEXT,
  data: {
    ui_version: CONTEXT_UI,
    product_name: '',
    product_type_id: '',
    product_price: '',
    ...properties,
  },
});
