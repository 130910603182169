import { IllustrativeIcon } from '@vst/beam';
import { RoadMap, Alert } from '@vst/beam-icons/illustrative-icons';

import { ModalType, ModalTypeEnum } from './modal';
import { FooterButtonProps } from './footer';

interface ModalDetailInput {
  type: ModalType;
  closeModal?(): void;
  saveAddress?(): void;
  closeOpenCards?(): void;
}

interface ModalDetails {
  icon: JSX.Element;
  footerButtons: Array<FooterButtonProps | undefined>;
  extraText?: string;
}

export function createModalDetails({
  type,
  closeModal,
  saveAddress,
  closeOpenCards,
}: ModalDetailInput): ModalDetails {
  let detailProps: ModalDetails;

  const saveAddressOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (saveAddress) saveAddress();
    if (closeModal) closeModal();
    event.preventDefault();
  };

  const closeOpenCardsOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (closeOpenCards) closeOpenCards();
    event.preventDefault();
  };

  switch (type) {
    case ModalTypeEnum.Address:
      detailProps = {
        icon: <IllustrativeIcon icon={RoadMap} color="teal_600" />,
        footerButtons: [
          {
            buttonText: 'editAddress',
            onClick: () => {
              if (closeModal) closeModal();
            }, //Closes modal to allow users to keep editing
          },
          {
            buttonText: 'useAddress',
            onClick: saveAddressOnClick, //Makes you save modal address and close
          },
        ],
      };
      break;
    case ModalTypeEnum.AddressError:
      detailProps = {
        icon: <IllustrativeIcon icon={Alert} color="teal_600" />,
        footerButtons: [
          undefined,
          {
            buttonText: 'close',
            onClick: () => {
              if (closeModal) closeModal();
            },
          },
        ],
      };
      break;
    case ModalTypeEnum.Unsaved:
    default:
      detailProps = {
        icon: <IllustrativeIcon icon={Alert} color="teal_600" />,
        footerButtons: [
          {
            buttonText: 'discardChanges',
            onClick: closeOpenCardsOnClick, //Discards changes and closes all cards
          },
          {
            buttonText: 'continueEditing',
            onClick: closeModal, //Closes modal to allow users to keep editing
          },
        ],
      };
      break;
  }
  return detailProps;
}
