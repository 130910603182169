import { enUS, ptBR, it } from 'date-fns/locale';

export enum DateFormat {
  MDY,
  DMY,
}

export const formatMonthAndDay = (text: string): string => {
  const parsedText = parseInt(text);
  if (!isNaN(parsedText) && parsedText < 10) {
    text = '0' + parseInt(text).toString();
  }
  return text;
};

export const formatDate = (
  month: string,
  day: string,
  year: string,
  dateFormat: DateFormat
): string => {
  switch (dateFormat) {
    case DateFormat.DMY:
      return `${day}/${month}/${year}`;
    default:
      return `${month}/${day}/${year}`;
  }
};

export const getDateFnsLocale = (locale: string) => {
  switch (locale) {
    case 'it-IT':
      return it;
    case 'pt-BR':
      return ptBR;
    case 'en-US':
    default:
      return enUS;
  }
};
