import {
  loginFromUrl as loginFromUrlAction,
  loginFromCookie as loginFromCookieAction,
  startRefreshCountdown,
  sendAuthParams as sendAuthParamsAction,
  loginFromCode as loginFromCodeAction,
  authUser as authUserAction,
} from '../authUserSlice';
import { refreshCountdown } from './refreshTokenCountdown';
import {
  loginFromUrl,
  loginFromCookie,
  sendAuthParams,
  loginFromCode,
  authUser,
} from './logInSagas';
import { all, takeLatest } from 'redux-saga/effects';

export function* watchAuth() {
  yield all([
    takeLatest(authUserAction.type, authUser),
    takeLatest(loginFromUrlAction.type, loginFromUrl),
    takeLatest(loginFromCookieAction.type, loginFromCookie),
    takeLatest(startRefreshCountdown.type, refreshCountdown),
    takeLatest(sendAuthParamsAction.type, sendAuthParams),
    takeLatest(loginFromCodeAction.type, loginFromCode),
  ]);
}
