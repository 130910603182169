import { takeLatest, call, put, all, select, spawn } from 'redux-saga/effects';
import {
  initializeRegistrationAnalytics as initializeRegistrationAnalyticsAction,
  selectRegistration,
  pageDisplayedEvent as pageDisplayedEventAction,
  buttonClickedEvent as buttonClickedEventAction,
  registrationFailedEvent as registrationFailedEventAction,
  registrationSuccessfulEvent as registrationSuccessfulEventAction,
  setAnalyticsInitialized,
} from './registrationSlice';
import { selectConfig } from '@mfe/shared/redux/config';
import { structuredEvent } from '../analytics';
import { setPixelOnWindow } from './registrationAnalyticsUtil';

export function* initializeRegistrationAnalytics() {
  const { env, platform }: ReturnType<typeof selectConfig> = yield select(
    selectConfig
  );

  yield call(setPixelOnWindow, env, platform);
  yield put(setAnalyticsInitialized(true));
}

export function* pageDisplayedEvent({
  payload: { pageName },
}: {
  type: string;
  payload: { pageName: string };
}) {
  const { registrationContext }: ReturnType<typeof selectRegistration> =
    yield select(selectRegistration);
  const context = registrationContext ? [registrationContext] : undefined;
  yield put(
    structuredEvent({
      category: 'Registration',
      action: 'page_displayed',
      params: {
        label: pageName,
        context,
      },
    })
  );
}

export function* buttonClickedEvent({
  payload: { pageName, buttonName },
}: {
  type: string;
  payload: { pageName: string; buttonName: string };
}) {
  const { registrationContext }: ReturnType<typeof selectRegistration> =
    yield select(selectRegistration);
  const context = registrationContext ? [registrationContext] : undefined;
  yield put(
    structuredEvent({
      category: 'Registration',
      action: 'button_clicked',
      params: {
        label: pageName,
        property: buttonName,
        context,
      },
    })
  );
}

export function* registrationFailedEvent({
  payload: { pageName, failureReason },
}: {
  type: string;
  payload: { pageName: string; failureReason: string };
}) {
  const { registrationContext }: ReturnType<typeof selectRegistration> =
    yield select(selectRegistration);
  const context = registrationContext ? [registrationContext] : undefined;
  yield put(
    structuredEvent({
      category: 'Registration',
      action: 'failed',
      params: {
        label: pageName,
        property: failureReason,
        context,
      },
    })
  );
}

export function* registrationSuccessfulEvent() {
  const { registrationContext }: ReturnType<typeof selectRegistration> =
    yield select(selectRegistration);
  const context = registrationContext ? [registrationContext] : undefined;
  yield put(
    structuredEvent({
      category: 'Registration',
      action: 'successful',
      params: {
        context,
      },
    })
  );
}

export function* watchRegistrationAnalytics() {
  yield spawn(function* () {
    yield all([
      takeLatest(pageDisplayedEventAction.type, pageDisplayedEvent),
      takeLatest(buttonClickedEventAction.type, buttonClickedEvent),
      takeLatest(registrationFailedEventAction.type, registrationFailedEvent),
      takeLatest(
        registrationSuccessfulEventAction.type,
        registrationSuccessfulEvent
      ),
      takeLatest(
        initializeRegistrationAnalyticsAction.type,
        initializeRegistrationAnalytics
      ),
    ]);
  });
}
