import { gql } from '@apollo/client';

export const GET_DIAGNOSTICS_QUERY = gql`
  query getDiagnostics($doRefresh: Boolean) {
    getDiagnostics(doRefresh: $doRefresh) {
      name
      isOK
    }
  }
`;

export const REBOOT_MODEM = gql`
  mutation rebootModem {
    rebootModem {
      rebootSuccess
    }
  }
`;

export const GET_MODEM_STATUS = gql`
  query getModemStatus($refetchData: Boolean) {
    getModemStatus(refetchData: $refetchData) {
      isOnline
    }
  }
`;
