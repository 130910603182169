import * as React from 'react';
import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { colors, Icon, Txt } from '@vst/beam';
import { Clear } from '@vst/beam-icons/icons';

import { ModalFooter } from './footer';
import { createModalDetails } from './create-modal-details';
import styles from './modal.module.scss';

export enum ModalTypeEnum {
  Address = 'AddressValidation',
  AddressError = 'AddressError',
  Unsaved = 'UnsavedChanges',
  BuyMore = 'BuyMore',
  ACP = 'ACP',
}

export type ModalType = ModalTypeEnum | null;

interface ModalProps {
  type: ModalType;
  onCancel?(): void;
  extraText?: string;
  saveAddress?(): void;
  closeOpenCards?(): void;
  children?: ReactNode;
}

export const ModalComponent = ({
  type,
  onCancel,
  extraText,
  saveAddress,
  closeOpenCards,
  children,
}: ModalProps): JSX.Element | null => {
  const { t } = useTranslation('NewProfile');
  const dataCy = `${(type ?? '').toLowerCase()}-modal`;
  let modalContent = children;

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!modalContent) {
    const details = createModalDetails({
      type,
      closeModal: onCancel,
      saveAddress,
      closeOpenCards,
    });

    if (!type) return null;

    modalContent = (
      <>
        <button className={styles['exit']} onClick={onCancel}>
          <Icon icon={Clear} style={{ color: colors.gray[700] }} />
        </button>
        <div className={styles['mainContent']}>
          {details.icon}
          <Txt component="span" variant="heading4" mt="24px" mb="16px">
            {t('title', { context: type })}
          </Txt>
          <Txt component="span" variant="bodyLargeRegular">
            {t('caption', { context: type })}
          </Txt>
          {extraText && (
            <Txt component="span" variant="bodyLargeBold" mt="16px">
              {extraText}
            </Txt>
          )}
        </div>
        <ModalFooter
          footerButtons={details.footerButtons}
          closeModal={onCancel}
        />
      </>
    );
  }

  return (
    <div data-cy={dataCy} className={styles['modal']}>
      <div className={styles['card']}>{modalContent}</div>
    </div>
  );
};
