import { Button, IllustrativeIcon, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { ComponentProps, PropsWithChildren } from 'react';
import styles from './index.module.scss';

type ConfirmProps = PropsWithChildren & {
  title: string;
  description: string;
  icon?: ComponentProps<typeof IllustrativeIcon>['icon'];
  buttons?: Array<ComponentProps<typeof Button>>;
};

export const Confirm: React.FC<ConfirmProps> = (props) => {
  const { title, description, children, icon = Alert, buttons = [] } = props;

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        {children ?? <IllustrativeIcon icon={icon} color={'teal_600'} />}
        <div className={styles['content']}>
          <Txt variant="heading4">{title}</Txt>
          <Txt component="p" variant="bodyLargeRegular">
            {description}
          </Txt>
        </div>
        {!!buttons.length && (
          <div className={styles['buttons']}>
            {buttons.map((button, index) => {
              return <Button key={index} {...button} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};
