import {
  GetBepInputsPayloadProduct,
  SummarizedWorkOrder,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';

/*
 * Sort order based on product instance status
 */
export const productStatusSortOrder = (status: string): number => {
  switch (status) {
    case ProductInstanceStatus.Active:
      return 0;
    case ProductInstanceStatus.Preinstall:
      return 1;
    case ProductInstanceStatus.Other:
    case ProductInstanceStatus.Error:
      return 2;
    case ProductInstanceStatus.Suspended:
      return 3;
    default:
      return 4;
  }
};

/*
 * Immediately after a change plan, the new plan will have status "OTHER"
 */
export const productStatusSortOrderPlanChanged = (status: string): number => {
  switch (status) {
    case ProductInstanceStatus.Other:
    case ProductInstanceStatus.Error:
      return 0;
    case ProductInstanceStatus.Active:
      return 1;
    case ProductInstanceStatus.Preinstall:
      return 2;
    case ProductInstanceStatus.Suspended:
      return 3;
    default:
      return 4;
  }
};

export const sortProductInstancesByStatus = (
  products: GetBepInputsPayloadProduct[],
  planChanged?: boolean
) => {
  return [...products].sort((a, b) => {
    const locationOrderA = planChanged
      ? productStatusSortOrderPlanChanged(a?.productInstanceStatus ?? '')
      : productStatusSortOrder(a?.productInstanceStatus ?? '');
    const locationOrderB = planChanged
      ? productStatusSortOrderPlanChanged(b?.productInstanceStatus ?? '')
      : productStatusSortOrder(b?.productInstanceStatus ?? '');

    return locationOrderA - locationOrderB;
  });
};

export const superSecureSecret = '31ce0501-8b44-4986-8b7e-c40ff895e597';

export const filterByWorkOrderType = (
  data: SummarizedWorkOrder[] | null | undefined,
  workOrderTypes: string[]
) =>
  data?.filter((workOrder) =>
    workOrderTypes.includes(workOrder?.workOrderType)
  ) ?? [];

export enum WorkOrderTypes {
  INSTALL = 'Install',
  COMMERCIAL_INSTALL = 'Commercial Install',
  UPGRADE = 'Upgrade',
}

export const filterServiceCallWorkOrders = (
  workOrders: SummarizedWorkOrder[]
) => {
  return workOrders.filter(
    (workOrder) =>
      ![
        WorkOrderTypes.COMMERCIAL_INSTALL,
        WorkOrderTypes.INSTALL,
        WorkOrderTypes.UPGRADE,
      ].includes(workOrder.workOrderType as WorkOrderTypes)
  );
};
