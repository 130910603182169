import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Logo, Surface } from '@vst/beam';
import { AccountCircleOutlined } from '@vst/beam-icons/icons';
import { ViasatLogoDefault } from '@vst/beam-icons/logos/viasat';

import { EventTypes } from '@mfe/services/window-messages';
import { selectConfig } from '@mfe/shared/redux/config';
import { postGeneralMessage } from '@mfe/to-be-migrated/redux/utils';
import { Platform } from '@mfe/shared/schema-types';

import styles from './styles.module.scss';

export const MobileProfileButton = () => {
  const { platform, isPWA } = useSelector(selectConfig);
  const history = useHistory();

  const handleClick = () => {
    history.push({ pathname: 'Profile' });
  };

  if (platform === Platform.Web) return null;

  return (
    <Surface
      className={styles['profile-button-container']}
      data-cy="profile-button"
    >
      <div className={styles['profile-logo']}>
        <Logo size={94} logo={ViasatLogoDefault} />
      </div>
      <button
        disabled={false}
        onClick={handleClick}
        className={styles['profile-button']}
      >
        <Icon icon={AccountCircleOutlined} color="gray_600" size={24} />
      </button>
    </Surface>
  );
};
