import { Locale } from '@mfe/shared/schema-types';

export const getCurrencySymbol = (locale: Locale) => {
  switch (locale) {
    case Locale.ItIt:
      return '€';
    case Locale.PtBr:
      return 'R$';
    case Locale.EnUs:
    default:
      return '$';
  }
};
