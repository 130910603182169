import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { AutopayMethod, Invoice, VppError } from '@mfe/shared/schema-types';
import { ErrorsObj } from '@mfe/shared/redux/graphql';

export enum PaymentMethodType {
  CARD = 'credit_card',
  BANK = 'bank_transfer',
  PAYPAL = 'paypal',
}

export interface PaymentsState {
  // Manage txn id
  currentTxnId: string | null;
  txnAmount: number;
  url: string;

  // Manage vpp events
  height: number;
  paymentMethodType: PaymentMethodType;
  paypalOpen: boolean;

  // Manage flow
  frameReady: boolean;
  submit: boolean;
  success: boolean | undefined;
  saveOTP: boolean | undefined;
  paymentMethod: Partial<AutopayMethod> | undefined;
  error: VppError | null | ErrorsObj;
  loading: boolean;
}

export const initialState: PaymentsState = {
  // Manage txn id
  currentTxnId: null,
  txnAmount: 0,
  url: '',

  // Manage vpp events
  height: 558,

  //VPP defaults to BANK as the payment method
  paymentMethodType: PaymentMethodType.BANK,
  paypalOpen: false,

  // Manage flow
  frameReady: false,
  submit: false,
  saveOTP: false,
  success: undefined,
  paymentMethod: undefined,
  error: null,
  loading: false,
};

type VppActionPayload = Partial<Invoice> | null | undefined;
export type PaymentAction = PayloadAction<VppActionPayload>;
export type ModifyTransactionAction = PayloadAction<{ amount: number }>;

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setCurrentTxnId: (state, action) => {
      state.currentTxnId = action.payload;
    },
    setPayments: (state, { payload }: { payload: Partial<PaymentsState> }) => ({
      ...state,
      ...payload,
    }),
    setTransactionIdError: (
      state,
      { payload }: { payload: Partial<PaymentsState> }
    ) => {
      state.error = payload.error || null;
    },
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload || false;
    },
    resetPayments: () => initialState,
  },
});

export const retrieveTransactionId = createAction<VppActionPayload>(
  'payments/retrieveTransactionId'
);

export const initiatePayment = createAction<VppActionPayload>(
  'payments/initiatePayment'
);

export const modifyTransaction = createAction<{ amount: number }>(
  'payments/modifyTransaction'
);

export const {
  setCurrentTxnId,
  setPayments,
  setTransactionIdError,
  setLoading,
  resetPayments,
} = paymentsSlice.actions;

export const selectPayments = (state: { payments: PaymentsState }) =>
  state.payments;
