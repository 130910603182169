import { RegistrationLocale } from './registrationSlice';
import { pixelAPI } from '@mfe/services/analytics';

import { createAnalyticsAppId } from '../../libUtils';
import { Platform } from '@mfe/shared/schema-types';

export const setPixelOnWindow = async (env: string, platform: Platform) => {
  const appId = createAnalyticsAppId(env, platform, null);

  await pixelAPI.init({ appId, env });

  return true;
};

export const buildRegistrationContext = ({
  accountNumber,
  countryCode = RegistrationLocale.US,
}: {
  accountNumber: string;
  countryCode?: RegistrationLocale;
}) => {
  const schema = 'registration_context';
  return {
    schema,
    data: {
      account_number: accountNumber,
      country: countryCode,
    },
  };
};
