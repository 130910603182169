// eslint-disable-next-line @nx/enforce-module-boundaries
import { call, select } from 'redux-saga/effects';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { getLink } from './apollo/links';

import { memoryStorage } from '@mfe/shared/util';
import { reduxGraphQL } from '@mfe/shared/redux/graphql';
import { Locale, TokenType } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUser, TokenInfo } from '@mfe/to-be-migrated/redux/auth';

const getBackEndLink = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3100/api/graphql';
  }

  return `${window.location.origin}/api/graphql`;
};

export const getClient = (
  tokenInfo: TokenInfo,
  locale: Locale,
  useStorage = true
) => {
  const backEndLink = getBackEndLink();
  const isRegistrationToken = tokenInfo.type === TokenType.Registration;
  const token = isRegistrationToken
    ? {
        accessToken: '',
        accessTokenExpirationTime: '',
        locale: Locale.EnUs,
      }
    : tokenInfo;

  const KEY = backEndLink;
  let client = useStorage ? memoryStorage.get(KEY) : null;
  if (!client) {
    client = new ApolloClient({
      link: getLink(backEndLink, token, locale),
      cache: new InMemoryCache(),
    });
    if (useStorage && token?.accessToken) {
      memoryStorage.set(KEY, client);
    }
  }

  return client;
};

function* initReduxClient(): any {
  const {
    user: {
      auth: { tokenInfo },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const client = yield call(getClient, tokenInfo, userLocale);

  return client;
}
reduxGraphQL.initialize(initReduxClient);
