import { useSelector } from 'react-redux';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

export const useHasFilledOutCustomAddress = () => {
  const { voiceConfig } = useSelector(selectAddOns);

  const { aptUnit, ...requiredFields } = voiceConfig.emergencyAddress;

  const hasFilledOutCustomAddress = Object.values(requiredFields).every(
    (value) => value
  );

  return { hasFilledOutCustomAddress };
};
