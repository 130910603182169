import { useTranslation } from 'react-i18next';
import {
  TextField,
  validators,
  Txt,
  HelperText,
  PhoneNumber,
  TextArea,
} from '@vst/beam';

import styles from './on-site-contact-information.module.scss';

interface OnSiteContactInformationProps {
  setTextInput: (value: string) => void;
  setTextAreaInput: (value: string) => void;
  textAreaMaxLength: number;
  isPhoneNumberValid: any;
  phoneNumberInput: string;
  setPhoneNumberInput: (value: string) => void;
  isNameValid: any;
  textFieldProps: { ref: React.Ref<HTMLInputElement>; name: string };
  phoneFieldProps: { ref: React.Ref<HTMLInputElement>; name: string };
}

export const OnSiteContactInformation = (
  props: OnSiteContactInformationProps
) => {
  const {
    setTextInput,
    setTextAreaInput,
    textAreaMaxLength,
    isPhoneNumberValid,
    phoneNumberInput,
    setPhoneNumberInput,
    isNameValid,
    textFieldProps,
    phoneFieldProps,
  } = props;

  const { t } = useTranslation('NewScheduleInstallation');

  return (
    <div className={styles['container']}>
      <Txt
        data-cy="contact-info-title"
        variant="headerCapsSmall"
        color="subtle"
      >
        {t('contactInformation.title')}
      </Txt>
      <Txt
        data-cy="installation-info"
        variant="bodySmallRegular"
        color="subtle"
      >
        {t('contactInformation.installationInfo')}
      </Txt>

      <HelperText
        data-cy="min-age-helpertext"
        state="info"
        helpMessage={t('contactInformation.minAgeInfo')}
        className={styles['helper-text']}
      />

      <div className={styles['inputs-container']}>
        <TextField
          ref={textFieldProps.ref}
          data-cy="name-field"
          className={styles['name-input']}
          required={false}
          fluid
          name={textFieldProps.name}
          id={textFieldProps.name}
          labelProps={{
            labelText: t('contactInformation.appointmentContact') as string,
          }}
          validationRules={[validators.define(isNameValid)]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTextInput(e.target.value)
          }
        />

        <div className={styles['phone-input']}>
          <PhoneNumber
            ref={phoneFieldProps.ref}
            onChange={(value: string) => setPhoneNumberInput(value)}
            labelProps={{
              labelText: t('contactInformation.phone'),
            }}
            countryCodeEditable={false}
            validationRules={[validators.define(isPhoneNumberValid)]}
            inputProps={{
              id: phoneFieldProps.name,
              name: phoneFieldProps.name,
              required: false,
            }}
            value={phoneNumberInput}
            fluid
          />
        </div>
      </div>

      <div>
        <TextArea
          mt="24px"
          mb="40px"
          maxCount={textAreaMaxLength}
          id="description"
          name="description"
          labelProps={{
            labelText: t('contactInformation.textareaLabel'),
            optionalText: '(optional)',
          }}
          fluid
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setTextAreaInput(e.target.value)
          }
          placeholder={t('contactInformation.textareaPlaceholder')}
        />
      </div>

      <HelperText
        data-cy="rental-helpertext"
        state="info"
        helpMessage={t('contactInformation.rentalInfo')}
      />
    </div>
  );
};
