import { Locale } from '@mfe/shared/schema-types';
import { CountryCode } from 'libphonenumber-js';

export const getLocaleCountryCode = (locale: Locale): CountryCode => {
  switch (locale) {
    case Locale.PtBr:
      return 'BR';
    case Locale.EnUs:
    default:
      return 'US';
  }
};
