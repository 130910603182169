import { colors } from '@vst/beam';

import * as icons from './icons';
import {
  ADDON_PRODUCT_KIND,
  EASY_CARE_ADDON_KINDS,
  OFFICE_HOURS_ADDON_KINDS,
  SHIELD_ADDON_KINDS,
  VOICE_ADDONS_KINDS,
} from '@mfe/shared/util';

export type AddonIconType = {
  kind: string;
  className?: string;
};

export const AddonIcon = (props: AddonIconType) => {
  const { kind, className } = props;
  const { icon: Icon, color, backgroundColor } = getAddonImagePropsByKind(kind);

  return (
    <div style={{ color, backgroundColor }} className={className}>
      <Icon />
    </div>
  );
};

export const getAddonImagePropsByKind = (kind: string) => {
  if (kind === ADDON_PRODUCT_KIND.voice || VOICE_ADDONS_KINDS.includes(kind)) {
    return {
      backgroundColor: colors.blue['100'],
      color: colors.blue['600'],
      icon: icons.Phone,
    };
  }

  if (kind === ADDON_PRODUCT_KIND.shield || SHIELD_ADDON_KINDS.includes(kind)) {
    return {
      backgroundColor: colors.warm['100'],
      color: colors.warm['600'],
      icon: icons.Security,
    };
  }

  if (
    kind === ADDON_PRODUCT_KIND.easyCare ||
    EASY_CARE_ADDON_KINDS.includes(kind)
  ) {
    return {
      backgroundColor: colors.green['100'],
      color: colors.green['600'],
      icon: icons.Construction,
    };
  }

  if (
    kind === ADDON_PRODUCT_KIND.officeHours ||
    OFFICE_HOURS_ADDON_KINDS.includes(kind)
  ) {
    return {
      backgroundColor: colors.pink['100'],
      color: colors.pink['500'],
      icon: icons.Briefcase,
    };
  }

  if (kind === ADDON_PRODUCT_KIND.viasatStream) {
    return {
      backgroundColor: colors.violet['100'],
      color: colors.violet['500'],
      icon: icons.Video,
    };
  }

  if (kind === ADDON_PRODUCT_KIND.staticIp) {
    return {
      backgroundColor: colors.gray['200'],
      color: colors.gray['700'],
      icon: icons.Language,
    };
  }

  return {
    backgroundColor: colors.gray['200'],
    color: colors.gray['700'],
    icon: icons.Construction,
  };
};
