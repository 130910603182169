import type {
  AutopayMethod as AutopayMethodType,
  Payment,
} from '@mfe/shared/schema-types';
import { PAYMENT_METHOD_NAME } from './constants';

type AutoPay = AutopayMethodType | Payment | null;
type TranslationContext =
  | 'ENROLLED'
  | 'INVALID'
  | 'BOLETO'
  | 'EXPIRED'
  | 'BANK'
  | 'PAYPAL';

export const getAutopayTranslationContext = (
  autoPay?: AutoPay,
  overviewAutoPay?: boolean
) => {
  let context: TranslationContext = 'ENROLLED';

  if (!autoPay || !autoPay?.paymentMethod) {
    context = 'INVALID';
  } else if (
    autoPay?.paymentMethod === PAYMENT_METHOD_NAME.Pix ||
    autoPay?.paymentMethod === PAYMENT_METHOD_NAME.Boleto
  ) {
    context = 'BOLETO';
  } else if (autoPay?.isExpired) {
    context = 'EXPIRED';
  } else if (
    overviewAutoPay &&
    autoPay?.paymentMethod === PAYMENT_METHOD_NAME.ACH
  ) {
    context = 'BANK';
  } else if (autoPay?.paymentMethod === PAYMENT_METHOD_NAME.PayPal) {
    context = 'PAYPAL';
  }

  const last4Digits = autoPay?.last4Digits ?? '';
  const count = last4Digits?.length ?? 0;

  return { context, count, last4Digits };
};
