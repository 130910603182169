import { gql } from '@apollo/client';

export const GET_BUY_MORE_HISTORY = gql`
  query getBuyMoreHistory(
    $refetchData: Boolean
    $startDate: String
    $endDate: String
  ) {
    getBuyMoreHistory(
      refetchData: $refetchData
      startDate: $startDate
      endDate: $endDate
    ) {
      name
      productStartDate
      productInstanceStatus
      productInstanceId
      products {
        name
        productStartDate
        price {
          amount {
            value
            currency {
              majorUnitSymbol
            }
          }
        }
        characteristics {
          name
          value
        }
      }
    }
  }
`;
