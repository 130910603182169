import {
  Address,
  StreamOnProduct,
  UserDetails,
  GetInvoiceHistoryPayload,
  SubmitFullOrderPayload,
} from '@mfe/shared/schema-types';

export interface AppState {
  deactivate: {
    loading: boolean | null;
    successful: boolean;
  };
  confirmModal: boolean;
  currentMonthlyBill: GetInvoiceHistoryPayload & { loading: boolean | null };
  currentPage: string;
  didOrderFail: SubmitFullOrderPayload;
  didOrderSucceed: boolean;
  editAddress: Address;
  isEdittingAddress: boolean;
  isEligible: boolean;
  hasStreamOnActive: boolean;
  isLoading: boolean;
  isOrderSubmitting: boolean;
  isScrubbingLoading: boolean;
  isEligibleLoading: boolean;
  offerDetails: StreamOnProduct & { loading: boolean | null };
  orderAttempts: number;
  persona: Persona;
  phoneNumber: string;
  shippingAddress: Address;
  scrubLocation: Address | null;
  userDetails: UserDetails;
  isEditingNumber: boolean;
  failureReason: string | null;
}

export enum Pages {
  login = '/',
  productDetails = '/productDetails',
  orderSummary = '/orderSummary',
  orderReview = '/orderReview',
  error = '/error',
  unavailable = '/unavailable',
  None = 'none',
}

export enum Persona {
  CUSTOMER = 'buy-mv',
  CARE_AGENT = 'buy-gac',
  PARTNER = 'buy-gpp',
}

export const initialState: AppState = {
  deactivate: {
    loading: null,
    successful: false,
  },
  shippingAddress: {
    addressLines: [''],
    municipality: '',
    region: '',
    postalCode: '',
    countryCode: '',
  },
  offerDetails: {
    loading: null,
    attributes: [],
    description: '',
    name: '',
    price: 0,
    buttonText: '',
    hyperlinkText: '',
    hyperlinkUrl: '',
    imageUrl: '',
    logoURL: '',
    title: '',
    tag: '',
    productTypeId: '',
    productFamily: '',
  },
  userDetails: {
    phoneNumber: '',
    email: '',
    error: {
      failureReason: '',
    },
  },
  isEligible: null,
  hasStreamOnActive: false,
  isLoading: false,
  isOrderSubmitting: false,
  isScrubbingLoading: false,
  isEligibleLoading: true,
  isEditingNumber: false,
  phoneNumber: '',
  scrubLocation: {
    addressLines: [''],
    municipality: '',
    region: '',
    postalCode: '',
    countryCode: '',
  },
  confirmModal: false,
  isEdittingAddress: false,
  editAddress: {
    addressLines: ['', ''],
    municipality: '',
    region: '',
    postalCode: '',
    countryCode: '',
  },
  currentMonthlyBill: {
    loading: null,
    currentMonthlyBill: 0,
    error: { failureReason: '' },
  },
  currentPage: Pages.login,
  persona: Persona.CUSTOMER,
  didOrderFail: {
    didOrderFail: false,
    failureReason: '',
  },
  didOrderSucceed: false,
  orderAttempts: 0,
} as unknown as AppState;

export const resetOrderAttempts = 0;
