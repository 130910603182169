import { createAction } from '@reduxjs/toolkit';
import {
  deleteUsernameMutation,
  DeleteUsernameArgs,
} from './deleteUsernameMutation';
import { all, takeLatest } from 'redux-saga/effects';

export const deleteUsername = createAction<DeleteUsernameArgs>(
  'deleteUsername/deleteUsername'
);

export function* watchUsernameMutations() {
  yield all([takeLatest(deleteUsername.type, deleteUsernameMutation)]);
}
