import { Action, PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { GetPlanOffersPayload, Locale } from '@mfe/shared/schema-types';

import {
  graphqlQueryWithErrors,
  graphqlMutationWithErrors,
  FetchWithErrorsQuery,
  FetchWithErrorsMutation,
} from '@mfe/shared/redux/graphql';
import { Storage, CHANGE_PLAN_SUCCESS_FLOW } from '@mfe/shared/util';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

import { waitForProductKind, waitForToken } from '../utils/utilsSagas';
import { AnalyticsAction, Categories, structuredEvent } from '../analytics';
import {
  getPlans,
  setPlans,
  refetchPlans,
  addPlanToCart,
  saveCart,
  submitOrder,
  setOrderSuccess,
  selectChangePlan,
  setError,
  setChangePlanStep,
  ChangePlanSteps,
  setSelectedPlanId,
  TransitionScheduleInput,
  getHasPendingTransition,
  setHasPendingTransition,
  setHasPendingTransitionError,
  setHasActivationError,
} from './changePlanSlice';
import {
  GET_ALL_CHANGE_PLAN_OFFERS,
  CONFIGURE_CART,
  TRANSITION_PLAN,
  GET_HAS_PENDING_TRANSITION,
} from './requests';
import { selectAddOns } from '../addOns';
import { selectPortfolio } from '../portfolio/portfolioSlice';
import { selectUser } from '../auth';

export function* fetchChangePlanOffers(payload?: {
  refetchData?: boolean;
  languageCode?: string;
}) {
  yield call(waitForToken);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ALL_CHANGE_PLAN_OFFERS,
    variables: {
      refetchData: payload?.refetchData,
      languageCode: Locale.EnUs,
    },
    fetchPolicy: payload?.refetchData ? 'no-cache' : 'cache-first',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getPlanOffers) {
    yield put(setPlans(data.getPlanOffers as GetPlanOffersPayload[]));
  }
}

export function* refetchChangePlanOffers() {
  yield fetchChangePlanOffers({ refetchData: true });
}

export function* addToCart(
  action: PayloadAction<{ newProductTypeId: string }>
) {
  const { newProductTypeId } = action.payload;
  const { portfolio } = yield select(selectPortfolio);

  yield put(setChangePlanStep(ChangePlanSteps.ORDER_REVIEW));
  yield put(setSelectedPlanId(newProductTypeId));

  const response: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: CONFIGURE_CART,
      variables: {
        input: {
          newProductTypeId,
          portfolio: JSON.stringify(portfolio),
        },
      },
    }
  );
  const { data, errors, runtimeError } = response;

  if (runtimeError) {
    yield put(setError(runtimeError));
    return;
  }

  if (errors) {
    yield put(setError(errors));
    yield put(
      structuredEvent({
        category: Categories.ChangePlan,
        action: AnalyticsAction.CONFIGURE_CART_FAILED,
      })
    );
    return;
  }

  if (data?.configureCart) {
    const cart = data.configureCart;
    yield put(saveCart(cart));

    const {
      addOns: { hasStaticIP },
    } = yield select(selectAddOns);

    if (data.configureCart.droppedAddOns.length || hasStaticIP)
      yield put(setChangePlanStep(ChangePlanSteps.DROPPED_ADDONS));
  }
}

type SubmitOrderAction = Action<'changePlan/submitOrder'> & {
  payload?: TransitionScheduleInput;
};

export function* placeOrder(action: SubmitOrderAction) {
  yield put(setChangePlanStep(ChangePlanSteps.ORDER_OUTCOME));
  const {
    cart,
    selectedPlanId: newProductTypeId,
  }: ReturnType<typeof selectChangePlan> = yield select(selectChangePlan);
  const {
    user: { productTypeId: currentProductTypeId },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const workOrderInformation = action?.payload;
  const input = {
    cartId: cart.id,
    workOrderInformation,
    currentProductTypeId,
    newProductTypeId,
  };

  const response: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    { mutation: TRANSITION_PLAN, variables: { input } }
  );

  const { data, errors, runtimeError } = response;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.transitionPlan) {
    yield put(
      setOrderSuccess({
        isSuccess: data.transitionPlan.success,
        appointment: data.transitionPlan.appointment ?? null,
      })
    );
    yield put(setHasPendingTransition(true));
    yield put(getHasPendingTransition());

    yield call([Storage, Storage.setItem], CHANGE_PLAN_SUCCESS_FLOW, true);

    yield put(setChangePlanStep(ChangePlanSteps.ORDER_OUTCOME));
  }
}

export function* fetchHasPendingTransition() {
  yield call(waitForProductKind);

  const {
    user: { productKind },
  } = yield select(selectUser);

  if (productKind === ProductInstanceTypes.WirelessInternet) return;

  const {
    user: { productInstanceId },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_HAS_PENDING_TRANSITION,
    fetchPolicy: 'network-only',
    variables: { productInstanceId, refetchData: true },
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    setHasPendingTransitionError(errors ?? runtimeError);
  }

  if (data?.getHasPendingTransition) {
    const { isChangePlanDisabled, hasActivationError } =
      data.getHasPendingTransition;
    if (isChangePlanDisabled) {
      yield put(setHasPendingTransition(isChangePlanDisabled));
    }
    if (hasActivationError) {
      yield put(setHasActivationError(hasActivationError));
    }
  }
}

export function* watchChangePlanOffers() {
  yield takeLatest(getPlans.type, fetchChangePlanOffers);
  yield takeLatest(refetchPlans.type, refetchChangePlanOffers);
  yield takeLatest(addPlanToCart.type, addToCart);
  yield takeLatest(submitOrder.type, placeOrder);
  yield takeLatest(getHasPendingTransition.type, fetchHasPendingTransition);
}
