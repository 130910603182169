import { ApolloLink } from '@apollo/client';
import { Locale, TokenType } from '@mfe/shared/schema-types';

export interface TokenInfo {
  accessToken: string;
  accessTokenExpirationTime: string;
  refreshToken?: string;
  type?: TokenType;
  idToken?: string;
  locale?: Locale;
}

export const getSecureLink = (
  tokenInfo?: TokenInfo,
  locale?: Locale
): ApolloLink =>
  new ApolloLink((operation, forward): any => {
    const context = operation.getContext();
    const headers = { ...context['headers'] };

    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlParams.entries());

    headers['x-query-params'] = JSON.stringify(queryParams);

    if (tokenInfo?.accessToken && tokenInfo?.type) {
      // Add headers for http authentication
      headers['x-auth-token'] = tokenInfo.accessToken;
      headers['x-auth-type'] = tokenInfo.type;
    }

    if (locale) {
      headers['x-locale'] = locale;
    }

    operation.setContext({
      ...context,
      headers,
    });

    return forward(operation);
  });
