import {
  AnalyticsEvent,
  AnalyticsEventName,
  EventSourceProperty,
  EventFailureProperty,
} from './types';
import { eventSchemaFactory } from './schema-factories';

type InititializationFailedEvent = AnalyticsEvent<EventFailureProperty>;
type InitiatedEvent = AnalyticsEvent<EventSourceProperty>;
type OrderFailedEvent = AnalyticsEvent<
  EventFailureProperty & Partial<EventSourceProperty>
>;
type OrderSubmittedEvent = AnalyticsEvent<EventSourceProperty>;

export type AnalyticsEventWithEmptyData = AnalyticsEvent<Record<string, never>>;
type OrderSuccessful = AnalyticsEventWithEmptyData;
type ProductSelectedEvent = AnalyticsEventWithEmptyData;

type BuyMoreAnalyticsEvent =
  | InititializationFailedEvent
  | InitiatedEvent
  | OrderFailedEvent
  | OrderSubmittedEvent
  | OrderSuccessful
  | ProductSelectedEvent;

type EventFactoryConfig = {
  name: AnalyticsEventName;
  data?:
    | EventSourceProperty
    | EventFailureProperty
    | (EventSourceProperty & EventFailureProperty);
};

type AnalyticsEventFactory = (
  config: EventFactoryConfig
) => BuyMoreAnalyticsEvent;

export const eventFactory: AnalyticsEventFactory = (config) => {
  const { name, data = {} } = config;

  const schema = eventSchemaFactory(name);

  return {
    schema,
    data,
  };
};
