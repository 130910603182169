import { addWeeks, formatISO } from 'date-fns';
import { all, call, put, takeLatest, select, take } from 'redux-saga/effects';

import { Schedule, SummarizedWorkOrder } from '@mfe/shared/schema-types';
import { scrollToTop } from '@mfe/shared/redux/utils';
import {
  graphqlMutationWithErrors,
  graphqlQueryWithErrors,
  FetchWithErrorsQuery,
  FetchWithErrorsMutation,
} from '@mfe/shared/redux/graphql';
import { selectUserInfo } from '../userInfo';
import {
  getInstallationWorkOrder,
  setFulfillmentAgreementId,
  setAvailableDates,
  setScheduleInstallationError,
  setExternalWorkOrderId,
  submitInstallation,
  setScheduleSuccess,
  refetchScheduleInstallation,
  SubmitInstallationActionType,
  getAvailableDates,
} from './scheduleInstallationSlice';
import { getTimeSlotsByDay } from './getTimeSlotsByDay';
import { excludeTimeSlot } from './excludeTimeSlot';
import {
  GET_FULFILLMENT_AGREEMENT_ID,
  GET_AVAILABLE_INSTALLATION_DATES,
  UPSERT_WORK_ORDER_SCHEDULE,
} from './requests';
import {
  getPendingInstallation,
  setHasRescheduled,
} from '../pendingInstallation';
import { waitForUserInfo } from '../utils/utilsSagas';
import { getWorkOrders, selectWorkOrders, setWorkOrders } from '../workOrders';
import {
  filterByWorkOrderType,
  filterServiceCallWorkOrders,
  WorkOrderTypes,
} from '../../serverUtils';

// For standalone scheduling
export function* fetchScheduleInstallation(payload?: {
  refetchData?: boolean;
}) {
  yield call(waitForWorkOrders);
  const { workOrders, installDate } = yield select(selectWorkOrders);
  yield call(waitForUserInfo);

  const {
    userInfo: { isPreInstall },
  } = yield select(selectUserInfo);

  const externalWorkOrderId = determineExternalWorkOrderId(
    workOrders,
    isPreInstall
  );

  if (externalWorkOrderId) {
    yield put(setExternalWorkOrderId(externalWorkOrderId));
  }

  const workOrderResponse: FetchWithErrorsQuery = yield call(
    graphqlQueryWithErrors,
    { query: GET_FULFILLMENT_AGREEMENT_ID, variables: { externalWorkOrderId } }
  );

  const { data, errors, runtimeError } = workOrderResponse;

  if (runtimeError || errors) {
    yield put(setScheduleInstallationError(runtimeError ?? errors));
    return;
  }

  if (data?.getWorkOrderFulfillmentAgreementId) {
    yield put(
      setFulfillmentAgreementId(
        data.getWorkOrderFulfillmentAgreementId.fulfillmentAgreementId
      )
    );
  }

  const currentDate = new Date();
  const futureDate = new Date(addWeeks(currentDate, 3));
  const {
    userInfo: {
      address: {
        service: {
          addressLines,
          region,
          postalCode,
          municipality,
          countryCode,
        },
      },
    },
  } = yield select(selectUserInfo);

  const input = {
    external_work_order_id: externalWorkOrderId,
    postal_code: postalCode,
    from: formatISO(currentDate, { representation: 'date' }),
    to: formatISO(futureDate, { representation: 'date' }),
    fulfillment_agreement_id:
      data?.getWorkOrderFulfillmentAgreementId?.fulfillmentAgreementId,
    country_code: countryCode,
    address_lines: addressLines,
    region,
    municipality,
  };

  const availableDatesResponse: FetchWithErrorsQuery = yield call(
    graphqlQueryWithErrors,
    {
      query: GET_AVAILABLE_INSTALLATION_DATES,
      variables: { input, refetchData: payload?.refetchData },
      fetchPolicy: 'no-cache',
    }
  );

  const {
    data: availableDates,
    errors: availableDatesErrors,
    runtimeError: availableDatesRuntimeError,
  } = availableDatesResponse;

  if (availableDatesRuntimeError) {
    yield put(setScheduleInstallationError(availableDatesRuntimeError));
    return;
  }

  if (availableDatesErrors) {
    yield put(
      setScheduleInstallationError(
        availableDatesErrors['getAvailableInstallationDates'].message
      )
    );
    return;
  }

  if (availableDates?.getAvailableInstallationDates) {
    const availabeSlots = availableDates.getAvailableInstallationDates
      .schedules as Schedule[];

    const filteredTimeSlots = excludeTimeSlot(installDate, availabeSlots);
    const timeSlotsByDay = getTimeSlotsByDay(filteredTimeSlots);

    yield put(setAvailableDates(timeSlotsByDay));
  }
}

function* waitForWorkOrders() {
  const { workOrders, loading } = yield select(selectWorkOrders);

  if (workOrders === null || loading) {
    yield take(getWorkOrders.type);
    yield take(setWorkOrders.type);
  }
}

function determineExternalWorkOrderId(
  workOrders: SummarizedWorkOrder[],
  isPreInstall: boolean
): string {
  const serviceCallWorkOrders = filterServiceCallWorkOrders(workOrders);

  const activeWorkOrder =
    serviceCallWorkOrders.length > 0
      ? serviceCallWorkOrders[0]
      : identifyWorkOrder(isPreInstall)?.[0];

  function identifyWorkOrder(isPreInstall: boolean) {
    const workOrderTypes = isPreInstall
      ? [WorkOrderTypes.INSTALL, WorkOrderTypes.COMMERCIAL_INSTALL]
      : [WorkOrderTypes.UPGRADE];

    return filterByWorkOrderType(workOrders, workOrderTypes);
  }

  return activeWorkOrder?.externalWorkOrderId;
}

export function* refetchScheduleInstallationData() {
  yield fetchScheduleInstallation({ refetchData: true });
}

export function* submitScheduling(action: SubmitInstallationActionType) {
  const response: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: UPSERT_WORK_ORDER_SCHEDULE,
      variables: { input: { ...action.payload } },
    }
  );

  const { data, errors, runtimeError } = response;

  if (runtimeError || errors) {
    yield put(setScheduleInstallationError(runtimeError ?? errors));
    return;
  }
  if (data?.upsertWorkOrderSchedule) {
    yield put(setScheduleSuccess());
    yield put(setHasRescheduled());
    yield put(getPendingInstallation());
  }
}

// For change plan
export function* fetchAvailableDates(action: {
  type: string;
  payload: { cartId: string; refetch: boolean };
}) {
  yield put(scrollToTop());
  const { cartId } = action.payload;

  const currentDate = new Date();
  const futureDate = new Date(addWeeks(currentDate, 3));
  const {
    userInfo: {
      address: {
        service: {
          addressLines,
          region,
          postalCode,
          municipality,
          countryCode,
        },
      },
    },
  } = yield select(selectUserInfo);

  const workOrderResponse: FetchWithErrorsQuery = yield call(
    graphqlQueryWithErrors,
    { query: GET_FULFILLMENT_AGREEMENT_ID }
  );

  const {
    data: woData,
    errors: woErrors,
    runtimeError: woRuntimeErrors,
  } = workOrderResponse;

  if (woRuntimeErrors || woErrors) {
    yield put(setScheduleInstallationError(woRuntimeErrors ?? woErrors));
    return;
  }

  if (woData?.getWorkOrderFulfillmentAgreementId) {
    yield put(
      setFulfillmentAgreementId(
        woData.getWorkOrderFulfillmentAgreementId.fulfillmentAgreementId
      )
    );
  }

  const input = {
    postal_code: postalCode,
    from: formatISO(currentDate, { representation: 'date' }),
    to: formatISO(futureDate, { representation: 'date' }),
    fulfillment_agreement_id:
      woData?.getWorkOrderFulfillmentAgreementId?.fulfillmentAgreementId,
    country_code: countryCode,
    address_lines: addressLines,
    region,
    municipality,
    shopping_cart_id: cartId,
  };

  const { data, errors, runtimeError }: FetchWithErrorsQuery = yield call(
    graphqlQueryWithErrors,
    {
      query: GET_AVAILABLE_INSTALLATION_DATES,
      variables: { input, refetchData: false },
      fetchPolicy: 'no-cache',
    }
  );

  if (runtimeError || errors) {
    yield put(scrollToTop());
    yield put(setScheduleInstallationError(runtimeError ?? errors));
    return;
  }

  const schedules = data?.getAvailableInstallationDates
    ?.schedules as Schedule[];
  yield put(scrollToTop());
  const timeSlotsByDay = getTimeSlotsByDay(schedules);

  yield put(setAvailableDates(timeSlotsByDay));
}

export function* watchScheduleInstallation() {
  yield all([
    takeLatest(getAvailableDates.type, fetchAvailableDates), // For change plan
    takeLatest(getInstallationWorkOrder.type, fetchScheduleInstallation), // For standalone scheduling
    takeLatest(
      refetchScheduleInstallation.type,
      refetchScheduleInstallationData
    ),
    takeLatest(submitInstallation.type, submitScheduling),
  ]);
}
