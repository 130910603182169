import {
  OktaAuth,
  APIError,
  NextStep,
  IdxStatus,
  IdxMessage,
  IdxResponse,
  ProceedOptions,
  IdxTransaction,
  AuthenticatorKey,
} from '@okta/okta-auth-js';

import { OAuthClient } from '@mfe/shared/schema-types';
import i18n from '@mfe/services/translations-service';

import { sendRaygunError } from '../raygun/raygunSagas';

export enum RegistrationSteps {
  enrollProfile = 'enroll-profile',
  selectAuthenticator = 'select-authenticator-enroll',
  enrollAuthenticator = 'enroll-authenticator',
  enrollAuthenticatorData = 'authenticator-enrollment-data',
}

export enum AuthenticatorTypes {
  Phone = 'Phone',
  Email = 'Email',
}

export enum PhoneMethodTypes {
  SMS = 'sms',
  Voice = 'voice',
}

export type TransactionResults = {
  status: IdxStatus;
  stepSucceeded: boolean;
  token?: string;
  tokenExpTime?: number;
  error?: APIError | IdxResponse;
  errorMessages?: string[];
  nextStep?: NextStep;
};

export class OktaRegistrationClient {
  protected oktaClient: OktaAuth;
  constructor(issuer: string, client: OAuthClient) {
    this.oktaClient = new OktaAuth({
      clientId: client.clientId,
      redirectUri: client.redirectUrl as string,
      issuer,
    });
  }

  cancelTransaction = async () => {
    await this.oktaClient.idx.cancel();
  };

  startRegistration = async (
    email: string,
    accountNumber: string,
    zipCode?: string
  ): Promise<TransactionResults> => {
    try {
      this.oktaClient.transactionManager.clear();
      this.oktaClient.tokenManager.clear();
      this.oktaClient.idx.clearTransactionMeta();
      let transaction = await this.oktaClient.idx.register();
      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.flowStart,
          logData: {
            accountNumber,
          },
        });
      }

      transaction = await this.oktaClient.idx.proceed({
        email,
        zipCode,
        accountNumber,
      } as ProceedOptions);

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.createUserProfile,
          logData: {
            accountNumber,
          },
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  sendVerificationEmail = async (): Promise<TransactionResults> => {
    try {
      const transaction = await this.oktaClient.idx.proceed({
        authenticator: AuthenticatorKey.OKTA_EMAIL,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.sendVerificationEmail,
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  verifyCode = async (
    code: string,
    authenticatorType: AuthenticatorTypes
  ): Promise<TransactionResults> => {
    try {
      const transaction = await this.oktaClient.idx.proceed({
        verificationCode: code,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle:
            API_ERROR_TITLE.verifyAuthenticatorCode(authenticatorType),
          authenticatorType,
          logData: {
            transaction,
            authenticatorType,
          },
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed,
        token: transaction?.tokens?.accessToken?.accessToken,
        tokenExpTime: transaction?.tokens?.accessToken?.expiresAt,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  submitPassword = async (password: string): Promise<TransactionResults> => {
    try {
      let transaction = await this.oktaClient.idx.proceed({
        authenticator: AuthenticatorKey.OKTA_PASSWORD,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.selectPasswordAuthenticator,
        });
      }

      transaction = await this.oktaClient.idx.proceed({
        password,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.createPassword,
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  submitPhone = async (
    skip: boolean,
    phoneNumber?: string,
    methodType?: PhoneMethodTypes
  ): Promise<TransactionResults> => {
    try {
      let transaction: IdxTransaction;
      if (skip) {
        transaction = await this.oktaClient.idx.proceed({ skip: true });

        if (!transaction.requestDidSucceed) {
          return this.handleTransactionError({
            transaction,
            apiErrorTitle: API_ERROR_TITLE.skipPhoneAuthenticator,
            logData: transaction,
          });
        }

        return {
          status: transaction.status,
          stepSucceeded: transaction.requestDidSucceed,
          token: transaction?.tokens?.accessToken?.accessToken,
          tokenExpTime: transaction?.tokens?.accessToken?.expiresAt,
        };
      }
      if (!methodType || !phoneNumber) {
        throw new Error(
          'must provide phoneNumber and methodType if not skipping phone validation'
        );
      }

      transaction = await this.oktaClient.idx.proceed({
        authenticator: AuthenticatorKey.PHONE_NUMBER,
      });
      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.selectPhoneAuthenticator,
          logData: transaction,
        });
      }
      transaction = await this.oktaClient.idx.proceed({
        methodType,
        phoneNumber,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle:
            API_ERROR_TITLE.selectPhoneAuthenticatorType(methodType),
          logData: transaction,
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed as boolean,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  resendEmailCode = async (): Promise<TransactionResults> => {
    try {
      const transaction = await this.oktaClient.idx.proceed({
        authenticator: AuthenticatorKey.OKTA_EMAIL,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.resendVerificationEmail,
          logData: transaction,
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  resendPhoneCode = async (
    phoneNumber: string,
    methodType: PhoneMethodTypes
  ): Promise<TransactionResults> => {
    try {
      let transaction = await this.oktaClient.idx.proceed({
        authenticator: AuthenticatorKey.PHONE_NUMBER,
      });

      transaction = await this.oktaClient.idx.proceed({
        methodType,
        phoneNumber,
      });

      if (!transaction.requestDidSucceed) {
        return this.handleTransactionError({
          transaction,
          apiErrorTitle: API_ERROR_TITLE.resendPhoneVerificationCode,
          logData: transaction,
        });
      }

      return {
        status: transaction.status,
        stepSucceeded: transaction.requestDidSucceed,
      };
    } catch {
      return SDK_FAILURE;
    }
  };

  private handleTransactionError(input: {
    transaction: IdxTransaction;
    apiErrorTitle: string;
    logData?: unknown;
    authenticatorType?: AuthenticatorTypes;
  }): TransactionResults {
    const { transaction, apiErrorTitle } = input;

    const errorMessages =
      input.transaction.messages?.map((message) =>
        mapKeyToMessage(message, input?.authenticatorType)
      ) ?? [];

    if (transaction.status === IdxStatus.FAILURE && transaction.error) {
      this.logAPIError(transaction.error, apiErrorTitle, input?.logData);
    }

    return {
      errorMessages,
      error: transaction.error,
      status: transaction.status,
      nextStep: transaction?.nextStep,
      stepSucceeded: transaction.requestDidSucceed as boolean,
    };
  }

  private logAPIError(
    errorObject: APIError | IdxResponse,
    errorTitle: string,
    data?: unknown
  ) {
    if (!this.isAPIError(errorObject)) {
      return;
    }

    sendRaygunError(
      new Error(
        `[OktaRegistrationClient] ${errorTitle}: ${this.formatAPIErrorMessage(
          errorObject
        )}`
      ),
      data,
      ['registration']
    );
  }

  private formatAPIErrorMessage(error: APIError): string {
    const { errorSummary, errorCauses } = error;

    if (errorCauses && errorCauses.length > 0) {
      return `${errorSummary}, causes: ${JSON.stringify(errorCauses)}`;
    }

    return errorSummary;
  }

  private isAPIError(error: APIError | IdxResponse): error is APIError {
    return (error as APIError).errorSummary !== undefined;
  }
}

const mapKeyToMessage = (
  message: IdxMessage,
  authenticatorType?: AuthenticatorTypes
) => {
  const key = message.i18n?.key;
  switch (key) {
    case 'registration.error.notUniqueWithinOrg':
      return i18n.t('Registration:errors.invalidEmail');
    case 'api.authn.error.PASSCODE_INVALID':
      return authenticatorType === AuthenticatorTypes.Phone
        ? i18n.t('Registration:errors.invalidCodePhone')
        : i18n.t('Registration:errors.invalidCodeEmail');
    case 'api.factors.error.sms.invalid_phone':
      return i18n.t('Registration:errors.phoneNotContacted');
    default:
      return i18n.t('Registration:errors.genericError');
  }
};

const SDK_FAILURE: TransactionResults = {
  status: IdxStatus.FAILURE,
  stepSucceeded: false,
  errorMessages: [i18n.t('Registration:errors.genericError')],
};

const API_ERROR_TITLE = {
  flowStart: 'starting the registration flow failed',
  createUserProfile:
    'creating user profile (with email, zipCode, accountNumber) failed',
  sendVerificationEmail: 'sending verification e-mail failed',
  verifyAuthenticatorCode: (authenticatorType: AuthenticatorTypes) =>
    `verifying ${authenticatorType} code failed, check if user is still staged in okta` as const,
  selectPasswordAuthenticator: 'selecting password authenticator failed',
  createPassword: 'creating user password failed',
  skipPhoneAuthenticator:
    'skipping phone authenticator failed, check if user is staged in okta',
  selectPhoneAuthenticator: `selecting ${AuthenticatorKey.PHONE_NUMBER} authenticator failed`,
  selectPhoneAuthenticatorType: (methodType: PhoneMethodTypes) =>
    `selecting ${methodType} phone authenticator failed` as const,
  resendVerificationEmail: 're-sending verification e-mail failed',
  resendPhoneVerificationCode: 're-sending phone verification code failed',
} as const;
