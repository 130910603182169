import { InlineLink, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { useScreenResolution } from '@mfe/shared/util';

import { FlowFailureOutcome, Container } from '../../index';

export interface ScheduleInstallationFailureProps {
  title: string;
  handleBackNavigation: () => void;
}

const ScheduleInstallationFailure = ({
  title,
  handleBackNavigation,
}: ScheduleInstallationFailureProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'ErrorAppointmentSchedule',
  });

  const hrefLink = `tel:${t('phoneNumber')}`;

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  return (
    <Container mt="56px">
      <FlowFailureOutcome goToOverview={handleBackNavigation}>
        <Txt
          data-cy="error-appointment-schedule-title"
          mt="48px"
          mb="12px"
          variant="heading4"
        >
          {title}
        </Txt>

        <Txt
          variant="bodyLargeRegular"
          data-cy="error-appointment-schedule-description"
          color="subtle"
          mb="40px"
        >
          {t('description')}
          {isMobile ? (
            <InlineLink
              data-cy="error-appointment-schedule-phoneNumber-mobile"
              href={hrefLink}
            >
              <Txt component="span" style={{ whiteSpace: 'nowrap' }}>
                {t('phoneNumber')}
              </Txt>
            </InlineLink>
          ) : (
            <Txt
              variant="headerCapsLarge"
              data-cy="error-appointment-schedule-phoneNumber"
              component="span"
            >
              {t('phoneNumber')}
            </Txt>
          )}
          .
        </Txt>
      </FlowFailureOutcome>
    </Container>
  );
};

export default ScheduleInstallationFailure;
