import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mfe/to-be-migrated/redux/alerts';
import { AccountErrorDescription } from '@mfe/shared/components';
import { Txt } from '@vst/beam';

export const useAlertDescription = (alert: Alert) => {
  const { t } = useTranslation(['Alerts']);

  return React.useMemo(() => {
    if (alert.customDescription) {
      return (
        <Txt variant="bodySmallRegular" color="subtle" component="span">
          <div dangerouslySetInnerHTML={{ __html: alert.customDescription }} />
        </Txt>
      );
    }

    if (
      alert.caption &&
      [
        t('Alerts:AccountStatus.error.caption'),
        t('Alerts:AccountStatus.transitionError.caption'),
      ].includes(alert.caption)
    ) {
      return <AccountErrorDescription caption={alert.caption} />;
    }

    return alert.caption;
  }, [alert.caption, alert.customDescription, t]);
};
