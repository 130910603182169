import { Surface, SelectionCard, Txt } from '@vst/beam';
import { selectScrubbedAddress } from '@mfe/to-be-migrated/redux/scrubbedAddress';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import {
  selectUpdateAddress,
  setAddressSelection,
} from '@mfe/to-be-migrated/redux/updateAddress';
import { useTranslation } from 'react-i18next';

export const AddressSelection = () => {
  const { rawInput, scrubbedAddress } = useSelector(selectScrubbedAddress);
  const { addressSelection } = useSelector(selectUpdateAddress);
  const dispatch = useDispatch();
  const { t } = useTranslation('UpdateAddress', {
    keyPrefix: 'recommendation',
  });

  const recommendedAddress = `${scrubbedAddress.rawAddress.addressLines.join(
    ' '
  )} ${scrubbedAddress.rawAddress.municipality}, ${
    scrubbedAddress.rawAddress.region
  } ${scrubbedAddress.rawAddress.postalCode}`;

  const originalAddress = `${rawInput.addressLines.join(' ')} ${
    rawInput.municipality
  }, ${rawInput.region} ${rawInput.postalCode}`;

  return (
    <Surface style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <Txt variant="bodySmallBold" component="span" data-cy="header">
        {t('header')}
      </Txt>
      <SelectionCard
        data-cy="recommended"
        radioGroup="addressSelection"
        className={styles['selection-card']}
        name="recommended"
        selectionType="radio"
        header={t('recommended')}
        body={recommendedAddress}
        value={'recommended'}
        checked={addressSelection === 'recommended'}
        onChange={() => dispatch(setAddressSelection('recommended'))}
      />

      <SelectionCard
        data-cy="original"
        radioGroup="addressSelection"
        className={styles['selection-card']}
        name="original"
        selectionType="radio"
        header={t('original')}
        body={originalAddress}
        value={'original'}
        checked={addressSelection === 'original'}
        onChange={() => dispatch(setAddressSelection('original'))}
      />
    </Surface>
  );
};
