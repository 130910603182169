import { format } from 'date-fns';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';
import { AssessmentName } from '@mfe/shared/schema-types';

import { waitForToken } from '../utils/utilsSagas';

import {
  runDiagnostic,
  setDiagnostic,
  setError,
  setRunDate,
} from './networkSlice';
import { GET_DIAGNOSTICS_QUERY } from './queries-and-mutations';

export function* fetchDiagnosticResult() {
  yield call(waitForToken);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_DIAGNOSTICS_QUERY,
    variables: {
      doRefresh: false,
    },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getDiagnostics) {
    const filteredDiagnostic = data.getDiagnostics.filter(
      (diagnostic) =>
        diagnostic.name !== AssessmentName.Billing &&
        diagnostic.name !== AssessmentName.DataUsage
    );

    const runDate = format(new Date(), 'MMMM do h:mm aa');
    yield put(setRunDate(runDate));
    yield put(setDiagnostic(filteredDiagnostic));
  }
}

export function* watchNetwork() {
  yield takeLatest(runDiagnostic.type, fetchDiagnosticResult);
}
