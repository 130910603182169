import { gql } from '@apollo/client';

export const GET_ALL_CHANGE_PLAN_OFFERS = gql`
  query getPlanOffers($languageCode: String) {
    getPlanOffers(input: { languageCode: $languageCode }) {
      id
      offerId
      description
      price
      savings
      disclosure
      isUnlimited
      isEquipmentChangeNeeded
      priceCurrency
      characteristics {
        downloadSpeed
        downloadUnits
        uploadSpeed
        uploadUnits
        dataCapGB
        dataCapUnits
        isGoodFor
        tag
        bestFor
        inflectionPointText
        bannerColor
        uploadRangeText
        downloadRangeText
        downloadRange
        dataAllowanceText
        offerName
        titleAddOns
        usageMeter
        upi
        serviceAreaId
        family
      }
    }
  }
`;

export const GET_FULFILLMENT_AGREEMENT_ID = gql`
  query getWorkOrderFulfillmentAgreementId($externalWorkOrderId: ID) {
    getWorkOrderFulfillmentAgreementId(
      externalWorkOrderId: $externalWorkOrderId
    ) {
      fulfillmentAgreementId
    }
  }
`;

export const GET_AVAILABLE_INSTALLATION_DATES = gql`
  query getAvailableInstallationDates(
    $input: GetAvailableInstallationDatesInput
  ) {
    getAvailableInstallationDates(input: $input) {
      timezone
      schedules {
        to
        from
      }
    }
  }
`;

export const CONFIGURE_CART = gql`
  mutation configureCart($input: ConfigureCartInput!) {
    configureCart(input: $input) {
      id
      requiresWorkOrder
      hasEquipmentShipping
      status
      leaseFeeShortName
      accountSetupShortName
      droppedAddOns {
        name
        productTypeId
      }
      cartItems {
        plan {
          categoryName
          name
          price
          accountSetupFeeShortName
          accountSetupFeePrice
          contractTerms
          contractTermsDisclosure
          leaseFeeShortName
          leaseFeePrice
        }
      }
      prices {
        monthly {
          totalWithPromos
          totalWithoutPromos
          lineItems {
            promo
            lease
          }
        }
        oneTime {
          lease
          totalWithPromos
          totalWithoutPromos
          lineItems {
            promo
          }
          feesAndDiscounts {
            fee
            discount
            total
            name
          }
        }
      }
    }
  }
`;

export const TRANSITION_PLAN = gql`
  mutation transitionPlan($input: TransitionPlanInput!) {
    transitionPlan(input: $input) {
      success
      message
      orderId
      appointment {
        endTime
        startTime
        workOrderScheduled
      }
    }
  }
`;

export const GET_HAS_PENDING_TRANSITION = gql`
  query getHasPendingTransition($refetchData: Boolean, $productInstanceId: ID) {
    getHasPendingTransition(
      refetchData: $refetchData
      productInstanceId: $productInstanceId
    ) {
      isChangePlanDisabled
      hasActivationError
    }
  }
`;
