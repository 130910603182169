import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BuyMoreProducts } from '@mfe/shared/schema-types';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

import { calculateTotalData, formatCurrentBuyMoreProducts } from './utils';

type ErrorType = ErrorsObj | RuntimeError | null;

export interface BuyMoreProduct {
  dataAmount: string;
  price: string;
  purchaseDate: string;
}

interface BuyMoreHistory {
  loading: boolean;
  data: BuyMoreProduct[];
  error: ErrorType;
  total: number;
}

export const initialBuyMoreHistoryState: BuyMoreHistory = {
  loading: false,
  data: [],
  error: null,
  total: 0,
};

export const buyMoreHistorySlice = createSlice({
  name: 'buyMoreHistory',
  initialState: initialBuyMoreHistoryState,
  reducers: {
    getBuyMoreHistory: (state) => {
      state.loading = true;
    },
    refetchBuyMoreHistory: (state) => {
      state.loading = true;
    },
    setBuyMoreHistoryData: (
      state,
      { payload }: PayloadAction<BuyMoreProducts[]>
    ) => {
      state.loading = false;
      state.data = formatCurrentBuyMoreProducts(payload);
      state.total = calculateTotalData(payload);
    },
    setBuyMoreHistoryError: (state, { payload }: PayloadAction<ErrorType>) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  getBuyMoreHistory,
  refetchBuyMoreHistory,
  setBuyMoreHistoryData,
  setBuyMoreHistoryError,
} = buyMoreHistorySlice.actions;

export const selectBuyMoreHistory = (state: {
  buyMoreHistory: BuyMoreHistory;
}) => state.buyMoreHistory;
