import { gql } from '@apollo/client';

export const HANDLE_STAGED_USERS = gql`
  mutation handleStagedUsers($input: HandleStagedUsersInput!) {
    handleStagedUsers(input: $input) {
      deletionFailureReason
      userDeleted
    }
  }
`;

export const DELETE_OKTA_USER = gql`
  mutation deleteOktaUser($input: DeleteOktaUserInput!) {
    deleteOktaUser(input: $input) {
      success
    }
  }
`;

export const UPDATE_REGISTRATION_DATA = gql`
  mutation updateRegistrationData($input: UpdateRegistrationDataInput!) {
    updateRegistrationData(input: $input) {
      externalId {
        type
        value
      }
      accountNumber
      partyId
      email
    }
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation updatePhoneNumber($input: UpdatePhoneNumberInput!) {
    updatePhoneNumber(input: $input) {
      accountNumber
      partyId
      phoneNumber
    }
  }
`;

export const VALIDATE_ACCOUNT_INFO = gql`
  query validateAccountInfo($input: ValidateAccountInfoInput!) {
    validateAccountInfo(input: $input) {
      accountNumberExistsInOkta
      validAccountInfo
      locale
      token
    }
  }
`;

export const UPDATE_OKTA_LOCALE = gql`
  mutation updateOktaLocale($input: UpdateOktaLocaleInput!) {
    updateOktaLocale(input: $input) {
      username
      locale
    }
  }
`;

export const GET_REGISTRATION_CONFIG = gql`
  query getRegistrationConfig($locale: Locale!) {
    getRegistrationConfig(locale: $locale) {
      issuer
      myViasatUrl
      client {
        clientId
        redirectUrl
      }
    }
  }
`;
