import { createSlice } from '@reduxjs/toolkit';

export interface RaygunState {
  loaded: boolean;
}
const initialState: RaygunState = {
  loaded: false,
};

export interface InitRaygunOptions {
  apiKey: string;
  enablePulse?: boolean;
  enableCrashReporting?: boolean;
  version?: string;
}

export const raygunSlice = createSlice({
  name: 'raygun',
  initialState: initialState,
  reducers: {
    setRaygunUser: (state) => state,
    sendRaygunError: (
      state,
      { payload }: { payload: { error: Error; data?: any; tags?: string[] } }
    ) => state,
    setRaygunLoaded: (state, { payload }: { payload: { loaded: boolean } }) => {
      state.loaded = payload.loaded;
    },
    initRaygun: (state, _: { payload: InitRaygunOptions }) => state,
  },
});

export const { initRaygun, setRaygunUser, setRaygunLoaded, sendRaygunError } =
  raygunSlice.actions;
export const selectRaygun = (state: { raygun: RaygunState }) => state.raygun;
export { initialState as initialRaygunState };
