import { ILogoProps, Logo } from '@vst/beam';
import {
  Amex,
  Mastercard,
  NoCreditCard,
  Bank,
  Barcode,
  DinersClub,
  Discover,
  Elo,
  Hipercard,
  PayPal,
  Visa,
  Sepa,
} from '@vst/beam-icons/logos/payments';

import { PAYMENT_METHOD_NAME, useScreenResolution } from '@mfe/shared/util';

type Props = {
  name?: string | null;
  width?: string;
};

type LogoProps = Pick<ILogoProps, 'logo' | 'alt'> & { id?: string };

const getLogoProps = (name: string | null | undefined): LogoProps => {
  switch (name) {
    case undefined:
    case null:
      return {
        logo: NoCreditCard,
        alt: 'NoCreditCard',
        id: 'notEnrolled',
      };

    case PAYMENT_METHOD_NAME.ACH:
      return { logo: Bank, alt: 'Bank' };

    case PAYMENT_METHOD_NAME.AmericanExpress:
      return {
        logo: Amex,
        alt: 'Amex',
      };

    case PAYMENT_METHOD_NAME.Boleto:
      return {
        logo: Barcode,
        alt: 'Barcode',
      };

    case PAYMENT_METHOD_NAME.DinersClub:
      return {
        logo: DinersClub,
        alt: 'DinersClub',
      };

    case PAYMENT_METHOD_NAME.Discover:
      return {
        logo: Discover,
        alt: 'Discover',
      };

    case PAYMENT_METHOD_NAME.Elo:
      return {
        logo: Elo,
        alt: 'Elo',
      };

    case PAYMENT_METHOD_NAME.Hipercard:
      return {
        logo: Hipercard,
        alt: 'Hipercard',
      };

    case PAYMENT_METHOD_NAME.MasterCard:
      return {
        logo: Mastercard,
        alt: 'Mastercard',
      };

    case PAYMENT_METHOD_NAME.PayPal:
      return { logo: PayPal, alt: 'PayPal' };

    case PAYMENT_METHOD_NAME.Pix:
      return {
        logo: Barcode,
        alt: 'Barcode',
      };

    case PAYMENT_METHOD_NAME.SEPA:
      return {
        logo: Sepa,
        alt: 'Sepa',
      };

    case PAYMENT_METHOD_NAME.Visa:
      return {
        logo: Visa,
        alt: 'Visa',
      };

    default:
      return { logo: Bank, alt: 'Bank' };
  }
};

export const CardLogo = ({ name, width }: Props): JSX.Element => {
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobileSize = isSmall || isExtraSmall;
  const logoWidth = width ?? (isMobileSize ? '48px' : '70px');

  const logoProps = getLogoProps(name);

  return <Logo {...logoProps} size={logoWidth} />;
};
