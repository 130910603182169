/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';
import { Mutation, MutationLogArgs } from '@mfe/shared/schema-types';
import { getApolloContext, gql, ApolloContextValue } from '@apollo/client';
import SomethingWentWrong from './SomethingWentWrong';

const LOG = gql`
  mutation Log($isError: Boolean!, $message: String!, $info: JSON!) {
    log(isError: $isError, message: $message, info: $info)
  }
`;

class ErrorBoundary extends Component<
  Record<string, unknown>,
  { hasError: boolean }
> {
  static override contextType = getApolloContext();

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: any, errorInfo: any) {
    try {
      const { client } = this.context as ApolloContextValue;
      const stack = errorInfo?.componentStack?.split('\n') ?? [];

      client?.mutate<Mutation, MutationLogArgs>({
        mutation: LOG,
        variables: {
          isError: true,
          message: `Error caught by error boundary`,
          info: {
            error: `${error}`,
            errorInfo,
            stack,
            // After moving logging-service to its new location,
            // importing the LogResult enum broke the build.
            // The error is detailed in the docs link below
            // https://nextjs.org/docs/messages/module-not-found#the-module-youre-trying-to-import-uses-nodejs-specific-modules
            result: 'ERROR',
          },
        },
      });
    } catch (e) {
      console.error('Error logging caught error', e);
      console.error(error, errorInfo);
    }
  }

  override render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }

    return this.props['children'] as React.ReactNode;
  }
}

export default ErrorBoundary;
