import { select, call } from 'redux-saga/effects';
import { addHours, format, parseISO } from 'date-fns';

import {
  BadgeStatus,
  Invoice,
  InvoiceStatus,
  Locale,
  NotificationLevel,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import i18n, { DisplayLanguage } from '@mfe/services/translations-service';
import { parseDateStringWithoutTime } from '@mfe/shared/util';

import { Alert, AlertAction } from './alertsSlice';
import { selectLocale } from '../locale';
import { selectUser } from '../auth';
import { selectBillingInfo } from '../billingInfo';
import { selectCurrentUsage, selectPlanCharacteristics } from '../plan';
import { selectWorkOrders } from '../workOrders';

export function* parseAccountAlerts() {
  const {
    user: { productInstanceStatus },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const alerts: Alert[] = [];
  let activeAlerts: Alert | undefined;

  switch (productInstanceStatus) {
    case ProductInstanceStatus.Active:
      activeAlerts = yield call(getActiveAlerts);
      if (activeAlerts) alerts.push(activeAlerts);
      break;
    case ProductInstanceStatus.Deactivated:
    case ProductInstanceStatus.Canceled:
      alerts.push(yield call(getDeactivatedAlerts));
      break;
    case ProductInstanceStatus.Suspended:
      alerts.push(yield call(getSuspendedAlerts));
      break;
    case ProductInstanceStatus.Preinstall:
      alerts.push(yield call(getPreinstallAlerts));
      break;
    default:
      break;
  }

  return alerts;
}

export const determineIsPreSoftSuspend = (
  invoices: Invoice[],
  isSoftSuspended: boolean
) => {
  if (invoices.length === 0) {
    return false;
  }

  const unpaidInvoice = invoices.find(
    (invoice) => invoice.invoiceStatus === InvoiceStatus.Unpaid
  );

  return Boolean(unpaidInvoice) && !isSoftSuspended;
};

export function* getActiveAlerts() {
  const {
    user: { isSoftSuspended, isACPSuspended },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const isUS = userLocale === Locale.EnUs;
  const isBrazil = userLocale === Locale.PtBr;
  const isItaly = userLocale === Locale.ItIt;

  const {
    billingInfo: { summaryInvoiceStatus, invoices },
  }: ReturnType<typeof selectBillingInfo> = yield select(selectBillingInfo);
  const hasPastDueInvoices = summaryInvoiceStatus === BadgeStatus.PastDue;

  const isPreSoftSuspended = determineIsPreSoftSuspend(
    invoices,
    isSoftSuspended
  );

  const {
    characteristics: { hasUnlimitedUsageMeter },
  }: ReturnType<typeof selectPlanCharacteristics> = yield select(
    selectPlanCharacteristics
  );

  const {
    data: { hasBreachedDataAllowance, endDate, monthlyDataCap },
    errors: usageErrors,
  }: ReturnType<typeof selectCurrentUsage> = yield select(selectCurrentUsage);

  const allDataUsed = hasBreachedDataAllowance;
  const usageResetDate = endDate ? parseDateStringWithoutTime(endDate) : '';

  const hasBuyMoreDataButton = isItaly
    ? null
    : {
        action: AlertAction.BuyMore,
        label: i18n.t('Alerts:AccountStatus.dataAllUsed.button.text'),
      };

  if (isUS && hasPastDueInvoices) {
    return {
      level: NotificationLevel.Warning,
      title: i18n.t('Alerts:AccountStatus.pastDue.title'),
      caption: i18n.t('Alerts:AccountStatus.pastDue.caption'),
      button: {
        action: AlertAction.GoToBilling,
        label: i18n.t('Alerts:AccountStatus.pastDue.button.text'),
      },
    };
  }

  if (isBrazil && isSoftSuspended) {
    return {
      level: NotificationLevel.Error,
      title: i18n.t('Alerts:AccountStatus.softSuspend.title'),
      caption: i18n.t('Alerts:AccountStatus.softSuspend.caption'),
      button: {
        action: AlertAction.GoToBilling,
        label: i18n.t('Alerts:AccountStatus.softSuspend.button.text'),
      },
    };
  }

  if (isBrazil && isPreSoftSuspended) {
    return {
      level: NotificationLevel.Warning,
      title: i18n.t('Alerts:AccountStatus.preSoftSuspend.title'),
      caption: i18n.t('Alerts:AccountStatus.preSoftSuspend.caption'),
      button: {
        action: AlertAction.GoToBilling,
        label: i18n.t('Alerts:AccountStatus.preSoftSuspend.button.text'),
      },
    };
  }

  if (
    !usageErrors &&
    allDataUsed &&
    hasUnlimitedUsageMeter &&
    usageResetDate &&
    monthlyDataCap
  ) {
    return {
      level: NotificationLevel.Warning,
      title: i18n.t('Alerts:AccountStatus.allDataUsedNextGen.title'),
      caption: i18n.t('Alerts:AccountStatus.allDataUsedNextGen.caption', {
        date: usageResetDate,
        amount: monthlyDataCap,
      }),
      button: {
        action: AlertAction.BuyMore,
        label: i18n.t('Alerts:AccountStatus.allDataUsedNextGen.button.text'),
      },
      link: {
        action: AlertAction.PlanUsage,
        label: i18n.t('Alerts:AccountStatus.allDataUsedNextGen.link.text'),
      },
    };
  }

  if (!usageErrors && allDataUsed && !isSoftSuspended && !isACPSuspended) {
    return {
      level: NotificationLevel.Warning,
      title: i18n.t('Alerts:AccountStatus.dataAllUsed.title'),
      caption: i18n.t('Alerts:AccountStatus.dataAllUsed.caption'),
      button: hasBuyMoreDataButton,
      link: {
        action: AlertAction.PlanUsage,
        label: i18n.t('Alerts:AccountStatus.dataAllUsed.link.text'),
      },
    };
  }

  return;
}

export function* getDeactivatedAlerts() {
  const {
    locale: { displayLanguage },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);
  const isPortuguese = displayLanguage === DisplayLanguage.Portuguese;
  if (isPortuguese) {
    return {
      level: NotificationLevel.Error,
      title: i18n.t('Alerts:AccountStatus.disconnected.title'),
      caption: i18n.t('Alerts:AccountStatus.disconnected.caption'),
      button: {
        action: AlertAction.GoToBilling,
        label: i18n.t('Alerts:AccountStatus.disconnected.button.text'),
      },
      link: {
        label: i18n.t('Alerts:AccountStatus.disconnected.link.text'),
        url: i18n.t('Alerts:AccountStatus.disconnected.link.url'),
      },
    };
  } else
    return {
      level: NotificationLevel.Error,
      title: i18n.t('Alerts:AccountStatus.disconnected.title'),
      caption: i18n.t('Alerts:AccountStatus.disconnected.caption'),
      button: {
        action: AlertAction.GoToBilling,
        label: i18n.t('Alerts:AccountStatus.disconnected.button.text'),
      },
    };
}

export function* getSuspendedAlerts() {
  const {
    user: { isACPSuspended },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const suspensionType = isACPSuspended ? 'acp_suspended' : 'suspended';

  return {
    level: NotificationLevel.Error,
    title: i18n.t(`Alerts:AccountStatus.${suspensionType}.title`),
    caption: i18n.t(`Alerts:AccountStatus.${suspensionType}.caption`),
    button: {
      action: AlertAction.GoToBilling,
      label: i18n.t(`Alerts:AccountStatus.${suspensionType}.button.text`),
    },
  };
}

export function* getPreinstallAlerts() {
  const { installDate } = yield select(selectWorkOrders);

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const showScheduleInstallation = userLocale === Locale.EnUs;

  if (!installDate) {
    return {
      level: NotificationLevel.Info,
      title: i18n.t('Alerts:AccountStatus.unscheduled.title'),
      caption: i18n.t('Alerts:AccountStatus.unscheduled.caption'),
      button: showScheduleInstallation
        ? {
            action: AlertAction.ScheduleInstall,
            label: i18n.t('Alerts:AccountStatus.unscheduled.button.text'),
          }
        : undefined,
    };
  }

  const formattedDate = format(new Date(installDate), 'MMMM dd');

  const from = new Date(installDate);
  const to = new Date(addHours(parseISO(installDate), 3));

  return {
    level: NotificationLevel.Info,
    title: i18n.t('Alerts:AccountStatus.scheduled.title'),
    caption: i18n.t('Alerts:AccountStatus.scheduled.caption', {
      formattedDate,
      from,
      to,
    }),
    button: showScheduleInstallation
      ? {
          action: AlertAction.ScheduleInstall,
          label: i18n.t('Alerts:AccountStatus.scheduled.button.text'),
        }
      : undefined,
  };
}
