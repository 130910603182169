import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  graphqlQueryWithErrors,
  FetchWithErrorsQuery,
  graphqlMutationWithErrors,
  FetchWithErrorsMutation,
  writeApolloCacheQueryFragment,
} from '@mfe/shared/redux/graphql';

import { GET_BILL_CYCLE_DAYS, UPDATE_BILL_CYCLE } from './queriesAndMutations';
import {
  setLoading,
  setBillCycleDays,
  setUpdateBillCycleError,
  setNewBillCycleDay,
  triggerBillCycleDayUpdate,
  getBillCycleDays,
} from './slice';
import { setBillingPageStatistics } from '../utils';
import { waitForToken } from '../utils/utilsSagas';
import { setBillingInfo } from '../billingInfo';
import {
  BILLING_ACCOUNT_REFETCH_FALSE_FRAGMENT,
  BILLING_ACCOUNT_REFETCH_TRUE_FRAGMENT,
} from '../billingInfo/queriesAndMutations';

export function* fetchBillCycleDays() {
  yield call(waitForToken);

  yield put(setLoading(true));

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_BILL_CYCLE_DAYS,
    fetchPolicy: 'network-only',
  });

  const { data, errors, runtimeError } = response;

  if (runtimeError || errors) {
    yield put(setUpdateBillCycleError(runtimeError ?? errors));
    return;
  }

  if (data?.getBillCycleDays) {
    yield put(setBillCycleDays(data.getBillCycleDays));
  }
}

export function* updateBillCycle(action: PayloadAction<number>) {
  const response: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: UPDATE_BILL_CYCLE,
      variables: {
        billingCycleDayOfMonth: action.payload,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { data, errors, runtimeError } = response;

  if (runtimeError || errors) {
    yield put(setUpdateBillCycleError(runtimeError ?? errors));
    return;
  }

  const newBillingAccount = data?.updateBillCycleDay;

  if (newBillingAccount && newBillingAccount?.billingCycleDayOfMonth) {
    yield call(writeApolloCacheQueryFragment, {
      fragment: BILLING_ACCOUNT_REFETCH_FALSE_FRAGMENT,
      data: { getBillingAccount: newBillingAccount },
    });

    yield call(writeApolloCacheQueryFragment, {
      fragment: BILLING_ACCOUNT_REFETCH_TRUE_FRAGMENT,
      data: { getBillingAccount: newBillingAccount },
    });

    yield put(setBillingInfo({ billingAccount: newBillingAccount }));

    yield put(setNewBillCycleDay(newBillingAccount.billingCycleDayOfMonth));
  }
}

export function* watchUpdateBillCycle() {
  yield all([
    takeLatest(getBillCycleDays.type, fetchBillCycleDays),
    takeLatest(triggerBillCycleDayUpdate.type, updateBillCycle),
    takeLatest(setBillingPageStatistics.type, fetchBillCycleDays),
  ]);
}
