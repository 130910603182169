import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Surface, Txt } from '@vst/beam';

import { ScheduleInput } from '@mfe/shared/schema-types';
// move this to a different lib?
import { formatTimeInterval } from '@mfe/to-be-migrated/redux/scheduleInstallation';

import styles from './time-picker.module.scss';

interface TimePickerProps {
  onTimeIntervalClick?: () => void;
  intervals: ScheduleInput[];
  selectedInterval: ScheduleInput | null;
  setSelectedInterval: (value: ScheduleInput | null) => void;
}

export const TimePicker = ({
  intervals,
  selectedInterval,
  setSelectedInterval,
  onTimeIntervalClick,
}: TimePickerProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'calendar',
  });

  const handleSlotSelect = useCallback(
    (i: ScheduleInput) => {
      setSelectedInterval?.(selectedInterval?.from === i.from ? null : i);

      if (onTimeIntervalClick) {
        onTimeIntervalClick();
      }
    },
    [selectedInterval, setSelectedInterval, onTimeIntervalClick]
  );

  return (
    <Surface
      variant="primary"
      data-testid="time"
      className={`${styles['time']}`}
    >
      <Txt variant="bodySmallBold" mb="16px">
        {t('timeInterval.title')}
      </Txt>
      <Surface className={styles['time-interval-container']}>
        {intervals.map((timeInterval: ScheduleInput) => {
          const selectedClassName =
            timeInterval.from === selectedInterval?.from
              ? styles['time-interval-selected']
              : '';

          const formattedTimeInterval = formatTimeInterval([
            timeInterval?.from ?? '',
            timeInterval?.to ?? '',
          ]);

          return (
            <Button
              type="button"
              variant="secondary"
              key={`selected-${formattedTimeInterval}`}
              data-cy="time-interval"
              pressed={timeInterval.from === selectedInterval?.from}
              className={`${styles['time-interval']} ${selectedClassName}`}
              onClick={() => handleSlotSelect(timeInterval)}
            >
              {formattedTimeInterval}
            </Button>
          );
        })}
      </Surface>
    </Surface>
  );
};

export default TimePicker;
