import React from 'react';
import { ChevronDownCurved } from '@vst/beam-icons/icons';

import styles from './styles.module.scss';

interface CollapsibleProps {
  summary: React.ReactNode;
  content: React.ReactNode;
  onToggle?: (isOpen: boolean) => void;
  hasBoxShadow?: boolean;
  style?: React.CSSProperties & {
    '--has-box-shadow'?: string;
  };
  classNames?: {
    details?: string;
    summary?: string;
  };
  dataCy?: string;
}

export const Collapsible = ({
  style,
  summary,
  content,
  onToggle,
  classNames,
  hasBoxShadow = false,
  dataCy,
}: CollapsibleProps) => {
  const { details: detailsClassName = '', summary: summaryClassName = '' } =
    classNames || {};

  return (
    <details
      data-cy={dataCy}
      style={{
        '--has-box-shadow': hasBoxShadow
          ? '0px 7px 16px 2px rgba(0, 0, 0, 0.11)'
          : 'none',
        ...style,
      }}
      className={`${styles['collapsible']} ${detailsClassName}`}
      onToggle={(event) => onToggle?.(event.currentTarget.open)}
    >
      <summary
        className={`${styles['collapsible-summary']} ${summaryClassName}`}
      >
        {summary}

        <ChevronDownCurved
          data-cy="chevron"
          style={{
            width: 20,
            height: 20,
            marginLeft: 'auto',
          }}
        />
      </summary>

      {content}
    </details>
  );
};
