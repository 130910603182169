import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

import { getPortfolio, setError, setPortfolio } from './portfolioSlice';
import { GET_PORTFOLIO } from './query';
import { waitForToken } from '../utils/utilsSagas';

export function* fetchPortfolio() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PORTFOLIO,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getPortfolio) {
    // We are omitting the typename because this portfolio is passed as parameter to configureCart in the change plan flow
    const portfolio = omitTypenameDeep(data.getPortfolio);
    yield put(setPortfolio(portfolio));
  }
}

function omitTypenameDeep(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(omitTypenameDeep);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key]) => key !== '__typename')
        .map(([key, value]) => [key, omitTypenameDeep(value)])
    );
  } else {
    return obj;
  }
}

export function* watchPortfolio() {
  yield all([takeLatest(getPortfolio.type, fetchPortfolio)]);
}
