import { SectionAlert, Txt } from '@vst/beam';
import { CalendarToday } from '@vst/beam-icons/icons';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SelectedDate } from '../hooks/use-schedule-installation';
import { formatTimeInterval } from '@mfe/to-be-migrated/redux/scheduleInstallation';
import { ScheduleInput } from '@mfe/shared/schema-types';

export enum AlertAction {
  DateAndTimeRequired = 'dateAndTimeRequired',
  TimeRequired = 'timeRequired',
  DateAndTimeConfirmed = 'dateAndTimeConfirmed',
}

export interface AlertScheduleInstallationProps {
  action: AlertAction;
  selectedDate: SelectedDate;
  selectedInterval: ScheduleInput | null;
}

export const AlertScheduleInstallation = ({
  action,
  selectedDate,
  selectedInterval,
}: AlertScheduleInstallationProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'ScheduleInstallationAlert',
  });

  const formattedDate =
    selectedDate.day &&
    selectedDate.month &&
    selectedDate.year &&
    format(
      new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day),
      'LLL d'
    );

  switch (action) {
    case AlertAction.DateAndTimeRequired:
      return (
        <SectionAlert
          data-cy="alert-dateAndTimeRequired"
          variant="error"
          label={t('DateAndTimeRequiredAlert')}
          size="large"
        />
      );
    case AlertAction.TimeRequired:
      return (
        <SectionAlert
          data-cy="alert-timeRequired"
          variant="error"
          label={t('TimeRequiredAlert')}
          size="large"
        />
      );
    case AlertAction.DateAndTimeConfirmed:
      return (
        <SectionAlert
          data-cy="alert-dateAndTimeConfirmed"
          variant="success"
          contentSlot={
            <div>
              <Txt color="regular" component="span" variant="smallRegular">
                {t('ConfirmationAlert.selectionMessage')}
              </Txt>
              <Txt color="regular" component="span" variant="smallBold">
                {t('ConfirmationAlert.dateBetweenTime', {
                  DATE: formattedDate,
                  TIME: formatTimeInterval([
                    selectedInterval?.from ?? '',
                    selectedInterval?.to ?? '',
                  ]),
                })}
              </Txt>
            </div>
          }
          icon={CalendarToday}
          size="large"
        />
      );
    default:
      return null;
  }
};
