import { createSlice } from '@reduxjs/toolkit';

import { Portfolio } from '@mfe/shared/schema-types';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

type PortfolioState = {
  loading: boolean;
  portfolio: Portfolio | null;
  error: ErrorsObj | RuntimeError | null;
};

// TODO: consider moving portfolio data to the change plan slice as part of the cleanup
export const initialPortfolioState: PortfolioState = {
  loading: false,
  portfolio: null,
  error: null,
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: initialPortfolioState,
  reducers: {
    getPortfolio: (state) => {
      state.loading = true;
    },
    setPortfolio: (state, { payload }) => {
      state.loading = false;
      state.portfolio = payload;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { getPortfolio, setPortfolio, setError } = portfolioSlice.actions;

export const selectPortfolio = (state: { portfolio: PortfolioState }) =>
  state.portfolio;
