import { gql } from '@apollo/client';

export const GET_BILL_CYCLE_DAYS = gql`
  query getBillCycleDays {
    getBillCycleDays {
      canChangeBillDate
      days
      reason
      billingCycleDayOfMonthUpdateDate
    }
  }
`;

export const UPDATE_BILL_CYCLE = gql`
  mutation updateBillCycleDay($billingCycleDayOfMonth: Int!) {
    updateBillCycleDay(billingCycleDayOfMonth: $billingCycleDayOfMonth) {
      accountNumber
      accountStatus
      balance {
        value
        text
        alphabeticCode
      }
      billingStatus
      billDate
      creditClass
      dueDate
      invoiceAmount {
        value
        text
        alphabeticCode
      }
      payDate
      paymentDueDays
      billingCycleDayOfMonth
      nextBillPeriodStartDate
    }
  }
`;
