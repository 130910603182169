import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { scrollToTop, goToUrl } from '@mfe/shared/redux/utils';
import { graphqlQuery, graphqlMutation } from '@mfe/shared/redux/graphql';
import {
  OktaRegistrationClient,
  PhoneMethodTypes,
  TransactionResults,
  AuthenticatorTypes,
} from './OktaRegistrationClient';
import {
  RegistrationFormState,
  requestVerifyEmailCode as requestVerifyEmailCodeAction,
  submitVerifyEmailCode as submitVerifyEmailCodeAction,
  requestVerifyPhoneCode as requestVerifyPhoneCodeAction,
  submitVerifyPhoneCode as submitVerifyPhoneCodeAction,
  resendEmailCode as resendEmailCodeAction,
  resendPhoneCode as resendPhoneCodeAction,
  fetchRegCreds as fetchRegCredsAction,
  registerEmailAndPassword as registerEmailAndPasswordAction,
  regLogout as regLogoutAction,
  setRegistrationLoading,
  setRegistrationStep,
  RegistrationSteps,
  goBackToSubmitEmail as goBackToSubmitEmailAction,
  setBackendError,
  registrationFailedEvent,
  RegistrationFailureReasons,
  initializeRegistrationAnalytics,
  SubmitVerifyEmailCodeAction,
  RegisterEmailAndPasswordAction,
  setRegistrationContext,
} from './registrationSlice';
import { selectConfig, setConfig } from '@mfe/shared/redux/config';
import { buildRegistrationContext } from './registrationAnalyticsUtil';
import {
  DeletionFailureReason,
  Mutation,
  MvEnv,
  Platform,
  Query,
  TokenType,
} from '@mfe/shared/schema-types';
import getAuthParams from '../auth/authSagas/getAuthParams';
import {
  getAuthUrls,
  selectUser,
  setRegistrationAuth,
  TOKEN_INFO_COOKIE_NAME,
} from '../auth';
import { isWebview } from './utils';
import { selectLocale } from '../locale';
import i18n from '@mfe/services/translations-service';
import {
  HANDLE_STAGED_USERS,
  UPDATE_OKTA_LOCALE,
  UPDATE_PHONE_NUMBER,
  UPDATE_REGISTRATION_DATA,
  VALIDATE_ACCOUNT_INFO,
  GET_REGISTRATION_CONFIG,
} from './queries';
import { IdxStatus } from '@okta/okta-auth-js';
import { sendRaygunError } from '../raygun/raygunSagas';
import { isPWAMyViasat } from '@mfe/shared/util';
import { setCookie } from '../events';

export function* getOktaClient() {
  const {
    reg: { client, issuer },
  }: ReturnType<typeof selectConfig> = yield select(selectConfig);

  return new OktaRegistrationClient(issuer, client);
}

//step -1 go to regular login
// we won't need this if the registration page gets embedded in MyViasat, but until then we'll need a way to go directly to a url
export function* regLogout() {
  const {
    auth: { client, baseUrl, myViasatUrl },
  }: ReturnType<typeof selectConfig> = yield select(selectConfig);

  const { loginUrl } = getAuthUrls(client, baseUrl);

  yield put(goToUrl(myViasatUrl ?? loginUrl));
}

//Step 0, request registration credentials
export function* fetchRegCreds() {
  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const data: Query = yield call(graphqlQuery, {
    query: GET_REGISTRATION_CONFIG,
    variables: { locale: userLocale },
  });
  const { client: regClient, issuer, myViasatUrl } = data.getRegistrationConfig;

  //we can get the env and platform from MyViasat once the registration page has been integrated within it
  const env = issuer.includes('uat') ? MvEnv.Test : MvEnv.Prod;
  const platform = isWebview() ? Platform.Ios : Platform.Web;
  const authMethod = isWebview() && !isPWAMyViasat() ? 'mobile' : 'web';
  const { client: authClient, baseUrl } = getAuthParams(
    env,
    authMethod,
    userLocale
  );

  yield put(
    setConfig({
      env,
      auth: { client: authClient, baseUrl, myViasatUrl } as any,
      platform,
      reg: { client: regClient, issuer },
    })
  );

  yield put(initializeRegistrationAnalytics());
}

// Submit step 1 or rerequest code
export function* requestVerifyEmailCode({
  payload: { accountNumber, postalCode, email },
}: {
  type: string;
  payload: Partial<RegistrationFormState>;
}): any {
  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  yield put(setRegistrationLoading({ loading: true }));

  const data: Query = yield call(graphqlQuery, {
    query: VALIDATE_ACCOUNT_INFO,
    variables: { input: { accountNumber, postalCode, locale: userLocale } },
    partialRefetch: true,
  });

  if (data.validateAccountInfo?.accountNumberExistsInOkta) {
    yield put(
      setBackendError({
        error: [i18n.t('Registration:errors.accountAlreadyRegistered')],
      })
    );
    yield put(scrollToTop());
    yield put(
      registrationFailedEvent({
        pageName: RegistrationSteps.submitEmail,
        failureReason: RegistrationFailureReasons.invalidAccountNumber,
      })
    );
    yield put(setRegistrationLoading({ loading: false }));
    return;
  }

  if (!data.validateAccountInfo?.validAccountInfo) {
    yield put(
      setBackendError({
        error: [i18n.t('Registration:errors.invalidAccountInfo')],
      })
    );

    yield put(
      registrationFailedEvent({
        pageName: RegistrationSteps.submitEmail,
        failureReason: RegistrationFailureReasons.invalidAccountInfo,
      })
    );
    yield put(setRegistrationLoading({ loading: false }));
    yield put(scrollToTop());
    return;
  }

  yield put(
    setRegistrationAuth({
      tokenInfo: {
        accessToken: data.validateAccountInfo?.token ?? '',
        accessTokenExpirationTime: '',
        type: TokenType.Registration,
      },
    })
  );

  const client: OktaRegistrationClient = yield call(getOktaClient);

  const startRegistrationResult: TransactionResults = yield call(
    client.startRegistration as any,
    email,
    accountNumber,
    postalCode
  );

  if (!startRegistrationResult.stepSucceeded) {
    if (
      startRegistrationResult?.errorMessages?.some(
        (message) =>
          i18n.t('Registration:errors.invalidAccountInfo') &&
          message.includes(i18n.t('Registration:errors.invalidAccountInfo'))
      )
    ) {
      yield put(
        registrationFailedEvent({
          pageName: RegistrationSteps.submitEmail,
          failureReason: RegistrationFailureReasons.invalidAccountInfo,
        })
      );

      yield put(scrollToTop());
    }
    if (
      startRegistrationResult?.errorMessages?.some(
        (message) =>
          i18n.t('Registration:errors.invalidEmail') &&
          message.includes(i18n.t('Registration:errors.invalidEmail'))
      )
    ) {
      const data: Mutation = yield call(graphqlMutation, {
        mutation: HANDLE_STAGED_USERS,
        variables: { input: { username: email, locale: userLocale } },
      });
      if (data.handleStagedUsers.userDeleted) {
        yield call(client.cancelTransaction);
        yield call(
          requestVerifyEmailCode,
          requestVerifyEmailCodeAction({ accountNumber, postalCode, email })
        );
      } else {
        const deletionReason = data.handleStagedUsers?.deletionFailureReason;
        let error = [];
        switch (deletionReason) {
          case DeletionFailureReason.AccountCreatedTooRecently:
            error = [i18n.t('Registration:errors.emailUsedTooRecently')];
            yield call(
              sendRaygunError,
              new Error(
                `[registrationSagas] ${i18n.t(
                  'Registration:errors.emailUsedTooRecently'
                )}`
              ),
              { accountNumber },
              ['registration']
            );
            break;
          default:
            error = startRegistrationResult.errorMessages;
            yield put(
              registrationFailedEvent({
                pageName: RegistrationSteps.submitEmail,
                failureReason: RegistrationFailureReasons.emailInUse,
              })
            );
            yield put(scrollToTop());
            break;
        }

        yield put(
          setBackendError({
            error,
          })
        );
        yield put(setRegistrationLoading({ loading: false }));
      }
      return;
    }
    yield put(
      setBackendError({
        error: startRegistrationResult.errorMessages,
      })
    );
    yield put(scrollToTop());
    yield put(setRegistrationLoading({ loading: false }));
    return;
  }
  try {
    const updateOktaLocaleResponse: Mutation = yield call(graphqlMutation, {
      mutation: UPDATE_OKTA_LOCALE,
      variables: {
        input: {
          username: email,
          locale: userLocale,
          uuid: data.validateAccountInfo?.token ?? '',
        },
      },
    });
  } catch (err) {
    yield put(
      setBackendError({
        error: [i18n.t('Registration:errors.invalidAccountNumber')],
      })
    );
    yield call(client.cancelTransaction);
    yield put(scrollToTop());
    yield put(setRegistrationLoading({ loading: false }));

    yield call(
      sendRaygunError,
      new Error(
        `[registrationSagas] update Okta locale failed: ${
          err instanceof Error ? err.message : err
        }`
      ),
      { accountNumber },
      ['registration']
    );
    return;
  }

  const sendEmailResult: TransactionResults = yield call(
    client.sendVerificationEmail
  );

  if (!sendEmailResult.stepSucceeded) {
    yield put(
      setBackendError({
        error: sendEmailResult.errorMessages,
      })
    );
    yield put(setRegistrationLoading({ loading: false }));
    yield put(scrollToTop());
    return;
  }

  yield put(
    setBackendError({
      error: [],
    })
  );

  yield put(setRegistrationLoading({ loading: false }));

  const registrationContext = buildRegistrationContext({
    accountNumber: accountNumber as string,
  });

  yield put(setRegistrationContext(registrationContext));
  yield put(setRegistrationStep(RegistrationSteps.verifyEmail));
}

// Go back to first card, clear context
export function* goBackToSubmitEmail() {
  yield put(setRegistrationContext(null));
  yield put(setBackendError({ error: [] }));
  yield put(setRegistrationStep(RegistrationSteps.submitEmail));
}

//Submit Step 1.5
export function* resendEmailCode() {
  const client: OktaRegistrationClient = yield call(getOktaClient);

  const result: TransactionResults = yield call(client.resendEmailCode);

  yield put(setBackendError({ error: result.errorMessages }));
  if (!result.stepSucceeded) {
    return;
  }
}

// Submit step 2
export function* submitVerifyEmailCode({
  payload: { verifyEmailCode },
}: SubmitVerifyEmailCodeAction) {
  yield put(setRegistrationLoading({ loading: true }));

  const client: OktaRegistrationClient = yield call(getOktaClient);

  const result: TransactionResults = yield call(
    client.verifyCode,
    verifyEmailCode,
    AuthenticatorTypes.Email
  );

  yield put(setRegistrationLoading({ loading: false }));
  yield put(setBackendError({ error: result.errorMessages }));
  //error
  if (!result.stepSucceeded) {
    return;
  }
  //success
  yield put(setRegistrationStep(RegistrationSteps.submitPassword));
}

// Submit step 3
export function* submitPassword({
  payload: { password, accountNumber, email },
}: RegisterEmailAndPasswordAction) {
  yield put(setRegistrationLoading({ loading: true }));

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const {
    user: {
      auth: {
        tokenInfo: { accessToken },
      },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  try {
    const data: Mutation = yield call(graphqlMutation, {
      mutation: UPDATE_REGISTRATION_DATA,
      variables: {
        input: {
          accountNumber,
          oktaEmail: email,
          locale: userLocale,
          uuid: accessToken,
        },
      },
    });
    if (!data?.updateRegistrationData?.partyId) {
      yield put(
        registrationFailedEvent({
          pageName: RegistrationSteps.submitPassword,
          failureReason: RegistrationFailureReasons.iraNotUpdated,
        })
      );
      yield put(
        setBackendError({
          error: [i18n.t('Registration:errors.iraError')],
        })
      );
      yield put(setRegistrationLoading({ loading: false }));
      yield put(setRegistrationStep(RegistrationSteps.submitEmail));
      yield put(scrollToTop());
      return;
    }
  } catch (err) {
    yield put(
      registrationFailedEvent({
        pageName: RegistrationSteps.submitPassword,
        failureReason: RegistrationFailureReasons.iraNotUpdated,
      })
    );
    yield put(
      setBackendError({
        error: [i18n.t('Registration:errors.iraError')],
      })
    );
    yield put(setRegistrationLoading({ loading: false }));
    yield put(scrollToTop());
    return;
  }

  const client: OktaRegistrationClient = yield call(getOktaClient);

  const result: TransactionResults = yield call(
    client.submitPassword,
    password
  );

  yield put(setBackendError({ error: result.errorMessages }));
  if (!result.stepSucceeded) {
    yield put(setRegistrationLoading({ loading: false }));
    return;
  }

  yield put(setRegistrationLoading({ loading: false }));
  yield put(setRegistrationStep(RegistrationSteps.addPhoneNumber));
}

// Submit step 4
export function* requestVerifyPhoneCode({
  payload: { phoneNumber, type, skip },
}: {
  type: string;
  payload: {
    email: string;
    accountNumber: string;
    phoneNumber?: string;
    type?: PhoneMethodTypes;
    skip: boolean;
  };
}) {
  const phone = `+${phoneNumber}`;

  yield put(setRegistrationLoading({ loading: true }));

  const client: OktaRegistrationClient = yield call(getOktaClient);

  const result: TransactionResults = yield call(
    client.submitPhone,
    skip,
    phone,
    type
  );

  yield put(setBackendError({ error: result.errorMessages }));
  if (!result.stepSucceeded && result.status === IdxStatus.PENDING) {
    yield put(setRegistrationLoading({ loading: false }));
    return;
  }

  if (!skip) {
    yield put(setRegistrationLoading({ loading: false }));
    yield put(setRegistrationStep(RegistrationSteps.verifyPhoneNumber));
    return;
  }

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const token = {
    accessToken: result.token,
    accessTokenExpirationTime: new Date(
      (result.tokenExpTime ?? 0) * 1000
    ).toISOString(),
    type: TokenType.Okta,
    locale: userLocale,
  };

  const stringifiedTokenInfo = JSON.stringify(token);

  yield put(
    setCookie({ key: TOKEN_INFO_COOKIE_NAME, value: stringifiedTokenInfo })
  );

  yield put(setRegistrationStep(RegistrationSteps.registrationSuccess));
}

//Submit step 4.5
export function* resendPhoneCode({
  payload: { phoneNumber, type },
}: {
  type: string;
  payload: { phoneNumber: string; type: PhoneMethodTypes };
}) {
  const phone = `+${phoneNumber}`;

  const client: OktaRegistrationClient = yield call(getOktaClient);

  const result: TransactionResults = yield call(
    client.resendPhoneCode,
    phone,
    type
  );

  yield put(setBackendError({ error: result.errorMessages }));
  if (!result.stepSucceeded) {
    return;
  }
}

// Submit step 5
export function* submitVerifyPhoneCode({
  payload: { verifyPhoneCode, phoneNumber, accountNumber, email },
}: {
  type: string;
  payload: {
    phoneNumber: string;
    verifyPhoneCode: string;
    email: string;
    accountNumber: string;
  };
}) {
  const phone = `+${phoneNumber}`;
  yield put(setRegistrationLoading({ loading: true }));
  const client: OktaRegistrationClient = yield call(getOktaClient);

  const result: TransactionResults = yield call(
    client.verifyCode,
    verifyPhoneCode,
    AuthenticatorTypes.Phone
  );

  yield put(setBackendError({ error: result.errorMessages }));
  if (!result.stepSucceeded && result.status === IdxStatus.PENDING) {
    yield put(setRegistrationLoading({ loading: false }));
    return;
  }

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const {
    user: {
      auth: {
        tokenInfo: { accessToken },
      },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  try {
    const data: Mutation = yield call(graphqlMutation, {
      mutation: UPDATE_PHONE_NUMBER,
      variables: {
        input: {
          accountNumber,
          username: email,
          locale: userLocale,
          phoneNumber: phone,
          uuid: accessToken,
        },
      },
    });
    if (data?.updatePhoneNumber?.phoneNumber !== phone) {
      yield call(
        sendRaygunError,
        new Error('[registrationSagas] writing phone number to IRA failed'),
        { accountNumber },
        ['registration']
      );
    }
  } catch (err) {
    yield call(
      sendRaygunError,
      new Error(
        `[registrationSagas] writing phone number to IRA failed: ${
          err instanceof Error ? err.message : err
        }`
      ),
      { accountNumber },
      ['registration']
    );
  }

  const token = {
    accessToken: result.token,
    accessTokenExpirationTime: new Date(
      (result.tokenExpTime ?? 0) * 1000
    ).toISOString(),
    type: TokenType.Okta,
    locale: userLocale,
  };

  const stringifiedTokenInfo = JSON.stringify(token);

  yield put(
    setCookie({ key: TOKEN_INFO_COOKIE_NAME, value: stringifiedTokenInfo })
  );

  yield put(setRegistrationStep(RegistrationSteps.registrationSuccess));
}

export function* watchRegistration() {
  yield all([
    takeLatest(goBackToSubmitEmailAction.type, goBackToSubmitEmail),
    takeLatest(requestVerifyEmailCodeAction.type, requestVerifyEmailCode),
    takeLatest(submitVerifyEmailCodeAction.type, submitVerifyEmailCode),
    takeLatest(requestVerifyPhoneCodeAction.type, requestVerifyPhoneCode),
    takeLatest(submitVerifyPhoneCodeAction.type, submitVerifyPhoneCode),
    takeLatest(registerEmailAndPasswordAction.type, submitPassword),
    takeLatest(resendEmailCodeAction.type, resendEmailCode),
    takeLatest(resendPhoneCodeAction.type, resendPhoneCode),
    takeLatest(fetchRegCredsAction.type, fetchRegCreds),
    takeLatest(regLogoutAction.type, regLogout),
  ]);
}
