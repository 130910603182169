import { Invoice, Locale, SettlementStatus } from '@mfe/shared/schema-types';

export const getUnpaidInvoice = (locale: Locale, invoices: Invoice[]) => {
  const unpaidInvoice = invoices?.find(
    (invoice) => invoice?.invoiceSettlementStatus !== SettlementStatus.Paid
  );
  if (unpaidInvoice && locale === Locale.PtBr) {
    return invoices?.[0];
  }
  return unpaidInvoice;
};
