import { useSelector } from 'react-redux';
import { RefObject, createContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, ScheduleInput } from '@mfe/shared/schema-types';

import { getDateFnsLocale } from '../utils/date-fns-helper/date';

export interface SelectedDate {
  day: number | null;
  month: number | null;
  year: number | null;
  timezone: string;
  arrivalWindow: string;
  note: string;
}

const INITIAL_SELECTED_DATE: SelectedDate = {
  day: null,
  month: null,
  year: null,
  timezone: '',
  arrivalWindow: '',
  note: '',
};

export const useScheduleInstallation = () => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'contactInformation',
  });

  const {
    userInfo: {
      accountType,
      fullName,
      phone: { primary, secondary },
      accountHolder: {
        fullName: fullNameSMB,
        phone: { primary: primarySMB, secondary: secondarySMB },
      },
    },
  } = useSelector(selectUserInfo);
  const name = accountType === AccountType.Smb ? fullNameSMB : fullName;
  const phoneNumber =
    accountType === AccountType.Smb
      ? primarySMB?.phoneNumber || secondarySMB?.phoneNumber
      : primary?.phoneNumber || secondary?.phoneNumber;

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const textFieldRef = useRef<HTMLInputElement>(null);
  const phoneFieldRef = useRef<HTMLInputElement>(null);

  const [textInput, setTextInput] = useState(name);

  const [phoneNumberInput, setPhoneNumberInput] = useState(phoneNumber ?? '');

  const [textAreaInput, setTextAreaInput] = useState('');

  const [selectedDate, setSelectedDate] = useState<SelectedDate>(
    INITIAL_SELECTED_DATE
  );

  const [selectedInterval, setSelectedInterval] =
    useState<ScheduleInput | null>(null);

  const [showAlert, setShowAlert] = useState(false);

  const calendarLocale = getDateFnsLocale(userLocale);

  const nameMaxLength = 25;
  const phoneNumberMaxLength = 15;

  const textAreaMaxLength = 500 - (nameMaxLength + phoneNumberMaxLength);

  const handleDateSelection = (day: Date | null) => {
    if (day) {
      setSelectedDate((prevSelectedDate) => ({
        ...prevSelectedDate,
        day: day.getDate(),
        month: day.getMonth() + 1,
        year: day.getFullYear(),
      }));
    }
    setSelectedInterval(null);
  };

  const isPhoneNumberValid = (
    value: string,
    element: any,
    resetError: boolean
  ) => {
    if (resetError) {
      return { error: false };
    }

    if (!element.value) {
      scrollToElement(phoneFieldRef);
      return { error: true, message: t('noPhoneNumberProvidedError') };
    }

    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const number = phoneUtil.parseAndKeepRawInput(value);
      const isValid = phoneUtil.isValidNumber(number);

      if (!isValid) {
        scrollToElement(phoneFieldRef);
      }

      return { error: !isValid, message: t('invalidPhoneNumberError') };
    } catch (error) {
      scrollToElement(phoneFieldRef);
      return { error: true, message: t('invalidPhoneNumberError') };
    }
  };

  const isNameValid = (value: string, element: any, resetError: boolean) => {
    if (resetError) {
      return { error: false };
    }

    if (!value) {
      scrollToElement(textFieldRef);
      return {
        error: true,
        message: t('noNameProvidedError'),
      };
    }
    return { error: false, message: '' };
  };

  const scrollToElement = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return {
    textInput,
    setTextInput,
    textAreaInput,
    setTextAreaInput,
    textAreaMaxLength,
    calendarLocale,
    selectedDate,
    selectedInterval,
    setSelectedInterval,
    handleDateSelection,
    showAlert,
    setShowAlert,
    phoneNumberInput,
    setPhoneNumberInput,
    isPhoneNumberValid,
    isNameValid,
    textFieldRef,
    phoneFieldRef,
  };
};

export const ScheduleInstallationContext = createContext({});
