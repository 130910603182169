import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NoteInput, Schedule, ScheduleInput } from '@mfe/shared/schema-types';

interface ScheduleInstallationState {
  installDate: string;
  loading: boolean;
  submitLoading: boolean;
  submitSuccess: boolean;
  error: null | boolean | string;
  externalWorkOrderId: string;
  fulfillmentAgreementId: string;
  availableDates: Record<string, ScheduleInput[]>;
}

export const initialScheduleInstallationState = {
  installDate: null,
  loading: false,
  submitLoading: false,
  submitSuccess: false,
  error: null,
  externalWorkOrderId: null,
  fulfillmentAgreementId: null,
  availableDates: null,
};

type InstallationOrderInput = {
  externalWorkOrderId: string;
  note?: NoteInput;
  schedule: Schedule;
};

export type SubmitInstallationActionType =
  PayloadAction<InstallationOrderInput>;

export const scheduleInstallationSlice = createSlice({
  name: 'scheduleInstallation',
  initialState: initialScheduleInstallationState,
  reducers: {
    // for change plan flow
    getAvailableDates: (
      state,
      action: { payload: { cartId: string; refetch: boolean } }
    ) => {
      state.loading = true;
      state.error = null;
    },
    // for standalone flow
    getInstallationWorkOrder: (state) => {
      state.loading = true;
      state.error = null;
    },
    setFulfillmentAgreementId: (state, { payload }) => {
      state.error = null;
      state.fulfillmentAgreementId = payload;
    },
    setExternalWorkOrderId: (state, { payload }) => {
      state.externalWorkOrderId = payload;
    },
    setAvailableDates: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.availableDates = payload;
    },
    refetchScheduleInstallation: (state) => {
      state.loading = true;
      state.error = null;
      state.submitSuccess = false;
    },
    submitInstallation: (state, action: SubmitInstallationActionType) => {
      state.submitLoading = true;
    },
    setScheduleSuccess: (state) => {
      state.submitLoading = false;
      state.submitSuccess = true;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
      state.submitLoading = false;
    },
    resetScheduleInstallation: () => initialScheduleInstallationState,
  },
});

export const {
  getAvailableDates, // for change plan only
  getInstallationWorkOrder,
  setFulfillmentAgreementId,
  setExternalWorkOrderId,
  setAvailableDates,
  refetchScheduleInstallation,
  submitInstallation,
  setScheduleSuccess,
  setError: setScheduleInstallationError,
  resetScheduleInstallation,
} = scheduleInstallationSlice.actions;

export const selectScheduleInstallation = (state: {
  scheduleInstallation: ScheduleInstallationState;
}) => state.scheduleInstallation;
