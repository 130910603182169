/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, call, spawn } from 'redux-saga/effects';
import type { WebToMobileAppEvents } from '../types';

export const sendMessageToMobileApp = createAction<WebToMobileAppEvents>(
  'sendMessageToMobileApp'
);

const sendMessageToMobileAppActionHandler = (message: WebToMobileAppEvents) => {
  // @ts-ignore
  window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

function* sendMessageToMobileAppSaga({
  payload,
}: {
  type: (typeof sendMessageToMobileApp)['type'];
  payload: WebToMobileAppEvents;
}) {
  yield call(sendMessageToMobileAppActionHandler, payload);
}

export function* watchCrossPlatformCommunication() {
  yield spawn(function* () {
    yield takeLatest(sendMessageToMobileApp.type, sendMessageToMobileAppSaga);
  });
}
