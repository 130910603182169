import { gql } from '@apollo/client';
import { takeLatest, call, put, all, select, spawn } from 'redux-saga/effects';
import { Mutation, Query } from '@mfe/shared/schema-types';
import { selectUser } from '../auth';
import i18n from '@mfe/services/translations-service';

import { graphqlQuery, graphqlMutation } from '@mfe/shared/redux/graphql';
import { waitForToken } from '../utils/utilsSagas';
import {
  getBuyMoreOffers as getBuyMoreOffersAction,
  purchaseBuyMore as purchaseBuyMoreAction,
  setBuyMoreOffers,
  setPurchaseError,
  setOffersError,
  setPurchaseSuccessful,
} from './buyMoreSlice';
import { selectLocale } from '../locale';
import { removeAlert } from '../alerts';

export const PURCHASE_BUYMORE = gql`
  mutation purchaseBuyMore($input: PurchaseBuyMoreInput!) {
    purchaseBuyMore(input: $input) {
      success
    }
  }
`;

export const GET_BUY_MORE = gql`
  query getBuyMore($productInstanceId: String!, $languageCode: String!) {
    getBuyMore(
      input: {
        productInstanceId: $productInstanceId
        languageCode: $languageCode
      }
    ) {
      id
      amount
      description
      price
      disclosure
      savingsText
    }
  }
`;

export function* getBuyMoreOffers() {
  yield call(waitForToken);
  const {
    user: { productInstanceId },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const {
    locale: { displayLanguage },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_BUY_MORE,
      variables: { productInstanceId, languageCode: displayLanguage },
      fetchPolicy: 'no-cache',
    });

    if (!data?.getBuyMore && !data?.getBuyMore?.length) {
      yield put(setOffersError('Error fetching offers'));
      return;
    }

    yield put(setBuyMoreOffers(data?.getBuyMore as any));
  } catch (e) {
    yield put(setOffersError('Error fetching offers'));
  }
}

export function* purchaseBuyMore({
  payload: { productTypeId },
}: {
  type: string;
  payload: { productTypeId: string };
}) {
  try {
    const data: Mutation = yield call(graphqlMutation, {
      mutation: PURCHASE_BUYMORE,
      variables: {
        input: { productTypeId },
      },
    });

    if (!data?.purchaseBuyMore?.success) {
      yield put(setPurchaseError({ error: null, success: false }));
      return;
    }

    yield put(setPurchaseSuccessful());
    yield put(removeAlert(i18n.t('Alerts:AccountStatus.dataAllUsed.title')));
  } catch (e) {
    yield put(
      setPurchaseError({ error: 'Error purchasing buy more', success: null })
    );
  }
}

export function* watchBuyMore() {
  yield spawn(function* () {
    yield all([takeLatest(getBuyMoreOffersAction.type, getBuyMoreOffers)]);
    yield all([takeLatest(purchaseBuyMoreAction.type, purchaseBuyMore)]);
  });
}
