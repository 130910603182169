import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PhoneMethodTypes } from './OktaRegistrationClient';
export interface RegistrationFormState {
  accountNumber: string;
  accountNumberError: string;
  postalCode: string;
  postalCodeError: string;
  email: string;
  emailError: string;
  verifyEmailCode: string;
  verifyEmailCodeError: string;
  password: string;
  passwordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
  phoneNumber: string;
  phoneNumberError: string;
  verifyPhoneCode: string;
  verifyPhoneCodeError: string;
  phonePrefix: string;
  countryCode: string;
}

export enum RegistrationSteps {
  submitEmail = 'Sign Up',
  verifyEmail = 'Verify Email',
  submitPassword = 'Create Password',
  addPhoneNumber = 'Set Up Mobile',
  verifyPhoneNumber = 'Verify Phone',
  registrationSuccess = 'Success',
}

export enum RegistrationFailureReasons {
  emailInUse = 'email_already_in_use',
  invalidAccountNumber = 'invalid_account_number_input',
  invalidAccountInfo = 'account_info_does_not_match_records',
  iraNotUpdated = 'ira_not_updated',
}

export enum RegistrationButtons {
  Next = 'Next',
  Skip = 'Skip and Continue',
  SMS = 'SMS',
  Phone = 'Call Now',
  Resend = 'Resend',
  UseDifferentEmail = 'use_different_email',
  VerifyAnotherWay = 'verify_phone_another_way',
}

const FIRST_REGISTRATION_STEP = RegistrationSteps.submitEmail;

export enum RegistrationLocale {
  US = 'US',
  BR = 'BR',
}

export interface RegistrationContextData {
  account_number: string;
  country: RegistrationLocale;
}

export interface RegistrationContext {
  schema: string;
  data: RegistrationContextData;
}

export interface RegistrationState {
  loading: boolean;
  analyticsInitialized: boolean;
  registrationContext: RegistrationContext | null;
  currentStep: RegistrationSteps;
  error: string[];
}

export type SubmitVerifyEmailCodeAction = PayloadAction<
  Pick<RegistrationFormState, 'verifyEmailCode'>
>;
export type RegisterEmailAndPasswordAction = PayloadAction<
  Pick<RegistrationFormState, 'email' | 'password' | 'accountNumber'>
>;

export const initialRegistrationState: RegistrationState = {
  loading: false,
  analyticsInitialized: false,
  registrationContext: null,
  currentStep: FIRST_REGISTRATION_STEP,
  error: [],
};

export const registrationSlice = createSlice({
  name: 'registrationState',
  initialState: initialRegistrationState,
  reducers: {
    fetchRegCreds: (state) => state,
    requestVerifyEmailCode: (
      state,
      _: { payload: Partial<RegistrationFormState> }
    ) => state,
    resendEmailCode: (state) => state,
    submitVerifyEmailCode: (state, action: SubmitVerifyEmailCodeAction) =>
      state,
    requestVerifyPhoneCode: (
      state,
      _: {
        payload: {
          phoneNumber?: string;
          type?: PhoneMethodTypes;
          email?: string;
          accountNumber?: string;
          skip: boolean;
        };
      }
    ) => state,
    resendPhoneCode: (
      state,
      _: {
        payload: {
          phoneNumber: string;
          type: PhoneMethodTypes;
        };
      }
    ) => state,
    registerEmailAndPassword: (state, action: RegisterEmailAndPasswordAction) =>
      state,
    initializeRegistrationAnalytics: (state) => {
      state.analyticsInitialized = true;
    },
    setAnalyticsInitialized: (state, { payload }: { payload: boolean }) => {
      state.analyticsInitialized = payload;
    },
    setRegistrationContext: (
      state,
      { payload }: { payload: RegistrationContext | null }
    ) => {
      if (!payload) state.registrationContext = null;
      else state.registrationContext = payload;
    },
    goBackToSubmitEmail: (state) => state,
    pageDisplayedEvent: (
      state,
      _: {
        payload: { pageName: RegistrationSteps };
      }
    ) => state,
    buttonClickedEvent: (
      state,
      _: {
        payload: { pageName: string; buttonName: string };
      }
    ) => state,
    registrationFailedEvent: (
      state,
      _: {
        payload: { pageName: string; failureReason: string };
      }
    ) => state,
    registrationSuccessfulEvent: (state) => state,
    submitVerifyPhoneCode: (
      state,
      _: {
        payload: {
          phoneNumber: string;
          verifyPhoneCode: string;
          email: string;
          accountNumber: string;
        };
      }
    ) => state,
    registerPhoneNumber: (
      state,
      _: {
        payload: {
          phonePrefix: string;
          phoneNumber: string;
          verifyPhoneCode: string;
        };
      }
    ) => state,
    setLoading: (state, { payload }: { payload: { loading: boolean } }) => {
      state.loading = payload.loading ?? false;
    },
    setRegistrationStep: (
      state,
      { payload }: { payload: RegistrationSteps }
    ) => {
      state.currentStep = payload;
    },
    setBackendError: (
      state,
      { payload }: { payload: { error: string[] | undefined } }
    ) => {
      state.error = payload?.error ?? [];
    },
    regLogout: (state) => state,
  },
});

export const {
  setLoading: setRegistrationLoading,
  registerEmailAndPassword,
  requestVerifyEmailCode,
  submitVerifyEmailCode,
  requestVerifyPhoneCode,
  submitVerifyPhoneCode,
  registerPhoneNumber,
  setRegistrationStep,
  resendEmailCode,
  resendPhoneCode,
  fetchRegCreds,
  setRegistrationContext,
  goBackToSubmitEmail,
  initializeRegistrationAnalytics,
  pageDisplayedEvent,
  buttonClickedEvent,
  registrationFailedEvent,
  registrationSuccessfulEvent,
  setAnalyticsInitialized,
  setBackendError,
  regLogout,
} = registrationSlice.actions;

export const selectRegistration = (state: {
  registrationState: RegistrationState;
}) => state.registrationState;
