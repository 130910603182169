import React from 'react';
import { useDispatch } from 'react-redux';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import styles from '../AppButtons.module.scss';
import GooglePlayStoreSVG from './GooglePlayStoreSVG';

export const GooglePlayStoreButton = ({
  url,
}: {
  url: string;
}): JSX.Element => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(openInNewTab(url));
  };

  return (
    <button
      onClick={onClick}
      data-cy="google-play-store-button"
      className={styles['appStoreButton']}
    >
      <GooglePlayStoreSVG />
    </button>
  );
};

export default GooglePlayStoreButton;
