import { TypePolicies } from '@apollo/client';
import { isBefore, isSameDay } from 'date-fns';

import { BillingAccount, BillingStatus } from '@mfe/shared/schema-types';
import { getDate } from '@mfe/shared/util';

export const typePolicies: TypePolicies = {
  BillingAccount: {
    fields: {
      billingStatus: (billingStatus: BillingStatus, { readField }) => {
        if ([BillingStatus.New, BillingStatus.Current].includes(billingStatus))
          return billingStatus;

        const dueDate = readField('dueDate') as BillingAccount['dueDate'];
        const balance = readField('balance') as BillingAccount['balance'];
        const invoiceAmount = readField(
          'invoiceAmount'
        ) as BillingAccount['invoiceAmount'];
        if (!dueDate || !invoiceAmount?.value || !balance?.value)
          return billingStatus;

        const today = new Date();
        const date = getDate(dueDate);
        const isReady = isBefore(today, date) || isSameDay(today, date);
        const hasPreviousBalance = balance.value > invoiceAmount.value;
        if (hasPreviousBalance || !isReady) return BillingStatus.Past;

        return BillingStatus.Ready;
      },
    },
  },
};
