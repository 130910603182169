import { format } from 'date-fns';

export const formatTimeInterval = (dates: [string, string]) => {
  const [from, to] = dates;
  if (!from || !to) return;

  const start = new Date(from);
  const end = new Date(to);

  const formattedStart = format(start, 'h aaa');
  const formattedEnd = format(end, 'h aaa');

  return `${formattedStart} - ${formattedEnd}`;
};
