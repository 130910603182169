import { Query, TokenType, Locale, Platform } from '@mfe/shared/schema-types';
import { gql } from '@apollo/client';
import { put, select, call } from 'redux-saga/effects';
import { getCookie, logout, setCookie } from '../../events';
import {
  setAuth,
  startRefreshCountdown,
  loginFromCookie as loginFromCookieAction,
  TokenInfo,
  setAuthMFELoaded,
} from '..';
import {
  getTokenFromUrl,
  TOKEN_INFO_COOKIE_NAME,
  parseTokenInfoCookie,
  LoginType,
  getAuthUrls,
  retrieveAuthCode,
} from '../utils';
import { tryToRefresh } from './refreshTokenCountdown';
import {
  Domains,
  AuthEventTypes,
  EventTypes,
} from '@mfe/services/window-messages';
import { graphqlQuery } from '@mfe/shared/redux/graphql';
import { postMessage } from '../../utils';
import {
  getLocaleDefaultLanguage,
  requestLanguageCookie,
  setLanguageCookie,
  setLocaleState,
} from '../../locale';
import { selectUser } from '../authUserSlice';
import getAuthParams from './getAuthParams';
import { DisplayLanguage } from '@mfe/services/translations-service';
import { computeDisplayLanguage, getDisplayLanguageCookie } from './utils';
import i18n from '@mfe/services/translations-service';
import { SendAuthParamsAction } from '../authUserSlice';
import { isPWAMyViasat } from '@mfe/shared/util';
import { AnalyticsAction, Categories, structuredEvent } from '../../analytics';
import {
  fetchConfig,
  selectConfig,
  setIssuer,
  Issuer,
  setConfig,
} from '@mfe/shared/redux/config';
export function* sendAuthParams(payload: {
  type: string;
  payload: SendAuthParamsAction['payload'];
}) {
  const { env, platform, mfeEnv, locale } = payload.payload;
  const isPWA = isPWAMyViasat();
  const authMethod = platform === Platform.Web || isPWA ? 'web' : 'mobile';

  const { baseUrl, client } = getAuthParams(env, authMethod, locale);

  yield put(
    setConfig({
      env,
      mfeEnv,
      isPWA,
      platform,
      auth: {
        authMethod,
        client,
        baseUrl,
      },
    })
  );

  yield put(setAuthMFELoaded({ isAuthMFELoaded: true }));
  const { logoutUrl, loginUrl } = getAuthUrls(client, baseUrl);

  yield put(
    postMessage({
      domain: Domains.General,
      eventType: AuthEventTypes.SendAuthParams,
      data: { loginUrl, logoutUrl, client },
    })
  );
}

/**
 * MFE's will call this to log in given a token
 */
export function* loginFromUrl() {
  const { token, productInstanceId, partyId, env } = getTokenFromUrl();
  if (token === 'undefined' || !token) {
    console.info('no token found in MFE url');
    yield put(
      postMessage({
        domain: Domains.General,
        eventType: EventTypes.GetToken,
      })
    );
    return;
  }

  yield put(setConfig({ mfeEnv: env }));
  yield call(loginFromAccessToken, {
    accessToken: token,
    productInstanceId,
    partyId,
  });
}

export const VERIFY_TOKEN = gql`
  query verifyToken(
    $token: String!
    $productInstanceId: String
    $partyId: String
  ) {
    verifyToken(
      token: $token
      productInstanceId: $productInstanceId
      partyId: $partyId
    ) {
      expiration
      uid
      subject
      type
      locale
      displayLanguage
      accountNumber
    }
  }
`;

export function* loginFromAccessToken(payload: {
  accessToken: string;
  productInstanceId: string | null;
  partyId: string | null;
}) {
  const { accessToken: token, productInstanceId, partyId } = payload;
  let verifyTokenResponse = null;
  let data: Query;
  try {
    data = yield call(graphqlQuery, {
      query: VERIFY_TOKEN,
      variables: { token, productInstanceId, partyId },
    });

    verifyTokenResponse = data?.verifyToken;
    yield put(
      postMessage({
        domain: Domains.General,
        eventType: EventTypes.TokenValid,
      })
    );
  } catch (err) {
    yield put(
      postMessage({
        domain: Domains.General,
        eventType: EventTypes.InvalidToken,
      })
    );
    return;
  }

  const tokenInfo: TokenInfo = {
    accessToken: token,
    accessTokenExpirationTime: new Date(
      verifyTokenResponse.expiration * 1000
    ).toISOString(),
    type: verifyTokenResponse.type as TokenType,
    locale: verifyTokenResponse?.locale as Locale,
  };

  yield put(
    setIssuer(
      verifyTokenResponse.type === TokenType.Salesforce
        ? Issuer.Salesforce
        : Issuer.MV
    )
  );

  const localeDefaultLanguage = getLocaleDefaultLanguage(
    data.verifyToken.displayLanguage ?? data.verifyToken.locale ?? undefined
  );

  yield call(i18n.changeLanguage, localeDefaultLanguage);

  yield put(
    setLocaleState({
      userLocale: data.verifyToken.locale ?? undefined,
      displayLanguage: localeDefaultLanguage,
    })
  );

  yield put(requestLanguageCookie());

  const {
    user: {
      auth: { tokenInfo: currTokenInfo },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  yield put(
    setAuth({
      tokenInfo: { ...currTokenInfo, ...tokenInfo },
      username: verifyTokenResponse.uid,
      isAuthenticated: Boolean(verifyTokenResponse.uid),
      subject: verifyTokenResponse.subject ?? undefined,
    })
  );

  yield put(fetchConfig());
}

export function* loginFromCookie(payload: {
  type: typeof loginFromCookieAction.type;
  payload: { stringifiedTokenInfo: string };
}) {
  const stringTokenInfo = payload.payload.stringifiedTokenInfo;
  const tokenInfo = parseTokenInfoCookie(stringTokenInfo);

  if (!tokenInfo) {
    console.info('tokenInfo cookie not found or deformed');
    yield put(logout());
    return;
  }

  yield call(loginFromToken, {
    type: 'loginFromToken',
    payload: { tokenInfo, loginType: 'cookie' },
  });

  const isReloaded = window
    ? window?.performance?.getEntriesByType?.('navigation')?.length &&
      (
        window?.performance?.getEntriesByType?.(
          'navigation'
        )[0] as PerformanceNavigationTiming
      )?.type === 'reload'
    : true;

  if (!isReloaded)
    yield put(
      structuredEvent({
        category: Categories.Login,
        action: AnalyticsAction.SUCCESS,
        params: {
          property: 'token',
        },
      })
    );
}

export function* loginFromToken(payload: {
  type: string;
  payload: { tokenInfo: TokenInfo; loginType?: LoginType };
}) {
  const { type, locale } = payload.payload.tokenInfo;
  let { accessToken, accessTokenExpirationTime, idToken, refreshToken } =
    payload.payload.tokenInfo;
  const loginType = payload.payload.loginType;
  const { platform }: ReturnType<typeof selectConfig> = yield select(
    selectConfig
  );

  const isAccessTokenExpired = new Date() > new Date(accessTokenExpirationTime);

  let tryRefreshToken = false;
  if (!isAccessTokenExpired) {
    try {
      yield call(verifyTokenInfo, {
        type: 'verifyTokenInfo',
        payload: {
          tokenInfo: {
            accessToken,
            refreshToken,
            accessTokenExpirationTime,
            idToken,
            type,
            locale,
          },
          loginType,
        },
      });
    } catch (err) {
      tryRefreshToken = true;
    }
  }

  if (isAccessTokenExpired || tryRefreshToken) {
    try {
      if (!refreshToken) {
        console.info('No refresh token');
        yield put(logout());
        return;
      }

      const {
        accessToken: newaccessToken,
        accessTokenExpirationTime: newExpireTime,
        idToken: newIdToken,
        refreshToken: newRefreshToken,
      } = yield call(tryToRefresh, { refreshToken, platform, locale });
      accessToken = newaccessToken;
      accessTokenExpirationTime = newExpireTime;
      idToken = newIdToken ?? idToken;
      refreshToken = newRefreshToken ?? refreshToken;
    } catch (err) {
      console.info(`failed to refresh token with error: ${err}`);
      yield put(logout());
      return;
    }

    try {
      yield call(verifyTokenInfo, {
        type: 'verifyTokenInfo',
        payload: {
          tokenInfo: {
            accessToken,
            refreshToken,
            accessTokenExpirationTime,
            idToken,
            type,
            locale,
          },
          loginType,
        },
      });
    } catch (err) {
      console.info(`failed to verifyToken with err: ${err}`);
      yield put(logout());
      return;
    }
  }
}

// If this doesn't error, logging in was successful
export function* verifyTokenInfo(payload: {
  type: string;
  payload: { tokenInfo: TokenInfo; loginType?: string };
}) {
  const {
    payload: {
      tokenInfo: {
        idToken,
        accessToken,
        refreshToken,
        locale: payloadLocale,
        accessTokenExpirationTime,
      },
      loginType,
    },
  } = payload;

  const { verifyToken: verifyTokenResponse }: Query = yield call(graphqlQuery, {
    query: VERIFY_TOKEN,
    variables: { token: accessToken },
  });

  yield put(
    setIssuer(
      verifyTokenResponse.type === TokenType.Salesforce
        ? Issuer.Salesforce
        : Issuer.MV
    )
  );

  const token = {
    accessToken,
    accessTokenExpirationTime,
    refreshToken,
    idToken,
    type: verifyTokenResponse.type ?? TokenType.Okta,
    locale: (payloadLocale ?? verifyTokenResponse.locale) as Locale,
  };

  yield put(
    setAuth({
      tokenInfo: token,
      username: verifyTokenResponse.uid,
      isAuthenticated: Boolean(verifyTokenResponse.uid),
      subject: verifyTokenResponse.subject ?? undefined,
    })
  );

  yield put(requestLanguageCookie());

  yield put(fetchConfig());
  const {
    auth: { client, baseUrl },
  }: ReturnType<typeof selectConfig> = yield select(selectConfig);

  const { logoutUrl } = getAuthUrls(client, baseUrl, idToken);

  yield put(
    postMessage({
      domain: Domains.General,
      eventType: AuthEventTypes.LoginEvent,
      data: {
        loginType: loginType ?? '',
        logoutUrl,
        username: verifyTokenResponse.uid,
      },
    })
  );

  const stringifiedTokenInfo = JSON.stringify(token);

  yield put(
    setCookie({ key: TOKEN_INFO_COOKIE_NAME, value: stringifiedTokenInfo })
  );

  yield put(
    setConfig({
      auth: {
        client,
        baseUrl,
      },
    } as any)
  );

  //on success start the refreshToken timer countdown
  yield put(startRefreshCountdown({ stringifiedTokenInfo }));

  const displayLanguageCookieValue: DisplayLanguage | undefined = yield call(
    getDisplayLanguageCookie
  );

  const displayLanguage: DisplayLanguage = yield call(
    computeDisplayLanguage,
    displayLanguageCookieValue,
    verifyTokenResponse.displayLanguage ?? token?.locale
  );

  yield put(
    setLocaleState({
      userLocale: token?.locale,
      displayLanguage,
    })
  );

  if (!displayLanguageCookieValue) {
    yield put(setLanguageCookie({ displayLanguage }));
  }
}

export const GET_TOKEN_USING_CODE = gql`
  query getTokenUsingCode($input: GetTokenUsingCodeInput!) {
    getTokenUsingCode(input: $input) {
      accessToken
      accessTokenExpirationTime
      refreshToken
      idToken
    }
  }
`;

export function* loginFromCode(payload: {
  type: string;
  payload: { code: string; tokenType: TokenType; locale: Locale };
}) {
  const { code, tokenType, locale } = payload.payload;
  const { env }: ReturnType<typeof selectConfig> = yield select(selectConfig);

  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_TOKEN_USING_CODE,
      variables: { input: { code, platform: Platform.Web, env, locale } },
    });
    const { accessToken, accessTokenExpirationTime, refreshToken, idToken } =
      data.getTokenUsingCode;
    const tokenInfo: TokenInfo = {
      accessToken,
      accessTokenExpirationTime,
      refreshToken: refreshToken ?? undefined,
      idToken: idToken ?? undefined,
      type: tokenType ?? undefined,
    };
    yield call(loginFromToken, {
      type: 'loginFromToken',
      payload: { tokenInfo, loginType: 'password' },
    });

    yield put(
      structuredEvent({
        category: Categories.Login,
        action: AnalyticsAction.SUCCESS,
        params: {
          property: 'password',
        },
      })
    );
  } catch (err) {
    console.info(
      `unable to get token using code with code: ${code} and err: ${err}`
    );
    yield put(logout());
    return;
  }
}

//this function is only for legacy MV to still be able to log in
export function* authUser() {
  const {
    auth: { client, myViasatUrl },
  }: ReturnType<typeof selectConfig> = yield select(selectConfig);

  const code = retrieveAuthCode(myViasatUrl, client);
  if (!code) {
    //ask for a tokenInfo cookie
    yield put(
      getCookie({
        key: TOKEN_INFO_COOKIE_NAME,
      })
    );
    return;
  }

  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_TOKEN_USING_CODE,
      variables: { input: { code, platform: Platform.Web } },
    });
    const { accessToken, accessTokenExpirationTime, refreshToken } =
      data.getTokenUsingCode;
    const tokenInfo: TokenInfo = {
      accessToken,
      accessTokenExpirationTime,
      refreshToken: refreshToken ?? undefined,
    };
    yield call(loginFromToken, {
      type: 'loginFromToken',
      payload: { tokenInfo, loginType: 'password' },
    });
  } catch (err) {
    console.info(
      `unable to get token using code with code: ${code} and err: ${err}`
    );
    yield put(logout());
    return;
  }
}
