import { createSlice } from '@reduxjs/toolkit';

export interface BuyMoreOffer {
  amount: number;
  description: string;
  disclosure: string;
  id: string;
  price: number;
  savingsText: string;
}

export interface BuyMoreState {
  offers: BuyMoreOffer[];
  offersError: string | null;
  transactionError: string | null;
  loading: boolean;
  transactionLoading: boolean;
  purchaseSuccessful: boolean | null;
  isCheckout: boolean | null;
  selectedProduct: BuyMoreOffer | null;
}

export const initialBuyMore: BuyMoreState = {
  offers: [],
  offersError: null,
  transactionError: null,
  purchaseSuccessful: null,
  loading: false,
  transactionLoading: false,
  isCheckout: null,
  selectedProduct: null,
};

export const buyMoreSlice = createSlice({
  name: 'buyMore',
  initialState: initialBuyMore,
  reducers: {
    getBuyMoreOffers: (state) => {
      state.loading = true;
      state.offersError = null;
      state.purchaseSuccessful = null;
      return state;
    },
    setBuyMoreOffers: (state, { payload }: { payload: BuyMoreOffer[] }) => {
      state.offers = payload;
      state.loading = false;
      return state;
    },
    setOffersError: (state, { payload }: { payload: string | null }) => {
      state.offersError = payload;
      state.loading = false;
      return state;
    },
    purchaseBuyMore: (state, _: { payload: { productTypeId: string } }) => {
      state.purchaseSuccessful = null;
      state.transactionError = null;
      state.transactionLoading = true;
      return state;
    },
    setPurchaseSuccessful: (state) => {
      state.transactionError = null;
      state.transactionLoading = false;
      state.purchaseSuccessful = true;
      return state;
    },
    setPurchaseError: (
      state,
      {
        payload: { success, error },
      }: { payload: { success: boolean | null; error: string | null } }
    ) => {
      state.purchaseSuccessful = success;
      state.transactionError = error;
      state.transactionLoading = false;
      return state;
    },
    setSelectedProduct: (
      state,
      { payload }: { payload: BuyMoreOffer | null }
    ) => {
      state.selectedProduct = payload;
      return state;
    },
    setIsCheckout: (state, { payload }: { payload: boolean | null }) => {
      state.isCheckout = payload;
      return state;
    },
    reset: (state) => initialBuyMore,
  },
});

export const {
  getBuyMoreOffers,
  setBuyMoreOffers,
  setOffersError,
  purchaseBuyMore,
  setPurchaseError,
  setPurchaseSuccessful,
  setSelectedProduct,
  setIsCheckout,
  reset,
} = buyMoreSlice.actions;

export const selectBuyMore = (state: { buyMore: BuyMoreState }) =>
  state.buyMore;
