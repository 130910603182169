import { InlineLink, Txt } from '@vst/beam';
import styles from './ErrorBoundary.module.scss';
import { useDispatch } from 'react-redux';
import { postUsageMessage } from '@mfe/to-be-migrated/redux/utils';
import { EventTypes } from '@mfe/services/window-messages';
import { useTranslation } from 'react-i18next';

export const SomethingWentWrong = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('UnexpectedError');
  const telLink = `tel:${t('customerCareNumber')}`;
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event) event.preventDefault();
    dispatch(
      postUsageMessage({
        eventType: EventTypes.OpenInNewTab,
        data: {
          url: telLink,
        },
      })
    );
  };

  return (
    <div className={styles['somethingWentWrong']} data-cy="somethingWentWrong">
      <Txt variant="heading1" component="h1">
        {t('title')}
      </Txt>
      <Txt variant="paragraphRegular" component="p">
        {t('description')}
      </Txt>
      <Txt variant="paragraphRegular" component="p">
        {t('callUs')}
        <InlineLink href="" onClick={onClick} variant="primary">
          {t('customerCareNumber')}
        </InlineLink>
      </Txt>
    </div>
  );
};

export default SomethingWentWrong;
