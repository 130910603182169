import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  AutopayMethod,
  BadgeStatus,
  BillingAccount,
  Boleto,
  Invoice,
  Money,
  Order,
  Payment,
  PendingPayment,
  InvoiceStatus,
  Locale,
} from '@mfe/shared/schema-types';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

import { RootState } from '../rootReducer';

export interface BillingInfo {
  autoPay?: AutopayMethod | null;
  billingAccount?: BillingAccount | null;
  invoices: Invoice[];
  invoicesLoading: boolean;
  orders: Order[] | null;
  payments: Payment[];
  pendingPayments: PendingPayment[];
  summaryInvoiceStatus: BadgeStatus | null;
  popupsBlocked?: boolean;
  totalBalance: Money;
  invoicePdf?: string;
  shouldRefetchData?: boolean;
}

export interface BillingInfoState {
  loading: boolean;
  overviewLoading: boolean;
  billingLoading: boolean;
  retryCount: number;
  errors: ErrorsObj | RuntimeError | null;
  billingInfo: BillingInfo;
  boletoInfo: {
    invoiceNumber?: string | null | undefined;
    loading: boolean;
    boletoData?: Boleto;
    isError?: boolean;
  };
  loadingInfo: {
    orders: boolean;
    pendingPayments: boolean;
    payments: boolean;
    // lists of invoice numbers
    invoicePdf: string[];
  };
}

export const initialState: BillingInfoState = {
  loading: true,
  overviewLoading: false,
  billingLoading: false,
  retryCount: 0,
  errors: null,
  billingInfo: {
    autoPay: null,
    billingAccount: null,
    invoices: [],
    invoicesLoading: true,
    orders: null,
    payments: [],
    pendingPayments: [],
    summaryInvoiceStatus: null,
    totalBalance: {},
    shouldRefetchData: false,
  },
  boletoInfo: {
    invoiceNumber: null,
    loading: false,
  },
  loadingInfo: {
    orders: false,
    pendingPayments: false,
    payments: false,
    invoicePdf: [],
  },
};

export const billingInfoSlice = createSlice({
  name: 'billingInfo',
  initialState,
  reducers: {
    fetchBillingInfo: (
      state,
      { payload }: { payload: 'overview' | 'billing' }
    ) => state,
    refetchBillingInfo: (state) => state,
    startLoading: (state) => {
      state.loading = true;
    },
    setOverviewLoading: (state, action: PayloadAction<boolean>) => {
      state.overviewLoading = action.payload;
    },
    setBillingLoading: (state, action: PayloadAction<boolean>) => {
      state.billingLoading = action.payload;
    },

    setBillingApiErrors: (
      state,
      { payload }: { payload: ErrorsObj | RuntimeError | null }
    ) => {
      state.errors = payload;
      state.loading = false;
    },
    setBillingInfo: (
      state,
      { payload }: { payload: Partial<BillingInfo> }
    ) => ({
      ...state,
      loading: false,
      billingInfo: { ...state.billingInfo, ...payload },
    }),
    setInvoices: (
      state,
      {
        payload,
      }: {
        payload: {
          invoices: Invoice[];
          summaryInvoiceStatus: BadgeStatus | null;
        };
      }
    ) => ({
      ...state,
      billingInfo: {
        ...state.billingInfo,
        invoices: payload.invoices,
        summaryInvoiceStatus: payload.summaryInvoiceStatus,
        invoicesLoading: false,
      },
    }),
    triggerGetOrders: (state) => {
      state.loadingInfo.orders = true;
    },
    resetOrders: (state) => {
      state.billingInfo.orders = null;
    },
    setOrders: (state, { payload }: { payload: Order[] }) => {
      state.billingInfo.orders = payload;
      state.loadingInfo.orders = false;
    },
    fetchPendingPayments: (state) => {
      state.loadingInfo.pendingPayments = true;
    },
    fetchInvoicePdf: (state, { payload }: { payload: string }) => {
      state;
    },
    setPendingPayments: (state, { payload }: { payload: PendingPayment[] }) => {
      state.billingInfo.pendingPayments = payload;
      state.loadingInfo.pendingPayments = false;
    },
    fetchPaymentsHistory: (state) => {
      state.loadingInfo.payments = true;
    },
    setPaymentsHistory: (state, { payload }: { payload: Payment[] }) => {
      state.billingInfo.payments = payload;
      state.loadingInfo.payments = false;
    },
    setPaymentsHistoryError: (
      state,
      { payload }: { payload: RuntimeError | ErrorsObj | null }
    ) => {
      state.errors = payload;
      state.loadingInfo.payments = false;
      state.loadingInfo.pendingPayments = false;
    },
    setBoletoInfo: (
      state,
      { payload }: { payload: BillingInfoState['boletoInfo'] }
    ) => {
      const invoice = state.billingInfo.invoices.find(
        (invoice) => invoice.invoiceNumber === payload.invoiceNumber
      );

      state.boletoInfo.isError = payload.isError;

      if (!invoice) {
        return state;
      }

      invoice.isBoletoLoading = payload.loading;
      return state;
    },
    invoiceLoading: (state, { payload }: { payload: string }) => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        invoicePdf: [...state.loadingInfo.invoicePdf, payload],
      },
    }),
    invoiceLoaded: (state, { payload }: { payload: string }) => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        invoicePdf: state.loadingInfo.invoicePdf.filter(
          (invoice) => invoice !== payload
        ),
      },
    }),
    requestBoleto: (
      state,
      { payload }: { payload: Partial<Invoice> | null | undefined }
    ) => state,
    setPopUpBlocked: (
      state,
      { payload }: { payload: boolean | undefined }
    ) => ({
      ...state,
      billingInfo: {
        ...state.billingInfo,
        popupsBlocked: payload,
      },
    }),
    reset: () => initialState,
  },
});

export const {
  fetchBillingInfo,
  refetchBillingInfo,
  startLoading,
  setOverviewLoading,
  setBillingLoading,
  setBillingApiErrors,
  setBillingInfo,
  setBoletoInfo,
  invoiceLoading,
  invoiceLoaded,
  requestBoleto,
  reset,
  triggerGetOrders,
  resetOrders,
  setOrders,
  setPendingPayments,
  fetchPaymentsHistory,
  fetchPendingPayments,
  setPaymentsHistory,
  setPaymentsHistoryError,
  setPopUpBlocked,
  setInvoices,
  fetchInvoicePdf,
} = billingInfoSlice.actions;

export const selectBillingInfo = (state: { billingInfo: BillingInfoState }) =>
  state.billingInfo;

export const selectBillingAccount = (state: {
  billingInfo: BillingInfoState;
}) => state.billingInfo.billingInfo.billingAccount;

export const makeSelectIsInvoiceMenuDisabled =
  (invoice?: Invoice) => (state: RootState) => {
    const {
      locale: { locale: localeState },
      billingInfo: { billingInfo: billingState },
    } = state;

    if (localeState.userLocale === Locale.PtBr) {
      const balance = billingState.totalBalance?.value;
      return (
        (balance && balance <= 0) ||
        invoice?.invoiceStatus === InvoiceStatus.Paid ||
        invoice?.invoiceStatus === InvoiceStatus.Pending
      );
    }

    return (
      invoice?.invoiceStatus === InvoiceStatus.Paid ||
      invoice?.invoiceStatus === InvoiceStatus.Pending
    );
  };
