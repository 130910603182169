export type AnalyticsVendors =
  | 'com.viasat.care.usage'
  | 'com.viasat.care'
  | 'com.viasat.general';

export enum AnalyticsEventNames {
  ACPBannerSelected = 'ACP Banner Selected',
  ACPBannerClosed = 'ACP Banner Closed',
  ACPBannerDisplayed = 'ACP Banner Displayed',
  ChatWithUs = 'Chat With Us',
  ChangePlanInitiated = 'Change Plan Initiated',
  ChangePlanSelected = 'Change Plan Selected',
  ChangePlanOrderSubmitted = 'Change Plan Order Submitted',
  ChangePlanOrderCancelled = 'Change Plan Order Cancelled',
  ChangePlanOrderSuccess = 'Change Plan Order Successful',
  ChangePlanOrderFailed = 'Change Plan Order Failed',
  ChangePlanNotSupported = 'Change Plan Order Not Supported',
  ChangePlanChatInitiated = 'Change Plan Chat Initiated',
  UsageSummaryDisplayed = 'Usage Summary Displayed',
  UsageSummaryLearnMoreSelected = 'Usage Summary Learn More Notification Selected',
  UsageSummaryBuyMoreSelected = 'Usage Summary Buy More Selected',
  UsageSummaryChangePlanSelected = 'Usage Summary Change Plan Selected',
  DeviceListDisplayed = 'device_list_displayed',
  DeviceListShieldLearnMoreSelected = 'device_list_shield_learn_more_selected',
  DeviceListDeviceExpanded = 'device_list_device_expanded',
  DeviceListSeeMoreSelected = 'device_list_see_more_selected',
  CommonQuestionsDisplayed = 'Common Questions Displayed',
  CommonQuestionsExpanded = 'Common Questions Expanded',
  CommonQuestionsCollapsed = 'Common Questions Collapsed',
  CommonQuestionsViewFaqSelected = 'Common Questions View FAQ Selected',
  CommonQuestionsChatWithUsSelected = 'Common Questions Chat With Us Selected',
  AddonsSelected = 'Addons Selected',
  AddonsPromoCardDisplayed = 'Addons Promo Card Displayed',
  AddonsPromoCardLoadFailed = 'Addons Promo Card Load Failed',
  AddonsPurchaseFlowInitiated = 'Addons Purchase Flow Initiated',
  AddonsPurchaseFlowCancelled = 'Addons Purchase Flow Canceled',
  AddonsOrderSubmitted = 'Addons Order Submitted',
  AddonsOrderSuccessful = 'Addons Order Successful',
  AddonsOrderFailed = 'Addons Order Failed',
  AddonsOrderFlowAbandoned = 'Addons Order Flow Abandoned',
  AddonsPurchaseFlowFailed = 'Addons Purchase Flow Failed',
  // billing
  BillBalanceDisplayed = 'Balance Displayed',
  BillCycleDateDisplayed = 'Bill Cycle Date Displayed',
  BillStatementDisplayed = 'Bill Statement Displayed',
  BillStatementSelected = 'Bill Statement Selected',
  BillStatementHistoryViewed = 'Bill Statement History Viewed',
  MakePaymentSuccessful = 'Make Payment Successful',
  MakePaymentFailed = 'Make Payment Failed',
  MakePaymentSelected = 'Make Payment Selected',
  MakePaymentSubmitted = 'Make Payment Submitted',
  ElementLoaded = 'Payments Element Loaded',
  BillCycleDateChangeSuccessful = 'Bill Cycle Date Change Successful',
  BillCycleDateChangeFailed = 'Bill Cycle Date Change Failed',
  UpdatePaymentMethodSuccess = 'Payment Method Update Successful',
  UpdatePaymentMethodFailed = 'Payment Method Update Failed',
  UpdatePaymentMethodUpdateSelected = 'Payment Method Update Selected',
  UpdatePaymentMethodUpdateSubmitted = 'Payment Method Update Submitted',
  BillCycleDateChangeSelected = 'Bill Cycle Date Change Selected',
  BillCycleDateNewDateSelected = 'Bill Cycle Date New Date Selected',
  BillCycleDateChangeSubmitted = 'Bill Cycle Date Change Submitted',
  PendingPaymentNotified = 'Payments Pending Payment Notified',
  // buy more
  BUY_MORE_CONTEXT = 'buymore_context',
  BUY_MORE_SELECTED = 'Buy More Selected',
  BUY_MORE_INITIATED = 'Buy More Initiated',
  BUY_MORE_ORDER_FAILED = 'Buy More Order Failed',
  BUY_MORE_ORDER_SUBMITTED = 'Buy More Order Submitted',
  BUY_MORE_ORDER_SUCCESSFUL = 'Buy More Order Successful',
  BUY_MORE_INITIALIZATION_FAILED = 'Buy More Initialization Failed',
}

export type SchemaContext =
  | 'usage_context'
  | 'registration_context'
  | 'display_context'
  | 'profile_context'
  | 'change_plan_context'
  | 'addons_context'
  | 'gfb_product_metadata_context'
  | 'billing_context';

export type SchemaVersions = '1-0-0' | '1-0-1';

export enum DisplayZone {
  USAGE_DEVICE = 'usage-device',
  USAGE_DEVICE_OVERVIEW = 'usage-device-overview',
  USAGE_SUMMARY = 'usage-summary',
  USAGE_SUMMARY_OVERVIEW = 'usage_summary_overview',
  USAGE_PAGE = 'usage_page', // Moving forward make the newer zones generic
  OVERVIEW_PAGE = 'overview_page',
  PLAN_DETAILS = 'plan_details',
  BILLING_PAGE = 'billing_page',
}

export enum Categories {
  ACP = 'Request Acp',
  DataUsage = 'Data Usage',
  PlanDetails = 'Plan Details',
  Alert = 'Alert',
  ChangePlan = 'Change Plan',
  Profile = 'Profile',
  Login = 'Login',
  ShieldAd = 'Shield Ad',
  UsageSummaryBuyMore = 'Usage Summary Buy More',
}

export enum MobilePlatform {
  IOS = 'IOS',
  ANDROID = 'Android',
}

export enum AnalyticsAction {
  DISPLAYED = 'Displayed',
  PAGE_DISPLAYED = 'Page Displayed',
  BUTTON_CLICKED = 'Button Clicked',
  QUESTION_ANSWER_SELECTED = 'Question Answer Selected',
  SUBMITTED = 'Submitted',
  SUCCESS = 'Successful',
  FAILED = 'Failed',
  LOAD_FAILED = 'Load Failed',
  CONTACT_US_DISPLAYED = 'Contact Us Displayed',
  CTA_SELECTED = 'CTA Selected',
  CLOSED = 'Closed',
  CONFIGURE_CART_FAILED = 'Configure Cart Failed',
  EDIT_SELECTED = 'Edit Selected',
  EDIT_SUCCESSFUL = 'Edit Successful',
  EDIT_FAILED = 'Edit Failed',
  SELECTED = 'Selected',
  EXPANDED = 'Expanded',
}

export type Zones = `${DisplayZone}`;
