import {
  eventFactory,
  contextFactory,
  AnalyticsEventName,
  AnalyticsEventSource,
} from './lib/analytics';

export { ErrorEnum as BuyMoreError } from './lib/utils/types';

export const createBuyMoreInitiated = (source: AnalyticsEventSource) => ({
  event: eventFactory({
    name: AnalyticsEventName.BUY_MORE_INITIATED,
    data: {
      source,
    },
  }),
  context: contextFactory(),
});

export { default as BuyMore } from './lib';
