import { differenceInDays } from 'date-fns';

import {
  BillCycleData,
  GetHistoricalUsageByBillCyclePayload,
  Maybe,
} from '@mfe/shared/schema-types';

import { HistoricalUsageInfoState } from './historicalUsageInfoSlice';

export interface BillCycleDuration {
  startDate: string;
  endDate: string;
}

export type UsageData = {
  date: string;
  usage: number;
};

interface UpdatedBillCycleData {
  averageUsage: string;
  endDate: string;
  startDate: string;
  usageData: UsageData[];
}

export interface BillCycles {
  [key: string]: UpdatedBillCycleData;
}

// TODO: Update this to use date-fns as part of https://jira.viasat.com/browse/VGFB-544
export const getBillCycleOptions = (billCycles?: Maybe<BillCycleData>[]) => {
  const billCycleDurations: BillCycleDuration[] = [];

  billCycles?.forEach((billCycle: Maybe<BillCycleData>) => {
    const localBillStart = `${billCycle?.startDate}`
      ? `${billCycle?.startDate}T00:00:00`
      : '';
    let localBillEnd = billCycle?.endDate ?? '';

    if (localBillEnd) {
      // Covert to INT time
      const _utcTime = new Date(`${localBillEnd}T00:00:00`).getTime() - 1000; // Subtract 1 sec
      const _timeDt = new Date(_utcTime);
      const endYear = _timeDt.getFullYear();
      const endMonth = `00${_timeDt.getMonth() + 1}`.slice(-2);
      const endDay = `00${_timeDt.getDate()}`.slice(-2);
      localBillEnd = `${endYear}-${endMonth}-${endDay}T00:00:00`; // YYYY-MM-DDT00:00:00
    }

    if (localBillStart && localBillEnd) {
      billCycleDurations.push({
        startDate: localBillStart,
        endDate: localBillEnd,
      });
    }
  });

  return billCycleDurations;
};

export const parseBillCycles = (
  payload: GetHistoricalUsageByBillCyclePayload,
  state: HistoricalUsageInfoState
): { billCycles: BillCycles; billCycleDurations: BillCycleDuration[] | [] } => {
  const MIN_USAGE_TO_DISPLAY = 0.01;
  const processedBillCycles = {
    ...payload.billCycles?.map((billCycle, billCycleIndex) => {
      const sum = billCycle?.usageData?.reduce((accumulator, data) => {
        return data && data.usage ? accumulator + data.usage : accumulator;
      }, 0);
      // If daily usage is negligible, then reset to 0
      if (billCycle?.usageData) {
        for (const usageData of billCycle.usageData) {
          if (usageData?.usage && usageData?.usage < MIN_USAGE_TO_DISPLAY) {
            usageData.usage = 0;
          }
        }
      }

      // calculate elapsed days only for current bill cycle (current date - current bill cycle start date)
      let elapsedDays = 0;
      if (billCycleIndex === 0 && billCycle?.startDate) {
        elapsedDays =
          differenceInDays(
            new Date(),
            new Date(billCycle?.startDate as string)
          ) + 1;
      }
      const averageUsage =
        !sum || !billCycle?.usageData?.length
          ? 0.0
          : sum / (elapsedDays ? elapsedDays : billCycle?.usageData?.length);
      return { ...billCycle, averageUsage: averageUsage.toFixed(1) };
    }),
  };

  const billCycleDurations =
    state.billCycleDurations.length === 0
      ? [...getBillCycleOptions(payload.billCycles ?? [])]
      : [...state.billCycleDurations];

  return {
    billCycles: processedBillCycles as unknown as BillCycles,
    billCycleDurations,
  };
};

export enum UnleashedTimeInterval {
  LAST_30_DAYS = '30',
  LAST_60_DAYS = '60',
  LAST_90_DAYS = '90',
}
