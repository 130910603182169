import { gql } from '@apollo/client';

export const SUBMIT_FULL_ORDER = gql`
  mutation submitFullOrder($input: SubmitFullOrderInput) {
    submitFullOrder(input: $input) {
      didOrderFail
      error {
        failureReason
      }
    }
  }
`;

export const DEACTIVATE_STREAM_ON = gql`
  mutation deactivateStreamOn {
    deactivateStreamOn {
      success
    }
  }
`;
