import { all, call, select, takeLatest, put, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ApolloQueryResult } from '@apollo/client';

import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
  graphqlMutationWithErrors,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';
import {
  Address,
  ConfigureAddonInput,
  PurchaseAddOnInput,
  Query,
} from '@mfe/shared/schema-types';

import {
  AddOnsState,
  selectAddOns,
  setAddOnsPricesAndDiscounts,
  setAddOnsProductInstanceIds,
  setError,
  getAvailableNumbers,
  reserveSelectedNumber,
  setAvailableNumbers,
  setAvailableNumbersError,
  setNumberReservationSuccess,
  setNumberReservationError,
  scrubEmergencyAddress,
  resetE911ScrubbedAddress,
  setE911Error,
  setE911ScrubbedAddress,
  setShowModal,
  purchaseAddOn,
  getPurchasableAddOns as getPurchasableAddOnsAction,
  setPurchasableAddOns,
  purchasableAddOnsError,
  purchaseAddOnError,
  purchaseAddOnSuccess,
  setAddOnOrderId,
  getAddOnsPricesAndDiscounts,
  loadingAddOnsPricesAndDiscounts,
  setConfigureAddonSuccess,
  setConfigureAddonError,
} from './addOnsSlice';
import { selectUserInfo } from '../userInfo';
import { waitForUserInfo } from '../utils/utilsSagas';
import {
  GET_ADD_ONS_PRICES_AND_DISCOUNTS,
  GET_AVAILABLE_PHONE_NUMBERS,
  GET_PURCHASABLE_ADDONS,
  PURCHASE_ADD_ON,
  RESERVE_PHONE_NUMBER,
  GET_SCRUBBED_ADDRESS,
  CONFIGURE_ADDON,
} from './queriesAndMutations';
import { ScrubAddressProcessStatus } from '@mfe/shared/graphql/PAL/types';
import { ScrubAddressRecommendation } from '@mfe/shared/graphql/PAL/types';
import { clearError } from '../error';
import { ModalTypeEnum } from '../scrubbedAddress/scrubbedAddressSagas';

export function* waitForAddOnsProductInstanceIds() {
  const addons: AddOnsState = yield select(selectAddOns);

  if (addons.loading) {
    yield take(setAddOnsProductInstanceIds.type);
  }
}

export function* fetchPurchasableAddOns() {
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PURCHASABLE_ADDONS,
    variables: {},
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(purchasableAddOnsError(runtimeError ?? errors));
    return;
  }

  const addons = data?.getPurchasableAddOns;

  if (addons) {
    yield put(setPurchasableAddOns(addons));
  }
}

export function* fetchAddOnsPricesAndDiscounts() {
  const { addOnsProductInstanceIds } = yield select(selectAddOns);

  if (!addOnsProductInstanceIds.length) {
    return;
  }
  yield put(loadingAddOnsPricesAndDiscounts());

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ADD_ONS_PRICES_AND_DISCOUNTS,
    variables: { addOnsProductInstanceIds },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getAddOnsPricesAndDiscounts) {
    yield put(
      setAddOnsPricesAndDiscounts(data.getAddOnsPricesAndDiscounts ?? [])
    );
  }
}

export function* fetchAvailablePhoneNumbers(action: PayloadAction<string>) {
  yield call(waitForUserInfo);
  const { userInfo } = yield select(selectUserInfo);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_AVAILABLE_PHONE_NUMBERS,
    variables: {
      input: {
        postal_code: action.payload || userInfo.address.service?.postalCode,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setAvailableNumbersError(runtimeError ?? errors));
    return;
  }

  if (data?.getAvailableTelephoneNumbers) {
    yield put(setAvailableNumbers(data.getAvailableTelephoneNumbers));
  }
}

export function* reserveNumber(action: PayloadAction<string>) {
  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: RESERVE_PHONE_NUMBER,
      variables: { input: { telephoneNumber: action.payload } },
      fetchPolicy: 'no-cache',
    }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setNumberReservationError(runtimeError ?? errors));
    return;
  }

  if (data?.reserveTelephoneNumber?.success) {
    yield put(setNumberReservationSuccess());
  }
}

export const parseE911ScrubbedAddress = (
  data: Partial<ApolloQueryResult<Query>['data']>,
  secondAddressLine: string
): AddOnsState['scrubbedE911Address'] | null => {
  const rawAddress = data.getScrubbedAddress;
  const firstAddressLine = rawAddress?.addressLines[0] ?? '';
  const municipality = rawAddress?.municipality ?? '';
  const region = rawAddress?.region ?? '';
  const postalCode = rawAddress?.postalCode ?? '';
  const countryName = rawAddress?.country ?? '';

  let formattedAddressLine1 = '';
  let formattedAddressLine2 = '';

  if (rawAddress?.processStatus !== ScrubAddressProcessStatus.Incorrect) {
    formattedAddressLine1 = `${firstAddressLine}, ${secondAddressLine} ${municipality}, `;
    formattedAddressLine2 = `${region} ${postalCode} ${countryName}`;
  }

  return {
    rawAddress,
    formattedAddress: { formattedAddressLine1, formattedAddressLine2 },
    processStatus: rawAddress?.processStatus as ScrubAddressProcessStatus,
    recommendation: rawAddress?.recommendation as ScrubAddressRecommendation,
  };
};

export function* scrubEmergencyAddressSaga({
  payload,
}: {
  type: string;
  payload: Address;
}) {
  yield put(clearError({}));

  payload.countryCode = payload.countryCode || 'US';

  try {
    const apiResponse: FetchWithErrorsQuery = yield call(
      graphqlQueryWithErrors,
      {
        query: GET_SCRUBBED_ADDRESS,
        variables: { address: payload },
      }
    );

    const { data, errors, runtimeError } = apiResponse;

    if (runtimeError || errors) {
      yield put(setE911Error(runtimeError ?? errors));
      return;
    }
    let scrubbedE911Address;
    if (data?.getScrubbedAddress) {
      const secondAddressLine = payload.addressLines[1];
      scrubbedE911Address = parseE911ScrubbedAddress(data, secondAddressLine);
    }

    yield put(resetE911ScrubbedAddress());

    if (scrubbedE911Address) {
      yield put(setE911ScrubbedAddress({ scrubbedE911Address }));
    } else {
      yield put(
        setE911Error({
          operation: 'getScrubbedAddress',
          message: ['E911 getScrubbedAddress attempt failed.'],
        })
      );
    }
  } catch (error) {
    yield put(
      setE911Error({
        operation: 'getScrubbedAddress',
        message: ['E911 getScrubbedAddress attempt failed.'],
      })
    );
    yield put(resetE911ScrubbedAddress());
  }
}

type PayloadType = {
  scrubbedE911Address: AddOnsState['scrubbedE911Address'];
};

function* checkScrubProcessStatus({
  payload,
}: {
  type: string;
  payload: PayloadType;
}) {
  const { processStatus, recommendation } = payload.scrubbedE911Address;

  if (
    processStatus === ScrubAddressProcessStatus.Verified ||
    (processStatus === ScrubAddressProcessStatus.Corrected &&
      recommendation === ScrubAddressRecommendation.RECOMMEND)
  ) {
    yield put(setShowModal(null));

    return;
  }

  if (processStatus === ScrubAddressProcessStatus.Corrected) {
    return;
  }
  yield put(setShowModal(ModalTypeEnum.AddressError));
}

function* configureAddon(action: PayloadAction<ConfigureAddonInput>) {
  const input = action.payload;
  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: CONFIGURE_ADDON,
      variables: { input },
      fetchPolicy: 'no-cache',
    }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setConfigureAddonError(runtimeError ?? errors));
  }

  if (data?.configureAddon?.success) {
    yield put(setConfigureAddonSuccess());
  }
}

export function* purchaseAddOnSaga(action: PayloadAction<PurchaseAddOnInput>) {
  try {
    yield call(configureAddon, action);

    const apiResponse: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: PURCHASE_ADD_ON,
        variables: { input: action.payload },
      }
    );

    const { data } = apiResponse;

    if (data?.purchaseAddOn?.success) {
      yield put(purchaseAddOnSuccess(data.purchaseAddOn.success));
      yield put(setAddOnOrderId(data.purchaseAddOn.orderId ?? null));
    } else {
      yield put(purchaseAddOnError('Operation unsuccessful'));
    }
  } catch (error) {
    yield put(purchaseAddOnError(error));
  }
}

export function* watchAddOns() {
  yield all([
    takeLatest(getAvailableNumbers.type, fetchAvailablePhoneNumbers),
    takeLatest(reserveSelectedNumber.type, reserveNumber),
    takeLatest(getAddOnsPricesAndDiscounts.type, fetchAddOnsPricesAndDiscounts),
    takeLatest(scrubEmergencyAddress.type, scrubEmergencyAddressSaga),
    takeLatest(setE911ScrubbedAddress.type, checkScrubProcessStatus),
    takeLatest(purchaseAddOn.type, purchaseAddOnSaga),
    takeLatest(getPurchasableAddOnsAction.type, fetchPurchasableAddOns),
  ]);
}
