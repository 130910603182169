import { gql } from '@apollo/client';

export const GET_PLAN_CHARACTERISTICS = gql`
  query getPlanCharacteristics($refetchData: Boolean) {
    getPlanCharacteristics(refetchData: $refetchData) {
      id
      name
      family
      isUnlimited
      isBuyMoreAvailable
      uploadSpeed
      isGoodFor
      dataAllowanceText
      inflectionPointText
      dataCap
      usageMeter
      bestFor
      titleAddOns
      inflectionPoint
      uploadSpeedText
      freeZoneTime
      dataCapUnits
      hasUnlimitedUsageMeter
      isUnleashed
    }
  }
`;

export const GET_PLAN_PRICE = gql`
  query getCurrentPlanPrice {
    getCurrentPlanPrice {
      price
    }
  }
`;
export const GET_USAGE_TIER = gql`
  query getUsageTier {
    getUsageTier {
      tier
    }
  }
`;

export const GET_CURRENT_USAGE = gql`
  query getCurrentUsage($refetchData: Boolean) {
    getCurrentUsage(refetchData: $refetchData) {
      monthlyDataUsed
      buyMoreDataUsed
      displayedTotalDataUsed
      monthlyDataCap
      buyMoreDataCap
      displayedTotalDataCap
      monthlyDataUsedRatio
      buyMoreDataUsedRatio
      monthlyDataRemaining
      buyMoreDataRemaining
      freeZoneDataUsed
      standardDataUsed
      hasBreachedDataAllowance
      endDate
      startDate
      daysLeft
    }
  }
`;

export const GET_DOWNLOAD_SPEEDS = gql`
  query getDownloadSpeeds {
    getDownloadSpeeds {
      id
      downloadSpeed
      downloadUnits
      downloadSpeedText
      downloadRangeText
    }
  }
`;
