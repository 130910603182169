import { useCallback, useMemo } from 'react';
import { BadgeDot, Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfWeek,
  Locale,
} from 'date-fns';

import { SelectedDate } from '../hooks/use-schedule-installation';

import styles from './date-picker.module.scss';

type DatePickerProps = {
  selectedDate: SelectedDate;
  locale: Locale;
  numberOfWeeks?: number;
  handleDateSelection?: (value: Date) => void;
  availableDays: Date[];
};

export const DatePicker = (props: DatePickerProps) => {
  const {
    selectedDate,
    locale,
    numberOfWeeks = 4,
    handleDateSelection,
    availableDays,
  } = props;

  const serializedDate = serializeCalendarDate(selectedDate);

  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'calendar',
  });

  const fnsOptions = locale && {
    locale: locale,
    weekStartsOn: locale.options?.weekStartsOn,
  };

  const today = useMemo(() => new Date(), []);
  const firstDOW = startOfWeek(today, fnsOptions);
  const allDOW = Array.from({ length: 7 }, (x, i) => addDays(firstDOW, i));

  const months = useMemo(() => {
    const allDays = eachDayOfInterval({
      start: firstDOW,
      end: addWeeks(firstDOW, numberOfWeeks),
    });
    const chunkDays: Date[][] = [];

    let monthsIndex = 0;
    for (let i = 0; i < allDays.length - 1; i += 1) {
      if (i && !isSameMonth(allDays[i], allDays[i - 1])) {
        monthsIndex += 1;
      }
      chunkDays[monthsIndex] ||= [];
      chunkDays[monthsIndex].push(allDays[i]);
    }

    return chunkDays;
  }, [firstDOW, numberOfWeeks]);

  const getDayHighlightClassName = useCallback(
    (entry: Date) => {
      const className = [styles['calendar-body-day']];

      if (isSameDay(entry, today)) {
        className.push(styles['calendar-body-day-today']);
      }
      if (isDateIncluded(entry, availableDays)) {
        className.push(styles['calendar-body-day-available']);
      }
      if (serializedDate && isSameDay(entry, serializedDate)) {
        className.push(styles['calendar-body-day-selected']);
      }

      return className.join(' ');
    },
    [availableDays, serializedDate, today]
  );

  const handleDaySelect = useCallback(
    (day: Date) => () => {
      if (!isDateIncluded(day, availableDays)) {
        return;
      }
      handleDateSelection?.(day);
    },
    [availableDays, handleDateSelection]
  );

  return (
    <Surface data-testid="calendar" className={`${styles['calendar']}`}>
      <Surface className={styles['calendarInfo']} mb="40px">
        <BadgeDot
          type="infoSecondary"
          theme="dark"
          label={t('calendarInfo.today')}
        />
        <BadgeDot
          type="infoPrimary"
          theme="dark"
          label={t('calendarInfo.availableDates')}
        />
      </Surface>

      {months.map((days, index) => {
        const monthDays = eachDayOfInterval({
          start: startOfWeek(days[0], fnsOptions),
          end: endOfWeek(days[days.length - 1], fnsOptions),
        });

        return (
          <div
            key={index}
            data-testid="calendar-month"
            className={styles['calendar-month']}
          >
            <div
              data-testid="calendar-month-title"
              className={styles['calendar-month-title']}
            >
              <Txt variant="labelMedium">
                {format(days[0], 'MMMM', fnsOptions)}
              </Txt>
            </div>
            <div
              data-testid="calendar-week-days"
              className={styles['calendar-week-days']}
            >
              {allDOW.map((weekDay, index) => (
                <div
                  key={index}
                  data-testid="calendar-week-day"
                  className={styles['calendar-week-day']}
                >
                  <Txt variant="labelSmall">
                    {format(weekDay, 'E', fnsOptions)}
                  </Txt>
                </div>
              ))}
            </div>
            <div
              data-testid="calendar-body-days"
              className={styles['calendar-body-days']}
            >
              {monthDays.map((day, index) =>
                isSameMonth(day, days[0]) ? (
                  <button
                    key={index}
                    type="button"
                    onClick={handleDaySelect(day)}
                    data-testid="calendar-body-day"
                    className={getDayHighlightClassName(day)}
                  >
                    <Txt variant="bodySmallRegular">
                      {format(day, 'd', fnsOptions)}
                    </Txt>
                  </button>
                ) : (
                  <div key={index} data-testid="calendar-body-day-empty" />
                )
              )}
            </div>
          </div>
        );
      })}
    </Surface>
  );
};

function isDateIncluded(date: Date, dates: Date[]) {
  return dates.find((d) => isSameDay(d, date));
}

function serializeCalendarDate(d: SelectedDate) {
  if (!d.year || !d.month || !d.day) return;
  return new Date(d.year, d.month - 1, d.day);
}
