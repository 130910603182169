import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import format from './format';
import enUS from './translations/en-US/translations.json';
import ptBR from './translations/pt-BR/translations.json';
import es419 from './translations/es-419/translations.json';
import itIT from './translations/it-IT/translations.json';
import { TranslationsRecords } from './types';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export function addAdditionalTranslations(
  namespace: string,
  translationRecords: TranslationsRecords
) {
  for (const [language, languageTranslations] of Object.entries(
    translationRecords
  )) {
    i18n.addResourceBundle(
      language,
      namespace,
      languageTranslations,
      true,
      true
    );
  }
}

i18n.use(initReactI18next).init({
  resources: { 'en-US': enUS, 'es-419': es419, 'pt-BR': ptBR, 'it-IT': itIT },
  fallbackLng: 'en-US',
  lng: undefined,
  returnNull: false,
  interpolation: {
    escapeValue: false,
    format,
  },
  // This prevents the key names from displaying in place of values before language is set
  parseMissingKeyHandler: () => '',
});

export default i18n;
