import Cookies from 'js-cookie';

import { Locale } from '@mfe/shared/schema-types';
import { DisplayLanguage } from '@mfe/services/translations-service';

import {
  getLocaleDefaultLanguage,
  DISPLAY_LANGUAGE_COOKIE_NAME,
} from '../../locale';

export function getDisplayLanguageCookie(): string | undefined {
  return Cookies.get(DISPLAY_LANGUAGE_COOKIE_NAME);
}

export function computeDisplayLanguage(
  cookieValue: DisplayLanguage | undefined,
  locale: string | Locale | undefined
): DisplayLanguage {
  return cookieValue ?? getLocaleDefaultLanguage(locale);
}
