import { createSlice, current } from '@reduxjs/toolkit';

import { Locale } from '@mfe/shared/schema-types';
import { DisplayLanguage } from '@mfe/services/translations-service';

export function getLocaleDefaultLanguage(locale: string | undefined) {
  switch (locale) {
    case Locale.PtBr:
      return DisplayLanguage.Portuguese;
    case Locale.EsMx:
      return DisplayLanguage.Spanish;
    case Locale.ItIt:
      return DisplayLanguage.Italian;
    default:
      return DisplayLanguage.English;
  }
}

// What prefixes to the urls should attempt to switch the language?
export const languagePrefixes: Record<Locale, string[]> = {
  [Locale.EnUs]: [],
  [Locale.PtBr]: [],
  [Locale.ItIt]: ['/it'],
  [Locale.EsMx]: [],
};

export type LocaleState = {
  localeFetched: boolean;
  locale: {
    userLocale: Locale;
    displayLanguage: DisplayLanguage;
    urlPrefix: string;
  };
};

type SetLocaleStatePayload = {
  payload: {
    displayLanguage?: DisplayLanguage;
    userLocale?: Locale;
  };
};

export const USER_LOCALE_COOKIE_NAME = 'userLocale';
export const DISPLAY_LANGUAGE_COOKIE_NAME = 'displayLanguage';
export const DEFAULT_LOCALE = Locale.EnUs;
export const DEFAULT_LANGUAGE = DisplayLanguage.English;
export const DEFAULT_URL_PREFIX = '';

export const initialLocaleState: LocaleState = {
  localeFetched: false,
  locale: {
    userLocale: DEFAULT_LOCALE,
    displayLanguage: DEFAULT_LANGUAGE,
    urlPrefix: DEFAULT_URL_PREFIX,
  },
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState: initialLocaleState,
  reducers: {
    setLocaleState: (state, { payload }: SetLocaleStatePayload) => {
      const currentState = current(state);
      if (
        !payload.displayLanguage &&
        payload.userLocale &&
        payload.userLocale !== currentState.locale.userLocale
      ) {
        state.locale.displayLanguage = getLocaleDefaultLanguage(
          payload.userLocale
        );
      }

      if (payload.displayLanguage)
        state.locale.displayLanguage = payload.displayLanguage;

      if (payload.userLocale) {
        state.locale.userLocale = payload.userLocale;

        const prefixes = languagePrefixes[payload.userLocale];
        state.locale.urlPrefix = prefixes.length
          ? prefixes[0]
          : DEFAULT_URL_PREFIX;
      }
      state.localeFetched = true;
      return state;
    },

    setLocaleFetched: (state) => {
      state.localeFetched = true;
      return state;
    },
    setLanguageCookie: (state, { payload }) => state,
    requestLanguageCookie: (state) => state,
    requestLocaleCookie: (state) => state,
    updateTranslation: (state) => state,
    resetLocale: () => initialLocaleState,
  },
});

export const {
  setLocaleState,
  setLocaleFetched,
  setLanguageCookie,
  requestLanguageCookie,
  requestLocaleCookie,
  updateTranslation,
  resetLocale,
} = localeSlice.actions;

export const selectLocale = (state: { locale: LocaleState }) => state.locale;
