import { Badge, IBadgeProps } from '@vst/beam';
import { useTranslation } from 'react-i18next';

export const PlanChangePendingBadge = (props: Partial<IBadgeProps>) => {
  const { t } = useTranslation('NewChangePlan');

  return (
    <Badge
      {...props}
      label={t('planChangePending')}
      showIcon={false}
      emphasis="medium"
      size="small"
      state="infoSecondary"
    />
  );
};
