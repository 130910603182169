import { useDispatch } from 'react-redux';
import { useRef, useEffect } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

import {
  Domains,
  EventTypes,
  WindowMessageData,
} from '@mfe/services/window-messages';
import { postMessage } from '@mfe/to-be-migrated/redux/utils';

export const RootSelectorComponent = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const dispatch = useDispatch();
  const rootComponent = useRef<HTMLDivElement>(null);
  const resizeObserver = new ResizeObserver(() => {
    const element = rootComponent.current;

    const message: WindowMessageData = {
      domain: Domains.General,
      eventType: EventTypes.Resize,
      data: {
        height: element?.scrollHeight,
      },
    };

    dispatch(postMessage(message));
  });

  useEffect(() => {
    const element = rootComponent.current;
    if (element) resizeObserver.observe(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootComponent]);

  return (
    <div ref={rootComponent} id="usage-root">
      {children}
    </div>
  );
};
