/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { selectUser } from '../auth';
import { selectConfig } from '@mfe/shared/redux/config';
import { EventTypes } from '@mfe/services/window-messages';
import { analyticsBus, inViewObserver } from '@mfe/services/analytics';
import {
  AutopayMethod,
  BillingAccount,
  GetCurrentUsagePayload,
  Payment,
} from '@mfe/shared/schema-types';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Categories, selectAnalytics } from '.';
import { selectBillingInfo, setBillingInfo } from '../billingInfo';
import { selectPlanCharacteristics, setUsage } from '../plan';
import { postBillingMessage } from '../utils';
import {
  ContextSchema,
  InViewSelfDescribingEvent,
  inViewSelfDescribingEvent as inViewSelfDescribingEventAction,
  InViewStructuredEvent,
  inViewStructuredEvent as inViewStructuredEventAction,
  LogEvent,
  selfDescribingEvent as selfDescribingEventAction,
  SelfDescribingEventInput,
  setBillingContext as setBillingContextAction,
  setUsageContext as setUsageContextAction,
  structuredEvent as structuredEventAction,
  trackBillingEvent as trackBillingEventAction,
  TrackBillingEventInput,
} from './analyticsSlice';
import { v1 } from 'uuid';
import { PayloadAction } from '@reduxjs/toolkit';
import { waitForToken } from '../utils/utilsSagas';

export interface getUsageContextVariables {
  productInstanceId: string;
}

type UsageContextData = {
  days_left_in_cycle: number;
  usage_total_gb: any;
  usage_cap_gb: any;
  plan_status: string;
  plan_name: string;
};

export const parseUsageContext = (
  {
    daysLeft,
    dataUsedGB,
    dataAllotmentGB,
  }: {
    daysLeft: number;
    dataUsedGB: number;
    dataAllotmentGB: number;
  },
  status: string,
  name: string
): UsageContextData => ({
  days_left_in_cycle: daysLeft,
  usage_total_gb: dataUsedGB,
  usage_cap_gb: dataAllotmentGB,
  plan_name: name,
  plan_status: status,
});

let _analyticsUrl = '';
let user_id = '';
let _allowAnalytics: boolean | null = null;

export const parseBillingContext = ({
  billingAccount,
  autoPay,
  payments,
}: {
  billingAccount?: BillingAccount | null;
  autoPay?: AutopayMethod | null;
  payments?: Payment[] | null;
}) => {
  const nextBillPeriodStartDate = billingAccount?.billDate;
  const billingCycleDayOfMonth = billingAccount?.billingCycleDayOfMonth;
  const paymentDueDate = billingAccount?.dueDate;
  const autoPayMethod = autoPay?.paymentProduct;
  const lastPaymentMadeDate = payments?.[0]?.paymentDate;
  const creditClass = billingAccount?.creditClass;

  return {
    next_bill_period_start_date: nextBillPeriodStartDate ?? '',
    bill_cycle_day: billingCycleDayOfMonth ?? 0,
    payment_due_date: paymentDueDate ?? '',
    autopay_method: autoPayMethod ?? '',
    last_payment_made_date: lastPaymentMadeDate ?? '',
    credit_class: creditClass ?? '',
  };
};

export function* setUsageContext(
  action: PayloadAction<GetCurrentUsagePayload>
) {
  yield call(waitForToken);

  const { daysLeft, monthlyDataUsed, monthlyDataCap } = action.payload;

  const usageInfo = {
    daysLeft: daysLeft as number,
    dataUsedGB: monthlyDataUsed,
    dataAllotmentGB: monthlyDataCap,
  };

  const {
    characteristics: { name },
  } = yield select(selectPlanCharacteristics);

  const {
    user: { productInstanceStatus },
  } = yield select(selectUser);

  if (action.payload) {
    yield put(
      setUsageContextAction({
        schema: 'usage_context',
        data: parseUsageContext(usageInfo, productInstanceStatus, name),
      })
    );
  }
}

const getSessionId = () => {
  const analyticsSession = document.cookie
    .split(';')
    .find((cookie) => {
      return cookie.split('=')[0].trim() === 'an_sid';
    })
    ?.split('=')[1];

  if (analyticsSession) return analyticsSession;

  const sessionId = v1();
  document.cookie = `an_sid=${sessionId}; expires=0;`;
  return sessionId;
};

export function* sendDataToBus(
  event_name: string,
  properties: any,
  contexts: ContextSchema[] | undefined
) {
  const session_id = getSessionId();
  const {
    user: {
      partyId,
      auth: { tokenInfo },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const {
    platform,
    version,
    env,
    analyticsUrl,
    allowAnalytics,
  }: ReturnType<typeof selectConfig> = yield select(selectConfig);
  const parsedContext = contextParser(contexts);
  const allowedCategories = [Categories.Login] as string[];

  const isAllowed = allowedCategories.every((category) =>
    event_name.includes(category)
  );

  _analyticsUrl = analyticsUrl;
  user_id = partyId;
  _allowAnalytics = allowAnalytics;

  if (!isAllowed) return;

  const interval = setInterval(() => {
    if (_analyticsUrl && user_id) {
      if (!_allowAnalytics) return;

      const eventData = {
        ssoToken: tokenInfo.accessToken,
        event: {
          timestamp: Date.now(),
          event_name: `MV - ${event_name}`,
          user_id,
          session_id,
          app_version: version,
          platform,
          plan: {
            branch: 'main',
            source: `Viasat Residential - ${
              env === 'PROD' ? 'Production' : 'Staging'
            }`,
            version: '1.0',
          },
          ...properties,
          ...parsedContext,
        },
      };

      analyticsBus(_analyticsUrl, eventData);
      clearInterval(interval);
    }
  }, 1000);
}

const contextParser = (contexts: ContextSchema[] | undefined) => {
  if (!contexts) return undefined;
  return contexts.reduce(
    (o, context) => Object.assign(o, { [context.schema]: context.data }),
    {}
  );
};

export function* setBillingContext() {
  const {
    billingInfo: { billingAccount, autoPay, payments },
  }: ReturnType<typeof selectBillingInfo> = yield select(selectBillingInfo);

  const billingContext = parseBillingContext({
    billingAccount,
    autoPay,
    payments,
  });

  yield put(
    setBillingContextAction({
      schema: 'billing_context',
      data: billingContext,
    })
  );

  yield put(
    postBillingMessage({
      eventType: EventTypes.BillingContext,
      data: {
        billingContext,
      },
    })
  );
}

export function* selfDescribingEvent({
  payload: { eventName, data, context },
}: {
  type: string;
  payload: SelfDescribingEventInput;
}) {
  yield call(sendDataToBus, eventName, data, context);
}

export function* trackBillingEvent({
  payload: { eventName, data },
}: {
  type: string;
  payload: TrackBillingEventInput;
}) {
  const { billingContext }: ReturnType<typeof selectAnalytics> = yield select(
    selectAnalytics
  );

  yield call(sendDataToBus, eventName, data, [billingContext]);
}

export function* structuredEvent({
  payload: { category, action, params },
}: {
  type: string;
  payload: LogEvent;
}) {
  const { label, property, value } = params ?? {};

  yield call(
    sendDataToBus,
    `${category} ${action}`,
    { label, property, value },
    params?.context
  );
}

export function* inViewSelfDescribingEvent({
  payload: { selector, eventName, context, triggerOnce = false, data },
}: {
  type: string;
  payload: InViewSelfDescribingEvent;
}) {
  yield call(inViewObserver, selector, triggerOnce, () => {
    sendDataToBus(eventName, data, context);
  });
}

export function* inViewStructuredEvent({
  payload: { selector, category, action, params, triggerOnce = false },
}: {
  type: string;
  payload: InViewStructuredEvent;
}) {
  const { label, property, value } = params ?? {};

  yield call(inViewObserver, selector, triggerOnce, () =>
    sendDataToBus(
      `${category} ${action}`,
      { label, property, value },
      params?.context
    )
  );
}

export function* watchAnalytics() {
  yield all([
    takeLatest(setUsage.type, setUsageContext),
    takeLatest(setBillingInfo.type, setBillingContext),
    takeLatest(structuredEventAction.type, structuredEvent),
    takeLatest(selfDescribingEventAction.type, selfDescribingEvent),
    takeLatest(inViewSelfDescribingEventAction.type, inViewSelfDescribingEvent),
    takeLatest(inViewStructuredEventAction.type, inViewStructuredEvent),
    takeLatest(trackBillingEventAction.type, trackBillingEvent),
  ]);
}
