import { ArrowBack } from '@vst/beam-icons/icons';
import { SpacingTypes } from '@vst/beam/src/react/common';
import { ButtonLink, Icon, StandAloneLink, Surface, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';

import styles from './HeaderWithBackNavigation.module.scss';

interface HeaderWithBackNavigationProps {
  title: string;
  additionalClassName?: string;
  handleBackNavigation: (e: React.MouseEvent<HTMLElement>) => void;
  goBackLabel: string;
  style?: React.CSSProperties;
  my?: SpacingTypes;
  additionalButton?: React.ReactNode;
}

export const HeaderWithBackNavigation = ({
  title,
  additionalClassName,
  handleBackNavigation,
  goBackLabel,
  style,
  my,
  additionalButton,
}: HeaderWithBackNavigationProps) => {
  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const defaultSurfaceClassName = styles['wrapper'];
  const surfaceClassName = additionalClassName
    ? `${defaultSurfaceClassName} ${additionalClassName}`
    : defaultSurfaceClassName;

  return (
    <Surface
      variant={isMobile ? 'primary' : 'secondary'}
      className={surfaceClassName}
      style={style}
      my={my}
    >
      <div className={styles['layout']}>
        <BackButton
          handleClick={handleBackNavigation}
          goBackLabel={goBackLabel}
        />
        <Txt data-cy="page-title" variant={isMobile ? 'heading5' : 'heading3'}>
          {title}
        </Txt>

        {additionalButton}
      </div>
    </Surface>
  );
};

interface BackButtonProps {
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  goBackLabel: string;
}

const BackButton = ({ handleClick, goBackLabel }: BackButtonProps) => {
  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleClick(e);
  };

  if (isMobile) {
    return (
      <StandAloneLink href="#" onClick={onClick} data-cy="go-back-link">
        <Icon icon={ArrowBack} color="gray_600" />
      </StandAloneLink>
    );
  }

  return (
    <ButtonLink
      data-cy="go-back-button-link"
      linkSize="medium"
      onClick={onClick}
      arrowPos={isMobile ? undefined : 'back'}
      href="#"
      variant={isMobile ? 'secondary' : 'primary'}
      label={isMobile ? '' : goBackLabel}
    />
  );
};
