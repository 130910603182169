import { createSlice } from '@reduxjs/toolkit';

import {
  GetPlanOffersPayload,
  TransitionSchedule,
  TransitionScheduleOutput,
  ShoppingCartResponse,
  DroppedAddOns,
} from '@mfe/shared/schema-types';
import { FetchWithErrorsQuery } from '@mfe/shared/redux/graphql';

export enum ChangePlanSteps {
  PLAN_SELECTION = 'PLAN_SELECTION',
  ORDER_REVIEW = 'ORDER_REVIEW',
  DROPPED_ADDONS = 'DROPPED_ADDONS',
  SELECT_SCHEDULE = 'SELECT_SCHEDULE',
  ORDER_OUTCOME = 'ORDER_OUTCOME',
}

export interface ChangePlanOffersState {
  loading: boolean;
  planOffersData: GetPlanOffersPayload[] | null;
  droppedAddOns: DroppedAddOns[];
  error: FetchWithErrorsQuery['errors'] | null;
  cart: ShoppingCartResponse;
  selectedPlanId: string;
  step: ChangePlanSteps;
  requiresWorkOrder: boolean;
  orderSuccess: boolean;
  hideBroadbandLabels: boolean;
  installationAppointment: Omit<
    TransitionScheduleOutput,
    'workOrderScheduled'
  > | null;
  hasPendingTransition: boolean;
  hasPendingTransitionLoading: boolean;
  hasPendingTransitionError: FetchWithErrorsQuery['errors'] | null;
  hasActivationError: boolean;
  hasEquipmentShipping: boolean;
}

export interface TransitionScheduleInput {
  notes?: string;
  appointment?: TransitionSchedule;
}

export const defaultCart = {
  accountSetupShortName: '',
  droppedAddOns: [],
  cartItems: {
    plan: undefined,
  },
  id: '',
  leaseFeeShortName: '',
  prices: {
    monthly: {
      lineItems: undefined,
      totalWithPromos: undefined,
      totalWithoutPromos: undefined,
    },
    oneTime: {
      lease: undefined,
      totalWithPromos: undefined,
      totalWithoutPromos: undefined,
      lineItems: {
        promo: undefined,
      },
      feesAndDiscounts: undefined,
    },
  },
  status: '',
  requiresWorkOrder: false,
  hasEquipmentShipping: false,
};

export const initialState: ChangePlanOffersState = {
  loading: false,
  error: null,
  step: ChangePlanSteps.PLAN_SELECTION,
  requiresWorkOrder: false,
  droppedAddOns: [],
  orderSuccess: false,
  selectedPlanId: '',
  installationAppointment: null,
  hideBroadbandLabels: false,
  planOffersData: null,
  cart: defaultCart,
  hasPendingTransition: false,
  hasPendingTransitionError: null,
  hasPendingTransitionLoading: false,
  hasActivationError: false,
  hasEquipmentShipping: false,
};

export const changePlanSlice = createSlice({
  name: 'changePlan',
  initialState,
  reducers: {
    getPlans: (state) => {
      state.loading = true;
      state.error = null;
    },
    setPlans: (state, { payload }: { payload: GetPlanOffersPayload[] }) => {
      state.loading = false;
      state.planOffersData = payload;
      state.error = null;
    },
    refetchPlans: (state) => {
      state.loading = true;
      state.error = null;
    },
    addPlanToCart: (
      state,
      { payload }: { payload: { newProductTypeId: string } }
    ) => {
      state.loading = true;
      state.error = null;
    },
    saveCart: (state, { payload }: { payload: ShoppingCartResponse }) => {
      state.loading = false;
      state.cart = payload;
      state.requiresWorkOrder = payload.requiresWorkOrder;
      state.droppedAddOns = payload.droppedAddOns;
      state.error = null;
    },
    submitOrder: (
      state,
      { payload }: { payload: TransitionScheduleInput | undefined }
    ) => {
      state.loading = true;
      state.error = null;
    },
    setOrderSuccess: (
      state,
      {
        payload,
      }: {
        payload: {
          isSuccess: boolean;
          appointment: TransitionScheduleOutput | null;
        };
      }
    ) => {
      state.loading = false;
      state.orderSuccess = payload.isSuccess;

      if (payload.appointment) {
        state.installationAppointment = payload.appointment;
      }

      state.error = null;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    setChangePlanStep: (state, { payload }: { payload: ChangePlanSteps }) => {
      state.step = payload;
    },
    setSelectedPlanId: (state, { payload }) => {
      state.selectedPlanId = payload;
    },
    reset: (state) => {
      state.loading = false;
      state.planOffersData = null;
      state.requiresWorkOrder = false;
      state.error = null;
    },
    toggleBroadbandLabels: (state) => {
      state.hideBroadbandLabels = !state.hideBroadbandLabels;
    },
    resetHideBroadbandLabels: (state) => {
      state.hideBroadbandLabels = false;
    },
    getHasPendingTransition: (state) => {
      state.hasPendingTransitionLoading = true;
    },
    setHasPendingTransition: (state, action) => {
      state.hasPendingTransition = action.payload;
      state.hasPendingTransitionLoading = false;
    },
    setHasActivationError: (state, { payload }) => {
      state.hasActivationError = payload;
    },
    // This was moved here from the old planInfoSlice
    updateDataStillLoading: (state, { payload }: { payload: boolean }) => {
      state.hasPendingTransition = payload;
    },
    setHasPendingTransitionError: (state, action) => {
      state.hasPendingTransitionError = action.payload;
      state.hasPendingTransitionLoading = false;
    },
  },
});

export const {
  getPlans,
  setPlans,
  refetchPlans,
  setError,
  addPlanToCart,
  submitOrder,
  setOrderSuccess,
  setChangePlanStep,
  setSelectedPlanId,
  saveCart,
  reset,
  toggleBroadbandLabels,
  resetHideBroadbandLabels,
  getHasPendingTransition,
  setHasPendingTransition,
  updateDataStillLoading,
  setHasPendingTransitionError,
  setHasActivationError,
} = changePlanSlice.actions;

export const selectChangePlan = (state: {
  changePlan: ChangePlanOffersState;
}) => state.changePlan;

export const selectChosenPlan = (state: {
  changePlan: ChangePlanOffersState;
}) =>
  state.changePlan.planOffersData?.find(
    (plan) => plan.id === state.changePlan.selectedPlanId
  ) as GetPlanOffersPayload;
