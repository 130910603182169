import {
  all,
  call,
  put,
  spawn,
  takeEvery,
  takeLatest,
  select,
  delay,
  take,
} from 'redux-saga/effects';
import { addMonths } from 'date-fns';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  graphqlQuery,
  graphqlQueryWithErrors,
  FetchWithErrorsQuery,
  evictFromCache,
  readApolloCacheQueryFragment,
  writeApolloCacheQueryFragment,
} from '@mfe/shared/redux/graphql';
import i18n from '@mfe/services/translations-service';
import {
  BillingAccount,
  Locale,
  NotificationLevel,
  Query,
} from '@mfe/shared/schema-types';

import { setAlerts } from '../alerts';
import { waitForToken } from '../utils/utilsSagas';
import {
  requestBoleto as requestBoletoAction,
  fetchBillingInfo as fetchBillingInfoAction,
  fetchInvoicePdf as fetchInvoicePDFAction,
  setBillingInfo,
  setBoletoInfo,
  triggerGetOrders,
  setOrders,
  refetchBillingInfo,
  setBillingApiErrors,
  selectBillingInfo,
  setPendingPayments,
  fetchPaymentsHistory,
  fetchPendingPayments,
  setPaymentsHistory,
  setPaymentsHistoryError,
  startLoading,
  setOverviewLoading,
  setBillingLoading,
  selectBillingAccount,
  BillingInfoState,
} from './billingInfoSlice';
import {
  GET_AGGREGATED_BILLING_INFO,
  GET_PAYMENT_HISTORY,
  GET_ORDERS_BY_CUSTOMER_RELATIONSHIP_ID,
  GET_PENDING_PAYMENTS,
  GET_BOLETO_VALUE,
  SEND_BOLETO_EMAIL,
  GET_BILLING_ACCOUNT,
  GET_INVOICE_PDF,
  AUTOPAY_REFETCH_TRUE_FRAGMENT,
  AUTOPAY_REFETCH_FALSE_FRAGMENT,
} from './queriesAndMutations';
import { selectLocale, setLocaleState } from '../locale';
import { parseInvoices } from './utils';

function* fetchBillingAccount() {
  yield call(waitForToken);
  yield put(startLoading());

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_BILLING_ACCOUNT,
    variables: { refetchData: true },
    fetchPolicy: 'network-only',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError ?? errors) {
    yield put(setBillingApiErrors(runtimeError ?? errors));
    return;
  }

  yield put(setBillingInfo({ billingAccount: data?.getBillingAccount }));
}

function* fetchOrders(payload?: { refetchData?: boolean }) {
  yield call(waitForToken);

  const query: Query = yield call(graphqlQuery, {
    query: GET_ORDERS_BY_CUSTOMER_RELATIONSHIP_ID,
    variables: { refetchData: payload?.refetchData },
    fetchPolicy: payload?.refetchData ? 'network-only' : 'cache-first',
  });

  yield put(setOrders(query?.getOrdersByCustomerRelationshipId ?? []));
}

function* fetchPayments(payload?: { refetchData?: boolean }) {
  yield call(waitForToken);
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PAYMENT_HISTORY,
    variables: { refetchData: payload?.refetchData },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (errors || runtimeError) {
    yield put(setPaymentsHistoryError(runtimeError ?? errors));
    return;
  }

  yield put(setPaymentsHistory(data?.getPaymentHistory ?? []));
}

function* queryPendingPayments(payload?: { refetchData?: boolean }) {
  yield call(waitForToken);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PENDING_PAYMENTS,
    variables: { refetchData: payload?.refetchData },
    fetchPolicy: payload?.refetchData ? 'network-only' : 'cache-first',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (errors || runtimeError) {
    yield put(setPaymentsHistoryError(runtimeError ?? errors));
    return;
  }

  yield put(setPendingPayments(data?.getPendingPayments ?? []));
}

export function* getNextBillPeriodStartDate() {
  const {
    billingInfo: { billingAccount },
  } = yield select(selectBillingInfo);

  if (!billingAccount) {
    yield call(fetchBillingAccount);
  }

  const {
    billingInfo: {
      billingAccount: { nextBillPeriodStartDate },
    },
  } = yield select(selectBillingInfo);

  return nextBillPeriodStartDate;
}

function* refetchOrders() {
  yield spawn(function* () {
    yield fetchOrders();
  });
}

export function* refetchPaymentsHistory(payload: any, data: any) {
  yield spawn(function* () {
    yield all([fetchPayments({ refetchData: true })]);
    yield put(
      setBoletoInfo({
        invoiceNumber: payload.invoiceNumber,
        loading: false,
        boletoData: data.getBoletoValue,
      })
    );
  });
}

export function* requestBoleto({ payload }: any) {
  if (!payload) {
    return;
  }
  yield put(
    setBoletoInfo({
      invoiceNumber: payload.invoiceNumber,
      loading: true,
      isError: undefined,
    })
  );

  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_BOLETO_VALUE,
      variables: {
        input: {
          invoiceNum: payload.invoiceNumber,
          txnType: 'Sale',
          txnAmount: {
            alphabeticCode: payload?.invoiceAmount?.alphabeticCode,
            value: payload?.invoiceAmount?.value,
          },
          txnDueDate: addMonths(new Date(), 1).toISOString(),
        },
      },
    });

    if (data?.getBoletoValue) {
      yield call(refetchPaymentsHistory, payload, data);
      if (data?.getBoletoValue?.invoiceNum) {
        yield call(graphqlQuery, {
          query: SEND_BOLETO_EMAIL,
          variables: { invoiceNumber: data.getBoletoValue.invoiceNum },
        });
      }
      const boletoRequested = {
        level: NotificationLevel.Success,
        title: i18n.t('Alerts:Billing.BoletoRequested.title'),
      };
      yield put(setAlerts([boletoRequested]));
    } else {
      yield put(
        setBoletoInfo({
          invoiceNumber: payload.invoiceNumber,
          loading: false,
          isError: true,
        })
      );
    }
  } catch (error) {
    yield put(
      setBoletoInfo({
        invoiceNumber: payload.invoiceNumber,
        loading: false,
        isError: true,
      })
    );
  }
}

type Variables = {
  refetchData: boolean;
  includeAutopay: boolean;
  includeBillingAccount: boolean;
  includeInvoices: boolean;
  nextBillPeriodStartDate: string | null;
};

export function* computeVariablesForOverview(
  userLocale: Locale,
  refetchData?: boolean
) {
  const defaultVariables = {
    refetchData: refetchData ?? false,
    includeAutopay: true,
    includeBillingAccount: true,
    includeInvoices: true,
    nextBillPeriodStartDate: null,
  };

  const isBrazil = userLocale === Locale.PtBr;

  if (isBrazil) {
    const nextBillPeriodStartDate: string = yield call(
      getNextBillPeriodStartDate
    );

    return {
      ...defaultVariables,
      includeInvoices: true,
      includeBillingAccount: !nextBillPeriodStartDate,
      nextBillPeriodStartDate,
    };
  }

  return defaultVariables;
}

export function* fetchBillingInfoForOverview(refetchData?: boolean) {
  yield call(waitForToken);

  const { localeFetched } = yield select(selectLocale);
  if (!localeFetched) {
    yield take(setLocaleState);
  }

  yield put(setOverviewLoading(true));

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const variables: Variables = yield call(
    computeVariablesForOverview,
    userLocale,
    refetchData
  );

  yield call(fetchBillingInfo, variables, userLocale);
  yield put(setOverviewLoading(false));
}

export function computeVariablesForBilling(refetchData?: boolean) {
  const defaultVariables = {
    refetchData: refetchData ?? false,
    includeAutopay: true,
    includeBillingAccount: true,
    includeInvoices: true,
    nextBillPeriodStartDate: null,
  };

  return defaultVariables;
}

export function* fetchBillingInfoForBillingPage(refetchData?: boolean) {
  yield call(waitForToken);

  const { localeFetched } = yield select(selectLocale);
  if (!localeFetched) {
    yield take(setLocaleState);
  }

  yield put(setBillingLoading(true));

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const variables: Variables = yield call(
    computeVariablesForBilling,
    refetchData
  );

  yield call(fetchBillingInfo, variables, userLocale);
  yield put(setBillingLoading(false));
}

export function* fetchBillingInfo(variables: Variables, userLocale: Locale) {
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_AGGREGATED_BILLING_INFO,
    variables,
    fetchPolicy: variables?.refetchData ? 'network-only' : 'cache-first',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError ?? errors) {
    yield put(setBillingApiErrors(runtimeError ?? errors));
  }

  if (data) {
    const existingBillingAccount: BillingAccount = yield select(
      selectBillingAccount
    );

    const billingAccount = data.getBillingAccount ?? existingBillingAccount;

    const parsedInvoices = parseInvoices(data, userLocale);
    let totalBalance = billingAccount?.balance;
    if (userLocale === Locale.PtBr && parsedInvoices.invoices) {
      totalBalance = parsedInvoices.invoices[0]?.amountOwedOnInvoice;
    }

    yield put(
      setBillingInfo({
        autoPay: data.getAutopayMethod,
        billingAccount,
        invoices: parsedInvoices.invoices,
        invoicesLoading: false,
        summaryInvoiceStatus: parsedInvoices.summaryInvoiceStatus,
        totalBalance: totalBalance ?? {},
      })
    );
  }
}

export function* refetchDataOnBillingPage() {
  const {
    billingInfo: { autoPay: staleAutoPay },
  } = yield select(selectBillingInfo);

  yield put(startLoading());
  yield delay(3000);

  yield call(fetchBillingInfoForBillingPage, true);

  const newAutopay: BillingInfoState['billingInfo']['autoPay'] = yield call(
    readApolloCacheQueryFragment,
    AUTOPAY_REFETCH_TRUE_FRAGMENT
  );

  yield call(writeApolloCacheQueryFragment, {
    fragment: AUTOPAY_REFETCH_FALSE_FRAGMENT,
    data: newAutopay,
  });

  if (staleAutoPay) {
    const id = `${staleAutoPay?.__typename}:${staleAutoPay?.id}`;
    yield call(evictFromCache, { id });
  }
}

export function* getBillingInfo(action: PayloadAction<'overview' | 'billing'>) {
  const { payload } = action;

  if (payload === 'overview') {
    yield call(fetchBillingInfoForOverview);
  } else {
    yield call(fetchBillingInfoForBillingPage);
  }
}

function* fetchInvoicePDF(action: PayloadAction<string>) {
  const { payload: invoiceDate } = action;
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_INVOICE_PDF,
    variables: { input: { invoiceDate } },
  });
  const invoicePDF = apiResponse?.data?.getInvoicePDF?.invoicePDF ?? undefined;

  yield put(setBillingInfo({ invoicePdf: invoicePDF }));
}

export function* watchBillingInfo() {
  yield spawn(function* () {
    yield all([
      takeLatest(fetchInvoicePDFAction.type, fetchInvoicePDF),
      takeLatest(fetchBillingInfoAction.type, getBillingInfo),

      takeLatest(refetchBillingInfo.type, refetchDataOnBillingPage),

      takeLatest(triggerGetOrders.type, refetchOrders),
      takeEvery(requestBoletoAction.type, requestBoleto),
      takeLatest(fetchPaymentsHistory.type, fetchPayments),
      takeLatest(fetchPendingPayments.type, queryPendingPayments),
    ]);
  });
}
