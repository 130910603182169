import { useSelector } from 'react-redux';

import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';

export const useHasAddOns = () => {
  const { addOns } = useSelector(selectAddOns);
  const { hasStreamOnActive } = useSelector(selectStreamOnPromo);

  return {
    hasAddOns:
      addOns.hasVoice ||
      addOns.hasEasyCare ||
      addOns.hasShield ||
      addOns.hasStaticIP ||
      addOns.hasOfficeHours ||
      addOns.hasStreamOn ||
      hasStreamOnActive,
    numberOfAddons: [
      hasStreamOnActive,
      addOns.hasEasyCare,
      addOns.hasOfficeHours,
      addOns.hasShield,
      addOns.hasStaticIP,
      addOns.hasVoice,
      addOns.hasStreamOn,
    ].filter(Boolean).length,
  };
};
