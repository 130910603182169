import styles from './modal.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@vst/beam';

export interface FooterButtonProps {
  buttonText: string;
  onClick?(event?: React.MouseEvent<HTMLElement>): void;
  href?: string;
}

interface ModalFooterProps {
  footerButtons: Array<FooterButtonProps | undefined>;
  closeModal?(): void;
}

export const ModalFooter = ({
  footerButtons,
  closeModal,
}: ModalFooterProps): JSX.Element => {
  const { t } = useTranslation('NewProfile');

  return (
    <div className={styles['footer']}>
      {footerButtons?.[1] && (
        <Button
          variant="primary"
          buttonSize="medium"
          onClick={footerButtons?.[1].onClick ?? closeModal}
        >
          {t(footerButtons?.[1].buttonText)}
        </Button>
      )}
      {footerButtons?.[0] && (
        <Button
          variant="secondary"
          buttonSize="medium"
          onClick={footerButtons?.[0].onClick ?? closeModal}
        >
          {t(footerButtons?.[0].buttonText)}
        </Button>
      )}
    </div>
  );
};
