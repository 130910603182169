/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { Platform } from '@mfe/shared/schema-types';

export interface PushNotificationState {
  enabled: boolean;
}

export const initialPushNotificationState: PushNotificationState = {
  enabled: false,
};

export const pushNotificationsSlice = createSlice({
  name: 'pushNotifications',
  initialState: initialPushNotificationState,
  reducers: {
    getPushNotificationState: (
      state,
      _: { payload: { accountNumber: string } }
    ) => state,
    persistPushNotificationState: (
      state,
      _: {
        payload: {
          shouldSubscribeToPushNotifications: boolean;
          accountNumber: string;
          platform: Platform;
          oldNotificationId?: string;
          newNotificationId?: string;
        };
      }
    ) => state,
    setPushNotificationState: (
      state,
      { payload }: { payload: { enabled: boolean } }
    ) => {
      state.enabled = payload.enabled;
    },
    // For handling legacy. Can be deleted after PWA has been deployed for some time
    receivePushNotificationState: (
      state,
      _: {
        payload: {
          enabled: boolean;
          accountNumber: string;
          platform: Platform;
        };
      }
    ) => state,
  },
});

export const {
  getPushNotificationState,
  persistPushNotificationState,
  setPushNotificationState,
  receivePushNotificationState,
} = pushNotificationsSlice.actions;

export const selectPushNotifications = (state: {
  pushNotifications: PushNotificationState;
}) => state.pushNotifications;
