import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Txt } from '@vst/beam';

import myversion from '@mfe/legacy/mv/myversion.json';

import { selectConfig } from '@mfe/shared/redux/config';

export const Version = () => {
  const { t } = useTranslation('Footer');

  const { env } = useSelector(selectConfig);

  const showVersionHash = env !== 'PROD' && env !== 'PREPROD';

  return (
    <Txt variant="smallRegular" component="span" data-cy="footer-version">
      {t(showVersionHash ? 'versionHash' : 'version', {
        versionNumber: myversion.version,
        hash: myversion.hash,
      })}
    </Txt>
  );
};
