import { ApolloQueryResult } from '@apollo/client';

import {
  BadgeStatus,
  Invoice,
  InvoiceStatus,
  Locale,
  Query,
  SettlementStatus,
} from '@mfe/shared/schema-types';

import { BillingInfo } from './billingInfoSlice';

interface GetBadgeStatusProps {
  invoiceStatus?: InvoiceStatus | null;
  invoiceSettlementStatus?: SettlementStatus | null;
}

const getBadgeStatus = ({
  invoiceStatus,
  invoiceSettlementStatus,
}: GetBadgeStatusProps): BadgeStatus | null => {
  if (invoiceStatus === InvoiceStatus.Paid) {
    return BadgeStatus.Paid;
  }
  if (invoiceStatus === InvoiceStatus.Unpaid) {
    if (invoiceSettlementStatus === SettlementStatus.PastDue) {
      return BadgeStatus.PastDue;
    }
    if (invoiceSettlementStatus === SettlementStatus.Issued) {
      return BadgeStatus.AwaitingPayment;
    }
    if (invoiceSettlementStatus === SettlementStatus.NoPaymentDue) {
      return BadgeStatus.NoPaymentDue;
    }
  }
  if (invoiceStatus === InvoiceStatus.Pending) {
    return BadgeStatus.Pending;
  }
  return null;
};

enum BadgeStatusPriority {
  Paid,
  Pending,
  AwaitingPayment,
  PastDue,
}

const getInvoiceStatusPriority = (invoices: Invoice[]): number[] => {
  const invoiceStatusPriority: number[] = invoices.map((invoice) => {
    switch (invoice.badgeStatus) {
      case BadgeStatus.Pending:
        return BadgeStatusPriority.Pending;
      case BadgeStatus.AwaitingPayment:
        return BadgeStatusPriority.AwaitingPayment;
      case BadgeStatus.PastDue:
        return BadgeStatusPriority.PastDue;
      case BadgeStatus.Paid:
      default:
        return BadgeStatusPriority.Paid;
    }
  });
  return invoiceStatusPriority;
};

const getSummaryInvoiceStatus = (
  invoices: Invoice[] | undefined,
  locale?: Locale
): BadgeStatus | null => {
  if (locale === Locale.PtBr && invoices) {
    if (!invoices?.[0]) return null;

    const mostRecentInvoice = [invoices?.[0]];
    const mostRecentInvoiceStatus = getInvoiceStatusPriority(
      mostRecentInvoice ?? []
    )[0];
    return BadgeStatusPriority[mostRecentInvoiceStatus] as BadgeStatus;
  }

  let invoiceStatusPriorityArray: number[] | [] = [];
  let summaryInvoiceStatusPriority = 0;
  if (invoices) {
    invoiceStatusPriorityArray = getInvoiceStatusPriority(invoices);
  }
  invoiceStatusPriorityArray?.forEach((priority) => {
    if (summaryInvoiceStatusPriority < priority) {
      summaryInvoiceStatusPriority = priority;
    }
  });

  return BadgeStatusPriority[summaryInvoiceStatusPriority] as BadgeStatus;
};

export const parseInvoices = (
  data: Partial<ApolloQueryResult<Query>['data']>,
  locale?: Locale
): {
  invoices: BillingInfo['invoices'] | undefined;
  summaryInvoiceStatus: BadgeStatus | null;
} => {
  const invoices = data.getInvoices;
  const parsedInvoices = invoices?.map((invoice) => {
    return {
      ...invoice,
      badgeStatus: getBadgeStatus({
        invoiceStatus: invoice.invoiceStatus,
        invoiceSettlementStatus: invoice.invoiceSettlementStatus,
      }),
    };
  });

  return {
    invoices: parsedInvoices ?? [],
    summaryInvoiceStatus: getSummaryInvoiceStatus(parsedInvoices, locale),
  };
};
