import { Surface } from '@vst/beam';
import { SpacingTypes } from '@vst/beam/src/react/common';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './Container.module.scss';

export const Container = ({
  children,
  mt,
  mb,
  mx,
}: {
  children: React.ReactNode;
  mt?: SpacingTypes;
  mb?: SpacingTypes;
  mx?: SpacingTypes;
}) => {
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  return (
    <Surface
      mt={mt}
      mb={mb}
      mx={mx}
      className={styles['container']}
      variant={isMobile ? 'primary' : 'secondary'}
    >
      {children}
    </Surface>
  );
};
