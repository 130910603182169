import {
  DisplayLanguage,
  Translations,
} from '@mfe/services/translations-service';
import enUS from './en-US/translations.json';
import itIT from './it-IT/translations.json';

export const streamOnTranslations: Translations = [
  'StreamOnPromo',
  {
    [DisplayLanguage.English]: enUS.StreamOnPromo,
    [DisplayLanguage.Italian]: itIT.StreamOnPromo,
  },
];
