export const BOLETO = 'Boleto';
export const SEPA = 'SEPA';
export const STORAGE_ALERTS_KEY = 'alertsData';
export const CHANGE_PLAN_SUCCESS_FLOW = 'CHANGE_PLAN_SUCCESS_FLOW';
export const OFFICE_HOURS_TITLE_ADD_ON = 'Plus Unlimited Office Hours data';
export const VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID =
  '147e55f6-8f33-42b0-b868-6750cee05047';

export const PAYMENT_METHOD_NAME = {
  ACH: 'ACH',
  AmericanExpress: 'American Express',
  Boleto: 'Boleto',
  DinersClub: 'Diners Club',
  Discover: 'Discover',
  Elo: 'Elo',
  Hipercard: 'Hipercard',
  MasterCard: 'MasterCard',
  PayPal: 'PayPal',
  Pix: 'Pix',
  SEPA: 'SEPA',
  Visa: 'Visa',
} as const;

export const AUTOPAY_PREAUTH_AMOUNT = 2.01;

export const VOICE_ADDONS_KINDS = [
  'RESIDENTIAL_VOICE',
  'VOICE_ATA',
  'BUSINESS_VOICE',
  'BUSINESS_VOICE_L3',
];

export const EASY_CARE_ADDON_KINDS = ['EASY_CARE'];
export const SHIELD_ADDON_KINDS = ['SHIELD'];
export const STATIC_IP_ADDON_KINDS = ['STATIC_IP'];
export const OFFICE_HOURS_ADDON_KINDS = ['OFFICE_HOURS'];

export const ADDON_PRODUCT_KIND = {
  easyCare: 'bep.ofm.product-types.easy-care',
  shield: 'bep.ofm.package-types.shield',
  staticIp: 'bep.ofm.package-types.static-ip',
  voice: 'bep.ofm.product-types.satellite-internet-add-on',
  officeHours: 'bep.ofm.product-types.office-hours',
  viasatStream: 'bep.ofm.product-types.streamon',
} as const;
