import { useState, useMemo, createContext, useContext } from 'react';
import { ModalType, ModalTypeEnum } from '@mfe/shared/components';

type DialogTypeHandlers = {
  resetDialogType: () => void;
  setBuyMoreDialog: () => void;
};

export const useDialogType = () => {
  const [type, setType] = useState<ModalType>(null);

  const handlers = useMemo<DialogTypeHandlers>(
    () => ({
      setBuyMoreDialog: () => setType(ModalTypeEnum.BuyMore),
      resetDialogType: () => setType(null),
    }),
    []
  );

  return [type, handlers] as const;
};

export const ModalContext = createContext({
  dialogType: null as ModalType,
  dialogTypeHandlers: {} as DialogTypeHandlers,
});

export const useModalContext = (): {
  dialogType: ModalType;
  dialogTypeHandlers: DialogTypeHandlers;
} => useContext(ModalContext);
