import { Locale } from '@mfe/shared/schema-types';

class LocaleFetcher {
  localeFetcher: (() => Locale) | undefined;

  init(localeFetcher: () => Locale): void {
    this.localeFetcher = localeFetcher;
  }

  get locale() {
    if (!this.localeFetcher) {
      throw new Error('Must initialize locale fetcher before showing locale!');
    }

    return this.localeFetcher();
  }
}
export const userLocaleFetcher = new LocaleFetcher();
