import { Locale } from '@mfe/shared/schema-types';

export const formatPrice = (
  locale: Locale,
  price?: number | null,
  currency?: string | null,
  minimumFractionDigits?: number
) => {
  if (!currency || !price) return '';

  const formattedLocale = locale.replace('_', '-');

  return new Intl.NumberFormat(formattedLocale, {
    style: 'currency',
    currency,
    minimumFractionDigits: minimumFractionDigits,
  }).format(price);
};

export const formatPriceWithCurrency = (
  locale: Locale,
  price?: number | null,
  currency?: string | null,
  minimumFractionDigits?: number
) => {
  if (!currency) return '';

  const formattedLocale = locale.replace('_', '-');

  return new Intl.NumberFormat(formattedLocale, {
    style: 'currency',
    currency,
    minimumFractionDigits: minimumFractionDigits,
  }).format(price ?? 0);
};
