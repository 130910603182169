import { gql } from '@apollo/client';

export const GET_TRANSACTION_ID = gql`
  query getTransactionId($input: GetTransactionIdInput) {
    getTransactionId(input: $input) {
      transactionId
    }
  }
`;

export const MAKE_PAYMENT = gql`
  mutation makePayment($input: MakePaymentInput!) {
    makePayment(input: $input) {
      success
      error {
        code
        message
      }
    }
  }
`;

export const MODIFY_TRANSACTION = gql`
  mutation modifyTransaction($input: ModifyTransactionInput!) {
    modifyTransaction(input: $input) {
      success
      errorCode
      message
    }
  }
`;
