export enum TxnType {
  SALE = 'Sale',
  PREAUTHORIZE = 'Preauthorize',
}

export interface PartialTransaction {
  userAgent?: string;
  txnType: TxnType;
  txnAmount: number;
  systemName: string;
  customerRef?: string;
  currencyIsoCode?: string;
  recurringPayment?: boolean;
  billingAddress?: {
    houseNumberOrName?: string;
    street?: string;
    city?: string;
    stateOrProvince?: string;
    postalCode?: string;
    country?: string;
  };
  email?: string;
  languageCode?: string;
  additionalDetails?: {
    [key: string]: any;
  };
  cancelURL?: string;
  returnURL?: string;
  couponCode?: string;
  paymentSource?: string;
  enabledPaymentMethods: string[];
}

export interface PaymentMethod {
  id?: string;
  systemName?: string;
  displayName?: string;
  last4Digits?: string;
  ccType?: string;
  ccExpMonth?: number;
  ccExpYear?: number;
  isExpired?: boolean;
  useAsDefault?: boolean;
  useForRecurringPayment?: boolean;
  first6Digits?: string;
  paymentMethod?: string;
  paymentProduct?: string;
  nameOnCard?: string;
  // Populated on error
  message?: string;
  errorCode?: string;
}

export interface SuccessOrErrorOutput {
  success: boolean;
  // Populated on error
  message?: string;
  errorCode?: string;
}

export interface CreateTransactionOutput {
  id: string;
  // Both of the below are only populated on error
  message?: string;
  errorCode?: string;
}

export interface DoSaleOutput {
  txnStatus: string;
  paymentOnFileId?: string;
  authCode?: string;
  pgResponseId?: string;
  relatedInfo: {
    [key: string]: any;
  };
  // Populated on error
  message?: string;
  errorCode?: string;
}

export interface GetPaymentMethodsInput {
  systemName?: string;
  customerRef?: string;
}

export interface GetPaymentMethodsOutput {
  pofDetails: PaymentMethod[];
}

export interface Transaction extends PartialTransaction {
  last4Digits?: string;
  secondaryTxns: any[];
  paymentSource?: string;
  paymentMethod?: string;
  parentTxn?: string;
  createdDate: string;
  LastModifiedDate: string;
  txnStatus: string;
  paymentOnFileId?: string;
  saveCard?: boolean;
  correlationId?: string;
  retryStatus?: null | 'Pending' | 'Success' | 'Failure' | 'Canceled';
  // Populated on error
  message?: string;
  errorCode?: string;
  txnId: string;
}

export interface UpdatePaymentMethodInput {
  displayName?: string;
  ccExpMonth?: number;
  ccExpYear?: number;
  useAsDefault?: boolean;
  useForRecurringPayment?: boolean;
}
