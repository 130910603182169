import { gql } from '@apollo/client';

export const GET_FULFILLMENT_AGREEMENT_ID = gql`
  query getWorkOrderFulfillmentAgreementId($externalWorkOrderId: ID) {
    getWorkOrderFulfillmentAgreementId(
      externalWorkOrderId: $externalWorkOrderId
    ) {
      fulfillmentAgreementId
    }
  }
`;

export const GET_WORK_ORDERS = gql`
  query getWorkOrders {
    getWorkOrders {
      workOrders {
        workOrder {
          externalWorkOrderId
          workOrderType {
            workOrderType
          }
          schedule {
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_AVAILABLE_INSTALLATION_DATES = gql`
  query getAvailableInstallationDates(
    $input: GetAvailableInstallationDatesInput
  ) {
    getAvailableInstallationDates(input: $input) {
      timezone
      schedules {
        to
        from
      }
    }
  }
`;

export const UPSERT_WORK_ORDER_SCHEDULE = gql`
  mutation upsertWorkOrderSchedule($input: UpsertWorkOrderScheduleInput!) {
    upsertWorkOrderSchedule(input: $input) {
      externalWorkOrderId
      success
    }
  }
`;
