import * as React from 'react';

import styles from './styles.module.scss';

// TODO: Check if this can be removed in favour of a BEAM Surface
export const Surface = ({ children }: { children: any }) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  return (
    <div data-cy="surface" ref={modalRef} className={styles['surface']}>
      {children}
    </div>
  );
};
