import { Form as BeamForm, IFormProps } from '@vst/beam';
import { FormEvent } from 'react';

interface ScheduleInstallationFormProps
  extends Pick<IFormProps, 'children' | 'initialValues'> {
  className?: string;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export const ScheduleInstallationForm = ({
  children,
  className,
  initialValues,
  handleSubmit,
}: ScheduleInstallationFormProps) => {
  return (
    <BeamForm
      id="schedule-installation-form"
      data-cy="schedule-installation-form"
      name="schedule-installation-form"
      validationMode="onChange"
      className={className}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {children}
    </BeamForm>
  );
};
