import {
  DisplayLanguage,
  Translations,
} from '@mfe/services/translations-service';
import enUS from './en-US/translations.json';

export const networkTranslations: Translations = [
  'Network',
  {
    [DisplayLanguage.English]: enUS.Network,
  },
];
