import { NotificationLevel } from '@mfe/shared/schema-types';
import { createSlice } from '@reduxjs/toolkit';

export enum AlertAction {
  GoToBilling = 'GoToBilling',
  ScheduleInstall = 'ScheduleInstall',
  RescheduleServiceCall = 'RescheduleServiceCall',
  BuyMore = 'BuyMore',
  PlanUsage = 'PlanUsage',
  AutoPay = 'AutoPay',
  OneTimePayment = 'OneTimePayment',
  Refresh = 'Refresh',
}

export type AlertButton = {
  action?: AlertAction;
  url?: string;
  label: string;
  openInSameTab?: boolean;
};
export interface Alert {
  level: NotificationLevel;
  title?: string;
  caption?: string;
  button?: AlertButton;
  link?: AlertButton;
  customDescription?: string;
  type?: 'local' | 'global';
}

export interface AlertsState {
  loading: boolean;
  alerts: Alert[];
  billingAlerts: Alert[];
}

export const initialAlertsState: AlertsState = {
  loading: true,
  alerts: [],
  billingAlerts: [],
};

export const alertsSlice = createSlice({
  name: 'alertsState',
  initialState: initialAlertsState,
  reducers: {
    setAlerts: (state, { payload }: { payload: Alert[] }) => {
      const newAlerts = payload.filter(
        (alert) => !state.alerts.find((a) => a.title === alert.title)
      );
      return {
        ...state,
        alerts: [...state.alerts, ...newAlerts],
        loading: false,
      };
    },
    setBillingAlerts: (state, { payload }: { payload: Alert[] }) => {
      const newAlerts = payload.filter(
        (alert) => !state.billingAlerts.find((a) => a.title === alert.title)
      );
      return {
        ...state,
        billingAlerts: [...state.billingAlerts, ...newAlerts],
        loading: false,
      };
    },
    removeAlert: (state, { payload }: { payload: Alert['title'] }) => ({
      ...state,
      alerts: state.alerts.filter((alert) => alert.title !== payload),
      billingAlerts: state.billingAlerts.filter(
        (alert) => alert.title !== payload
      ),
    }),
    resetAlerts: () => initialAlertsState,
    fetchAccountAlerts: (state) => state,
  },
});

export const {
  setBillingAlerts,
  setAlerts,
  removeAlert,
  resetAlerts,
  fetchAccountAlerts,
} = alertsSlice.actions;

export const selectAlerts = (state: { alertsState: AlertsState }) =>
  state.alertsState;
