import { buildIgluSchema } from '@mfe/services/analytics';

import {
  AnalyticsVendor,
  AnalyticsEventName,
  EventSchemaVersion,
  ContextSchemaVersion,
} from './types';
import {
  ANALYTICS_VENDOR,
  EVENT_SCHEMA_VERSION,
  CONTEXT_SCHEMA_VERSION,
} from './constants';

type SchemaFactory = (
  eventName: AnalyticsEventName,
  version: EventSchemaVersion | ContextSchemaVersion
) => string;

type EventSchemaFactory = (eventName: AnalyticsEventName) => string;
type ContextSchemaFactory = () => string;

export const schemaFactory: SchemaFactory = (eventName, version) =>
  buildIgluSchema<
    AnalyticsVendor,
    AnalyticsEventName,
    EventSchemaVersion | ContextSchemaVersion
  >(ANALYTICS_VENDOR, eventName, version);

export const eventSchemaFactory: EventSchemaFactory = (eventName) => {
  const eventsWithNewSchemaVersion = [
    AnalyticsEventName.BUY_MORE_INITIATED,
    AnalyticsEventName.BUY_MORE_ORDER_SUBMITTED,
  ];

  if (eventsWithNewSchemaVersion.includes(eventName)) {
    return schemaFactory(eventName, EVENT_SCHEMA_VERSION['1-0-1']);
  }

  return schemaFactory(eventName, EVENT_SCHEMA_VERSION['1-0-0']);
};

export const contextSchemaFactory: ContextSchemaFactory = () =>
  schemaFactory(AnalyticsEventName.BUY_MORE_CONTEXT, CONTEXT_SCHEMA_VERSION);
