import React from 'react';
import styles from './card.module.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  testID?: string;
}

export const Card = ({
  children,
  style,
  testID,
  className,
  ...otherProps
}: CardProps): JSX.Element => {
  return (
    <div
      className={`${styles['container']} ${className}`}
      data-cy={testID}
      {...otherProps}
      style={style}
    >
      {children}
    </div>
  );
};
