import { Invoice } from '@mfe/shared/schema-types';
import { createSlice } from '@reduxjs/toolkit';

export enum Route {
  Home = 'Home',
  Payments = 'Payments',
  OneTimePayment = 'OneTimePayment',
  Autopay = 'Autopay',
}

interface Props {
  invoice?: Invoice | null;
}

export interface BillingNavState {
  route: Route;
  props: Props;
}

export const initialState: BillingNavState = {
  route: Route.Home,
  props: {},
};

export const billingNavSlice = createSlice({
  name: 'billingNav',
  initialState,
  reducers: {
    setBillingNav: (
      state,
      { payload }: { payload: Partial<BillingNavState> }
    ) => ({
      route: payload?.route ? payload.route : state.route,
      props: payload.props ?? {},
    }),
    resetBillingNav: () => initialState,
  },
});

export const { setBillingNav, resetBillingNav } = billingNavSlice.actions;

export const selectBillingNav = (state: { billingNav: BillingNavState }) =>
  state.billingNav;
