import {
  AnalyticsVendor,
  EventSchemaVersion,
  ContextSchemaVersion,
} from './types';

export const ANALYTICS_VENDOR: AnalyticsVendor = 'com.viasat.care.buymore';

export const EVENT_SCHEMA_VERSION: Record<
  EventSchemaVersion,
  EventSchemaVersion
> = {
  '1-0-0': '1-0-0',
  '1-0-1': '1-0-1',
};

export const CONTEXT_SCHEMA_VERSION: ContextSchemaVersion = '1-0-1';
export const CONTEXT_UI = 'hornet';
