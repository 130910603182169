import React from 'react';
import { Txt, ButtonLink } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';

import styles from './article-card.module.scss';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { useDispatch } from 'react-redux';

interface ArticleProps {
  image: JSX.Element;
  title: string;
  caption: string;
  link?: { text: string; url: string };
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  extraContent?: JSX.Element;
  isPortrait?: boolean;
}

export const ArticleCard = ({
  title,
  caption,
  image,
  link,
  onClick,
  extraContent,
  isPortrait,
}: ArticleProps): JSX.Element => {
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMediumOrLarger = !(isSmall || isExtraSmall);

  const articleIllustration = (
    <div data-cy="article-card-image" className={styles['articleCardImage']}>
      {image}
    </div>
  );

  const classNames = [styles['articleCardLayout']];

  if (isPortrait) {
    classNames.push(styles['articleCardLayoutPortrait']);
  }

  const className = classNames.join(' ');

  return (
    <div data-cy="article-card" className={className}>
      {isMediumOrLarger && articleIllustration}
      <div data-cy="article-card-body" className={styles['articleCardContent']}>
        <Txt variant="bodyLargeBold">{title}</Txt>
        <Txt variant="bodyLargeRegular">{caption}</Txt>
        {extraContent}
        {link && (
          <ButtonLink
            data-cy="article-card-link"
            href={link.url}
            label={link.text}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};

type Question = {
  title: string;
  caption: string;
  linkText?: string;
  websiteLink?: string;
};

type QuestionToArticleProps = Omit<ArticleProps, 'image'>;

const getCharacterLimit = (
  isMedium: boolean,
  isSmallOrSmaller: boolean
): number => {
  if (isMedium) return 300;
  if (isSmallOrSmaller) return 108;
  return 129;
};

const shortenString = (fullString: string, maxCount: number): string => {
  let substring = fullString;
  if (fullString.length > maxCount) {
    substring = fullString.substring(0, maxCount);
    substring = substring.concat('...');
  }
  return substring;
};

export const useQuestionArticleCardProps = (question: Question) => {
  const dispatch = useDispatch();

  const { isMedium, isSmall, isExtraSmall } = useScreenResolution();
  const isSmallOrSmaller = isSmall || isExtraSmall;
  const stringLength = getCharacterLimit(isMedium, isSmallOrSmaller);
  const cutOffText = false;

  const caption = React.useMemo(() => {
    if (question.caption && cutOffText) {
      return shortenString(question.caption, stringLength);
    }
    return question.caption;
  }, [cutOffText, question.caption, stringLength]);

  return React.useMemo(() => {
    const props: QuestionToArticleProps = {
      title: question.title,
      caption: caption,
    };

    if (question.linkText && question.websiteLink) {
      const websiteLink = question.websiteLink;

      props.link = {
        text: question.linkText,
        url: question.websiteLink,
      };

      props.onClick = (event) => {
        event.preventDefault();
        dispatch(openInNewTab(websiteLink));
      };
    }

    return props;
  }, [caption, dispatch, question]);
};
