import { Locale } from '@mfe/shared/schema-types';

interface DateFormats {
  full: string;
  full_precise: string;
  long: string;
  long_precise: string;
  medium: string;
  medium_precise: string;
  day: string;
  month: string;
  monthDay: string;
  monthYear: string;
  dayOfWeekDayMonth: string;
  hour: string;
}
interface PriceFormats {
  price: string;
  price_fixed: string;
}

interface LanguageFormatting {
  dateFormats: DateFormats;
  priceFormats: PriceFormats;
}

const enUS: LanguageFormatting = {
  dateFormats: {
    full: 'MMMM d, yyyy', // January 9, 2023
    full_precise: 'MMMM dd, yyyy', // January 9, 2023
    long: 'MMM d, yyyy', // Jan 9, 2023
    long_precise: 'MMM dd, yyyy', // Jan 09, 2023
    medium: 'MMM d', // Jan 9
    medium_precise: 'MMM dd', // Jan 09
    day: 'd', // 9
    month: 'MMMM', // January
    monthDay: 'MMMM d', // January 9
    monthYear: 'MMMM yyyy', // January 2023
    dayOfWeekDayMonth: 'EEE, MMM, dd', // Mon, Jan, 09
    hour: 'h aaa', // 11 am
  },
  priceFormats: {
    price: '$0,0[.]00',
    price_fixed: '$0,0.00',
  },
};
const ptBR: LanguageFormatting = {
  dateFormats: {
    full: 'dd/MM/yyyy', // 09/01/2023
    full_precise: 'dd/MM/yyyy', // 09/01/2023
    long: 'dd/MM/yyyy', // 09/01/2023
    long_precise: 'dd/MM/yyyy', // 09/01/2023
    medium: 'dd/MM', // 09/01
    medium_precise: 'dd/MM', // 09/01
    day: 'd', // 9
    month: 'MMMM', // January
    monthDay: 'dd/MM', // 09/01
    monthYear: 'MMMM yyyy', // January 2023
    dayOfWeekDayMonth: 'EEEEEE, dd/MM', // Mo 09/01
    hour: 'h aaa', // 11 am
  },
  priceFormats: {
    price: '$ 0,0[.]00',
    price_fixed: '$ 0,0.00',
  },
};
const itIT: LanguageFormatting = {
  dateFormats: {
    full: 'd MMMM yyyy', // 9 January 2023
    full_precise: 'dd MMMM yyyy', // 09 January 2023
    long: 'd MMM yyyy', // 9 Jan 2023
    long_precise: 'dd MMM yyyy', // 09 Jan 2023
    medium: 'd MMM', // 9 Jan
    medium_precise: 'dd MMM', // 09 Jan
    day: 'd', // 9
    month: 'MMMM', // January
    monthDay: 'd MMMM', // 09/01
    monthYear: 'MMMM yyyy', // January 2023
    dayOfWeekDayMonth: 'EEE, dd MMM', // Mon, 09 Jan
    hour: 'h aaa', // 11 am
  },
  priceFormats: {
    price: '$0,0[.]00',
    price_fixed: '$0,0.00',
  },
};
const es419: LanguageFormatting = enUS;

const formats: Record<Locale, LanguageFormatting> = {
  [Locale.EnUs]: enUS,
  [Locale.PtBr]: ptBR,
  [Locale.ItIt]: itIT,
  [Locale.EsMx]: es419,
};

export const getDateFormat = (locale: Locale, type: keyof DateFormats) => {
  return formats[locale]['dateFormats'][type];
};

// TODO: In the future we should use this getPriceFormat function for all our price displays
// rather than the weird stuff billing is doing today
export const getPriceFormat = (locale: Locale, type: keyof PriceFormats) => {
  return formats[locale]['priceFormats'][type];
};
