import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { userLocaleFetcher } from '@mfe/services/translations-service';

export const I18nLocaleProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  React.useEffect(() => {
    userLocaleFetcher.init(() => userLocale);
  }, [userLocale]);

  if (!userLocale) {
    return null;
  }

  return <>{props.children}</>;
};
