import { ApolloQueryResult, gql } from '@apollo/client';
import { takeLatest, call, put, spawn, all } from 'redux-saga/effects';
import { Query, StreamOnStatus } from '@mfe/shared/schema-types';
import {
  setStreamOn,
  StreamOnState,
  initialStreamOnState,
} from './streamOnSlice';
import { graphqlQuery } from '@mfe/shared/redux/graphql';
import { setStreamOnPageStatistics } from '../utils';
import { waitForToken } from '../utils/utilsSagas';

export const GET_STREAM_ON = gql`
  query getStreamOn {
    getStreamOnStatus {
      status
      macAddress
      zeroRatedGB
      shippingInfo {
        delivery {
          code
          date
          description
          status
        }
        toAddress {
          addressLine
          countryCode
          municipality
          postalCode
          region
        }
        trackingNumber
        trackingUrl
      }
    }
  }
`;

export interface GetStreamOnVariables {
  productInstanceId: string;
}

export const parseStreamOn = (
  data: Partial<ApolloQueryResult<Query>['data']>
): StreamOnState['streamOn'] => {
  const status = data?.getStreamOnStatus?.status ?? StreamOnStatus.Error;
  const macAddress = data?.getStreamOnStatus?.macAddress ?? '';
  const dataSavedGB = data?.getStreamOnStatus?.zeroRatedGB ?? 0;

  const shippingInfoRaw = data?.getStreamOnStatus?.shippingInfo;
  const trackingUrl = shippingInfoRaw?.trackingUrl ?? '';
  const trackingNumber = shippingInfoRaw?.trackingNumber ?? '';
  //put a comma in between address lines
  const addressLine = shippingInfoRaw?.toAddress?.addressLine?.join(', ');
  //seperate different address fields via a comma
  const deliveryAddress = `${addressLine}, ${shippingInfoRaw?.toAddress?.municipality}, ${shippingInfoRaw?.toAddress?.region} ${shippingInfoRaw?.toAddress?.postalCode}`;
  const shippingInfo = shippingInfoRaw
    ? {
        deliveryAddress,
        trackingUrl,
        trackingNumber,
      }
    : initialStreamOnState.streamOn.shippingInfo;

  return {
    hasStreamOn: true,
    status,
    macAddress,
    dataSavedGB,
    shippingInfo,
  };
};

export function* fetchStreamOn() {
  yield call(waitForToken);

  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_STREAM_ON,
    });

    yield put(setStreamOn(parseStreamOn(data)));
  } catch (err) {
    yield put(setStreamOn({ status: StreamOnStatus.RequestErrored }));
  }
}

export function* watchStreamOn() {
  yield spawn(function* () {
    yield all([takeLatest(setStreamOnPageStatistics.type, fetchStreamOn)]);
  });
}
