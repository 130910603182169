import { Storage } from '../index';
import { STORAGE_ALERTS_KEY } from './constants';

export const saveAlertNameInStorage = (key?: string) => {
  if (!key) {
    return;
  }
  const storageData = Storage.getItem(STORAGE_ALERTS_KEY) ?? {};
  storageData[key] = 'true';

  Storage.setItem(STORAGE_ALERTS_KEY, storageData);
};
