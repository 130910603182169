import { useSelector } from 'react-redux';

import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

export const useHasPlanChangePending = () => {
  const { hasPendingTransition, hasActivationError } =
    useSelector(selectChangePlan);

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  if (isPreInstall) {
    return false;
  }

  return hasActivationError || hasPendingTransition;
};
