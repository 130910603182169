import { createSlice } from '@reduxjs/toolkit';

interface ErrorMessage {
  operation: string;
  message: string[];
}

export interface ErrorState {
  error: ErrorMessage | null;
}

export const initialErrorState: ErrorState = {
  error: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: initialErrorState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
      return state;
    },
    clearError: (state, { payload }) => {
      state.error = null;
      return state;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export const selectError = (state: { error: ErrorState }) => state.error;
