import i18n from '@mfe/services/translations-service';
import { AddressType } from '@mfe/to-be-migrated/redux/updateAddress';
const PO_BOX = 'PO BOX';
const ZIPCODE_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/;
const STATE_REGEX = /^([^0-9]\s?)*$/;
const CITY_REGEX = STATE_REGEX;

type FieldType = 'addressLine1' | 'city' | 'state' | 'postalCode';

type ValidateAddressProps = {
  input: string;
  type: FieldType;
  addressType?: AddressType;
};

export function validateAddress(
  languageCode: string,
  { input, type, addressType }: ValidateAddressProps
): string | undefined {
  const t = i18n.getFixedT(languageCode, 'UpdateAddress');
  const trimmedInput = input.trim();
  if (trimmedInput.length === 0) return t('errors.empty', { context: type });

  switch (type) {
    case 'addressLine1':
      return addressType === 'shipping' && input.toUpperCase().includes(PO_BOX)
        ? t('errors.addressLine1')
        : undefined;
    case 'city':
      return !CITY_REGEX.test(trimmedInput) ? t('errors.city') : undefined;
    case 'state':
      return !STATE_REGEX.test(trimmedInput) ? t('errors.state') : undefined;
    case 'postalCode':
      return !ZIPCODE_REGEX.test(trimmedInput)
        ? t('errors.postalCode')
        : undefined;
    default:
      break;
  }

  return undefined;
}
