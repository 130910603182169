import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
} from '@mfe/shared/redux/graphql';
import {
  Addon,
  AddOnsPricesAndDiscounts,
  Address,
  AvailablePhoneNumber,
  DirectoryListType,
  GetScrubbedAddressPayload,
  ProductType,
} from '@mfe/shared/schema-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalType } from '../scrubbedAddress/scrubbedAddressSagas';
import {
  ScrubAddressProcessStatus,
  ScrubAddressRecommendation,
} from '@mfe/shared/graphql/PAL/types';

export enum AddOnType {
  Voice = 'Voice',
  EasyCare = 'EasyCare',
  Shield = 'Shield',
  StaticIP = 'StaticIP',
}

type ScrubbedE911Address = {
  rawAddress?: Omit<
    GetScrubbedAddressPayload,
    'coordinates' | 'country' | 'processStatus' | 'recommendation'
  > | null;
  formattedAddress: {
    formattedAddressLine1: string;
    formattedAddressLine2: string;
  } | null;
  processStatus?: ScrubAddressProcessStatus;
  recommendation?: ScrubAddressRecommendation;
};

export interface AddOnsState {
  loading: boolean;
  error: FetchWithErrorsQuery['errors'] | null;
  addOns: {
    hasEasyCare?: boolean;
    hasVoice?: boolean;
    hasShield?: boolean;
    hasStaticIP?: boolean;
    hasOfficeHours?: boolean;
    hasStreamOn?: boolean;
  };
  addOnsProductInstanceIds: string[];
  addOnsPricesAndDiscounts: AddOnsPricesAndDiscounts[];
  addOnsPricesAndDiscountsLoading?: boolean;
  availableAddons: {
    loading: boolean;
    error: FetchWithErrorsQuery['errors'] | null;
    productTypes: Array<ProductType> | null;
  };
  currentShopAddonId: string | null;
  currentAddons: Array<Addon> | null;
  availableNumbersLoading: boolean;
  availableNumbers: AvailablePhoneNumber[] | null;
  availableNumbersError: FetchWithErrorsQuery['errors'] | null;
  reserveNumberLoading: boolean;
  reserveNumberSuccess: boolean | null;
  reserveNumberError: FetchWithErrorsMutation['errors'] | null;
  configureAddonLoading: boolean;
  configureAddonSuccess: boolean | null;
  configureAddonError: FetchWithErrorsMutation['errors'] | null;
  voiceConfig: {
    postalCode: string;
    selectedPhoneNumber: string;
    emergencyAddress: {
      streetAddress: string; // should be line 1 of addressLines
      aptUnit?: string; // should be line 2 of addressLines
      cityAddress: string; // municipality
      stateProvince: string; // region
      zipCode: string; // postalCode
    };
    useServiceAddressForE911: boolean;
    callerId: string;
    blockCallerId: boolean;
    directoryListingType: DirectoryListType | null;
    publishDirectoryListAddress: boolean;
    showLastStepError: boolean;
  };
  showModal: ModalType;
  isScrubbingE911Loading: boolean;
  scrubbedE911Address: ScrubbedE911Address;
  errorE911: FetchWithErrorsQuery['errors'] | null;
  submitOrder: {
    loading: boolean;
    error: FetchWithErrorsQuery['errors'] | null;
    success: boolean;
  };
  addOnOrderId: string | null;
}

export const initialAddOnsState: AddOnsState = {
  loading: true,
  error: null,
  addOns: {},
  addOnsProductInstanceIds: [],
  addOnsPricesAndDiscounts: [],
  addOnsPricesAndDiscountsLoading: false,
  availableAddons: {
    loading: false,
    error: null,
    productTypes: null,
  },
  currentShopAddonId: null,
  currentAddons: null,
  availableNumbersLoading: false,
  availableNumbers: null,
  availableNumbersError: null,
  reserveNumberLoading: false,
  reserveNumberSuccess: null,
  reserveNumberError: null,
  configureAddonLoading: false,
  configureAddonSuccess: null,
  configureAddonError: null,
  voiceConfig: {
    postalCode: '',
    selectedPhoneNumber: '',
    emergencyAddress: {
      streetAddress: '',
      aptUnit: '',
      cityAddress: '',
      stateProvince: '',
      zipCode: '',
    },
    useServiceAddressForE911: true,
    callerId: '',
    blockCallerId: false,
    directoryListingType: null,
    publishDirectoryListAddress: false,
    showLastStepError: false,
  },
  showModal: null,
  isScrubbingE911Loading: false,
  scrubbedE911Address: {
    rawAddress: {
      addressLines: [],
      countryCode: '',
      municipality: '',
      postalCode: '',
      region: '',
    },
    formattedAddress: { formattedAddressLine1: '', formattedAddressLine2: '' },
    processStatus: undefined,
    recommendation: undefined,
  },
  errorE911: null,
  submitOrder: {
    loading: false,
    error: null,
    success: false,
  },
  addOnOrderId: null,
};

export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState: initialAddOnsState,
  reducers: {
    fetchStreamAddOnData: (state) => {
      state.loading = true;
    },
    fetchStreamAddOnDataFinished: (state) => {
      state.loading = false;
    },
    setAddOns: (
      state,
      { payload }: { payload: Partial<AddOnsState['addOns']> }
    ) => {
      state.loading = false;
      state.addOns = { ...state.addOns, ...payload };
    },
    clearAddOnsProductInstanceIds: (state) => {
      state.addOnsProductInstanceIds = [];
    },
    setAddOnsProductInstanceIds: (
      state,
      { payload }: { payload: string[] | [] }
    ) => {
      state.addOnsProductInstanceIds = payload;
    },
    loadingAddOnsPricesAndDiscounts: (state) => {
      state.addOnsPricesAndDiscountsLoading = true;
    },
    setAddOnsPricesAndDiscounts: (state, { payload }) => {
      state.loading = false;
      state.addOnsPricesAndDiscountsLoading = false;
      state.addOnsPricesAndDiscounts = payload;
    },
    setCurrentShopAddonId: (state, { payload }: { payload: string | null }) => {
      state.currentShopAddonId = payload;
    },
    setVoiceFormValues: (state, { payload }) => {
      state.voiceConfig = {
        ...state.voiceConfig,
        ...payload,
        emergencyAddress: {
          ...state.voiceConfig.emergencyAddress,
          ...payload.emergencyAddress,
        },
      };
    },
    setShowLastStepError: (state, { payload }: { payload: boolean }) => {
      state.voiceConfig.showLastStepError = payload;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.addOnsPricesAndDiscountsLoading = false;
      state.error = payload;
    },
    getPurchasableAddOns: (state) => {
      state.availableAddons.loading = true;
    },
    setPurchasableAddOns: (
      state,
      { payload }: { payload: Array<ProductType> }
    ) => {
      state.availableAddons.loading = false;
      state.availableAddons.productTypes = payload;
    },
    purchasableAddOnsError: (state, { payload }) => {
      state.availableAddons.loading = false;
      state.availableAddons.error = payload;
    },
    purchaseAddOn: (
      state,
      {
        payload,
      }: {
        payload: {
          productTypeId: string;
          shoppingCartId: string;
          voice?: string;
        };
      }
    ) => {
      state.submitOrder.loading = true;
    },

    purchaseAddOnSuccess: (state, { payload }) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
      state.submitOrder.success = payload;
    },

    setAddOnOrderId(state, { payload }: { payload: string | null }) {
      state.addOnOrderId = payload;
    },

    purchaseAddOnError: (state, { payload }) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = payload;
    },
    resetAddOns: () => initialAddOnsState,
    resetSubmit: (state) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
      state.submitOrder.success = false;
    },
    setCurrentAddons: (
      state,
      { payload }: { payload: Array<Addon> | null }
    ) => {
      state.currentAddons = payload;
    },
    getAvailableNumbers: (state, action) => {
      state.availableNumbersLoading = true;
      state.availableNumbersError = null;
    },
    setAvailableNumbers: (state, action) => {
      state.availableNumbers = action.payload;
      state.availableNumbersLoading = false;
    },
    setAvailableNumbersError: (state, action) => {
      state.availableNumbersLoading = false;
      state.availableNumbersError = action.payload;
    },
    reserveSelectedNumber: (state, action) => {
      state.voiceConfig.selectedPhoneNumber = action.payload;
      state.reserveNumberLoading = true;
    },
    setNumberReservationSuccess: (state) => {
      state.reserveNumberLoading = false;
      state.reserveNumberSuccess = true;
    },
    setNumberReservationError: (state, action) => {
      state.reserveNumberSuccess = false;
      state.reserveNumberLoading = false;
      state.reserveNumberError = action.payload;
    },
    scrubEmergencyAddress: (state, _action: PayloadAction<Address>) => {
      state.isScrubbingE911Loading = true;
    },
    setShowModal: (state, action: PayloadAction<ModalType>) => {
      state.showModal = action.payload;
    },
    setE911ScrubbedAddress: (
      state,
      {
        payload,
      }: {
        payload: Pick<AddOnsState, 'scrubbedE911Address'>;
      }
    ) => {
      state.isScrubbingE911Loading = false;
      state.scrubbedE911Address = payload.scrubbedE911Address;
    },
    setE911Error: (state, { payload }) => {
      state.isScrubbingE911Loading = false;
      state.errorE911 = payload;
    },
    resetE911ScrubbedAddress: (state) => {
      return {
        ...state,
        scrubbedE911Address: initialAddOnsState.scrubbedE911Address,
      };
    },
    setConfigureAddonSuccess: (state) => {
      state.configureAddonLoading = false;
      state.configureAddonSuccess = true;
    },
    setConfigureAddonError: (state, action) => {
      state.configureAddonLoading = false;
      state.configureAddonError = action.payload;
      state.configureAddonSuccess = false;
    },
    getAddOnsPricesAndDiscounts: (state) => state,
  },
});

export const {
  fetchStreamAddOnData,
  setAddOns,
  resetAddOns,
  setAddOnsProductInstanceIds,
  loadingAddOnsPricesAndDiscounts,
  setError,
  setAddOnsPricesAndDiscounts,
  setCurrentShopAddonId,
  setCurrentAddons,
  setVoiceFormValues,
  setShowLastStepError,
  getAvailableNumbers,
  setAvailableNumbers,
  setAvailableNumbersError,
  reserveSelectedNumber,
  setNumberReservationSuccess,
  setNumberReservationError,
  scrubEmergencyAddress,
  setShowModal,
  setE911ScrubbedAddress,
  setE911Error,
  resetE911ScrubbedAddress,
  setConfigureAddonError,
  setConfigureAddonSuccess,
  purchaseAddOn,
  purchaseAddOnSuccess,
  purchaseAddOnError,
  getPurchasableAddOns,
  setPurchasableAddOns,
  purchasableAddOnsError,
  resetSubmit,
  setAddOnOrderId,
  clearAddOnsProductInstanceIds,
  fetchStreamAddOnDataFinished,
  getAddOnsPricesAndDiscounts,
} = addOnsSlice.actions;

export const selectAddOns = (state: { addOns: AddOnsState }) => state.addOns;
export const selectCurrentShopAddon = (state: { addOns: AddOnsState }) => {
  const { currentShopAddonId } = state.addOns;

  const currentShopAddon = state.addOns.availableAddons.productTypes?.find(
    (addon) => addon.id === currentShopAddonId
  );

  if (!currentShopAddon) {
    return null;
  }

  return currentShopAddon;
};
