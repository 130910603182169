import { gql } from '@apollo/client';

export const GET_BROADBAND_LABEL_ATTRIBUTES = gql`
  query getBroadbandLabelAttributes {
    getBroadbandLabelAttributes {
      upi
      serviceAreaId
      satelliteName
      serviceId
      modemDeviceModel
    }
  }
`;
