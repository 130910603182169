import { gql } from '@apollo/client';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  selectUpdateAddress,
  setUpdateSuccessful,
  submitAddress as submitAddressType,
  updateAddress as updateAddressType,
} from './slice';
import {
  fetchScrubbedAddress,
  selectScrubbedAddress,
} from '../scrubbedAddress';
import { selectUserInfo, setUserInfo } from '../userInfo';
import { graphqlMutation } from '@mfe/shared/redux/graphql';
import {
  AccountType,
  ContactMethodLabels,
  Mutation,
  Role,
} from '@mfe/shared/schema-types';

export const UPDATE_CONTACT_INFO = gql`
  mutation updateContactInfo($input: UpdateAccountInfoInput!) {
    updateAccountInfo(input: $input) {
      address {
        billing {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
        shipping {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_INFO_SMB = gql`
  mutation updateContactInfoSMB($input: UpdateAccountInfoSMBInput!) {
    updateAccountInfoSMB(input: $input) {
      address {
        billing {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
        shipping {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
      }
    }
  }
`;

export function* submitAddress() {
  const {
    addressLine1,
    addressLine2,
    state,
    municipality,
    postalCode,
    countryCode,
  }: ReturnType<typeof selectUpdateAddress> = yield select(selectUpdateAddress);

  yield put(
    fetchScrubbedAddress({
      addressLines: [addressLine1, addressLine2],
      municipality,
      postalCode,
      region: state,
      countryCode,
    })
  );
}

export function* setAddress() {
  const {
    rawInput,
    scrubbedAddress,
  }: ReturnType<typeof selectScrubbedAddress> = yield select(
    selectScrubbedAddress
  );

  const {
    userInfo: { accountType },
  }: ReturnType<typeof selectUserInfo> = yield select(selectUserInfo);

  const {
    addressType,
    addressSelection,
  }: ReturnType<typeof selectUpdateAddress> = yield select(selectUpdateAddress);

  const address =
    addressSelection === 'original' ? rawInput : scrubbedAddress.rawAddress;
  const isSMB = accountType === AccountType.Smb;

  const addresses = [
    {
      addressLines: address?.addressLines ?? [''],
      countryCode: address?.countryCode ?? '',
      municipality: address?.municipality ?? '',
      postalCode: address?.postalCode ?? '',
      region: address?.region ?? '',
      labels:
        addressType === 'shipping'
          ? [ContactMethodLabels.Shipping]
          : [ContactMethodLabels.Invoice],
    },
  ];

  try {
    const identifier = isSMB ? 'updateAccountInfoSMB' : 'updateAccountInfo';
    const mutation = isSMB ? UPDATE_CONTACT_INFO_SMB : UPDATE_CONTACT_INFO;
    const response: Mutation = yield call(graphqlMutation, {
      mutation,
      variables: {
        input: {
          addresses,
          role: isSMB ? Role.Customer : undefined,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (response?.[identifier]?.address) {
      yield put(setUpdateSuccessful(true));
      yield put(
        setUserInfo({
          address: response?.[identifier]?.address,
        })
      );
    }
  } catch (error) {
    yield put(setUpdateSuccessful(false));
  }
}

export function* watchUpdateAddress() {
  yield all([
    takeLatest(submitAddressType.type, submitAddress),
    takeLatest(updateAddressType.type, setAddress),
  ]);
}
