/* eslint-disable @typescript-eslint/ban-ts-comment */
/* TODO move this folder to push notifications folder once https://jira.viasat.com/browse/ECJ-4820 is complete */
import {
  all,
  call,
  takeLatest,
  spawn,
  debounce,
  put,
} from 'redux-saga/effects';
import { gql } from '@apollo/client';
import {
  receivePushNotificationState,
  persistPushNotificationState,
  getPushNotificationState,
  setPushNotificationState,
} from './pushNotificationsSlice';
import {
  getPushNotifStateCookie,
  savePushNotifCookie,
} from './pushCookieHandlers';
import { graphqlMutation } from '@mfe/shared/redux/graphql';
import {
  UpdatePushNotificationStatusInput,
  Mutation,
} from '@mfe/shared/schema-types';

const UPDATE_PUSH_NOTIF_STATUS = gql`
  mutation updatePushNotificationStatus(
    $input: UpdatePushNotificationStatusInput!
  ) {
    updatePushNotificationStatus(input: $input) {
      success
      message
    }
  }
`;

function* getPushNotifStateSaga({
  payload,
}: {
  type: (typeof getPushNotificationState)['type'];
  payload: Parameters<typeof getPushNotificationState>[0];
}) {
  const enabled: ReturnType<typeof getPushNotifStateCookie> = yield call(
    getPushNotifStateCookie,
    payload
  );
  yield put(setPushNotificationState({ enabled }));
}

function* persistPushNotificationsStateSaga({
  payload,
}: {
  type: (typeof persistPushNotificationState)['type'];
  payload: Parameters<typeof persistPushNotificationState>[0];
}) {
  const {
    oldNotificationId,
    newNotificationId,
    shouldSubscribeToPushNotifications,
    platform,
  } = payload;

  yield put(
    setPushNotificationState({ enabled: shouldSubscribeToPushNotifications })
  );

  if (!oldNotificationId || !newNotificationId) return;

  const input: UpdatePushNotificationStatusInput = {
    oldNotificationId,
    newNotificationId,
    shouldSubscribeToPushNotifications,
    platform,
  };
  const data: Mutation = yield call(graphqlMutation, {
    mutation: UPDATE_PUSH_NOTIF_STATUS,
    variables: { input },
  });

  const wasEnabled = data.updatePushNotificationStatus?.success ?? false;
  yield call(savePushNotifCookie, { ...payload, enabled: wasEnabled });
  yield put(setPushNotificationState({ enabled: wasEnabled }));
}

function* receivePushNotificationsStateSaga({
  payload,
}: {
  type: (typeof receivePushNotificationState)['type'];
  payload: Parameters<typeof receivePushNotificationState>[0];
}) {
  yield call(savePushNotifCookie, payload);
  yield put(setPushNotificationState({ enabled: payload.enabled }));
}

export function* watchPushNotification() {
  yield spawn(function* () {
    yield all([
      debounce(
        1000,
        persistPushNotificationState.type,
        persistPushNotificationsStateSaga
      ),
      takeLatest(getPushNotificationState.type, getPushNotifStateSaga),
      takeLatest(
        receivePushNotificationState.type,
        receivePushNotificationsStateSaga
      ),
    ]);
  });
}

export {
  getPushNotificationState,
  receivePushNotificationState,
  pushNotificationsSlice,
  persistPushNotificationState,
  selectPushNotifications,
  setPushNotificationState,
} from './pushNotificationsSlice';
