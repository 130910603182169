import { createSlice } from '@reduxjs/toolkit';

import { GetPendingInstallationDetailsPayload } from '@mfe/shared/schema-types';

type PendingInstallationState = {
  loading: boolean;
  error: string | null;
  hasPendingInstallation: boolean;
  schedule: Pick<GetPendingInstallationDetailsPayload, 'schedule'> | null;
  newOfferName: Pick<
    GetPendingInstallationDetailsPayload,
    'newOfferName'
  > | null;
  isEquipmentShipped: boolean;
};

const initialState: PendingInstallationState = {
  loading: false,
  error: null,
  schedule: null,
  newOfferName: null,
  hasPendingInstallation: false,
  isEquipmentShipped: false,
};

export const pendingInstallationSlice = createSlice({
  name: 'pendingInstallation',
  initialState,
  reducers: {
    getPendingInstallation: (state) => state,
    setHasRescheduled: (state) => {
      state.loading = true;
    },
    setPendingInstallation: (state, action) => {
      state.loading = false;
      state.schedule = action.payload.schedule;
      state.newOfferName = action.payload.newOfferName;
      state.isEquipmentShipped = action.payload.isEquipmentShipped;
      state.hasPendingInstallation = action.payload.hasPendingInstallation;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPendingInstallation,
  setHasRescheduled,
  setPendingInstallation,
  setError: setPendingInstallationError,
} = pendingInstallationSlice.actions;

export const selectingPendingInstallation = (state: {
  pendingInstallation: any;
}) => state.pendingInstallation;
