import { InlineLink, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

export const AccountErrorDescription = ({ caption }: { caption: string }) => {
  const { t } = useTranslation(['Global', 'Alerts']);

  const phoneHref = `tel:${t('Global:callCustomerSupportPhoneNumber')}`;
  return (
    <Txt variant="bodySmallRegular" color="subtle">
      {caption}
      <InlineLink
        href={phoneHref}
        style={{ textDecoration: 'none' }}
        data-cy="alert-link"
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
    </Txt>
  );
};
