//pasted code from https://github.com/atomantic/is-ua-webview/blob/master/index.js
export const isWebview = (): boolean => {
  const webViewRules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to lollipop webview will put {version}.0.0.0
    'Android.*(wv|.0.0.0)',
    // old chrome android webview agent
    'Linux; U; Android',
  ];
  const webviewRegex = new RegExp('(' + webViewRules.join('|') + ')', 'ig');
  const ua = window.navigator.userAgent;
  return !!ua.match(webviewRegex);
};
