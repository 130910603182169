import { format, parseISO } from 'date-fns';

import { Schedule } from '@mfe/shared/schema-types';

type TimeSlotByDay = Record<string, Schedule[]>;

export const getTimeSlotsByDay = (
  schedules: Schedule[],
  dateFormat = 'yyyy-MM-dd'
) => {
  const timeSlotsByDay: TimeSlotByDay = {};

  schedules.forEach((schedule: Schedule) => {
    const { from, to } = schedule;

    if (from && isString(from) && to && isString(to)) {
      const dateKey = format(parseISO(from), dateFormat);

      if (!timeSlotsByDay[dateKey]) {
        timeSlotsByDay[dateKey] = [];
      }

      timeSlotsByDay[dateKey].push({ from, to });
    }
  });

  return timeSlotsByDay;
};

function isString(variable: unknown) {
  return typeof variable === 'string';
}
