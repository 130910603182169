import {
  ActionPattern,
  all,
  call,
  delay,
  put,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ApolloQueryResult, gql } from '@apollo/client';

import {
  AutopayMethod,
  NotificationLevel,
  OutageNotification,
  ProductInstanceStatus,
  Query,
  SummarizedWorkOrder,
} from '@mfe/shared/schema-types';
import { graphqlQuery } from '@mfe/shared/redux/graphql';
import i18n from '@mfe/services/translations-service';

import {
  BillingInfoState,
  selectBillingInfo,
  setBillingInfo,
} from '../billingInfo';
import { setLocaleState } from '../locale';
import { selectPlanCharacteristics } from '../plan';
import { waitForPlanCharacteristics, waitForUsage } from '../plan';
import { setBillingPageStatistics, setOverviewPageStatistics } from '../utils';
import { waitForToken } from '../utils/utilsSagas';
import {
  Alert,
  AlertAction,
  resetAlerts,
  setAlerts,
  setBillingAlerts,
  fetchAccountAlerts as fetchAccountAlertsAction,
} from './alertsSlice';
import { parseAccountAlerts } from './parseAccountAlerts';

import { selectUser } from '../auth';
import { waitForUserData } from '../auth/userSagas';
import { fetchHasPendingTransition } from '../changePlan/changePlanSagas';
import { selectChangePlan } from '../changePlan';
import { displayServiceCallAlert } from '../workOrders';
import { format } from 'date-fns';

export const GET_OUTAGE_NOTIFS = gql`
  query getOutageNotifications {
    getOutageNotifications {
      outages {
        level
        type
      }
    }
  }
`;

export const parseBillingAlerts = (autoPay?: AutopayMethod | null): Alert[] => {
  const alerts: Alert[] = [];

  if (!autoPay) {
    alerts.push({
      level: NotificationLevel.Warning,
      title: i18n.t('Alerts:Billing.NoValidPaymentMethod.title'),
      caption: i18n.t('Alerts:Billing.NoValidPaymentMethod.caption'),
      button: {
        action: AlertAction.AutoPay,
        label: i18n.t('Alerts:Billing.NoValidPaymentMethod.button.text'),
      },
    });
  } else if (autoPay?.isExpired) {
    alerts.push({
      level: NotificationLevel.Warning,
      title: i18n.t('Alerts:Billing.PaymentMethodExpired.title'),
      caption: i18n.t('Alerts:Billing.PaymentMethodExpired.caption'),
      button: {
        action: AlertAction.AutoPay,
        label: i18n.t('Alerts:Billing.PaymentMethodExpired.button.text'),
      },
    });
  }

  return alerts;
};

export const parseOutages = (
  data: Partial<ApolloQueryResult<Query>['data']>
): Alert[] => {
  const outages = data?.getOutageNotifications?.outages ?? [];

  return outages.map((notification: OutageNotification): Alert => {
    return {
      level: notification.level,
      title: i18n.t(`Alerts:Outage.${notification.type}.title`),
    };
  });
};

export const hasSetAutopay = (
  action: PayloadAction<BillingInfoState['billingInfo']>
) =>
  action.type === setBillingInfo.type &&
  Object.keys(action.payload).includes('autoPay');

export function* fetchBillingAlerts() {
  yield call(waitForToken);
  yield take(hasSetAutopay as ActionPattern);

  const {
    billingInfo: { autoPay },
  }: ReturnType<typeof selectBillingInfo> = yield select(selectBillingInfo);

  const billingAlerts: Alert[] = yield call(parseBillingAlerts, autoPay);
  yield put(setBillingAlerts(billingAlerts));
}

export const hasSetSummaryInvoiceStatus = (
  action: PayloadAction<BillingInfoState['billingInfo']>
) =>
  action.type === setBillingInfo.type &&
  Object.keys(action.payload).includes('summaryInvoiceStatus');

export function* fetchAccountAlerts() {
  yield call(waitForToken);
  yield take(hasSetSummaryInvoiceStatus as ActionPattern);
  yield call(waitForPlanCharacteristics);
  yield call(waitForUsage);

  const {
    characteristics,
    errors: characteristicsErrors,
  }: ReturnType<typeof selectPlanCharacteristics> = yield select(
    selectPlanCharacteristics
  );

  if (characteristics && !characteristicsErrors) {
    const alerts: Alert[] = yield call(parseAccountAlerts);
    yield put(setAlerts(alerts));
    return;
  }
}

export function* fetchAccountErrorAlerts() {
  yield call(waitForToken);
  yield call(waitForUserData);

  const {
    user: { productInstanceStatus, hasErrorProductInstance },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  if (hasErrorProductInstance) {
    yield call(fetchHasPendingTransition);
  }

  const { hasActivationError } = yield select(selectChangePlan);

  const alerts: Alert[] = [
    {
      level: NotificationLevel.Error,
      title: i18n.t('Alerts:AccountStatus.error.title'),
      caption: i18n.t('Alerts:AccountStatus.error.caption'),
    },
    {
      level: NotificationLevel.Error,
      title: i18n.t('Alerts:AccountStatus.transitionError.title'),
      caption: i18n.t('Alerts:AccountStatus.transitionError.caption'),
    },
  ];

  if (productInstanceStatus === ProductInstanceStatus.Error) {
    yield put(setAlerts([alerts[0]]));
    return;
  }

  if (
    productInstanceStatus === ProductInstanceStatus.Active &&
    hasActivationError
  ) {
    yield put(setAlerts([alerts[1]]));
    return;
  }
}

export function* fetchOutageAlerts() {
  yield call(waitForToken);
  const data: Query = yield call(graphqlQuery, {
    query: GET_OUTAGE_NOTIFS,
  });

  const outages = parseOutages(data);

  yield put(setAlerts(outages));
}

function* refetchAllAlerts() {
  yield put(resetAlerts());
  yield call(fetchAccountErrorAlerts);
  yield call(fetchAllAlerts);
}

function* debounceRefetchAccountAlerts() {
  yield delay(1500);
  yield call(fetchAccountAlerts);
}

function* fetchAllAlerts() {
  yield all([
    call(fetchAccountErrorAlerts),
    call(fetchBillingAlerts),
    call(fetchAccountAlerts),
    call(fetchOutageAlerts),
  ]);
}

type DisplayServiceCallAlertAction = PayloadAction<SummarizedWorkOrder>;

function* getServiceCallAlert(action: DisplayServiceCallAlertAction) {
  const serviceCallWorkOrder = action.payload;
  const formattedDate = format(
    new Date(serviceCallWorkOrder?.schedule?.from as string),
    'MMMM d'
  );

  const from = new Date(serviceCallWorkOrder?.schedule?.from as string);
  const to = new Date(serviceCallWorkOrder?.schedule?.to as string);

  const serviceCallAlert = {
    level: NotificationLevel.Info,
    title: i18n.t('Alerts:AccountStatus.serviceCall.title'),
    customDescription: `${i18n.t(
      'Alerts:AccountStatus.serviceCall.caption'
    )} <span class="beam-font--bodySmallBold">${i18n.t(
      'Alerts:AccountStatus.serviceCall.dateRange',
      {
        formattedDate,
        from,
        to,
      }
    )}</span>`,
    button: {
      action: AlertAction.RescheduleServiceCall,
      label: i18n.t('Alerts:AccountStatus.serviceCall.button.text'),
    },
    type: 'global' as const,
  };

  yield put(setAlerts([serviceCallAlert]));
}

export function* watchAlerts() {
  yield spawn(function* () {
    yield all([
      takeLatest(displayServiceCallAlert.type, getServiceCallAlert),
      takeLatest(setBillingPageStatistics.type, fetchAllAlerts),
      takeLatest(setLocaleState.type, refetchAllAlerts),
      takeLatest(setOverviewPageStatistics.type, fetchAllAlerts),
      takeLatest(fetchAccountAlertsAction.type, debounceRefetchAccountAlerts),
    ]);
  });
}
