import Cookies from 'js-cookie';

const PUSH_NOTIF_COOKIE_PREFIX = 'PUSH_ENABLED';
const PUSH_NOTIF_ENABLED_VALUE = '1';

const getCookieName = (accountNumber: string) =>
  `${PUSH_NOTIF_COOKIE_PREFIX}${accountNumber}`;

export const getPushNotifStateCookie = ({
  accountNumber,
}: {
  accountNumber: string;
}): boolean => {
  const cookieName = getCookieName(accountNumber);
  const savedPushNotifState = Cookies.get(cookieName);
  return Boolean(
    savedPushNotifState && savedPushNotifState === PUSH_NOTIF_ENABLED_VALUE
  );
};

export const savePushNotifCookie = ({
  enabled,
  accountNumber,
}: {
  enabled: boolean;
  accountNumber: string;
}) => {
  const cookieName = getCookieName(accountNumber);
  if (enabled) {
    Cookies.set(cookieName, PUSH_NOTIF_ENABLED_VALUE);
  } else {
    Cookies.remove(cookieName);
  }
};
