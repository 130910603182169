/* eslint-disable no-case-declarations */
import {
  Domains,
  AuthEventTypes,
  EventTypes,
} from '@mfe/services/window-messages';
import { takeLatest, all, select, put, spawn, call } from 'redux-saga/effects';
import { EventChannel, eventChannel } from 'redux-saga';
import {
  addListeners as addListenersAction,
  getCookie as getCookieAction,
  setCookie as setCookieAction,
  goToUrl as goToUrlAction,
  logout as logoutAction,
  KeyValuePair,
  GoToUrl,
  GetCookiePayload,
  _setCookie,
  setOrigin,
  selectEvent,
} from './eventSlice';

import {
  initialClient,
  selectConfig,
  setConfig,
} from '@mfe/shared/redux/config';
import { postMessage } from '../utils';
import {
  Locale,
  MvEnv,
  OAuthClient,
  Platform,
  TokenType,
} from '@mfe/shared/schema-types';
import {
  selectUser,
  sendAuthParams,
  getAuthUrls,
  loginFromCookie,
  TOKEN_INFO_COOKIE_NAME,
  DISMISS_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
  loginFromCode,
  authUser,
  TokenInfo,
} from '../auth';
import { handleUserTokenErrors } from '../../libUtils';
import {
  loginFromAccessToken,
  loginFromToken,
} from '../auth/authSagas/logInSagas';
import {
  setLocaleState,
  setLocaleFetched,
  USER_LOCALE_COOKIE_NAME,
  DISPLAY_LANGUAGE_COOKIE_NAME,
  setLanguageCookie,
} from '../locale';
import Cookies from 'js-cookie';
import { DisplayLanguage } from '@mfe/services/translations-service';
import { fetchAccountAlerts } from '../alerts';
import {
  getCharacteristics,
  getDownloadSpeeds,
  getPrice,
  getUsage,
} from '../plan';

export const COOKIE_LIFETIME_DAYS = 14;

interface MVLDConfig {
  showAppDownload?: boolean;
  showPreInstall?: boolean;
  showTransitionPlans?: boolean;
  showTSUsageMicrofrontEnd?: boolean;
  profileReadonly?: boolean;
  salesforce?: unknown;
  rocketChat?: unknown;
  platform?: Platform;
  client?: OAuthClient;
  baseUrl?: string;
  env?: MvEnv;
  mfeEnv?: MvEnv;
  version?: string;
}

type AuthParams = {
  url?: string;
  baseUrl?: string;
  platform?: Platform;
  client?: { id: string; redirectUri: string };
};

export interface MvInfo {
  platform: Platform;
  env: MvEnv;
  mfeEnv?: MvEnv;
  tokenType: TokenType;
  locale: Locale;
}

export function* logout() {
  const {
    auth: { client, baseUrl, authMethod },
  }: ReturnType<typeof selectConfig> = yield select(selectConfig);
  const {
    user: {
      auth: {
        tokenInfo: { idToken },
      },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);
  const { logoutUrl } = getAuthUrls(client, baseUrl, idToken);
  yield put(
    setCookieAction({
      key: TOKEN_INFO_COOKIE_NAME,
      value: '',
    })
  );
  yield put(
    setCookieAction({
      key: USER_ID_COOKIE_NAME,
      value: '',
    })
  );
  yield put(
    setCookieAction({
      key: DISMISS_COOKIE_NAME,
      value: '',
    })
  );
  yield put(goToUrlAction({ url: logoutUrl }));
  authMethod === 'web' && window.location.assign(logoutUrl);
}

const isValidOrigin = (origin: string): boolean => {
  if (
    origin.startsWith('http://localhost:') ||
    origin.startsWith('http://10.0.2.2:')
  )
    return true;
  if (
    origin.startsWith('https://my.viasat.com') ||
    origin.startsWith('https://minha.viasat.com')
  )
    return true;
  if (origin.endsWith('.viasat.io')) return true;
  if (origin.endsWith('.force.com')) return true;

  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleMessage(messageData: any) {
  const messageOrigin = messageData.origin;
  const data = messageData.data;
  const type = data.type || data.eventType;
  const subject = data.subject ?? undefined;

  if (!isValidOrigin(messageOrigin)) return;

  const { origin }: ReturnType<typeof selectEvent> = yield select(selectEvent);

  //message origin should be defined and window location should not be the same as message origin, then origin on saga should be undefined
  const shouldSetOrigin =
    messageOrigin && window.location.origin !== messageOrigin && !origin;

  if (shouldSetOrigin) yield put(setOrigin({ origin: messageOrigin }));

  switch (type) {
    case EventTypes.SetOrigin:
    case EventTypes.Init:
      yield put(setOrigin({ origin: messageOrigin }));
      break;
    case EventTypes.ClientConfig:
      yield put(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setConfig({ platform: data.data.platform ?? Platform.Web })
      );
      break;
    case EventTypes.RefreshToken:
      const token = data.data.token;
      if (!token) {
        yield call(handleUserTokenErrors, true, undefined, subject);
        break;
      }
      const tokenInfo: TokenInfo = {
        accessToken: token,
        accessTokenExpirationTime: '',
      };
      call(loginFromToken, {
        type: 'loginFromToken',
        payload: { tokenInfo },
      });
      break;
    case EventTypes.MVLaunchDarklyConfig:
      const mvLDConfig = data.data as MVLDConfig;
      yield put(
        setConfig({
          showChangePlan: true,
          isProfileReadOnly: false,
          mfeEnv: mvLDConfig.mfeEnv ?? '',
          platform: mvLDConfig.platform ?? Platform.Web,
          version: mvLDConfig.version ?? '4.4.3',
        })
      );
      break;
    case EventTypes.ChangeLanguage:
      const displayLanguage = data.data as DisplayLanguage;

      yield put(setLocaleState({ displayLanguage }));
      yield put(setLanguageCookie({ displayLanguage }));
      break;
    //this case is only for legacy MV
    case EventTypes.SendAuthParams:
      const authParams = data.data as AuthParams;
      yield put(
        setConfig({
          platform: authParams.platform ?? Platform.Web,
          auth: {
            authMethod: authParams.platform !== Platform.Web ? 'mobile' : 'web',
            client: authParams.client
              ? {
                  clientId: authParams.client.id,
                  redirectUrl: authParams.client.redirectUri,
                }
              : initialClient,
            baseUrl: authParams.baseUrl ?? '',
            myViasatUrl: authParams.url ?? '',
          },
        })
      );
      yield put(authUser());
      break;
    case AuthEventTypes.GetAuthParams:
      const { env, platform, mfeEnv, locale } = data.data as MvInfo;
      yield put(
        sendAuthParams({
          env,
          platform,
          mfeEnv: mfeEnv as MvEnv,
          locale,
        })
      );
      break;
    case AuthEventTypes.LoginFromCode:
      const code = data.data.code as string;
      yield put(loginFromCode({ code }));
      break;
    case AuthEventTypes.GetCookie:
      const payload = data.data as KeyValuePair;
      if (payload.key === TOKEN_INFO_COOKIE_NAME) {
        const {
          user: {
            auth: { isAuthenticated },
          },
        }: ReturnType<typeof selectUser> = yield select(selectUser);

        if (!isAuthenticated)
          yield put(loginFromCookie({ stringifiedTokenInfo: payload.value }));
      }

      if (
        payload.key === USER_LOCALE_COOKIE_NAME ||
        payload.key === DISPLAY_LANGUAGE_COOKIE_NAME
      ) {
        yield put(setLocaleState({ [payload.key]: payload.value }));
        if (payload.key === USER_LOCALE_COOKIE_NAME)
          yield put(setLocaleFetched());
      }

      yield put(_setCookie(payload));
      break;
    case AuthEventTypes.SendToken:
      const accessToken = data.data?.token as string;
      const productInstanceId = data.data?.productInstanceId ?? '';
      const partyId = data.data?.partyId ?? '';
      yield call(loginFromAccessToken, {
        accessToken,
        productInstanceId,
        partyId,
      });
      break;
    case AuthEventTypes.Logout:
      yield put(logoutAction());
      break;
    case EventTypes.ChangedPlan:
      // changedPlan action only set a loading variable to true
      // TODO: figure out what it was used for & what it can be replaced with
      // yield put(changedPlan());
      break;
    case EventTypes.RefetchPlanInfo:
      yield put(getCharacteristics());
      yield put(getUsage());
      yield put(getPrice());
      yield put(getDownloadSpeeds());
      break;
    case EventTypes.ScheduledInstall:
      yield put(fetchAccountAlerts());
      break;
  }
}

const setMessageChannel = (): EventChannel<unknown> =>
  eventChannel((emitter) => {
    window.addEventListener('message', emitter);
    return () => window.removeEventListener('message', emitter);
  });

export function* addListeners() {
  yield takeLatest(setMessageChannel(), handleMessage);
}

export function* setCookie({
  payload,
}: {
  type: string;
  payload: KeyValuePair;
}) {
  const { env }: ReturnType<typeof selectConfig> = yield select(selectConfig);

  //if we're directly imported
  yield call(Cookies.set, payload.key, payload.value, {
    secure: env !== MvEnv.DevLocal,
    expires: COOKIE_LIFETIME_DAYS,
  });

  //if we're iframed
  yield put(
    postMessage({
      domain: Domains.General,
      eventType: AuthEventTypes.SetCookie,
      data: payload,
    })
  );
}

export function* getCookie({
  payload,
}: {
  type: string;
  payload: GetCookiePayload;
}) {
  yield put(
    postMessage({
      domain: Domains.General,
      eventType: AuthEventTypes.GetCookie,
      data: payload,
    })
  );
}

export function* goToUrl({ payload }: { type: string; payload: GoToUrl }) {
  yield put(
    postMessage({
      domain: Domains.General,
      eventType: AuthEventTypes.GoToUrl,
      data: payload,
    })
  );
}

export function* watchEvents() {
  yield spawn(function* () {
    yield all([
      takeLatest(addListenersAction.type, addListeners),
      takeLatest(setCookieAction.type, setCookie),
      takeLatest(getCookieAction.type, getCookie),
      takeLatest(goToUrlAction.type, goToUrl),
      takeLatest(logoutAction.type, logout),
    ]);
  });
}
