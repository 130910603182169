import { gql } from '@apollo/client';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { waitForToken } from '../utils/utilsSagas';
import {
  graphqlQueryWithErrors,
  FetchWithErrorsQuery,
} from '@mfe/shared/redux/graphql';
import {
  fetchHistoricalUsageInfo,
  setHistoricalUsageInfo,
  setApiErrors,
  selectHistoricalUsageInfo,
  fetchUnleashedUsage,
  setUnleashedUsage,
  FetchUnleashedUsageAction,
} from './historicalUsageInfoSlice';
import type { UsageData } from './utils';

export const GET_HISTORICAL_USAGE = gql`
  query getHistoricalUsageByBillCycle($refetchData: Boolean) {
    getHistoricalUsageByBillCycle(refetchData: $refetchData) {
      billCycles {
        startDate
        endDate
        usageData {
          date
          usage
        }
      }
    }
  }
`;

const GET_HISTORICAL_USAGE_BY_DATE = gql`
  query getHistoricalUsageByDate(
    $refetchData: Boolean
    $start: String
    $end: String
  ) {
    getHistoricalUsageByDate(
      refetchData: $refetchData
      start: $start
      end: $end
    ) {
      intervalStart
      intervalEnd
      usageData {
        date
        usage
      }
    }
  }
`;

export function* fetchHistoricalUsageByBillCycle() {
  const {
    errors: hasApiErrorOccurred,
  }: ReturnType<typeof selectHistoricalUsageInfo> = yield select(
    selectHistoricalUsageInfo
  );

  const refetchData = Boolean(hasApiErrorOccurred);

  yield call(waitForToken);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_HISTORICAL_USAGE,
    variables: {
      refetchData,
    },
    fetchPolicy: refetchData ? 'no-cache' : 'cache-first',
  });

  const { data, errors } = apiResponse;
  if (errors) {
    yield put(setApiErrors(errors));
  }

  if (data?.getHistoricalUsageByBillCycle) {
    yield put(setHistoricalUsageInfo(data.getHistoricalUsageByBillCycle));
  }
}

function* fetchHistoricalUsageByDate(action: FetchUnleashedUsageAction) {
  const { unleashedUsage, errors: existingErrors } = yield select(
    selectHistoricalUsageInfo
  );
  const hasDataForSelectedInterval =
    unleashedUsage[action.payload.selectedInterval];

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_HISTORICAL_USAGE_BY_DATE,
    variables: {
      refetchData: !hasDataForSelectedInterval,
      start: action.payload.start,
      end: action.payload.end,
    },
    fetchPolicy: existingErrors ? 'network-only' : 'cache-first',
  });

  const { data, errors, runtimeError } = response;

  if (runtimeError || errors) {
    yield put(setApiErrors(runtimeError ?? errors));
    return;
  }

  if (data?.getHistoricalUsageByDate) {
    yield put(
      setUnleashedUsage({
        usageData:
          (data.getHistoricalUsageByDate.usageData as UsageData[]) ?? [],
        interval: action.payload.selectedInterval,
      })
    );
  }
}

export function* watchHistoricalUsageInfo() {
  yield all([
    takeLatest(fetchHistoricalUsageInfo.type, fetchHistoricalUsageByBillCycle),
    takeLatest(fetchUnleashedUsage.type, fetchHistoricalUsageByDate),
  ]);
}
