/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WindowMessageData, Domains } from '@mfe/services/window-messages';

interface IndividualPageStatistics {
  lastUpdatedTime: number | null;
}
type OpenInNewTabAction = PayloadAction<string>;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UtilsState {
  pageStats: {
    currentPage: Domains | 'usage-summary' | 'stream-on' | null;
    pages: {
      profile: IndividualPageStatistics;
      usage: IndividualPageStatistics;
      overview: IndividualPageStatistics;
      billing: IndividualPageStatistics;
      usageSummary: IndividualPageStatistics;
      streamOn: IndividualPageStatistics;
    };
  };
}

export const initialUtilsState: UtilsState = {
  pageStats: {
    currentPage: null,
    pages: {
      profile: { lastUpdatedTime: null },
      usage: { lastUpdatedTime: null },
      overview: { lastUpdatedTime: null },
      billing: { lastUpdatedTime: null },
      usageSummary: { lastUpdatedTime: null },
      streamOn: { lastUpdatedTime: null },
    },
  },
};

export const utilsSlice = createSlice({
  name: 'utils',
  initialState: initialUtilsState,
  reducers: {
    postMessage: (state, _: { payload: WindowMessageData }) => state,
    refreshContext: (state) => state,
    openInNewTab: (state, OpenInNewTabAction) => state,
    setOverviewPageStatistics: (state) => {
      if (state.pageStats.currentPage !== Domains.Overview) {
        state.pageStats.pages.overview.lastUpdatedTime = Date.now();
      }
      state.pageStats.currentPage = Domains.Overview;
    },
    setBillingPageStatistics: (state) => {
      if (state.pageStats.currentPage !== Domains.Billing) {
        state.pageStats.pages.billing.lastUpdatedTime = Date.now();
      }
      state.pageStats.currentPage = Domains.Billing;
    },
    setProfilePageStatistics: (state) => {
      if (state.pageStats.currentPage !== Domains.Profile) {
        state.pageStats.pages.profile.lastUpdatedTime = Date.now();
      }
      state.pageStats.currentPage = Domains.Profile;
    },
    setUsagePageStatistics: (state) => {
      if (state.pageStats.currentPage !== Domains.Usage) {
        state.pageStats.pages.usage.lastUpdatedTime = Date.now();
      }
      state.pageStats.currentPage = Domains.Usage;
    },
    setUsageSummaryPageStatistics: (state) => {
      if (state.pageStats.currentPage !== 'usage-summary') {
        state.pageStats.pages.usageSummary.lastUpdatedTime = Date.now();
      }
      state.pageStats.currentPage = 'usage-summary';
    },
    setStreamOnPageStatistics: (state) => {
      if (state.pageStats.currentPage !== 'stream-on') {
        state.pageStats.pages.streamOn.lastUpdatedTime = Date.now();
      }
      state.pageStats.currentPage = 'stream-on';
    },
  },
});

export const postUsageMessage = (message: Omit<WindowMessageData, 'domain'>) =>
  utilsSlice.actions.postMessage({ domain: Domains.Usage, ...message });

export const postOverviewMessage = (
  message: Omit<WindowMessageData, 'domain'>
) => utilsSlice.actions.postMessage({ domain: Domains.Overview, ...message });
export const postProfileMessage = (
  message: Omit<WindowMessageData, 'domain'>
) => utilsSlice.actions.postMessage({ domain: Domains.Profile, ...message });

export const postGeneralMessage = (
  message: Omit<WindowMessageData, 'domain'>
) => utilsSlice.actions.postMessage({ domain: Domains.General, ...message });

export const postBillingMessage = (
  message: Omit<WindowMessageData, 'domain'>
) => utilsSlice.actions.postMessage({ domain: Domains.Billing, ...message });

export const {
  postMessage,
  setOverviewPageStatistics,
  setBillingPageStatistics,
  setProfilePageStatistics,
  setUsagePageStatistics,
  setUsageSummaryPageStatistics,
  setStreamOnPageStatistics,
  refreshContext,
  openInNewTab,
} = utilsSlice.actions;

export const selectUtil = (state: { utils: UtilsState }) => state.utils;
