import { colors, EmptyState, Icon, Spinner } from '@vst/beam';
import { Alert, Clear } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';

import { resetScrubbedAddress } from '@mfe/to-be-migrated/redux/scrubbedAddress';

import styles from './loading.module.scss';

interface ModalProps {
  onCancel(): void;
  modalText?: string;
  addressTimeout?: boolean;
}

export const ModalLoading = ({
  modalText,
  onCancel,
  addressTimeout,
}: ModalProps): JSX.Element | null => {
  const { t } = useTranslation(['NewProfile', 'Error']);
  if (addressTimeout) {
    resetScrubbedAddress();
  }

  const loadingText = modalText ? t(`NewProfile:${modalText}`) : undefined;

  return (
    <div data-cy="modal-loading" className={styles['modal']}>
      <div className={styles['card']}>
        <button className={styles['exit']} onClick={onCancel}>
          <Icon icon={Clear} style={{ color: colors.gray[700] }} />
        </button>
        <div className={styles['loadingContainer']}>
          {addressTimeout ? (
            <EmptyState
              icon={Alert}
              title={t('Error:title')}
              description={t('Error:modal')}
            />
          ) : (
            <Spinner size="small" description={loadingText ?? undefined} />
          )}
        </div>
      </div>
    </div>
  );
};
