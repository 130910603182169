import '../styles/globals.scss';
import '@mfe/services/translations-service';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { store } from '@mfe/to-be-migrated/redux/store';
import Script from 'next/script';
import { useEffect } from 'react';
import ErrorBoundary from '../lib/ErrorBoundary/ErrorBoundary';
import { ApolloProvider } from '@mfe/shared/components';
import { UserProvider } from 'lib/UserProvider';
import { RootSelectorComponent } from 'lib/RootSelectorComponent';
import { BeamProvider, ToastContainer } from '@vst/beam';
import {
  addAdditionalTranslations,
  Translations,
} from '@mfe/services/translations-service';
import { streamOnTranslations } from '@mfe/features/stream-on/translations';
import { networkTranslations } from '@mfe/features/network/translations';
import { I18nLocaleProvider } from '@mfe/shared/components';

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    try {
      if (
        'serviceWorker' in navigator &&
        !window.location.hostname.includes('localhost')
      ) {
        navigator.serviceWorker.register('/serviceWorker.js').then(
          function (registration) {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope
            );
          },
          function (err) {
            console.log('Service Worker registration failed: ', err);
          }
        );
      }
    } catch (error) {
      console.error('error in loadServiceWorker', { error });
    }

    [streamOnTranslations, networkTranslations].forEach(
      (translations: Translations) => {
        addAdditionalTranslations(translations[0], translations[1]);
      }
    );
  });
  return (
    <>
      <Script src="/js/raygun.js" />
      <Provider store={store}>
        <I18nLocaleProvider>
          <ApolloProvider>
            <ErrorBoundary>
              <UserProvider>
                <RootSelectorComponent>
                  <BeamProvider withToastProvider>
                    <ToastContainer position={'bottom-center'} />
                    <Component {...pageProps} />
                  </BeamProvider>
                </RootSelectorComponent>
              </UserProvider>
            </ErrorBoundary>
          </ApolloProvider>
        </I18nLocaleProvider>
      </Provider>
    </>
  );
};
export default MyApp;
