export const analyticsBus = async (url: string, data: any) => {
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((mes) => console.log(mes))
    .catch((err) => console.log(err));
};
