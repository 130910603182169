import { colors } from '@vst/beam';
import { hexToRgbValue } from '@mfe/shared/util';

export const borderRadiusValues = {
  left: '16px 0 0 16px',
  all: '16px',
  right: '0 16px 16px 0',
  rightSmall: '0 8px 8px 0',
};

/* When read in the browser, the gradient contain the rgb values of the colors
So we convert them to rgb preemptively in order to compare the values in e2e tests */
const teal300RGB = `rgb(${hexToRgbValue(colors.teal[300])})`;
const teal600RGB = `rgb(${hexToRgbValue(colors.teal[600])})`;

export const barHighlightColorBuyMore = `repeating-linear-gradient(135deg, ${teal300RGB}, ${teal300RGB} 3px, ${teal600RGB} 1px, ${teal600RGB} 9px)`;
