import { gql } from '@apollo/client';
import { select, put, call, delay } from 'redux-saga/effects';

import { Query, Platform, TokenType, Locale } from '@mfe/shared/schema-types';

import { logout } from '../../events';
import { graphqlQuery } from '@mfe/shared/redux/graphql';
import { selectConfig } from '@mfe/shared/redux/config';
import { verifyTokenInfo } from './logInSagas';
import { MS_IN_A_MINUTE, parseTokenInfoCookie } from '../utils';

export const GET_TOKEN_USING_REFRESH = gql`
  query getTokenUsingRefresh($input: GetTokenUsingRefreshInput!) {
    getTokenUsingRefresh(input: $input) {
      accessToken
      accessTokenExpirationTime
      idToken
      refreshToken
    }
  }
`;

export function* tryToRefresh(payload: {
  refreshToken: string;
  platform: Platform;
  locale?: Locale;
}) {
  const { refreshToken, platform, locale } = payload;

  const data: Query = yield call(graphqlQuery, {
    query: GET_TOKEN_USING_REFRESH,
    variables: { input: { refreshToken, platform, locale } },
  });

  const accessToken = data.getTokenUsingRefresh.accessToken;
  const accessTokenExpirationTime =
    data.getTokenUsingRefresh.accessTokenExpirationTime;
  const idToken = data.getTokenUsingRefresh.idToken;
  const newRefreshToken = data.getTokenUsingRefresh.refreshToken;

  return {
    accessToken,
    accessTokenExpirationTime,
    idToken,
    refreshToken: newRefreshToken,
  };
}

export function* renewToken(payload: {
  payload: { refreshToken: string; tokenType?: TokenType; locale?: Locale };
}) {
  const { refreshToken, locale } = payload.payload;

  const { platform }: ReturnType<typeof selectConfig> = yield select(
    selectConfig
  );
  try {
    if (!refreshToken) {
      console.info('No refresh token');
      yield put(logout());
      return;
    }
    const {
      accessToken,
      accessTokenExpirationTime,
      idToken,
      refreshToken: newRefreshToken,
    } = yield call(tryToRefresh, { refreshToken, platform, locale });
    const tokenInfo = {
      accessToken,
      refreshToken: newRefreshToken ?? refreshToken,
      accessTokenExpirationTime,
      idToken,
    };
    yield call(verifyTokenInfo, {
      type: 'verifyTokenInfo',
      payload: {
        tokenInfo,
      },
    });
  } catch (err) {
    console.info(`failed to refresh token with err: ${err}`);
    yield put(logout());
    return;
  }
}

export function* refreshCountdown(payload: {
  type: string;
  payload: { stringifiedTokenInfo: string };
}) {
  const stringifiedTokenInfo = payload.payload.stringifiedTokenInfo;
  const tokenInfo = parseTokenInfoCookie(stringifiedTokenInfo);
  if (!tokenInfo) {
    console.info('tokenInfo cookie not found or deformed');
    yield put(logout());
    return;
  }
  const { accessTokenExpirationTime } = tokenInfo;
  const timeToRefreshMS =
    new Date(accessTokenExpirationTime).getTime() -
    new Date().getTime() -
    5 * MS_IN_A_MINUTE;

  yield delay(timeToRefreshMS);

  yield call(renewToken, {
    payload: {
      refreshToken: tokenInfo.refreshToken ?? '',
      tokenType: tokenInfo.type,
      locale: tokenInfo.locale,
    },
  });
}
