import {
  ApolloClient,
  ApolloProvider as ApolloHooksProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectEvent, addListeners } from '@mfe/to-be-migrated/redux/events';
import { getLink } from '@mfe/services/graphql-client';
import { initRaygun } from '@mfe/to-be-migrated/redux/raygun';
import { memoryStorage } from '@mfe/shared/util';
import { Locale, TokenType } from '@mfe/shared/schema-types';

import { typePolicies } from './typePolicies';

export const ApolloProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const {
    user: {
      auth: { tokenInfo },
    },
  } = useSelector(selectUser);
  const { listenersAdded } = useSelector(selectEvent);
  const {
    localeFetched,
    locale: { userLocale },
  } = useSelector(selectLocale);

  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);

  useEffect(() => {
    if (window.location.hostname.includes('prod')) return;
    dispatch(
      initRaygun({
        apiKey: '8z2fQgqWpyLo9erqIS4g',
        enableCrashReporting: true,
        enablePulse: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // Event listeners allow reading Cookies from parent window
    if (!listenersAdded) dispatch(addListeners());

    const KEY = `${window.location.origin}/api/graphql`;

    const isRegistrationToken = tokenInfo.type === TokenType.Registration;
    const token = isRegistrationToken
      ? {
          accessToken: '',
          accessTokenExpirationTime: '',
          locale: Locale.EnUs,
        }
      : tokenInfo;

    const link = getLink(KEY, token, userLocale);

    let client = memoryStorage.get(KEY);
    if (!client) {
      client = new ApolloClient({
        link,
        cache: new InMemoryCache({ typePolicies }),
      });
      if (token?.accessToken) {
        memoryStorage.set(KEY, client);
      }
    }

    setClient(client);
  }, [tokenInfo, userLocale, localeFetched, listenersAdded, dispatch]);

  if (!client) return null;

  return <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>;
};
