import { gql } from '@apollo/client';

export const GET_PORTFOLIO = gql`
  query getPortfolio {
    getPortfolio {
      portfolioItems {
        isRoot
        productInstanceId
        productTypeId
        kind
        characteristics {
          name
          value
        }
        locationIds
      }
      portfolioRelationships {
        source {
          id
          name
        }
        destination {
          id
          name
        }
      }
    }
  }
`;

export const GET_PLAN_PRICE = gql`
  query getCurrentPlanPrice {
    getCurrentPlanPrice {
      price
    }
  }
`;
