import { useCallback, useEffect, useRef } from 'react';

function useTimer({
  isLoading,
  onFinished,
}: {
  isLoading: boolean;
  onFinished: (durationMS: number) => void;
}): void {
  const startTime = useRef(0);
  // We track if this is the first run or not
  // This way if we won't call onFinished if isFinished is initially true
  const firstRun = useRef(true);

  useEffect(() => {
    if (!isLoading && !firstRun.current) {
      const endTime = new Date().getTime();
      const duration = endTime - startTime.current;
      onFinished(duration);
    } else {
      startTime.current = new Date().getTime();
      firstRun.current = false;
    }
  }, [isLoading, onFinished]);
}

export function useTrackTiming({
  eventName,
  isLoading,
}: {
  eventName: string;
  isLoading: boolean;
}): void {
  const onFinished = useCallback(
    (duration: number) => {
      try {
        // Uncomment this line to debug timings
        // console.debug(`Track timing ${eventName}: ${duration}ms`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rg4js('trackEvent', {
          type: 'customTiming',
          name: eventName,
          duration: duration,
        });
      } catch {
        // No op
      }
    },
    [eventName]
  );

  useTimer({ isLoading, onFinished });
}
