import React from 'react';
import { useDispatch } from 'react-redux';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import styles from '../AppButtons.module.scss';
import AppleStoreSVG from './AppleStoreSVG';

export const AppleAppStoreButton = ({ url }: { url: string }): JSX.Element => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(openInNewTab(url));
  };

  return (
    <button
      onClick={onClick}
      data-cy="apple-app-store-button"
      className={styles['appStoreButton']}
    >
      <AppleStoreSVG />
    </button>
  );
};

export default AppleAppStoreButton;
