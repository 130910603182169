/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  EventTypes,
  UserTokenError,
  WindowMessageData,
  Domains,
} from '@mfe/services/window-messages';
import { JwtPayload } from 'jsonwebtoken';
import { differenceInMilliseconds } from 'date-fns';
import { Issuer } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';

const WARNING_TIME_BUFFER = 300000;
const ANALYTICS_APP_ID_PREFIX = 'MyViasat';

export interface SalesforceToken extends JwtPayload {
  partyId?: string;
}

const _buildInvalidTokenEventMessage = ({
  eventType,
  message,
  details,
  subject,
}: UserTokenError): WindowMessageData => {
  const invalidTokenEventMessage: WindowMessageData = {
    domain: Domains.Usage,
    eventType,
    subject,
    data: {
      message,
      details,
    },
  };

  return invalidTokenEventMessage;
};

const sendDataToParent = (data?: unknown) => {
  // TODO pull targetOrigin domain from env
  const targetOrigin = '*';
  const sentData = typeof data === 'string' ? JSON.parse(data) : data;

  // @ts-ignore
  if (window.ReactNativeWebView & !isPWAMyViasat()) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(sentData));
  } else {
    // Note: stringifying and unstringifying intentionally here
    // This makes sure Mozilla's postMessage doesn't error trying to serialize the object

    window.parent?.postMessage(sentData, targetOrigin);
  }
};

const _dispatchUserTokenError = (userTokenError: UserTokenError): void => {
  const invalidTokenEventMessage =
    _buildInvalidTokenEventMessage(userTokenError);

  sendDataToParent(JSON.stringify(invalidTokenEventMessage));
};

const _isClient = () => {
  return typeof window != 'undefined' && window.document;
};

const _noTokenProvidedError = (noTokenProvided: boolean): UserTokenError => {
  const userTokenError = {
    eventType: EventTypes.NoTokenProvided,
    message: `A user token was not found, domain ${Domains.Usage} is not logged in.`,
    details: { noTokenProvided },
  };

  return userTokenError;
};

const _invalidTokenError = (error: Error): UserTokenError => {
  const userTokenError = {
    eventType: EventTypes.InvalidToken,
    message: error.message,
    details: { error },
  };

  return userTokenError;
};

export const handleUserTokenErrors = (
  noTokenProvided: boolean,
  invalidTokenError: Error | undefined,
  subject?: string
): UserTokenError | undefined => {
  let userTokenError: UserTokenError | undefined;
  if (_isClient() && noTokenProvided) {
    userTokenError = _noTokenProvidedError(noTokenProvided);
  } else if (invalidTokenError) {
    userTokenError = _invalidTokenError(invalidTokenError);
  }

  if (userTokenError) {
    _dispatchUserTokenError({ ...userTokenError, subject });
  }

  return userTokenError;
};

export const getTokenFromMessage = (
  messageString: string
): string | undefined => {
  try {
    return JSON.parse(messageString).token;
  } catch (err) {
    return undefined;
  }
};

export const notificationBeforeTokenExpiration = (
  expiration: number,
  subject: string
) => {
  const exp = expiration * 1000;
  const msToExpire = differenceInMilliseconds(exp, new Date());

  //Send warning 5 mins before the token expire
  const sendExpirationWarningMessage = setTimeout((): void => {
    sendDataToParent(
      _buildInvalidTokenEventMessage({
        eventType: EventTypes.TokenWarning,
        message: 'The access token will expire soon',
        subject,
      })
    );

    clearTimeout(sendExpirationWarningMessage);
  }, msToExpire - WARNING_TIME_BUFFER);

  //Send token expired
  const sendExpirationMessage = setTimeout((): void => {
    sendDataToParent(
      _buildInvalidTokenEventMessage({
        eventType: EventTypes.InvalidToken,
        message: 'The access token has expired',
        subject,
      })
    );

    clearTimeout(sendExpirationMessage);
  }, msToExpire);
};

export const createAnalyticsAppId = (
  env: string,
  platform: Platform,
  issuer: string | null
) => {
  const isApp = platform === Platform.Ios || platform === Platform.Android;
  const _platform = issuer === Issuer.Salesforce || !isApp ? 'Web' : 'App';

  if (env.toLowerCase() === 'prod')
    return `${ANALYTICS_APP_ID_PREFIX}-${_platform}`;
  return `${ANALYTICS_APP_ID_PREFIX}-${env.toLowerCase()}-${_platform}`;
};
