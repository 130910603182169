import { BuyMoreProducts } from '@mfe/shared/schema-types';

export const calculateTotalData = (products: BuyMoreProducts[]) => {
  return products?.reduce((acc: number, product: BuyMoreProducts) => {
    const match = product?.name?.match(/\d+/g);
    if (!match) return acc;
    acc += Number(match[0]);
    return acc;
  }, 0);
};

export const formatCurrentBuyMoreProducts = (products: BuyMoreProducts[]) => {
  return products.map((product) => {
    const currencySymbol = product.price?.amount?.currency?.majorUnitSymbol;
    const value = product.price?.amount?.value;

    const match = product?.name?.match(/\d+/g);
    const amount = match?.[0];

    return {
      dataAmount: amount ?? '',
      price: `${currencySymbol}${value}` ?? '',
      purchaseDate: product?.productStartDate ?? '',
    };
  });
};
