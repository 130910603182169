export enum ErrorEnum {
  PURCHASE_ERROR,
  FETCH_PRODUCTS_ERROR,
  PURCHASE_TRANSACTION_ERROR,
}

export enum Resolutions {
  xsMax = 375,
  smMin = 376,
  smMax = 767,
  mdMin = 768,
  mdMax = 991,
  lgMin = 992,
  lgMax = 1199,
  xlMin = 1200,
}

export type Product = {
  id: string;
  price: number;
  amount: number;
  description: string;
  savingsText: string;
  disclosure?: string;
};

export type ServerUrls = {
  queryUrl: string;
  mutationUrl: string;
};
